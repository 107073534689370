import React, { useEffect, useState } from "react";
import "./CustomerOrderDetails.css";
import ArrowLeft from "../../Assets/User/arrow-left.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { listOfModal, tableOrderDetails } from "../../DummyArray/HiscravesData";
import { Button } from "@material-ui/core";
import ReadOnlyDetails from "../../Components/ReadOnlyDetails/ReadOnlyDetails";
import ModalContainer from "../../Components/Modal/Modal";
import ReadOnlyInput from "../../Components/ReadOnlyDetails/ReadOnlyInput";
import DataTable from "../../Components/DataTable/DataTable";
import ShippingDetails from "../../Components/ShippingDetails/ShippingDetails";
import Navbar from "../../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getCorderDetails } from "../../Actions/CustomerActions/CustomerActions";
import Loader from "../../Components/Loader/Loader";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import HelmetTitle from "../../Components/MetaData/MetaData";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import { useAlert } from "react-alert";
import TableLoader from "../../Components/Loader/TableLoader";

const CustomerOrderDetails = () => {
  const alert = useAlert();
  const { orderDetails, loading } = useSelector(
    (state) => state.customerOrderDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, customer_id } = useParams();

  const [load, setLoad] = useState(false);

  const [loadIndex, setLoadIndex] = useState(null);

  const [isIndex, setIsIndex] = useState(null);
  const [order_id, setOrder_id] = useState();
  const handleModal = (index) => {
    if (
      currentOrderDetails &&
      currentOrderDetails?.display_order_statuses !== "Pending" &&
      index === 5
    ) {
      return alert.error("You cannot Cancel This Order...");
    }
    if (
      currentOrderDetails &&
      currentOrderDetails?.display_order_statuses !== "Delivered" &&
      index === 4
    ) {
      return alert.error("You cannot Create Return For This Order...");
    }
    if (index === 0) {
      return navigate(`/tracking-details/${id}`);
    } else if (index === 10 && id !== "" && id !== null) {
      return navigate(`/ticket-details/${id}?customer=${true}`);
    } else if (index === 3) {
      return navigate(
        `/goodwill-refund/${customer_id}/${currentOrderDetails?.order_no}`
      );
    } else if (index === 100) {
      setLoad(true);
      setLoadIndex(100);
      return api
        .get(
          `${BaseURL}/agent/send-order-invoice?order_no=${currentOrderDetails?.order_no}`
        )
        .then((response) => {
          if (response && response.data?.success === true) {
            alert.success(response && response?.data?.message);
            setLoad(false);
          } else if (response && response.data?.success === false) {
            alert.error(response && response?.data?.message);
            setLoad(false);
          }
        })
        .catch((err) => {
          // console.log(err);
          alert.error(err.message);
          setLoad(false);
        });
    } else if (index === 101) {
      return setIsIndex(17);
    }
    setIsIndex(index);
    if (id) {
      setOrder_id(id);
    }
  };

  console.log("Order_Id: ", order_id);

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          order_id={id}
          create_return_customer_details={true}
          customer_id={customer_id}
          orderNo={currentOrderDetails && currentOrderDetails?.order_no}
          order_items={currentOrderDetails && currentOrderDetails?.order_items}
          payment_mode={
            currentOrderDetails && currentOrderDetails?.payment_mode
          }
          order_status={
            currentOrderDetails && currentOrderDetails?.display_order_statuses
          }
        />
      );
    }
    return null;
  };

  console.log("Order Id: ", id);

  useEffect(() => {
    if (id && id !== undefined && isIndex === null) {
      dispatch(getCorderDetails(id));
    }
  }, [dispatch, id, isIndex]);

  let currentOrderDetails = orderDetails && orderDetails?.data;

  console.log("Order Details: ", loadIndex);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  console.log(
    "IS_REPLACEMENT_ORDER: ",
    currentOrderDetails && currentOrderDetails?.display_order_statuses
  );

  let billing_last_name =
    currentOrderDetails && currentOrderDetails?.billing_lname !== null
      ? currentOrderDetails?.billing_lname
      : "";

  let return_availability = [];
  currentOrderDetails &&
    Array.isArray(currentOrderDetails?.order_items) &&
    currentOrderDetails?.order_items?.forEach((i) => {
      if (!return_availability.includes(i?.product?.id)) {
        return_availability.push(i?.product?.return_available);
      }
    });

  console.log("Return Availability: ", return_availability);
  return (
    <>
      <HelmetTitle
        title={`${
          loading
            ? "Loading..."
            : currentOrderDetails &&
              // eslint-disable-next-line
              currentOrderDetails?.order_no + " - " + "Customer Order Details"
        }`}
      />
      <Navbar searchBar={handleSearchBar} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {orderDetails && !orderDetails?.logout ? (
            <div className="home-container">
              <Link onClick={() => navigate(-1)} className="header-redirection">
                <img src={ArrowLeft} alt="" />
                <h1>Customer Orders Details</h1>
              </Link>
              <div className="list-of-modal-grid-row">
                {listOfModal &&
                  listOfModal.map((i, index) => (
                    <Button
                      onClick={() => handleModal(i.id, order_id)}
                      key={index}
                      style={{
                        backgroundColor:
                          (currentOrderDetails &&
                            currentOrderDetails?.display_order_statuses !==
                              "Pending" &&
                            index === 5) ||
                          (currentOrderDetails &&
                            currentOrderDetails?.display_order_statuses !==
                              "Delivered" &&
                            index === 4)
                            ? "#C7C7C7"
                            : i.color,
                        display:
                          currentOrderDetails?.return_qty_left === 0 &&
                          i.id === 4
                            ? "none"
                            : currentOrderDetails?.return_qty_left === 0 &&
                              i.id === 101
                            ? "none"
                            : currentOrderDetails?.is_replacement_order === 1 &&
                              i.id === 4
                            ? "none"
                            : currentOrderDetails?.is_replacement_order === 1 &&
                              i.id === 101
                            ? "none"
                            : !return_availability.includes(1) && i.id === 4
                            ? "none"
                            : !return_availability.includes(1) && i.id === 101
                            ? "none"
                            : "",
                      }}
                    >
                      {i.id === loadIndex && load ? (
                        <TableLoader />
                      ) : (
                        <>
                          <img src={i.uri} alt="" />
                          <p>{i.title}</p>
                        </>
                      )}
                    </Button>
                  ))}
              </div>
              {/* order details input */}
              <div className="order-details">
                <ReadOnlyDetails title="Order Details" />
                <div className="input-grid-order-details">
                  <ReadOnlyInput
                    value={currentOrderDetails && currentOrderDetails?.order_no}
                    label="Order No"
                    id="OrderNo"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails &&
                      new Date(currentOrderDetails?.created_at).toLocaleString()
                    }
                    label="Order Created at"
                    id="OrderCreatedat"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails &&
                      currentOrderDetails?.display_order_statuses
                    }
                    label="Order Status"
                    id="OrderStatus"
                  />
                  {/* <ReadOnlyInput
    value={orderstatus2}
    label="Order Status"
    id="OrderStatus2"
  /> */}
                </div>
              </div>
              <div className="order-details">
                <ReadOnlyDetails title="Shipping Details" />
                <ShippingDetails
                  CustomerName={
                    currentOrderDetails &&
                    currentOrderDetails?.shipping_fname !== null
                      ? currentOrderDetails?.shipping_fname
                      : // eslint-disable-next-line
                      "" + " " + currentOrderDetails?.shipping_lname !== null
                      ? currentOrderDetails?.shipping_lname
                      : ""
                  }
                  EmailId={currentOrderDetails && currentOrderDetails?.email}
                  MobileNumber={
                    currentOrderDetails && currentOrderDetails?.shipping_phone
                  }
                  Address1={
                    currentOrderDetails &&
                    currentOrderDetails?.shipping_address1
                  }
                  Address2={
                    currentOrderDetails &&
                    currentOrderDetails?.shipping_address2
                  }
                  City={
                    currentOrderDetails && currentOrderDetails?.shipping_city
                  }
                  State={
                    currentOrderDetails &&
                    currentOrderDetails?.shipping_state_name
                  }
                  Country={
                    currentOrderDetails && currentOrderDetails?.shipping_country
                  }
                  Pincode={
                    currentOrderDetails && currentOrderDetails?.shipping_pincode
                  }
                />
              </div>
              <div className="order-details">
                <ReadOnlyDetails title="Billing Details" />
                <ShippingDetails
                  CustomerName={
                    // currentOrderDetails &&
                    currentOrderDetails?.billing_fname + " " + billing_last_name
                  }
                  EmailId={
                    currentOrderDetails && currentOrderDetails?.billing_email
                  }
                  MobileNumber={
                    currentOrderDetails && currentOrderDetails?.billing_phone
                  }
                  Address1={
                    currentOrderDetails && currentOrderDetails?.billing_address1
                  }
                  Address2={
                    currentOrderDetails && currentOrderDetails?.billing_address2
                  }
                  City={
                    currentOrderDetails && currentOrderDetails?.billing_city
                  }
                  State={
                    currentOrderDetails &&
                    currentOrderDetails?.billing_state_name
                  }
                  Country={
                    currentOrderDetails && currentOrderDetails?.billing_country
                  }
                  Pincode={
                    currentOrderDetails && currentOrderDetails?.billing_pincode
                  }
                />
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  <h2>Order Items</h2>
                  <DataTable
                    header={tableOrderDetails}
                    courier_name={
                      currentOrderDetails && currentOrderDetails?.consignee
                    }
                    orderItems={
                      currentOrderDetails && currentOrderDetails?.order_items
                    }
                    tracking_no={
                      currentOrderDetails?.display_order_statuses ===
                        "Pending" ||
                      currentOrderDetails?.display_order_statuses ===
                        "Cancelled"
                        ? ""
                        : currentOrderDetails &&
                          currentOrderDetails?.tracking_no
                    }
                  />
                </div>
              </div>

              <div className="order-details">
                <ReadOnlyDetails title="Payment Details" />
                <div className="input-grid-order-details">
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.payment_mode
                    }
                    label="Payment Mode"
                    id="PaymentMode"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.payment_id
                    }
                    label="Payment Id"
                    id="PaymentId"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails &&
                      currentOrderDetails?.display_payment_statuses
                    }
                    label="Payment Status"
                    id="PaymentStatus"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.item_count
                    }
                    label="Item Count"
                    id="ItemCount"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.grand_total
                    }
                    label="Grand Total"
                    id="GrandTotal"
                    sign="₹"
                  />
                  <ReadOnlyInput
                    value={"NA"}
                    label="Coupon Discount Used"
                    id="CouponDiscountUsed"
                  />
                  <ReadOnlyInput
                    value={currentOrderDetails && currentOrderDetails?.discount}
                    label="Coupon discount"
                    id="Coupondiscount"
                    sign="₹"
                  />
                  <ReadOnlyInput
                    value={"NA"}
                    label="Normal Offer Used"
                    id="NormalOfferUsed"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails &&
                      currentOrderDetails?.global_offer?.name
                    }
                    label="Global Offer Used"
                    id="GlobalOfferUsed"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails &&
                      currentOrderDetails?.global_offer_discount
                    }
                    label="Global Offer Offer Discount"
                    id="GlobalOfferOfferDiscount"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.from_wallet
                    }
                    label="Wallet Amount Used"
                    id="WalletAmountUsed"
                    sign="₹"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.paid_amount
                    }
                    label="Payable Amount"
                    id="PayableAmount"
                    sign="₹"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.paid_amount
                    }
                    label="Paid Amount"
                    id="PaidAmount"
                    sign="₹"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.is_paid === 0
                        ? "No"
                        : "Yes"
                    }
                    label="Paid"
                    id="Paid"
                  />
                </div>
              </div>
              <div className="order-details">
                <ReadOnlyDetails title="Tracking Details" />
                <div className="input-grid-order-details">
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.consignee
                    }
                    label="Courier"
                    id="Courier"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.tracking_no
                    }
                    label="Tracking No"
                    id="TrackingNo"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.tracking_msg
                    }
                    label="Tracking Msg"
                    id="Trackingmsg"
                  />
                  <ReadOnlyInput
                    value={
                      currentOrderDetails && currentOrderDetails?.tacking_status
                    }
                    label="Tracking Status"
                    id="TrackingStatus"
                  />
                </div>
              </div>
              {currentOrderDetails &&
                currentOrderDetails?.order_items.map((i, index) => {
                  return (
                    <div key={index}>
                      {i?.return_request_data?.map((j, ix) => (
                        <div key={ix}>
                          {i?.return_data !== null && (
                            <div className="order-details">
                              <ReadOnlyDetails
                                title="Return Request Details"
                                product={j?.product_id}
                              />
                              <div className="input-grid-order-details">
                                <ReadOnlyInput
                                  value={j?.return_id}
                                  label="Return Request Id"
                                  id="ReturnRequestId"
                                />
                                <ReadOnlyInput
                                  value={new Date(
                                    j?.created_at
                                  ).toLocaleString()}
                                  label="Return Request Created At"
                                  id="ReturnRequestCreatedon"
                                />
                                <ReadOnlyInput
                                  value={
                                    j?.admin_user !== null
                                      ? j?.admin_user?.first_name +
                                        " " +
                                        j?.admin_user?.last_name
                                      : ""
                                  }
                                  label="Created by"
                                  id="Createdby"
                                />
                                <ReadOnlyInput
                                  value={j?.return_status}
                                  label="Refund Approval Status"
                                  id="ReturnStatus"
                                />
                                <ReadOnlyInput
                                  value={j?.display_return_payment_statuses}
                                  label="Refund Status"
                                  id="RefundStatus"
                                />
                                <ReadOnlyInput
                                  value={j?.return_amount}
                                  label="Refund Value"
                                  id="RefundValue"
                                  sign="₹"
                                />
                                <ReadOnlyInput
                                  value={j?.wallet_return_amount}
                                  label="Wallet Refund Value"
                                  id="walletRefundValue"
                                  sign="₹"
                                />
                                <ReadOnlyInput
                                  value={j?.rev_tracking_no}
                                  label="Return Tracking Number"
                                  id="ReturnTrackingNumber"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  );
                })}
              {renderModal()}
            </div>
          ) : (
            <NoDataFromApi
              btn={true}
              title="Something went wrong, Please Try Logout & Login Again..."
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomerOrderDetails;
