import React from "react";
import ModalContainer, { HeadingTitle } from "../../Modal/Modal";
import ReadOnlyInputs from "../../ReadOnlyInputs/ReadOnlyInputs";
import "./TicketDetails.css";
import Select from "react-select";
import { useState } from "react";
import { customStylesSKU } from "../../../DummyArray/HiscravesData";
import { Button } from "@material-ui/core";
import ChatHistory from "../../../Assets/Customer-Details/message-time.png";
import call from "../../../Assets/Customer-Details/call.png";
import frame from "../../../Assets/Customer-Details/Frame.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTeamTicketDetails,
  getTicketStatus,
  updateTicketStatus,
} from "../../../Actions/TeamLeaderActions/TeamLeaderActions";
import PaginationLoader from "../../Loader/PaginationLoader";
import NoDataFromApi from "../../NotFound/NoDataFromApi";
import { useAlert } from "react-alert";
import { UPDATE_TICKET_STATUS__RESET } from "../../../Constants/TeamLeaderConstants/TeamLeaderConstants";
import { clearErrors } from "../../../Actions/LoginActions/LoginActions";
import TableLoader from "../../Loader/TableLoader";

const TicketDetails = (props) => {
  const { ticket_id } = props;
  const { close } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const { teamTicketDetails, loading } = useSelector(
    (state) => state.teamTicketDetails
  );
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );
  const {
    isUpdated,
    message,
    loading: updateLoading,
  } = useSelector((state) => state.updateTicketStatus);

  const [status, setStatus] = useState("");
  const [ticket_status, setTicket_status] = useState();

  let teamTicketsData = teamTicketDetails && teamTicketDetails?.data;

  useEffect(() => {
    if (props.ticket_id) {
      dispatch(getTeamTicketDetails(props.ticket_id));
    }
    if (teamTicketsData && teamTicketsData?.status) {
      setStatus(teamTicketsData?.status);
    }
    // eslint-disable-next-line
  }, [dispatch, props.ticket_id]);

  //   Modal Script starts here
  const [isIndex, setIsIndex] = useState(null);
  const closeModal = () => {
    setIsIndex(null);
  };

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          ticket_id={ticket_id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (teamTicketsData && teamTicketsData?.status) {
      setStatus(teamTicketsData?.status);
    }
    dispatch(getTicketStatus());
  }, [dispatch, teamTicketsData]);

  let statusList = Allstatus?.data && Object.values(Allstatus?.data);

  const updateStatus = () => {
    if (ticket_status && props.ticket_id) {
      dispatch(updateTicketStatus(props.ticket_id, ticket_status));
    } else {
      alert.error("Please Select Status");
    }
  };

  useEffect(() => {
    if (isUpdated) {
      setTicket_status(null);
      close(null);
      alert.success(message);
      dispatch({
        type: UPDATE_TICKET_STATUS__RESET,
      });
      dispatch(clearErrors());
    }
  }, [dispatch, isUpdated, alert, message, close]);

  return (
    <>
      <HeadingTitle title="Ticket Details" closeModal={props.close} />
      {loading && statusLoading ? (
        <PaginationLoader />
      ) : (
        <>
          {teamTicketDetails && teamTicketDetails?.logout ? (
            <NoDataFromApi
              height={true}
              title="Something went wrong, Please Try Logout & Login Again......"
              btn={true}
            />
          ) : (
            <div>
              <div className="ticket-details-grid-row">
                <ReadOnlyInputs
                  label="Ticket ID"
                  id="TicketID"
                  value={teamTicketsData && teamTicketsData?.ticket_no}
                />
                <ReadOnlyInputs
                  label="Title"
                  id="Title"
                  value={teamTicketsData && teamTicketsData?.title}
                />
                <ReadOnlyInputs
                  label="Order Id"
                  id="OrderId"
                  value={teamTicketsData && teamTicketsData?.order_id}
                />
                <ReadOnlyInputs
                  label="Create Date"
                  id="CreateDate"
                  value={
                    teamTicketsData &&
                    new Date(teamTicketsData?.created_at).toLocaleString()
                  }
                />
                <ReadOnlyInputs
                  label="Last Update Date"
                  id="LastUpdateDate"
                  value={
                    teamTicketsData &&
                    new Date(teamTicketsData?.updated_at).toLocaleString()
                  }
                />
                <ReadOnlyInputs
                  label="Language"
                  id="Language"
                  value={teamTicketsData && teamTicketsData?.language}
                />
              </div>
              <div className="another-priority-grid-row">
                <ReadOnlyInputs
                  label="Priority"
                  id="Priority"
                  value={teamTicketsData && teamTicketsData?.priority}
                />
                <ReadOnlyInputs
                  label="Remarks"
                  id="Remarks"
                  value={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry......"
                  }
                />
              </div>
              <div className="ticket-details-grid-row">
                <div className="inputs top">
                  <label htmlFor="SelectProduct">Status</label>
                  <Select
                    placeholder={`${status !== "" ? status : "Status"}`}
                    options={
                      statusList?.length > 0 &&
                      statusList.map((option, index) => ({
                        value: option,
                        label: option,
                        index: index + 1,
                      }))
                    }
                    className="select"
                    id="SelectProduct"
                    value={status}
                    styles={customStylesSKU}
                    onChange={(selectedOption) =>
                      setStatus(selectedOption.value) +
                      setTicket_status(selectedOption.index)
                    }
                  />
                </div>
              </div>

              <div className="button-grid-row">
                <Button onClick={() => handleModal(1)}>
                  <img src={ChatHistory} alt="" />
                  &nbsp;<p>View chat History</p>
                </Button>
                {/* debugger */}
                <Button onClick={() => handleModal(9, ticket_id)}>
                  <img src={call} alt="" />
                  &nbsp;<p>View Call History</p>
                </Button>
                <Button onClick={updateStatus}>
                  {updateLoading ? (
                    <TableLoader />
                  ) : (
                    <>
                      <img src={frame} alt="" />
                      &nbsp;<p>Update</p>
                    </>
                  )}
                </Button>
              </div>
              {renderModal()}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TicketDetails;
