import React, { useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  assignNewTeamTickets,
  getTeamAllAgentsListForAssignee,
} from "../../../Actions/TeamLeaderActions/TeamLeaderActions";
import PaginationLoader from "../../Loader/PaginationLoader";
import "./AssignedAgents.css";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import { ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET } from "../../../Constants/TeamLeaderConstants/TeamLeaderConstants";
import { clearErrors } from "../../../Actions/LoginActions/LoginActions";
import NoDataFromApi from "../../NotFound/NoDataFromApi";

const AssignedAgents = (props) => {
  const alert = useAlert();
  const { close } = props;
  const dispatch = useDispatch();
  const { allagentsList, loading: agentLoading } = useSelector(
    (state) => state.agentList
  );

  const { assignee, loading: assignLoading } = useSelector(
    (state) => state.assignNewTicketsTeam
  );

  let agentList = allagentsList && allagentsList?.data;

  const [buttonLoading, setButtonLoading] = useState(null);

  const [isHit, setIsHit] = useState(false);

  const handleAssignTicket = (agent_id, index) => {
    setIsHit(true);
    setButtonLoading(index);
    if (props.ticket_id && agent_id) {
      dispatch(assignNewTeamTickets(props.ticket_id, agent_id));
    } else {
      alert.error("Something Went Wrong, Try Refreshing The Page...");
    }
  };

  useEffect(() => {
    dispatch(getTeamAllAgentsListForAssignee());
  }, [dispatch]);

  useEffect(() => {
    if (assignee && assignee?.success === false && isHit) {
      alert.error(assignee && assignee?.message);
      dispatch(clearErrors());
      close(null);
      setIsHit(false);
      dispatch({
        type: ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET,
      });
    } else if (assignee && assignee?.success === true && isHit) {
      alert.success(assignee && assignee?.message);
      dispatch(clearErrors());
      close(null);
      setIsHit(false);
      dispatch({
        type: ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET,
      });
    }
  }, [dispatch, assignee, alert, close, isHit]);

  console.log(agentList, " Agent List");
  console.log("Id: ", assignee);

  return (
    <>
      <HeadingTitle title="Select Agent" closeModal={props.close} />
      {agentLoading ? (
        <PaginationLoader />
      ) : (
        <div className="agent-list">
          {Array.isArray(agentList) && agentList?.length > 0 ? (
            agentList?.map((i, index) => (
              <div key={i.id} className="agent-flex-row">
                <div className="agent-list-col">
                  {i?.avatar_location ? (
                    <img src={i?.avatar_location} alt="" />
                  ) : (
                    <div className="user-name-assign">
                      <p>
                        {i?.full_name?.slice(0, 1)}
                        {i?.full_name?.split(" ")[1]?.charAt(0)}
                      </p>
                    </div>
                  )}
                  <p>{i.full_name}</p>
                </div>
                <div className="agent-list-col">
                  <div className="asiggned">
                    <Button
                      onClick={() => handleAssignTicket(i.id, index)}
                      disabled={buttonLoading === index}
                    >
                      {buttonLoading === index && assignLoading
                        ? "Please Wait..."
                        : "Assign"}
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFromApi width_new={true} title="No Agents Found..." />
          )}
        </div>
      )}
    </>
  );
};

export default AssignedAgents;
