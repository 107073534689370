import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import Select from 'react-select';
import { RadiuscustomStyles, bankArr } from '../../DummyArray/HiscravesData';

const CardHeadline = (props) => {
    const [bankData, setBankData] = useState('');

    const handleDateChange = (selectedOption) => {
        setBankData(selectedOption)
    }
    return (
        <div className="customer-details-row">
            <div>
                <h3 className='cust-details'>{props.title}</h3>
            </div>
            <div>
                {
                    props.btnTitle && <Button variant='text' className='edit-btn'>{props.btnTitle}</Button>
                }
                {
                    props.select && <Select placeholder={`${bankData !== '' ? bankData : bankArr[0]}`} options={bankArr.map((option) => ({
                        value: option,
                        label: option
                    }))}
                        value={bankData}
                        styles={RadiuscustomStyles}
                        onChange={handleDateChange}
                    />
                }
            </div>
        </div>
    )
}

export default CardHeadline