import React from "react";
import "./ReadOnlyInput.css";

const ReadOnlyInput = (props) => {
  return (
    <div className="input-details-readonly">
      <label htmlFor={props.id}>{props.label}</label> <br />
      <input
        type="text"
        id={props.id}
        readOnly
        defaultValue={props.value}
      />
    </div>
  );
};

export default ReadOnlyInput;
