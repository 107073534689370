import React, { useEffect, useState } from "react";
import "./Tracking.css";
import { useDispatch, useSelector } from "react-redux";
import { getOrderTracking } from "../../../Actions/CustomerActions/CustomerActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Arrow from "../../../Assets/Agents-Img/arrow-left.png";
import Loader from "../../Loader/Loader";
import NoDataFromApi from "../../NotFound/NoDataFromApi";
import HelmetTitle from "../../MetaData/MetaData";

const Tracking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ordertracking, loading } = useSelector((state) => state.orderTracked);
  const { order_id } = useParams();

  console.log("Order Id: ", order_id);

  useEffect(() => {
    if (order_id && order_id !== "") {
      dispatch(getOrderTracking(order_id));
    }
  }, [dispatch, order_id]);

  let tracking =
    ordertracking && ordertracking?.data && ordertracking?.data?.orderTracks;

  let courier_tracking_scans =
    ordertracking &&
    ordertracking?.data &&
    ordertracking?.data?.courier_tracking_details?.scans;

  console.log("Tracking Data: ", ordertracking);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <HelmetTitle title={`${loading ? "Loading..." : "Tracking Details"}`} />
      <Navbar zindex={true} searchBar={handleSearchBar} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {ordertracking?.logout ? (
            <NoDataFromApi
              width_new={true}
              title={
                "Something went wrong, Please Try Logout & Login Again......"
              }
            />
          ) : (
            <div className="home-container">
              <Link
                onClick={(e) => handleRedirect(e)}
                className="agent-profile-head"
              >
                <img src={Arrow} alt="" />
                <h1>Tracking Details</h1>
              </Link>
              <div className="distribution-tracking-grid-row">
                <div>
                  <h4 className="heading-tracking-title">Tracking</h4>
                  {tracking &&
                    tracking.map((i, index) => {
                      return (
                        <div className="tracking-grid-row" key={index}>
                          <div
                            className={`${
                              index === tracking.length - 1
                                ? "remove-dotted dotted"
                                : i.show === true
                                ? "select-dotted dotted"
                                : "deselect-dotted dotted"
                            }`}
                          >
                            <div
                              className="icon icon-new"
                              style={{
                                backgroundColor:
                                  i.show === true ? "#62DE9B38" : "#D4D4D438",
                              }}
                            >
                              <div
                                className="bg-icon"
                                style={{
                                  backgroundColor:
                                    i.show === true ? "#62DE9B" : "#D7D7D7",
                                }}
                              ></div>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                          <div className="content-col">
                            <h4>{i.status}</h4>
                            <p>
                              {new Date(i.date).toLocaleString() ===
                              "Invalid Date"
                                ? "We Will Update Shortly"
                                : new Date(i.date).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  <h4 className="heading-tracking-title">Courier Tracking</h4>
                  {courier_tracking_scans &&
                  courier_tracking_scans.length > 0 ? (
                    courier_tracking_scans.map((i, index) => {
                      return (
                        <div
                          className="tracking-grid-row min-width-container"
                          key={index}
                        >
                          <div>
                            <div
                              className="date-update"
                              dangerouslySetInnerHTML={{
                                __html:
                                  new Date(i.updated_at).toLocaleString() ===
                                  "Invalid Date"
                                    ? "We Will Update Shortly"
                                    : new Date(i.updated_at)
                                        .toLocaleString()
                                        .replace(/,/g, "<br>"),
                              }}
                            />
                          </div>
                          <div
                            className={`${
                              index === courier_tracking_scans.length - 1
                                ? "remove-dotted dotted"
                                : show === true
                                ? "select-dotted dotted"
                                : "deselect-dotted dotted"
                            }`}
                          >
                            <div
                              className="icon icon-new"
                              style={{
                                backgroundColor:
                                  show === true ? "#62DE9B38" : "#D4D4D438",
                              }}
                            >
                              <div
                                className="bg-icon"
                                style={{
                                  backgroundColor:
                                    show === true ? "#62DE9B" : "#D7D7D7",
                                }}
                              ></div>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                          <div className="content-col">
                            <h4>{i.status}</h4>
                            <p style={{ fontSize: "14px" }}>{i.description}.</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <NoDataFromApi width_small={true} mb={true} height={true} title="No Data To Show..." />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Tracking;
