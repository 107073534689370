import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Customer.css";
import { header } from "../../DummyArray/HiscravesData";
import CardHeadline from "../../Components/CardHeadline/CardHeadline";
import UserDetails from "../../Components/UserDetails/UserDetails";
import DataTable from "../../Components/DataTable/DataTable";
import { Button } from "@material-ui/core";
import SwiperCards from "../../Components/Swiper/Swiper";
import Tracking from "../../Assets/Slider/routing.png";
import Ticket from "../../Assets/Slider/ticket.png";
import Syncing from "../../Assets/Slider/syncing.png";
import Refund from "../../Assets/Slider/refund.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GroupedBar from "../../Components/Bar/GroupedBar";
import ModalContainer from "../../Components/Modal/Modal";
import Navbar from "../../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountdetailsListingActions,
  getAllPriorityActions,
  getCustomerDashboardChartActions,
  getCustomerOrder,
  getCustomerTickets,
  getCustomerTilesAction,
  synchronizedOrder,
} from "../../Actions/CustomerActions/CustomerActions";
import Loader from "../../Components/Loader/Loader";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import { useRef } from "react";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import PaginationLoader from "../../Components/Loader/PaginationLoader";
import TableLoader from "../../Components/Loader/TableLoader";
import { useAlert } from "react-alert";
import { ORDER_SYNCHRONIZED_RESET } from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import CardHeader from "../../Components/CardHeader/CardHeader";
import { customStyles, dateArray } from "../../DummyArray/DashboardArrData";
import HelmetTitle from "../../Components/MetaData/MetaData";
import Download_Icon from "../../Assets/document-download.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Select from "react-select";
import { getTicketStatus } from "../../Actions/TeamLeaderActions/TeamLeaderActions";

const CustomerDashboard = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { customerTilesdata, loading } = useSelector((state) => state.customer);
  const { customerOrder, loading: customerOrderLoading } = useSelector(
    (state) => state.customerOrder
  );
  const { customerTickets, loading: TicketsLoading } = useSelector(
    (state) => state.customerTickets
  );

  const { customer_dashboard_chart } = useSelector(
    (state) => state.dashboardChart
  );

  const {
    synchronized,
    isSynchronized,
    loading: synchronizedLoading,
  } = useSelector((state) => state.sync);

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  let phone_number_params = searchParams.get("phone_number");
  const location = useLocation();
  const call_data_obj = location?.state?.call_data;
  const [date, setDate] = useState(dateArray && dateArray[3]?.id);

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");

  const [customer_id, setCustomer_id] = useState("");

  let customerTiles = customerTilesdata && customerTilesdata?.data?.tiles;
  let categoriesBought =
    customerTilesdata && customerTilesdata?.data?.categories_customer_bought;
  let customerInfo = customerTilesdata && customerTilesdata?.data?.customer;
  let customerAddress =
    customerTilesdata && customerTilesdata?.data?.customer_address;

  const DashboardData = [
    {
      id: 1,
      count: customerTiles && customerTiles?.total_order_value,
      title: "Total Orders Value",
      color: "#D9ACF5",
    },
    {
      id: 2,
      count: customerTiles && customerTiles?.total_orders,
      title: "Total Orders",
      color: "#ABDBF5",
    },
    {
      id: 3,
      count: customerTiles && customerTiles?.total_return_orders,
      title: "Total Returns Order",
      color: "#FFCCDE",
    },
    {
      id: 4,
      count: customerTiles && customerTiles?.return_percentage + "%",
      title: "Total Returns Percentage",
      color: "#F5C4C4",
    },
    {
      id: 5,
      count: customerTiles && customerTiles?.total_canceled_orders,
      title: "Total Cancel",
      color: "#ABF5BC",
    },
    {
      id: 6,
      count: customerTiles && customerTiles?.order_avg_frequency_in_days,
      title: "Order Average Frequency In Days",
      color: "#ABC0F5",
    },
    {
      id: 7,
      count:
        customerTiles &&
        customerTiles?.avg_reviews !== "" &&
        customerTiles?.avg_reviews !== null
          ? customerTiles?.avg_reviews
          : 0,
      title: "Average Feedback Given",
      color: "#F5D7AB",
    },
    {
      id: 8,
      count: customerTiles && customerTiles?.total_ref_earn,
      title: "Total Promo Code Earning",
      color: "#EB96B8",
    },
    {
      id: 9,
      count: customerTiles && customerTiles?.customer_age_on_site,
      title: "Customer Age on Site",
      color: "#FFD5B6",
    },
    {
      id: 10,
      count: "₹" + customerTiles && customerTiles?.total_wallet_amount_used,
      title: "Total Wallet Amount Used",
      color: "#EC9595",
    },
    {
      id: 11,
      count: "₹" + customerTiles && customerTiles?.total_coupon_amount_used,
      title: "Total Coupons Amount Used",
      color: "#75E3E3",
    },
    {
      id: 12,
      count: customerTiles && customerTiles?.total_paid_percentage + "%",
      title: "Total Paid",
      color: "#ED7AE8",
    },
    {
      id: 13,
      count: customerTiles && customerTiles?.avg_service_feedback_score,
      title: "Services avg. feedback Score",
      color: "#E1ECC8",
    },
    {
      id: 14,
      count:
        customerTiles && customerTiles?.avg_conversation_feedback_score + "%",
      title: "Conversation avg. feedback",
      color: "#E4D0D0",
    },
    {
      id: 15,
      count: customerTiles && customerTiles?.total_replacement_orders,
      title: "Total Replacement Orders",
      color: "#D6D46D",
    },
    {
      id: 16,
      count: customerTiles && customerTiles?.sla_breach_count,
      title: "SLA Breach Count",
      color: "#EADFB4",
    },
    {
      id: 17,
      count: customerTiles && customerTiles?.sla_breach_age_avg,
      title: "SLA Breach Age Avg.",
      color: "#FFEAA7",
    },
  ];

  const userDetails = useMemo(() => {
    const object = {
      avatar: customerInfo && customerInfo?.avatar_location,
      UserID: customerInfo && customerInfo?.id,
      CustomerName: customerInfo && customerInfo?.full_name,
      CustomerEmailID: customerInfo && customerInfo?.email,
      MobileNumber: customerInfo && customerInfo?.phone,
      JoiningDate:
        customerInfo && new Date(customerInfo?.created_at).toLocaleString(),
      Address: customerAddress
        ? customerAddress?.shipping_address1 +
          ", " +
          customerAddress?.shipping_city +
          ", " +
          customerAddress?.shipping_pincode +
          ", " +
          customerAddress?.shipping_state_name
        : "No Address",
    };
    return object;
  }, [customerInfo, customerAddress]);

  const [isIndex, setIsIndex] = useState(null);
  const [order_id, setorder_id] = useState("");

  const [is_replacement_order, setIsReplacement_order] = useState();
  const [payment_mode, setpayment_mode] = useState();
  const [order_status, setorder_status] = useState();

  const handleModal = (
    index,
    id,
    status,
    replacement,
    pay_mode,
    order_status
  ) => {
    if (index === 0) {
      return navigate(`/tracking-details/${id}`);
    }
    if (index === 10) {
      return navigate(
        `/ticket-details/${id}?customer=${true}&customer_id=${phone_number_params}`
      );
    }
    if (index === 2) {
      return navigate(`/goodwill-refund/${customer_id}/${id}`);
    }
    if (index === 5 && status !== "Pending") {
      return alert.error("You cannot Cancel This Order...");
    }
    setIsIndex(index);
    setorder_id(id);
    setIsReplacement_order(replacement);
    setpayment_mode(pay_mode);
    setorder_status(order_status);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          order_id={order_id}
          customer_id={customer_id}
          data_call={call_data_obj && call_data_obj}
          is_replacement_order={is_replacement_order}
          payment_mode={payment_mode}
          order_status={order_status}
        />
      );
    }
    return null;
  };

  const handleSearchBar = (search) => {
    phone_number_params = search;
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  useEffect(() => {
    if (phone_number_params) {
      dispatch(getCustomerTilesAction(phone_number_params));
    }
  }, [dispatch, phone_number_params]);

  useEffect(() => {
    if (customerInfo && customerInfo?.id) {
      setCustomer_id(customerInfo?.id);
    }
  }, [dispatch, customerInfo]);

  useEffect(() => {
    if (customerInfo?.id && customerInfo?.id !== "") {
      dispatch(getCustomerOrder(customerInfo?.id, 6));
      dispatch(getCustomerTickets(customerInfo?.id, 10));
    }
  }, [dispatch, customerInfo?.id]);

  // load More orders
  const [ordersList, setOrdersList] = useState([]);
  const [page, setPage] = useState(1);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const pageRef = useRef(page);
  let per_page = 6;

  const loadMore = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    let page = pageRef.current;
    setPaginationLoader(true);
    api
      .get(
        `${BaseURL}/agent/customer-orders?customer_id=${customer_id}&per_page=${per_page}&page=${page}`
      )
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          setHasNextPage(false);
          // console.log('0')
          setPaginationLoader(false);
        } else {
          const mergedAgents = [...ordersList, ...response?.data?.data];
          const uniqueArr = Array.from(
            new Set(mergedAgents.map((obj) => JSON.stringify(obj)))
          ).map((str) => JSON.parse(str));
          setOrdersList(uniqueArr);
          setPaginationLoader(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        alert.error(err.message);
        setPaginationLoader(false);
      });
  };

  useEffect(() => {
    if (customerOrder && customerOrder?.data) {
      setOrdersList(customerOrder && customerOrder?.data);
    }
  }, [customerOrder]);

  // Customer Tickets Pagination
  const [customerTicketsList, setCustomerTicketsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Tpage, setTPage] = useState(1);

  let per_pages = 10;

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);
  const [total_page, setTotal_page] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const [status, setStatus] = useState("");
  const [display_status, setDisplay_status] = useState("");

  const [priority, setPriority] = useState("");
  const [display_priority, setDisplay_priority] = useState("");

  const [loading_filters, setLoading_filters] = useState(false);

  const fetchTickets = useCallback(
    (page, newStatus, newPriority, sec) => {
      if (!sec) {
        if (
          (newStatus !== "" && newStatus !== null && newStatus !== undefined) ||
          (newPriority !== "" &&
            newPriority !== null &&
            newPriority !== undefined)
        ) {
          setLoading_filters(true);
        }
      }
      setIsLoading(true);
      let api_url_NewX = `${BaseURL}/agent/customer-tickets?customer_id=${customer_id}&page=${page}&per_page=${per_pages}`;

      if (newStatus !== undefined && newStatus !== null) {
        api_url_NewX += `&status=${newStatus}`;
      }

      if (
        // newPriority !== "" &&
        newPriority !== undefined &&
        newPriority !== null
      ) {
        api_url_NewX += `&priority=${newPriority}`;
      }
      api
        .get(api_url_NewX)
        .then((response) => {
          // if (response && response?.data?.data?.length > 0) {
          setTotal_page(response && response?.data?.total_page);
          setTotal_entries(response && response?.data?.total);
          // }
          setCustomerTicketsList(response && response?.data?.data);
          setFirst_item(response?.data && response?.data?.first_item);
          setLast_item(response?.data && response?.data?.last_item);
          setIsLoading(false);
          setLoading_filters(false);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          alert.error(err.message);
          setIsLoading(false);
          setLoading_filters(false);
        });
    },
    [customer_id, per_pages, alert]
  );

  const handlePageClick = useCallback(
    (page) => {
      setTPage(page);
      fetchTickets(page, status, priority, true);
    },
    [fetchTickets, priority, status]
  );

  let maxVisibilePages = 5;

  const [VisiblePages, setVisibilePages] = useState([]);

  const renderPagination = useCallback(() => {
    if (customerTickets && customerTickets?.total_page !== Tpage) {
      setRight_icon(false);
    }
    if (total_page && total_page !== Tpage) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page && total_page !== "" ? total_page : customerTickets?.total_page
    );

    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = Tpage - pageOffset;
    let endpage = Tpage + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={Tpage === i ? "active-index" : "page-numbers"}
        >
          {Tpage === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    Tpage,
    customerTickets,
    isLoading,
    handlePageClick,
    total_page,
    maxVisibilePages,
  ]);

  const fetch = useCallback(() => {
    customerTickets && setCustomerTicketsList(customerTickets?.data);
  }, [customerTickets]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  console.log("Customer Tickets: ", ordersList);

  const [isIndexIn, setIsIndexIn] = useState();
  const syncHandle = (order, index) => {
    setIsIndexIn(index);
    if (order && order !== "") {
      dispatch(synchronizedOrder(order));
    }
  };

  useEffect(() => {
    if (isSynchronized) {
      alert.success(synchronized);
      dispatch({
        type: ORDER_SYNCHRONIZED_RESET,
      });
    }
  }, [isSynchronized, alert, synchronized, dispatch]);

  const [filterTrue, setFilterTrue] = useState(false);
  const [loadingTrue, setLoadingTrue] = useState(false);

  const handleIssueOrderChart = async (children) => {
    setFilterTrue(true);
    setLoadingTrue(true);
    setDate(children.id);
    await dispatch(getCustomerDashboardChartActions(customer_id, children.id));
    setLoadingTrue(false);
  };

  // 164 * 197  && 152 * 183 8700896910

  useEffect(() => {
    if (customer_id && customer_id !== "" && !filterTrue) {
      dispatch(getCustomerDashboardChartActions(customer_id, date));
    }
  }, [dispatch, customer_id, filterTrue, date]);

  let customer_chart =
    customer_dashboard_chart && customer_dashboard_chart?.data;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const updated_customer_chart =
    Array.isArray(customer_chart) && customer_chart?.length > 0
      ? customer_chart.map((i) => {
          const monthNumber = i.month;
          const monthname = monthNames[monthNumber - 1];

          return {
            ...i,
            month: monthname,
          };
        })
      : [];

  // account details list state
  const { account_details_list, loading: account_details_loading } =
    useSelector((state) => state.customer_account_details_list);

  useEffect(() => {
    if (customer_id !== "" && customer_id !== null) {
      dispatch(AccountdetailsListingActions(customer_id));
    }
  }, [customer_id, dispatch]);

  console.log("Account Details: ", account_details_list);

  let vpa = account_details_list && account_details_list?.data?.vpa;
  let bank_account =
    account_details_list && account_details_list?.data?.bank_accounts;

  console.log("UPI ID: ", vpa);
  console.log("Bank Account: ", bank_account);

  const handleAcccount = () => {
    navigate(`/account-details/${customer_id}`);
  };

  const handleToggle = () => {
    if (isIndex === 13) {
      setIsIndex(null);
    } else if (isIndex === null) {
      setIsIndex(13);
    }
  };

  useEffect(() => {
    if (call_data_obj) {
      if (Object.keys(call_data_obj && call_data_obj).length !== 0) {
        setIsIndex(13);
      }
    }
  }, [call_data_obj]);

  console.log("Customer Dashboard: ", ordersList);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      let apiUrl_newX = `${BaseURL}/agent/export-customer-tickets?customer_id=${customer_id}`;
      if (status !== "") {
        apiUrl_newX += `&status=${status}`;
      }

      if (priority !== "") {
        apiUrl_newX += `&priority=${priority}`;
      }

      const response = await api.get(apiUrl_newX);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoadingCsv(false);
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoadingCsv(false);
    }
  };

  const handleDecrement = () => {
    if (Tpage === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setTPage(Tpage - 1);
      fetchTickets(Tpage - 1, status, priority, true);
    }
  };
  const handleIncrement = () => {
    if (
      (customerTickets && customerTickets?.total_page !== Tpage) ||
      (total_page && total_page !== Tpage)
    ) {
      // setPage(page);
      setRight_icon(false);
      setTPage(Tpage + 1);
      // if (Tpage >= total_page) {
      //   return;
      // } else {
      fetchTickets(Tpage + 1, status, priority, true);
      // }
    } else {
      setRight_icon(true);
    }
  };

  console.log(customerTickets?.total_page, Tpage);

  useEffect(() => {
    if (customerTickets && customerTickets?.total_page !== Tpage) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [customerTickets, Tpage]);

  useEffect(() => {
    if (total_page && total_page !== Tpage) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, Tpage]);

  // console.log(error, "ERROR");

  // filter table component starts here

  // filter for all tickets

  useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  // get All Status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  let statusList = Allstatus?.data ? Object.values(Allstatus?.data) : [];

  if (
    Array.isArray(statusList) &&
    statusList?.length > 0 &&
    !statusList.includes("All")
  ) {
    statusList?.splice(0, 0, "All");
  }

  let priority_list_arr =
    Array.isArray(priority_list?.data) && priority_list?.data?.length > 0
      ? priority_list?.data
      : [];

  if (
    Array.isArray(priority_list?.data) &&
    priority_list?.data?.length > 0 &&
    !priority_list_arr?.includes("All")
  ) {
    priority_list_arr?.splice(0, 0, "All");
  }

  console.log("Status: ", priority_list_arr);

  const handleStatus = (selectedOption) => {
    setTPage(1);
    setStatus(selectedOption?.id);
    setDisplay_status(selectedOption?.value);
  };

  const handlePriority = (selectedOption) => {
    // setnewTicketLoading(false);
    setTPage(1);
    setPriority(selectedOption?.index);
    setDisplay_priority(selectedOption?.value);
  };

  useEffect(() => {
    if (status !== null && status !== undefined) {
      fetchTickets(Tpage, status, priority);
    } else if (priority !== null && priority !== undefined) {
      fetchTickets(Tpage, status, priority);
    }
  }, [fetchTickets, priority, Tpage, status]);
  // filter table component ends here

  console.log("PRIORITY: ", priority);

  console.log("TOTAL_PAGE: ", customerTilesdata?.data?.repeated);

  return (
    <>
      <HelmetTitle
        title={`${
          loading || customerOrderLoading || TicketsLoading
            ? "Loading..."
            : userDetails &&
              // eslint-disable-next-line
              userDetails.CustomerName + " - " + "Profile Details"
        }`}
      />
      <Navbar
        loaded={true}
        searchValue={phone_number_params}
        searchBar={handleSearchBar}
      />
      {loading || TicketsLoading ? (
        <Loader />
      ) : (
        <>
          {customerTilesdata?.message !== "Customer details not found." &&
          !customerTilesdata?.logout ? (
            <div className="home-container">
              {call_data_obj && (
                <>
                  {Object.keys(call_data_obj && call_data_obj)?.length ===
                  0 ? null : (
                    <div className="call-popup-cta">
                      <Button onClick={() => handleToggle()}>
                        Call Details
                      </Button>
                    </div>
                  )}
                </>
              )}
              <Link onClick={() => navigate(-1)} className="agent-profile-head">
                <img src={Arrow} alt="" />
                <h1>Go Back</h1>
              </Link>
              {/* dashboard count */}
              <h1>Customer Dashboard</h1>
              <div className="grid-box-row">
                {DashboardData &&
                  DashboardData.map((i) => (
                    <div
                      className="data-display"
                      key={i.id}
                      style={{ backgroundColor: i.color }}
                    >
                      <p>{i.count}</p>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>
              {/* dashboard count */}

              {/* user info */}
              <div className="user-info-grid-row">
                <div>
                  <CardHeadline title="Customer Details" />
                  {/* user-details */}
                  {/* gradient tag */}
                  {customerTilesdata?.data?.repeated &&
                  customerTilesdata?.data?.repeated !== null ? (
                    <div className="tag">
                      <div className="triangle"></div>
                      <p>Repeated</p>
                    </div>
                  ) : null}
                  <UserDetails userDetails={userDetails} />
                </div>
                <div>
                  <CardHeadline title="Categories Bought" />
                  {/* Categories map */}
                  <div className="categories-customer-bought">
                    {categoriesBought && categoriesBought?.length > 0
                      ? categoriesBought.map((i, index) => (
                          <div className="category" key={index}>
                            <p className="category-text">{i.name}</p>
                            <p className="count-text">{i.product_count}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div>
                  <CardHeadline title="Account Details" />
                  {account_details_loading ? (
                    <PaginationLoader />
                  ) : (
                    <>
                      <div className="upi-id-text">
                        {vpa && vpa?.length > 0
                          ? vpa.map((i) => (
                              <div key={i.id}>
                                <p className="upi-id-col">
                                  UPI ID&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp;
                                  <span>{i.upi_id}</span>
                                </p>
                              </div>
                            ))
                          : null}
                      </div>
                      <hr className="divider-hr" />
                      <div className="cta-update-bank">
                        <h3>Bank Details</h3>
                        {bank_account && bank_account?.length > 0
                          ? bank_account.map((i, index) => (
                              <div
                                className="bank-account-details-list"
                                key={i.id}
                              >
                                <h4>Account {index + 1}</h4>
                                <div className="account-details">
                                  <span>Account Holder Name</span>{" "}
                                  <span>:</span> <span>{i.account_name}</span>
                                </div>
                                <div className="account-details">
                                  <span>Account Number</span> <span>:</span>{" "}
                                  <span>{i.account_no}</span>
                                </div>
                                <div className="account-details hide-border">
                                  <span>IFSC Code</span> <span>:</span>{" "}
                                  <span>{i.ifsc}</span>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                      <div className="cta-update-banks">
                        <Button onClick={handleAcccount}>Update</Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* user info */}
              <div className="bar-wrapper">
                <CardHeader
                  BarGraph={handleIssueOrderChart}
                  para="Statistics"
                  title="Orders details"
                  dropdown={true}
                  showOldData={true}
                />
                {loadingTrue ? (
                  <PaginationLoader />
                ) : (
                  <div>
                    {updated_customer_chart &&
                    updated_customer_chart?.length > 0 ? (
                      <GroupedBar
                        order_chart={
                          updated_customer_chart && updated_customer_chart
                        }
                      />
                    ) : (
                      <NoDataFromApi
                        title="No Data To Show, Try Changing Filter Dropdown..."
                        height={true}
                        width_small={true}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* grouped chart */}

              {/* swiper */}
              <p className="swiper-statistics">Statistics</p>
              <h3 className="swiper-order">Current Order</h3>
              {customerOrderLoading ? (
                <PaginationLoader />
              ) : (
                <>
                  <div className="current-order-grid-row">
                    {Array.isArray(ordersList) && ordersList?.length > 0
                      ? ordersList.map((i, index) => (
                          <div key={index} className="current-Order-col">
                            <div
                              className={
                                i.is_replacement_order === 1
                                  ? "replacement-container"
                                  : i.is_return_pickup_available === 0 &&
                                    "pickup-container"
                              }
                            >
                              {/* {i.is_replacement_order === 1 &&
                          i.is_return_pickup_available ? (
                            <p>
                              {"Replacement Order" +
                                "/ " +
                                "Return pickup not available"}
                            </p>
                          ) : i.is_replacement_order === 1 ? (
                            <p>Replacement Order</p>
                          ) : i.is_return_pickup_available ? (
                            <p>Return pickup not available</p>
                          ) : null} */}
                              {i.is_return_pickup_available === 0 ? (
                                <p>Return pickup not available</p>
                              ) : (
                                i.is_replacement_order === 1 && (
                                  <p>Replacement Order</p>
                                )
                              )}
                            </div>
                            <Link
                              to={`/customer-order-details/${i.id}/${customer_id}`}
                              className="inner-grid-row"
                            >
                              <div>
                                <SwiperCards Slider={i.items_images} />
                              </div>
                              <div>
                                <div className="slider-details">
                                  <p>Order Number</p>
                                  <p
                                    className={`${
                                      !i.order_number ? "order_no_sec" : ""
                                    }`}
                                  >
                                    {i.order_number}
                                  </p>
                                </div>
                                <div className="slider-details">
                                  <p>Order Created at</p>
                                  <p>
                                    {i?.created_at !== null
                                      ? new Date(
                                          i?.created_at
                                        ).toLocaleDateString()
                                      : "No Date"}
                                  </p>
                                </div>
                                <div className="slider-details">
                                  <p>Total Qty</p>
                                  <p
                                    className={`${
                                      !i.items_count ? "order_no_sec" : ""
                                    }`}
                                  >
                                    {i.items_count}
                                  </p>
                                </div>
                                <div className="slider-details">
                                  <p>Total Order Value</p>
                                  <p>{i.order_value}</p>
                                </div>
                                <div className="slider-details">
                                  <p>Tracking Number</p>
                                  {i.tracking_number ? (
                                    <p>{i.tracking_number}</p>
                                  ) : (
                                    <p style={{ marginBottom: "22px" }}></p>
                                  )}
                                </div>
                                <div className="side-flex">
                                  <div>
                                    <p>Courier Name</p>
                                    <p>{i.delivery_partner}</p>
                                  </div>
                                  <div>
                                    <p>Status</p>
                                    <p>
                                      {i?.order_status?.length > 10
                                        ? i?.order_status?.slice(0, 10) + "..."
                                        : i?.order_status}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            <div className="btn-groups">
                              <div>
                                <Button
                                  onClick={() => handleModal(0, i.id)}
                                  variant="outlined"
                                >
                                  <img src={Tracking} alt="" />
                                  &nbsp; Tracking
                                </Button>
                              </div>
                              <div>
                                <Button
                                  onClick={() => handleModal(10, i.id)}
                                  variant="outlined"
                                >
                                  <img src={Ticket} alt="" />
                                  &nbsp; Ticket Log
                                </Button>
                              </div>
                              <div>
                                <Button
                                  onClick={() => syncHandle(i.id, index)}
                                  variant="outlined"
                                >
                                  {synchronizedLoading &&
                                  isIndexIn === index ? (
                                    <TableLoader />
                                  ) : (
                                    <>
                                      <img src={Syncing} alt="" />
                                      &nbsp; Syncing
                                    </>
                                  )}
                                </Button>
                              </div>
                              <div>
                                <Button
                                  className="refund-dia"
                                  onClick={() => handleModal(2, i.order_number)}
                                  variant="outlined"
                                >
                                  <img src={Refund} alt="" />
                                  &nbsp; Refund
                                </Button>
                              </div>
                            </div>
                            <div
                              onClick={() =>
                                handleModal(
                                  5,
                                  i.id,
                                  i.order_status,
                                  i.is_replacement_order,
                                  i.payment_mode,
                                  i.order_status
                                )
                              }
                              className="cancel-btn"
                            >
                              <Button
                                style={{
                                  backgroundColor:
                                    i.order_status !== "Pending" ||
                                    i.order_status === "Cancelled"
                                      ? "#C7C7C7"
                                      : "#ff0000",
                                }}
                              >
                                {i.order_status === "Pending"
                                  ? "Cancel Order"
                                  : "Cancelled"}
                              </Button>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>

                  {paginationLoader ? (
                    <PaginationLoader />
                  ) : (
                    <div className="View-more-orders-btn">
                      {hasNextPage && (
                        <Button variant="contained" onClick={() => loadMore()}>
                          VIEW MORE
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}

              <div
                className="table-wrapper-pagination"
                style={{ marginTop: customerOrderLoading && "30px" }}
              >
                <div className="table-container">
                  <div className="header-text-dropdown">
                    <h3>Tickets</h3>
                    {/* <Button onClick={downloadCSV} className="export-csv-cta">
                      {loadingCSV ? (
                        <TableLoader />
                      ) : (
                        <>
                          <img src={Download_Icon} alt="" />
                          &nbsp; Export CSV
                        </>
                      )}
                    </Button> */}
                    <div className="select-dropdown-filters">
                      <Button onClick={downloadCSV} className="export-csv-cta">
                        {loadingCSV ? (
                          <TableLoader />
                        ) : (
                          <>
                            <img src={Download_Icon} alt="" />
                            &nbsp; Export CSV
                          </>
                        )}
                      </Button>
                      <Select
                        placeholder={
                          display_status ? display_status : "Select Status"
                        }
                        options={
                          Array.isArray(statusList) &&
                          statusList.map((option, index) => ({
                            value: option,
                            label: option,
                            id: option === "All" ? "" : index,
                          }))
                        }
                        value={display_status}
                        styles={customStyles}
                        isLoading={statusLoading}
                        onChange={handleStatus}
                      />
                      <Select
                        placeholder={
                          display_priority
                            ? display_priority
                            : "Select Priority"
                        }
                        options={
                          Array.isArray(priority_list_arr) &&
                          priority_list_arr.length > 0
                            ? priority_list_arr?.map((option, index) => ({
                                value: option,
                                label: option,
                                index: option === "All" ? "" : index - 1,
                              }))
                            : []
                        }
                        value={display_priority}
                        styles={customStyles}
                        isLoading={priority_loading}
                        onChange={handlePriority}
                      />
                    </div>
                  </div>
                  {loading_filters ? (
                    <PaginationLoader />
                  ) : (
                    <DataTable
                      header={header}
                      data={customerTicketsList && customerTicketsList}
                      customer_name={userDetails && userDetails?.CustomerName}
                      customer_mobile_number={
                        userDetails && userDetails?.MobileNumber
                      }
                      customer_img={userDetails && userDetails?.avatar}
                      items_per_page={10}
                      current_page={Tpage}
                    />
                  )}
                  <div className="pagination-entries-row">
                    <p>
                      Showing {first_item !== "" ? first_item : 1} To{" "}
                      {last_item !== ""
                        ? last_item
                        : customerTickets && customerTickets?.last_item}{" "}
                      of,{" "}
                      {total_entries && total_entries !== ""
                        ? total_entries
                        : customerTickets && customerTickets?.total}{" "}
                      entries
                    </p>
                    <div className="pagination-chevron-icon">
                      <Button
                        disabled={left_icon || Tpage === 1 ? true : false}
                        className="icon-chevron"
                        onClick={handleDecrement}
                      >
                        <ChevronLeftIcon />
                      </Button>
                      <div className="view-more">{VisiblePages}</div>
                      <Button
                        disabled={
                          right_icon ||
                          (customerTickets &&
                            customerTickets?.total_page === Tpage)
                            ? true
                            : right_icon || (total_page && total_page === Tpage)
                            ? true
                            : false
                        }
                        className="icon-chevron"
                        onClick={handleIncrement}
                      >
                        <ChevronRightIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {renderModal()}
            </div>
          ) : (
            <NoDataFromApi
              title={
                customerTilesdata?.message !== "Customer details not found."
                  ? "Something went wrong, Please Try Logout & Login Again..."
                  : "Customer details not found."
              }
              btn={
                customerTilesdata?.message !== "Customer details not found."
                  ? true
                  : false
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomerDashboard;
