export const unreplied_conversation_header = [
  "Sr. No",
  "Id",
  "Ticket No",
  "Title",
  "Order Id",
  "Language",
  "Priority",
  <>
    Created <br /> Date
  </>,
  <>
    Last update <br /> date
  </>,
  "Agent Name",
  "Status",
  "Action",
];

export const team_leader_call_counts_header = [
  "Sr. No",
  "Call Id",
  "Phone Number",
  "Start Date & Time",
  "End Date & Time",
  "Call Duration",
  "Call Type",
  <>
    Agent Name <br /> & ID
  </>,
  "Call Recording",
];

export const DashboardData = [
  {
    id: 1,
    count: 20000,
    title: "Total Orders Value",
    color: "#D9ACF5",
  },
  {
    id: 2,
    count: 48,
    title: "Total Orders",
    color: "#ABDBF5",
  },
  {
    id: 3,
    count: 11,
    title: "Total Returns",
    color: "#FFCCDE",
  },
  {
    id: 4,
    count: "22%",
    title: "Total Returns Percentage",
    color: "#F5C4C4",
  },
  {
    id: 5,
    count: 7,
    title: "Total Cancel",
    color: "#ABF5BC",
  },
  {
    id: 6,
    count: "3 Days",
    title: "Order Average Frequency",
    color: "#ABC0F5",
  },
  {
    id: 7,
    count: 4.1,
    title: "Average Feedback Given",
    color: "#F5D7AB",
  },
  {
    id: 8,
    count: 4000,
    title: "Total Promo Code Earning",
    color: "#EB96B8",
  },
  {
    id: 9,
    count: 193,
    title: "Customer Age on Site",
    color: "#FFD5B6",
  },
  {
    id: 10,
    count: "₹4000",
    title: "Total Wallet Amount Used",
    color: "#EC9595",
  },
  {
    id: 11,
    count: "₹2000",
    title: "Total Coupons Amount Used",
    color: "#75E3E3",
  },
  {
    id: 12,
    count: "50%",
    title: "Total Paid",
    color: "#ED7AE8",
  },
];

export const bankArr = ["Bank/UPI Id", "Card Details"];

export const RadiuscustomStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#F0F0F0" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "12px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#F0F0F0",
    border: "none",
    boxShadow: "none",
    fontSize: "12px",
    borderRadius: "23px",
    padding: "0px 5px 0px 5px",
    minHeight: "28px",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const RadiuscustomStylesDate = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#E0E0E0",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "23px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const customStylesSKU = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected
      ? "#000"
      : state.data.isDisabled
      ? "red"
      : "#6C6C6C",
    backgroundColor: state.isSelected ? "#fffff" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles, base) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#fffff",
    border: "1px solid #E6E6E6",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "5px",
    "&:hover": {
      border: "1px solid #E6E6E6 !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins_SemiBold",
    fontSize: "12px", // Replace 'Your desired font' with the font you want to use
    maxWidth: "300px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    // Add other menu styles if needed
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#6C6C6C" }),
};

export const customStylesSKU_data = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected
      ? "#000"
      : state.data.isDisabled
      ? "red"
      : "#6C6C6C",
    backgroundColor: state.isSelected ? "#fffff" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles, base) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#f0f0f0",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "5px",
    "&:hover": {
      border: "none !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins_SemiBold",
    fontSize: "12px", // Replace 'Your desired font' with the font you want to use
    maxWidth: "300px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    // Add other menu styles if needed
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#6C6C6C" }),
};

export const userDetails = {
  UserID: 101,
  CustomerName: "Aryan Sharma",
  CustomerEmailID: "aryansharma@gmail.com",
  MobileNumber: "+91 9509 689 698",
  JoiningDate: "06 January, 2023 | 10:51 AM",
  Address:
    "Sardarpua, C Road,  Near by Puma Showroom, Jodhpur 342001, Rajasthan",
};

export const categories = [
  {
    id: 1,
    category: "Men’s Category",
    totalCount: "24 Orders",
  },
  {
    id: 2,
    category: "Women’s Category",
    totalCount: "16 Orders",
  },
  {
    id: 3,
    category: "Kid’s Category",
    totalCount: "06 Orders",
  },
  {
    id: 4,
    category: "Men’s Accessories",
    totalCount: 0,
  },
  {
    id: 5,
    category: "Women’s Accessories",
    totalCount: "0",
  },
];

export const header = [
  "Sr. No",
  "Id",
  "Ticket No",
  "Title",
  "Order Id",
  "Language",
  "Priority",
  "SLA",
  <>
    Created <br /> Date
  </>,
  <>
    Last update <br /> date
  </>,
  "Status",
  "Action",
];

export const teamLeaderNewTicket = [
  "Sr. No",
  "Id",
  "Ticket No",
  "Title",
  "Order Id",
  "Language",
  "Priority",
  "SLA",
  "CreatedDate",
  "Action",
];

export const teamLeaderAllTicket = [
  "Sr. No",
  "Id",
  "Ticket No",
  "Title",
  "Order Id",
  "Agent Id",
  "SLA",
  "Agent Name",
  "Language",
  "Status",
  "CreatedDate",
  "Action",
];

export const DataTableData = [
  {
    id: "01",
    ticket_id: "JV13941497",
    title: "Return Product",
    order_id: "MRC000052933",
    language: "Hindi",
    priority: "High",
    crated_at: "2023-02-01 10:05:59 AM",
    updated_at: "2023-02-01 10:05:59 AM",
    status: "Open",
  },
  {
    id: "02",
    ticket_id: "JV13941497",
    title: "Return Product",
    order_id: "MRC000052933",
    language: "Hindi",
    priority: "High",
    crated_at: "2023-02-01 10:05:59 AM",
    updated_at: "2023-02-01 10:05:59 AM",
    status: "Open",
  },
  {
    id: "03",
    ticket_id: "JV13941497",
    title: "Return Product",
    order_id: "MRC000052933",
    language: "Hindi",
    priority: "High",
    crated_at: "2023-02-01 10:05:59 AM",
    updated_at: "2023-02-01 10:05:59 AM",
    status: "Open",
  },
  {
    id: "04",
    ticket_id: "JV13941497",
    title: "Return Product",
    order_id: "MRC000052933",
    language: "Hindi",
    priority: "High",
    crated_at: "2023-02-01 10:05:59 AM",
    updated_at: "2023-02-01 10:05:59 AM",
    status: "Open",
  },
];

export const CurrentOrder = [
  {
    OrderNumber: "MRC000052933",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide1.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide2.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide3.png"),
      },
    ],
  },
  {
    OrderNumber: "MRC000052934",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide1.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide2.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide3.png"),
      },
    ],
  },
  {
    OrderNumber: "MRC000052935",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide3.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide1.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide2.png"),
      },
    ],
  },
  {
    OrderNumber: "MRC000052936",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide1.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide2.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide3.png"),
      },
    ],
  },
  {
    OrderNumber: "MRC000052937",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide2.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide3.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide1.png"),
      },
    ],
  },
  {
    OrderNumber: "MRC000052938",
    OrderCreatedAt: "06 June, 2023 | 01:10 PM",
    TotalQty: 4,
    TotalOrderValue: 1299.0,
    TrackingNumber: 1234567890,
    CourierName: "Delhivery",
    Status: "On the way",
    image: [
      {
        id: 1,
        uri: require("../Assets/Slider/slide3.png"),
      },
      {
        id: 2,
        uri: require("../Assets/Slider/slide1.png"),
      },
      {
        id: 3,
        uri: require("../Assets/Slider/slide2.png"),
      },
    ],
  },
];

export const listOfModal = [
  {
    id: 0,
    title: "Tracking",
    uri: require("../Assets/Customer-Details/routing.png"),
    color: "#3F6FEF",
  },
  {
    id: 10,
    title: "Ticket Log",
    uri: require("../Assets/Customer-Details/ticket.png"),
    color: "#EF8AB2",
  },
  {
    id: 3,
    title: "Refund",
    uri: require("../Assets/Customer-Details/money-2.png"),
    color: "#37B99C",
  },
  {
    id: 100,
    title: "Send Invoice",
    uri: require("../Assets/Customer-Details/document-text.png"),
    color: "#75E3E3",
  },
  {
    id: 4,
    title: "Create Returns",
    uri: require("../Assets/Customer-Details/back-square.png"),
    color: "#ABC0F5",
  },
  {
    id: 5,
    title: "Cancel Order",
    uri: require("../Assets/Customer-Details/bag-cross.png"),
    color: "#FF6E6E",
  },
  {
    id: 101,
    title: "Create Replacement",
    uri: require("../Assets/Customer-Details/arrange-square.png"),
    color: "rgba(245, 202, 171, 1)",
  },
  {
    id: 6,
    title: "Share Coupons",
    uri: require("../Assets/Customer-Details/receipt-disscount.png"),
    color: "#FFDBA6",
  },
];

export const dateArr = ["Today", "Yesterday", "Last 6 Months", "This Year"];

export const productSkus = [
  "L-T285HSAS7WHDNGER",
  "L-T285HSAS7WHDNGES",
  "L-T285HSAS7WHDNGEK",
];

export const products = [
  "MRC000052933",
  "MRC000052934",
  "MRC000052935",
  "MRC000052936",
];
export const Returnreason = [
  "Wrong Product",
  "Product Damaged",
  "Other Reason",
];
export const refund_Option = ["UPI", "BankAccount", "OriginalSource", "Wallet"];

export const coupans = [
  {
    id: 1,
    title: "Offer Price ₹300",
    content: "Extra Upto 40% off on 2500 and above. Max discount 1500.",
    CTA: "VIEW ALL PRODUCT",
    validity: "Valid Date : July 15, 2023",
    color: "#E6BD57",
  },
  {
    id: 2,
    title: "Offer Price ₹300",
    content: "Extra Upto 40% off on 2500 and above. Max discount 1500.",
    CTA: "VIEW ALL PRODUCT",
    validity: "Valid Date : July 15, 2023",
    color: "#5B9877",
  },
  {
    id: 3,
    title: "Offer Price ₹300",
    content: "Extra Upto 40% off on 2500 and above. Max discount 1500.",
    CTA: "VIEW ALL PRODUCT",
    validity: "Valid Date : July 15, 2023",
    color: "#D67D53",
  },
  {
    id: 4,
    title: "Offer Price ₹300",
    content: "Extra Upto 40% off on 2500 and above. Max discount 1500.",
    CTA: "VIEW ALL PRODUCT",
    validity: "Valid Date : July 15, 2023",
    color: "#E7A29C",
  },
];

export const tableOrderDetails = [
  "Id",
  "Picture",
  "Color",
  "Size",
  "Order Id",
  // "Item ID",
  "Qty.",
  "Price",
  "Courier",
  "Status",
  <>
    Return <br />
    Availability
  </>,
  "Tracking No",
];

export const orderItems = [
  {
    id: "01",
    uri: require("../Assets/User/Ellipse 2.png"),
    OrderId: "MRC000052933",
    ItemID: "XL-T285HSAS7WHDNGR",
    Qty: 2,
    Price: "₹399.00",
    Courier: "Delhivery",
    Status: "Pending",
    RevTrackingNo: "Null",
  },
  {
    id: "02",
    uri: require("../Assets/User/Ellipse 2 (1).png"),
    OrderId: "MRC000052933",
    ItemID: "XL-T285HSAS7WHDNGR",
    Qty: 2,
    Price: "₹399.00",
    Courier: "Delhivery",
    Status: "Pending",
    RevTrackingNo: "Null",
  },
];

export const Goodwill_refund_header = [
  "Order Id",
  "Product SKU",
  "Qty",
  <>
    Refund <br /> Amount
  </>,
  "Reason",
  <>
    Payment <br /> Mode
  </>,
  <>
    Razorpay <br /> Reference ID
  </>,
  <>
    Created <br /> by
  </>,
  <>
    Created <br /> on
  </>,
  "Status",
  "Action",
];

export const Goodwill_normal_refund_header = [
  "Order Id",
  "Product SKU",
  "Qty",
  <>
    Refund <br /> Amount
  </>,
  <>
    Return <br /> Reason
  </>,
  <>
    Return Sub <br /> Reason
  </>,
  <>
    Payment <br /> Mode
  </>,
  <>
    Razorpay <br /> Reference ID
  </>,
  <>
    Return <br /> Type
  </>,
  <>
    Created <br /> at
  </>,
  "Status",
  "Action",
];
