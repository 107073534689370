import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./UnAuthorized.css";
import UnAuthorizedIcon from "../../Assets/funny-404-error-page-design.gif";
import { Button } from "@material-ui/core";

const UnAuthorized = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));

  return isAuthenticated && isAuthenticated?.user_type === "1" ? (
    <Navigate to="/" replace />
  ) : isAuthenticated?.user_type === "2" ? (
    <Navigate to="/team-leader-dashboard" replace />
  ) : (
    <div className="unauthorized-access">
      <img src={UnAuthorizedIcon} alt="" />
      <h1>UnAuthorized Access</h1>
      <Button onClick={handleLogout} className="redirect-to-login">
        Redirect To Login
      </Button>
    </div>
  );
};

export default UnAuthorized;
