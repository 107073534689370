import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { header } from "../../../DummyArray/HiscravesData";
import DataTable from "../../DataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCustomerTicketLogs } from "../../../Actions/CustomerActions/CustomerActions";
import PaginationLoader from "../../Loader/PaginationLoader";
import NoDataFromApi from "../../NotFound/NoDataFromApi";

const CustomerTicketLogs = (props) => {
  const { order_id } = props;
  const dispatch = useDispatch();
  console.log(props.order_id);

  const { ticketLogs, loading } = useSelector(
    (state) => state.ticketLogsCustomer
  );

  useEffect(() => {
    if (order_id) {
      dispatch(getCustomerTicketLogs(order_id));
    }
  }, [dispatch, order_id]);

  console.log("TicketLogs: ", ticketLogs);

  let logs = ticketLogs && ticketLogs?.data;

  return (
    <>
      <HeadingTitle title="Ticket Logs" closeModal={props.close} />
      {loading ? (
        <PaginationLoader />
      ) : (
        <>
          {logs && logs?.length > 0 ? (
            <div className="table-wrapper-pagination">
              <div className="table-container">
                <h3>Tickets</h3>
                <DataTable header={header} data={logs && logs} />
              </div>
            </div>
          ) : ticketLogs && ticketLogs?.logout ? (
            <NoDataFromApi
              height={true}
              title="Something went wrong, Please Try Logout & Login Again..."
              btn={true}
            />
          ) : (
            <NoDataFromApi
              height={true}
              title="There is No Logs Found With The selected Order...."
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomerTicketLogs;
