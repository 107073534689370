import {
  CALL_TO_CUSTOMER_FAILURE,
  CALL_TO_CUSTOMER_REQUEST,
  CALL_TO_CUSTOMER_SUCCESS,
  GET_AGENTS_DOUGHNUT_FAILURE,
  GET_AGENTS_DOUGHNUT_REQUEST,
  GET_AGENTS_DOUGHNUT_SUCCESS,
  GET_AGENT_BY_ID_FAILURE,
  GET_AGENT_BY_ID_REQUEST,
  GET_AGENT_BY_ID_SUCCESS,
  GET_AGENT_CALL_COUNT_FAILURE,
  GET_AGENT_CALL_COUNT_REQUEST,
  GET_AGENT_CALL_COUNT_SUCCESS,
  GET_AGENT_DOUGHUNUT_BY_ID_FAILURE,
  GET_AGENT_DOUGHUNUT_BY_ID_REQUEST,
  GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS,
  GET_AGENT_TICKETS_BY_ID_FAILURE,
  GET_AGENT_TICKETS_BY_ID_REQUEST,
  GET_AGENT_TICKETS_BY_ID_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS,
} from "../../Constants/AgentContants/AgentConstants";
import { CLEAR_ERRORS } from "../../Constants/UserConstants/UserConstants";

export const getAgentDashboardReducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        message: action.payload.logout,
      };
    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentDoughnutReducers = (state = { doughnut: {} }, action) => {
  switch (action.type) {
    case GET_AGENTS_DOUGHNUT_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENTS_DOUGHNUT_SUCCESS:
      return {
        ...state,
        loading: false,
        doughnut: action.payload,
        message: action.payload.message,
      };
    case GET_AGENTS_DOUGHNUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentByIdReducers = (state = { agent: {} }, action) => {
  switch (action.type) {
    case GET_AGENT_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        agent: action.payload,
        message: action.payload.message,
      };
    case GET_AGENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentDoughuntByIdReducers = (
  state = { agentIndi: {} },
  action
) => {
  switch (action.type) {
    case GET_AGENT_DOUGHUNUT_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        agentIndi: action.payload,
        message: action.payload.message,
      };
    case GET_AGENT_DOUGHUNUT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentTicketsByIdReducers = (
  state = { ticketsById: [] },
  action
) => {
  switch (action.type) {
    case GET_AGENT_TICKETS_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENT_TICKETS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketsById: action.payload,
        message: action.payload.message,
      };
    case GET_AGENT_TICKETS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentTicketsByBearerReducers = (
  state = { ticketsBearer: [] },
  action
) => {
  switch (action.type) {
    case GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketsBearer: action.payload,
        message: action.payload.message,
      };
    case GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const callToCustomerReducers = (
  state = { calling_customer: {} },
  action
) => {
  switch (action.type) {
    case CALL_TO_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case CALL_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        calling_customer: action.payload,
        message: action.payload.message,
      };
    case CALL_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getUnrepliedticketConversationReducers = (
  state = { unreplied_conversation: [] },
  action
) => {
  switch (action.type) {
    case GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unreplied_conversation: action.payload,
        message: action.payload.message,
      };
    case GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAgentCall_Count_Reducers = (
  state = { agent_call_count: [] },
  action
) => {
  switch (action.type) {
    case GET_AGENT_CALL_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_AGENT_CALL_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        agent_call_count: action.payload,
        message: action.payload.message,
      };
    case GET_AGENT_CALL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
