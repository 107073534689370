import React from "react";
import LoaderIcon from "../../Assets/b3f27715658889.562956b8d8c18.gif";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderIcon} alt="" />
    </div>
  );
};

export default Loader;
