import React, { useState } from "react";
import Select from "react-select";
import {
  agent_dateArray,
  customStyles,
  dateArray,
} from "../../DummyArray/DashboardArrData";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const CardHeader = (props) => {
  const [dateArr, setDateArr] = useState("");
  const handleChange = (selectedOption) => {
    setDateArr(selectedOption.value);
    props.BarGraph
      ? props.BarGraph(selectedOption)
      : props.DoughnutGraph(selectedOption);
  };

  const handleRange = (val) => {
    props.dataRange(val);
  };

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  return (
    <div
      className="agent-card-header"
      style={{
        flexDirection: props.showBottom && "column",
        alignItems: props.showBottom && "flex-start",
      }}
    >
      <div>
        <p>{props.para}</p>
        <h3 style={{ marginBottom: props.showBottom && "10px" }}>
          {props.title}
        </h3>
      </div>
      <div>
        {props.dropdown ? (
          <Select
            placeholder={`${
              dateArr !== ""
                ? dateArr
                : props.showOldData
                ? dateArray[3]?.date
                : dateArray[0]?.date
            }`}
            options={
              props.showNew
                ? agent_dateArray.map((option) => ({
                    value: option.date,
                    label: option.date,
                    id: option.id,
                  }))
                : dateArray.map((option) => ({
                    value: option.date,
                    label: option.date,
                    id: option.id,
                  }))
            }
            value={dateArr}
            styles={customStyles}
            onChange={handleChange}
          />
        ) : (
          props.showDateRange && (
            <DateRangePicker
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date"
              yearAriaLabel="Year"
              onChange={handleRange}
              value={props.DoughnutGraph}
              minDate={oneYearAgo}
              // maxDate={new Date()}
            />
          )
        )}
      </div>
    </div>
  );
};

export default CardHeader;
