import React from "react";
import LoaderIcon from "../../Assets/loader-black.gif";

const PaginationLoader = (props) => {
  return (
    <div className="pagination-loader">
      <img
        style={{ width: props.small ? "40px" : "60px" }}
        src={LoaderIcon}
        alt=""
      />
    </div>
  );
};

export default PaginationLoader;
