import React, { useCallback, useState } from "react";
import AgentsProfileCount from "../../Components/Counts/AgentsProfileCount";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import "./AgentProfile.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AgentUri from "../../Assets/Agents-Img/Ellipse 1.png";
import ReadOnlyInputs from "../../Components/ReadOnlyInputs/ReadOnlyInputs";
import { useEffect } from "react";
import CardHeader from "../../Components/CardHeader/CardHeader";
import { Rating } from "@mui/material";
import StarIcon from "@material-ui/icons/Star";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import DoughnutChart from "../../Components/Bars/DougnutChart/DoughnutChart";
import Navbar from "../../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentById,
  getAgentDougnutById,
  getAgentDougnutById_with_normal,
  getAgentTicketsByIdAction,
} from "../../Actions/AgentActions/AgentActions";
import Loader from "../../Components/Loader/Loader";
import { header } from "../../DummyArray/HiscravesData";
import DataTable from "../../Components/DataTable/DataTable";
import { Button } from "@material-ui/core";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import TableLoader from "../../Components/Loader/TableLoader";
import PaginationLoader from "../../Components/Loader/PaginationLoader";
import { useAlert } from "react-alert";
import HelmetTitle from "../../Components/MetaData/MetaData";
import Select from "react-select";
import { customStyles } from "../../DummyArray/DashboardArrData";
import { getTicketStatus } from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import { getAllPriorityActions } from "../../Actions/CustomerActions/CustomerActions";
import Download_Icon from "../../Assets/document-download.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const AgentProfile = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { agent, loading, error } = useSelector(
    (state) => state.IndividualAgent
  );
  const { agentIndi } = useSelector((state) => state.AgentIdDoughnut);
  const { ticketsById, loading: ticketsLoading } = useSelector(
    (state) => state.agentTicketsId
  );
  const { agent_id } = useParams();

  console.log("Tickets: ", ticketsById);

  // get All Status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  const getPreviousDate = (monthsAgo) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthsAgo);
    return currentDate;
  };

  const [previousDate, setPreviousDate] = useState(getPreviousDate(6));

  useEffect(() => {
    setPreviousDate(getPreviousDate(6));
  }, []);

  const [DoughnutGraph, SetDoughnutGraph] = useState([
    previousDate,
    new Date(),
  ]);
  // console.log(DoughnutGraph, "ss");

  // const start_date = DoughnutGraph && DoughnutGraph[0];
  // const end_date = DoughnutGraph && DoughnutGraph[1];

  // Format the date as "dd-MM-yyyy"
  // const from_date = start_date
  //   ? start_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  // const to_date = end_date
  //   ? end_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  const [filterTrue, setFilterTrue] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  // filter for all tickets
  const [isTrigger, setIsTrigger] = useState(false);
  const [status, setStatus] = useState("");
  const [display_status, setDisplay_status] = useState("");

  const [priority, setPriority] = useState("");
  const [display_priority, setDisplay_priority] = useState("");

  const [width, setWidth] = useState("3rem");

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");

  const handleDoughnutChart = async (children) => {
    const start_date = children && children[0];
    const end_date = children && children[1];

    // Format the date as "dd-MM-yyyy"
    const from_date = start_date
      ? start_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;

    const to_date = end_date
      ? end_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;
    setFilterTrue(true);
    setLoadingFilter(true);
    SetDoughnutGraph(children);
    if (from_date !== null && to_date !== null) {
      await dispatch(getAgentDougnutById(agent_id, from_date, to_date));
    } else {
      await dispatch(getAgentDougnutById_with_normal(agent_id, 6));
    }
    setLoadingFilter(false);
  };

  let agent_tiles = agent && agent?.data;
  let agent_info = agent_tiles && agent_tiles?.agent;

  const agentProfileCount = [
    {
      id: 1,
      count: agent_tiles && agent_tiles?.total_tickets,
      title: "Total Tickets",
      color: "#9DE4F2",
    },
    {
      id: 2,
      count: agent_tiles && agent_tiles?.resolve_tickets,
      title: "Resolve Tickets",
      color: "#F2AE24",
    },
    {
      id: 3,
      count: agent_tiles && agent_tiles?.open_tickets,
      title: "Open Tickets",
      color: "#E0AED0",
    },
    {
      id: 4,
      count: agent_tiles && agent_tiles?.hold_tickets,
      title: "Hold Tickets",
      color: "#F23079",
    },
    {
      id: 5,
      count: agent_tiles && agent_tiles?.pending_tickets,
      title: "Pending Tickets",
      color: "#F28D85",
    },
    {
      id: 6,
      count: agent_tiles && agent_tiles?.total_call_count,
      title: "Total Call Counts",
      color: "#F2BEB6",
    },
    {
      id: 7,
      count: agent_tiles && agent_tiles?.total_call_valume,
      title: "Total Call Volume",
      color: "#A3D9CC",
    },
    {
      id: 8,
      count: agent_tiles && agent_tiles?.missed_call_count,
      title: "Missed Call Count",
      color: "#B8F39E",
    },
    {
      id: 9,
      count: agent_tiles && agent_tiles?.recieved_call_count,
      title: "Received Call Count",
      color: "#DE8F5F",
    },
    {
      id: 10,
      count: agent_tiles && agent_tiles?.daily_call_talk_time,
      title: "Total Daily Talk Time",
      color: "#D6D46D",
    },
    {
      id: 11,
      count: agent_tiles && agent_tiles?.un_replied_tickets_count,
      title: "UnReplied Tickets Count",
      color: "#F9B572",
    },
  ];

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setWidth("2.8rem");
    }
  }, []);

  useEffect(() => {
    if (agent_id && !filterTrue) {
      dispatch(getAgentById(agent_id));
      dispatch(getAgentDougnutById_with_normal(agent_id, 6));
      dispatch(getAgentTicketsByIdAction(agent_id, 10));
    }
  }, [dispatch, agent_id, filterTrue]);

  // Table pagination
  const [agentTickets, setAgentsTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let per_page = 10;

  const handleStatus = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    setStatus(selectedOption?.id);
    setDisplay_status(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  const handlePriority = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    // setXPage(1);
    setPriority(selectedOption?.index);
    setDisplay_priority(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);
  const [total_page, setTotal_page] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const fetchTickets = useCallback(
    (page) => {
      setIsLoading(true);
      let apiUrl = `${BaseURL}/agent/agent-tickets?agent_id=${agent_id}&per_page=${per_page}&page=${page}`;
      if (status !== null && status !== undefined) {
        apiUrl += `&status=${status}`;
      }

      if (priority !== "" && priority !== undefined) {
        apiUrl += `&priority=${priority}`;
      }
      api
        .get(apiUrl)
        .then((response) => {
          if (response && response?.data?.data?.length > 0) {
            setTotal_page(response && response?.data?.total_page);
            setTotal_entries(response && response?.data?.total);
          }
          setAgentsTickets(response && response?.data?.data);
          setFirst_item(response?.data && response?.data?.first_item);
          setLast_item(response?.data && response?.data?.last_item);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          alert.error(err.message);
          setIsLoading(false);
        });
    },
    [per_page, agent_id, priority, status, alert]
  );

  const handlePageClick = useCallback(
    (page) => {
      setPage(page);
      fetchTickets(page);
    },
    [fetchTickets]
  );

  let maxVisibilePages = 5;
  const [VisiblePages, setVisibilePages] = useState([]);

  const renderPagination = useCallback(() => {
    if (ticketsById && ticketsById?.total_page !== page) {
      setRight_icon(false);
    }
    if (total_page && total_page !== page) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page && total_page !== "" ? total_page : ticketsById?.total_page
    );
    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;
    const pageNumbers = [];

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    isLoading,
    maxVisibilePages,
    handlePageClick,
    page,
    ticketsById,
    total_page,
  ]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  const fetch = useCallback(() => {
    ticketsById && setAgentsTickets(ticketsById?.data);
  }, [ticketsById]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  let review = agent_tiles && agent_tiles?.agent_review;

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [error, alert]);

  useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  let statusList = Allstatus?.data ? Object.values(Allstatus?.data) : [];

  if (
    Array.isArray(statusList) &&
    statusList?.length > 0 &&
    !statusList.includes("All")
  ) {
    statusList?.splice(0, 0, "All");
  }

  let priority_list_arr =
    Array.isArray(priority_list?.data) && priority_list?.data?.length > 0
      ? priority_list?.data
      : [];

  if (
    Array.isArray(priority_list?.data) &&
    priority_list?.data?.length > 0 &&
    !priority_list_arr?.includes("All")
  ) {
    priority_list_arr?.splice(0, 0, "All");
  }

  useEffect(() => {
    if (isTrigger) {
      fetchTickets(page);
    }
  }, [isTrigger, fetchTickets, page]);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      let apiUrl_new = `${BaseURL}/agent/export-agents-profile`;

      if (status !== "") {
        apiUrl_new += `?status=${status}`;
      }

      if (priority !== "") {
        apiUrl_new += `${status === "" ? "?" : "&"}priority=${priority}`;
      }

      const response = await api.get(apiUrl_new);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoadingCsv(false);
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoadingCsv(false);
    }
  };

  const handleDecrement = () => {
    if (page === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setPage(page - 1);
      fetchTickets(page - 1);
    }
  };
  const handleIncrement = () => {
    if (
      (ticketsById && ticketsById?.total_page === page) ||
      (total_page && total_page === page)
    ) {
      // setPage(page);
      setRight_icon(true);
    } else {
      setRight_icon(false);
      setPage(page + 1);
      fetchTickets(page + 1);
    }
  };

  useEffect(() => {
    if (ticketsById && ticketsById?.total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [ticketsById, page]);

  useEffect(() => {
    if (total_page && total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, page]);

  const handleNavigation = () => {
    navigate(`/unreplied-Conversations/${agent_info && agent_info?.id}`);
  };

  // unused function
  const clickRevert = () => {};

  console.log("Total Entries: ", review?.rating);

  return (
    <>
      <HelmetTitle
        // eslint-disable-next-line
        title={`${
          loading || ticketsLoading
            ? "Loading..."
            : // eslint-disable-next-line
              agent_info?.full_name + " - " + "Profile Details"
        }`}
      />
      <Navbar searchBar={handleSearchBar} />
      {loading || ticketsLoading ? (
        <Loader />
      ) : (
        <div>
          {agent && !agent?.logout ? (
            <div className="home-container">
              <Link
                to={`/team-leader-dashboard`}
                className="agent-profile-head"
              >
                <img src={Arrow} alt="" />
                <h1>Agent Profile</h1>
              </Link>
              {/* agent dashboard count */}
              <div className="grid-team-leader-counts">
                {agentProfileCount &&
                  agentProfileCount.map((i, index) => (
                    <div
                      className="grid-column-team-leader-counts"
                      style={{
                        backgroundColor: i.color,
                        cursor: index === 10 && "pointer",
                      }}
                      onClick={index === 10 ? handleNavigation : clickRevert}
                      key={index}
                    >
                      <AgentsProfileCount
                        index={index}
                        color={i.color}
                        title={i.title}
                        count={i.count}
                      />
                    </div>
                  ))}
              </div>
              {/* agent dashboard count */}

              {/* user details graph ratings  */}
              <div className="user-details-graph-ratings-grid-row">
                <div>
                  <div className="agent-tags-container">
                    <div
                      className={`${
                        agent_tiles && agent_tiles.is_active === 1
                          ? "tag-agents"
                          : "tag-inAgents"
                      }`}
                    >
                      <p>
                        {agent_tiles && agent_tiles.is_active === 1
                          ? "Active User"
                          : "Not Active"}
                      </p>
                      <div className="triangle"></div>
                    </div>
                  </div>
                  <div className="agent-details-left-side">
                    <div className="agent-img-left-side">
                      <img src={AgentUri} alt="" />
                    </div>
                  </div>
                  <ReadOnlyInputs
                    label="User Id"
                    id="UserId"
                    value={agent_info?.id}
                  />
                  <ReadOnlyInputs
                    label="Username"
                    id="Username"
                    value={agent_info?.full_name}
                  />
                  <ReadOnlyInputs
                    label="Join Date"
                    id="JoinDate"
                    value={
                      new Date(agent_info?.join_data).toLocaleDateString() +
                      " , " +
                      new Date(agent_info?.join_data).toLocaleTimeString()
                    }
                  />
                </div>
                <div>
                  <CardHeader para="Statistics" title="Feedbacks" />
                  <h3 className="rating-count">
                    {review && review.average !== null
                      ? parseFloat(review?.average).toFixed(1)
                      : "0"}
                  </h3>
                  <div className="rating-stars">
                    <Rating
                      size="large"
                      name="simple-controlled"
                      value={review && parseInt(review?.average)}
                      sx={{
                        fontSize: "2.8rem",
                        "& .MuiRating-icon": {
                          width: width,
                        },
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </div>
                  {/* Rating Bar component starts here */}
                  <div className="rating-progress-bar">
                    {review && review?.rating?.length > 0
                      ? review?.rating?.map((x, index) => (
                          <div key={index} className="progress-bar-grid-row">
                            <div className="star-left-side">
                              <p style={{ flex: 0.5 }}>{x.agent_rating}</p>
                              <StarIcon style={{ color: "#F9CF3A" }} />
                            </div>
                            <div>
                              <ProgressBar
                                totalUsers={x.agent_rating}
                                fiveStarUsers={x.total}
                                bgcolor={
                                  x?.agent_rating === 5
                                    ? "#57E32C"
                                    : x?.agent_rating === 4
                                    ? "#B7DD29"
                                    : x?.agent_rating === 3
                                    ? "#FFE234"
                                    : x?.agent_rating === 2
                                    ? "#FFA534"
                                    : x?.agent_rating === 1
                                    ? "#FF4545"
                                    : "#57E32C"
                                }
                              />
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  {/* Rating Bar component ends here */}
                </div>
                <div>
                  <CardHeader
                    // DoughnutGraph={handleDoughnutChart}
                    // para="Statistics"
                    // title="Tickets Status Wise"
                    // dropdown={true}
                    // showOldData={true}

                    dataRange={handleDoughnutChart}
                    para="Statistics"
                    title="Tickets Status Wise"
                    dropdown={false}
                    showOldData={true}
                    showNew={true}
                    showDateRange={true}
                    DoughnutGraph={DoughnutGraph}
                    showBottom={true}
                  />
                  {loadingFilter ? (
                    <PaginationLoader />
                  ) : (
                    <DoughnutChart data={agentIndi && agentIndi?.data} />
                  )}
                </div>
              </div>
              {/* user details graph ratings  */}
              <h2 className="all-tickets-head">All Tickets</h2>
              <div className="table-wrapper-pagination">
                <div className="table-container">
                  <div className="header-text-dropdown">
                    <h3>Assigned Tickets</h3>
                    <div className="select-dropdown-filters">
                      <Button onClick={downloadCSV} className="export-csv-cta">
                        {loadingCSV ? (
                          <TableLoader />
                        ) : (
                          <>
                            <img src={Download_Icon} alt="" />
                            &nbsp; Export CSV
                          </>
                        )}
                      </Button>
                      <Select
                        placeholder={
                          display_status ? display_status : "Select Status"
                        }
                        options={
                          Array.isArray(statusList) &&
                          statusList.map((option, index) => ({
                            value: option,
                            label: option,
                            id: option === "All" ? "" : index,
                          }))
                        }
                        value={display_status}
                        styles={customStyles}
                        isLoading={statusLoading}
                        onChange={handleStatus}
                      />
                      <Select
                        placeholder={
                          display_priority
                            ? display_priority
                            : "Select Priority"
                        }
                        options={
                          Array.isArray(priority_list_arr) &&
                          priority_list_arr?.length > 0
                            ? priority_list_arr?.map((option, index) => ({
                                value: option,
                                label: option,
                                index: option === "All" ? "" : index - 1,
                              }))
                            : []
                        }
                        value={display_priority}
                        styles={customStyles}
                        isLoading={priority_loading}
                        onChange={handlePriority}
                      />
                    </div>
                  </div>
                  <DataTable
                    header={header}
                    data={agentTickets && agentTickets}
                    changeCTA={true}
                    items_per_page={10}
                    current_page={page}
                  />
                  <div className="pagination-entries-row">
                    <p>
                      Showing {first_item !== "" ? first_item : 1} To{" "}
                      {last_item !== ""
                        ? last_item
                        : ticketsById && ticketsById?.last_item}{" "}
                      of,{" "}
                      {total_entries && total_entries !== ""
                        ? total_entries
                        : ticketsById && ticketsById?.total}{" "}
                      entries
                    </p>
                    <div className="pagination-chevron-icon">
                      <Button
                        disabled={left_icon || page === 1 ? true : false}
                        className="icon-chevron"
                        onClick={handleDecrement}
                      >
                        <ChevronLeftIcon />
                      </Button>
                      <div className="view-more">{VisiblePages}</div>
                      <Button
                        disabled={
                          right_icon ||
                          (ticketsById && ticketsById?.total_page === page)
                            ? true
                            : right_icon || (total_page && total_page === page)
                            ? true
                            : false
                        }
                        className="icon-chevron"
                        onClick={handleIncrement}
                      >
                        <ChevronRightIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoDataFromApi
              title="Something went wrong, Please Try Logout & Login Again..."
              btn={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AgentProfile;
