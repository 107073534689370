import React from 'react'
import './ShippingDetails.css'

const ShippingDetails = (props) => {
    return (
        <>
            <div className="shipping-grid-row">
                <div className="input-shipping">
                    <label htmlFor="CustomerName">Customer Name</label> <br />
                    <input type="text" id='CustomerName' defaultValue={props.CustomerName} />
                </div>
                <div className="input-shipping">
                    <label htmlFor="EmailId">Email Id</label> <br />
                    <input type="text" id='EmailId' defaultValue={props.EmailId} />
                </div>
                <div className="input-shipping">
                    <label htmlFor="MobileNumber">Mobile Number</label> <br />
                    <input type="text" id='MobileNumber' defaultValue={props.MobileNumber} />
                </div>
            </div>
            <div className="input-shipping mt-2">
                <label htmlFor="address1">Address-1</label> <br />
                <input type="text" id='address1' defaultValue={props.Address1} />
            </div>
            <div className="input-shipping mt-2">
                <label htmlFor="address2">Address-2</label> <br />
                <input type="text" id='address2' defaultValue={props.Address2} />
            </div>
            <div className="shipping-grid-row">
                <div className="input-shipping">
                    <label htmlFor="City">City</label> <br />
                    <input type="text" id='City' defaultValue={props.City} />
                </div>
                <div className="input-shipping">
                    <label htmlFor="State">State</label> <br />
                    <input type="text" id='State' defaultValue={props.State} />
                </div>
                <div className="input-shipping">
                    <label htmlFor="Country">Country</label> <br />
                    <input type="text" id='Country' defaultValue={props.Country} />
                </div>
                <div className="input-shipping">
                    <label htmlFor="Pincode">Pincode</label> <br />
                    <input type="text" id='Pincode' defaultValue={props.Pincode} />
                </div>
            </div>
        </>
    )
}

export default ShippingDetails