import React, { useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getOrderProductSKUActions,
  getReasonsActions,
  getSubReasonsActions,
} from "../../../Actions/CustomerActions/CustomerActions";
import Select from "react-select";
import { customStylesSKU } from "../../../DummyArray/HiscravesData";
import { Button } from "@mui/material";
import api from "../../../axiosConfig";
import { BaseURL } from "../../../BaseURL";
import { useAlert } from "react-alert";
import TableLoader from "../../Loader/TableLoader";

const CreateReplacement = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { close, order_id, orderNo, order_items } = props;

  // product sku state
  // const { product_sku_order, loading: sku_loading } = useSelector(
  //   (state) => state.product_sku
  // );

  const { orderDetails, loading } = useSelector(
    (state) => state.customerOrderDetails
  );

  const { reasons, loading: reason_loading } = useSelector(
    (state) => state.reasons
  );
  const { Sub_reasons, loading: subReasonLoading } = useSelector(
    (state) => state.subReasons
  );

  const [reason, setReason] = useState("");
  const [id, setId] = useState("");

  const [sub_reason, setSub_Reason] = useState("");
  const [sub_id, setsub_Id] = useState("");

  const handleReason = (option) => {
    setReason(option.value);
    setId(option.id);
  };

  const handleSubReason = (option) => {
    setSub_Reason(option.value);
    setsub_Id(option.id);
  };

  // eslint-disable-next-line
  const [order_no, setOrder_no] = useState(orderNo);

  // create return some additonal usestate
  const [selectedValues, setSelectedValues] = useState([]);
  const [order_items_qty, setOrder_item_qty] = useState([]);

  const [comment, setComment] = useState("");

  const [selectedColor, setSelectedColor] = useState();
  const [filtercolor_id, setFilter_color_id] = useState();

  const handleColor = (selectedOptions) => {
    setSelectedColor(selectedOptions);
    setFilter_color_id(selectedOptions?.id);
  };

  console.log("selected Color: ", selectedColor);

  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);

    // Update new fields based on selected options
    setOrder_item_qty((prevFields) => {
      const updatedFields = selectedOptions.map((option) => {
        // Check if there's a previously entered value for this field
        const prevField = prevFields.find((field) => field.id === option.value);
        return {
          id: option.value,
          qty: prevField ? prevField.qty : "",
          prod_id: prevField ? prevField.prod_id : "",
          label: option.label,
        };
      });

      return updatedFields;
    });
  };

  const handleNewFieldValueChange = (index, value) => {
    if (value > 0) {
      setOrder_item_qty((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].qty = value;
        return updatedFields;
      });
    } else {
      setOrder_item_qty((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[index].qty = "";
        return updatedFields;
      });
      // alert.error("Please Enter Only Positive Numbers...");
    }
  };

  const handleProd_idFieldValueChange = (selectedOption, index) => {
    console.log("Index: ", index);
    setOrder_item_qty((prevFields) => {
      const updatedFields = [...prevFields];

      // Check if there is at least one item in the array
      if (updatedFields.length > 0) {
        updatedFields[index].prod_id = selectedOption.id;
      }

      return updatedFields;
    });
  };

  // useEffect(() => {
  //   if (orderNo !== "" && orderNo !== null && orderNo !== undefined) {
  //     dispatch(getOrderProductSKUActions(orderNo));
  //     // setIsChanges(false);
  //   }
  //   // if (orderId !== "" && orderId !== null && orderId !== undefined) {
  //   //   dispatch(getCorderDetails(orderId));
  //   // }
  // }, [order_no, dispatch, orderNo]);

  console.log("Selected Items: ", selectedValues);
  console.log("Order Items Qty: ", order_items_qty);

  console.log("Prod Id: ", orderDetails);

  let color_list_arr = [];

  if (orderDetails && orderDetails.data && orderDetails.data.order_items) {
    color_list_arr = orderDetails.data.order_items.flatMap(
      (i) => i?.product || []
    );
  }

  let size_list_arr = [];

  if (orderDetails && orderDetails.data && orderDetails.data.order_items) {
    size_list_arr = orderDetails.data.order_items.flatMap(
      (i) => i?.product?.sizes || []
    );
  }

  console.log(orderDetails.data.order_items, "c");

  const filteredOptions =
    size_list_arr &&
    size_list_arr.filter(
      (option) => option.product_sub_group_id === filtercolor_id
    );

  console.log(filteredOptions, "sssss", filtercolor_id);

  let reasons_list = reasons && reasons?.data && reasons?.data?.reason_list;
  let sub_reason_list =
    Sub_reasons && Sub_reasons?.data && Sub_reasons?.data?.sub_reason_list;

  useEffect(() => {
    if (id) {
      dispatch(getSubReasonsActions("Cancel", id));
    }
    dispatch(getReasonsActions("Cancel"));
  }, [dispatch, id]);

  const [Replacement_loading, setReplacement_loading] = useState(false);

  const handleReplacementOrder = () => {
    console.log("Reason Id: ", id);
    console.log("Reason Sub Id: ", sub_id);
    console.log("Order Id:", order_id);
    console.log("Selected Order Items: ", order_items_qty);
    console.log("Comment: ", comment);

    const noneEmpty = (obj) =>
      Object.values(obj).every(
        (value) => value !== "" && value !== undefined && value !== null
      );

    // Check if all objects have non-empty keys
    const allObjectsHaveNonEmptyKeys = order_items_qty?.every(noneEmpty);

    if (id !== "" && order_id !== "" && allObjectsHaveNonEmptyKeys) {
      setReplacement_loading(true);
      api
        .post(`${BaseURL}/agent/order-replace`, {
          order_id: order_id,
          selected_order_items: order_items_qty,
          reason_id: id,
          sub_reason_id: sub_id,
          comments: comment,
        })
        .then((res) => {
          if (res && res?.data?.status === "error") {
            alert.error(res && res?.data?.message);
            setReplacement_loading(false);
          } else if (res && res?.data?.status === "success") {
            alert.success(res && res?.data?.message);
            setReplacement_loading(false);
            close();
          }
        })
        .catch((err) => {
          setReplacement_loading(false);
          alert.error(err.message);
        });
    } else {
      alert.error(
        allObjectsHaveNonEmptyKeys
          ? "Please Select Product, Size, Color and Qty."
          : !id
          ? "Please Choose Reason..."
          : "Please Fill all the Fields Qty, Size & Color"
      );
    }
  };

  console.log("Order_ID: ", order_items_qty);

  // const ColorOption = ({ data, ...props }) => (
  //   <components.Option {...props}>
  //     <div
  //       style={{
  //         // backgroundColor: data,
  //         width: "20px",
  //         height: "20px",
  //       }}
  //     />
  //   </components.Option>
  // );

  const isOptionDisabled = (option) => {
    // Example condition: Disable options where name is 'DisabledOption'
    return option.total_available === 0;
    // Modify this condition as per your requirement
  };

  const isOptionDisabledSKU = (option) => {
    // Example condition: Disable options where name is 'DisabledOption'
    return (
      option.return_qty_left === 0 || option.product.return_available === 0
    );
    // Modify this condition as per your requirement
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div className="replacement-product-img" {...innerProps}>
      <img src={data?.data?.product?.front_img_url} alt={label} />
      <p
        className={`${
          data?.data?.return_qty_left === 0 ||
          data?.data?.product?.return_available === 0
            ? "product-label"
            : "product-label-new"
        }`}
      >
        {data?.data?.product?.title}
      </p>
    </div>
  );

  return (
    <>
      <HeadingTitle title={"Create Replacement"} closeModal={close} />
      <div className="inputs">
        <label className="label-refund" htmlFor="">
          Select Order
        </label>{" "}
        <br />
        <input type="text" readOnly value={order_no} />
      </div>
      <div className="inputs">
        <div>
          <label className="label-refund" htmlFor="">
            Select Product <span className="dot-red">*</span>
          </label>{" "}
          <br />
          <Select
            options={((order_items && order_items) || []).map((option) => ({
              value: option.id,
              label: option?.product?.product_code,
              isDisabled: isOptionDisabledSKU(option),
              data: option,
            }))}
            components={{ Option: CustomOption }}
            placeholder="Select Product"
            isMulti={true}
            styles={customStylesSKU}
            value={selectedValues}
            onChange={handleSelectChange}
            isOptionDisabled={(option) => option.isDisabled}
            isLoading={order_items && order_items?.length > 0 ? false : true}
          />
        </div>
      </div>
      <div className="inputs">
        {order_items_qty.map((field, index) => (
          <div className="inputs" key={index}>
            <label className="label-refund">
              Qty. ({field.label}): <span className="dot-red">*</span>
            </label>{" "}
            <br />
            <input
              className="qty"
              type="number"
              value={field.qty}
              min={1}
              onChange={(e) => handleNewFieldValueChange(index, e.target.value)}
            />
          </div>
        ))}
      </div>

      <div className="inputs">
        {order_items_qty &&
          order_items_qty.map((filterItem, index) => {
            // Filter the color_list_arr based on the filterItem's label
            const filteredColors = (color_list_arr || []).filter(
              (option) => option.product_code === filterItem.label
            );
            console.log("XMAS: ", filteredColors);
            return (
              <div className="tm-22" key={index}>
                <label className="label-refund" htmlFor="">
                  Select Color ({filterItem.label}):{" "}
                  <span className="dot-red">*</span>
                </label>{" "}
                <br />
                <Select
                  key={index}
                  options={filteredColors.flatMap((option) =>
                    option.colors.map((color) => ({
                      value: color.color_code,
                      label: color.color_code,
                      id: color.product_sub_group_id,
                    }))
                  )}
                  getOptionLabel={(option) => (
                    <span style={{ marginLeft: "25px" }}>{option.label}</span>
                  )}
                  getOptionValue={(option) => option.value}
                  // menuIsOpen={true}
                  placeholder="Select Color"
                  // styles={customStylesSKU}
                  // components={{ Option: ColorOption }}
                  styles={{
                    // option: (defaultStyles, state) => ({
                    //   ...defaultStyles,
                    //   color: state.isSelected ? "#000" : "#6C6C6C",
                    //   backgroundColor: state.isSelected ? "#fffff" : "#fffff",
                    //   fontFamily: "Poppins_SemiBold",
                    //   fontSize: "14px",
                    // }),

                    noOptionsMessage: (provided) => ({
                      ...provided,
                      color: "#000",
                      fontFamily: "Poppins_SemiBold",
                      fontSize: "14px",
                    }),

                    control: (defaultStyles, base) => ({
                      ...defaultStyles,
                      fontFamily: "Poppins_SemiBold",
                      backgroundColor: "#fffff",
                      border: "1px solid #E6E6E6",
                      boxShadow: "none",
                      fontSize: "14px",
                      borderRadius: "5px",
                      padding: "0px 0px",
                      minHeight: "48px",
                      marginTop: "5px",
                      "&:hover": {
                        border: "1px solid #E6E6E6 !important",
                      },
                    }),
                    singleValue: (defaultStyles) => ({
                      ...defaultStyles,
                      color: "#6C6C6C",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.data.value,
                      width: "30px",
                      height: "30px",
                      marginLeft: "10px",
                      fontFamily: "Poppins_SemiBold",
                      fontSize: "14px",
                      borderRadius: "50%",
                      color: state.isSelected ? "#000" : "#6C6C6C",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  onChange={handleColor}
                  isLoading={loading}
                />
              </div>
            );
          })}
      </div>
      <div className="inputs">
        {order_items_qty &&
          order_items_qty.map((i, index) => (
            <div className="tm-22" key={index}>
              <label className="label-refund" htmlFor="">
                Select Sizes ({i.label}): <span className="dot-red">*</span>
              </label>{" "}
              <br />
              <Select
                options={(filteredOptions || []).map((option) => ({
                  value: option?.code,
                  label: option?.code,
                  id: option.product_id,
                  isDisabled: isOptionDisabled(option),
                }))}
                placeholder="Select Sizes"
                styles={customStylesSKU}
                onChange={(selectedOptions) =>
                  handleProd_idFieldValueChange(selectedOptions, index)
                }
                isLoading={loading}
                isOptionDisabled={(option) => option.isDisabled}
              />
            </div>
          ))}
      </div>
      <div className="inputs">
        <label htmlFor="SelectReason">
          Select Reason <span className="dot-red">*</span>
        </label>
        <Select
          placeholder={`${reason !== "" ? reason : "Select Reason"}`}
          options={
            reasons_list &&
            reasons_list.map((option) => ({
              value: option.name,
              label: option.name,
              id: option.id,
            }))
          }
          className="select"
          id="SelectReason"
          value={reason}
          isLoading={reason_loading}
          styles={customStylesSKU}
          onChange={(selectedOption) => handleReason(selectedOption)}
        />
      </div>
      <div className="inputs">
        <label htmlFor="SelectSubReason">Select Sub Reason</label>
        <Select
          placeholder={`${
            sub_reason !== "" ? sub_reason : "Select Sub Reason"
          }`}
          isLoading={subReasonLoading}
          options={
            sub_reason_list &&
            sub_reason_list.map((option) => ({
              value: option.name,
              label: option.name,
              id: option.id,
            }))
          }
          className="select"
          id="SelectSubReason"
          value={sub_reason}
          styles={customStylesSKU}
          onChange={(selectedOption) => handleSubReason(selectedOption)}
        />
      </div>
      <div className="inputs">
        <label htmlFor="Comment">Comment</label> <br />
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          id="Comment"
          rows="3"
        ></textarea>
      </div>
      <div className="btn-create-return">
        <Button type="submit" onClick={handleReplacementOrder}>
          {Replacement_loading ? <TableLoader /> : "Submit"}
        </Button>
      </div>
    </>
  );
};

export default CreateReplacement;
