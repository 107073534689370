import {
  CANCEL_CUSTOMER_ORDER_FAILURE,
  CANCEL_CUSTOMER_ORDER_REQUEST,
  CANCEL_CUSTOMER_ORDER_RESET,
  CANCEL_CUSTOMER_ORDER_SUCCESS,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_DETAILS_FAILURE,
  CREATE_ACCOUNT_DETAILS_REQUEST,
  CREATE_ACCOUNT_DETAILS_SUCCESS,
  CREATE_NEW_TICKET_FAILURE,
  CREATE_NEW_TICKET_REQUEST,
  CREATE_NEW_TICKET_RESET,
  CREATE_NEW_TICKET_SUCCESS,
  CREATE_RETURN_FAILURE,
  CREATE_RETURN_REQUEST,
  CREATE_RETURN_SUCCESS,
  GET_ALL_COUPANS_FAILURE,
  GET_ALL_COUPANS_REQUEST,
  GET_ALL_COUPANS_SUCCESS,
  GET_ALL_PRIORITY_FAILURE,
  GET_ALL_PRIORITY_REQUEST,
  GET_ALL_PRIORITY_SUCCESS,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS,
  GET_CUSTOMER_ADDRESS_FAILURE,
  GET_CUSTOMER_ADDRESS_REQUEST,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE,
  GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST,
  GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS,
  GET_CUSTOMER_DASHBOARD_CHART_FAILURE,
  GET_CUSTOMER_DASHBOARD_CHART_REQUEST,
  GET_CUSTOMER_DASHBOARD_CHART_SUCCESS,
  GET_CUSTOMER_DASHBOARD_DATA_FAILURE,
  GET_CUSTOMER_DASHBOARD_DATA_REQUEST,
  GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
  GET_CUSTOMER_ORDER_DATA_FAILURE,
  GET_CUSTOMER_ORDER_DATA_REQUEST,
  GET_CUSTOMER_ORDER_DATA_SUCCESS,
  GET_CUSTOMER_STATE_LIST_FAILURE,
  GET_CUSTOMER_STATE_LIST_REQUEST,
  GET_CUSTOMER_STATE_LIST_SUCCESS,
  GET_CUSTOMER_TICKETS_FAILURE,
  GET_CUSTOMER_TICKETS_REQUEST,
  GET_CUSTOMER_TICKETS_SUCCESS,
  GET_CUSTOMER_TICKET_LOGS_FAILURE,
  GET_CUSTOMER_TICKET_LOGS_REQUEST,
  GET_CUSTOMER_TICKET_LOGS_SUCCESS,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS,
  GET_GOODWILL_REFUND_COUNT_FAILURE,
  GET_GOODWILL_REFUND_COUNT_REQUEST,
  GET_GOODWILL_REFUND_COUNT_SUCCESS,
  GET_GOODWILL_REFUND_HISTORY_FAILURE,
  GET_GOODWILL_REFUND_HISTORY_REQUEST,
  GET_GOODWILL_REFUND_HISTORY_SUCCESS,
  GET_ISSUE_TYPES_FAILURE,
  GET_ISSUE_TYPES_REQUEST,
  GET_ISSUE_TYPES_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_PRODUCT_SKU_FAILURE,
  GET_ORDER_PRODUCT_SKU_REQUEST,
  GET_ORDER_PRODUCT_SKU_SUCCESS,
  GET_ORDER_TRACKING_FAILURE,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_SUCCESS,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
  ORDER_REFUND_FAILURE,
  ORDER_REFUND_REQUEST,
  ORDER_REFUND_SUCCESS,
  ORDER_REPLACE_CANCEL_ORDER_FAILURE,
  ORDER_REPLACE_CANCEL_ORDER_REQUEST,
  ORDER_REPLACE_CANCEL_ORDER_SUCCESS,
  ORDER_SYNCHRONIZED_FAILURE,
  ORDER_SYNCHRONIZED_REQUEST,
  ORDER_SYNCHRONIZED_RESET,
  ORDER_SYNCHRONIZED_SUCCESS,
  POST_REPLY_ON_TICKET_FAILURE,
  POST_REPLY_ON_TICKET_REQUEST,
  POST_REPLY_ON_TICKET_SUCCESS,
  SHARE_COUPANS_FAILURE,
  SHARE_COUPANS_REQUEST,
  SHARE_COUPANS_SUCCESS,
  UPDATE_ACCOUNT_DETAILS_FAILURE,
  UPDATE_ACCOUNT_DETAILS_REQUEST,
  UPDATE_ACCOUNT_DETAILS_RESET,
  UPDATE_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_RESET,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_INFO_FAILURE,
  UPDATE_CUSTOMER_INFO_REQUEST,
  UPDATE_CUSTOMER_INFO_RESET,
  UPDATE_CUSTOMER_INFO_SUCCESS,
  UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE,
  UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST,
  UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS,
  UPDATE_PRIORITY_FAILURE,
  UPDATE_PRIORITY_REQUEST,
  UPDATE_PRIORITY_SUCCESS,
  VERIFY_MOBILE_NUMBER_OTP_FAILURE,
  VERIFY_MOBILE_NUMBER_OTP_REQUEST,
  VERIFY_MOBILE_NUMBER_OTP_SUCCESS,
} from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import { CLEAR_ERRORS } from "../../Constants/UserConstants/UserConstants";

export const getCustomerDashboardReducers = (
  state = { customerTilesdata: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_DASHBOARD_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        customerTilesdata: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerCurrentOrderReducers = (
  state = { customerOrder: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDER_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_ORDER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        customerOrder: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_ORDER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerTicketsReducers = (
  state = { customerTickets: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerTickets: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerOrderDetailsReducer = (
  state = { orderDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_ORDER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
        message: action.payload.message,
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerTicketLogsReducers = (
  state = { ticketLogs: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_TICKET_LOGS_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_TICKET_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketLogs: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_TICKET_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const orderSynchronizedReducers = (
  state = { synchronized: {} },
  action
) => {
  switch (action.type) {
    case ORDER_SYNCHRONIZED_REQUEST:
      return {
        loading: true,
      };
    case ORDER_SYNCHRONIZED_SUCCESS:
      return {
        ...state,
        loading: false,
        synchronized: action.payload.message,
        isSynchronized: true,
      };
    case ORDER_SYNCHRONIZED_RESET:
      return {
        ...state,
        loading: false,
        isSynchronized: false,
      };
    case ORDER_SYNCHRONIZED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getOrderTrackingReducers = (
  state = { ordertracking: {} },
  action
) => {
  switch (action.type) {
    case GET_ORDER_TRACKING_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        ordertracking: action.payload,
      };
    case GET_ORDER_TRACKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const postReplyOnTicketReducers = (state = { reply: {} }, action) => {
  switch (action.type) {
    case POST_REPLY_ON_TICKET_REQUEST:
      return {
        loading: true,
      };
    case POST_REPLY_ON_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        reply: action.payload,
        message: action.payload.message,
        isReplied: true,
      };
    case POST_REPLY_ON_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        isReplied: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getReasonsforCancellingOrderReducers = (
  state = { reasons: {} },
  action
) => {
  switch (action.type) {
    case GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST:
      return {
        loading: true,
      };
    case GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        reasons: action.payload,
        message: action.payload.message,
      };
    case GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getSubReasonsforCancellingOrderReducers = (
  state = { Sub_reasons: {} },
  action
) => {
  switch (action.type) {
    case GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST:
      return {
        loading: true,
      };
    case GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        Sub_reasons: action.payload,
        message: action.payload.message,
      };
    case GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerDashboardChartReducers = (
  state = { customer_dashboard_chart: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_DASHBOARD_CHART_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        customer_dashboard_chart: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_DASHBOARD_CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerDashboardByIdReducers = (
  state = { customer_dashboard_data: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        customer_dashboard_data: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const UpdateCustomerInfoReducers = (
  state = { updated_customer_info: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_INFO_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updated_customer_info: action.payload,
        message: action.payload.message,
        isUpdated: true,
      };
    case UPDATE_CUSTOMER_INFO_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case UPDATE_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const CancelCustomerOrderReducers = (
  state = { cancel_customer_order: {} },
  action
) => {
  switch (action.type) {
    case CANCEL_CUSTOMER_ORDER_REQUEST:
    case CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case CANCEL_CUSTOMER_ORDER_SUCCESS:
    case CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        cancel_customer_order: action.payload,
        message: action.payload.message,
        isCancelled: true,
      };
    case CANCEL_CUSTOMER_ORDER_RESET:
      return {
        ...state,
        loading: false,
        isCancelled: false,
      };
    case CANCEL_CUSTOMER_ORDER_FAILURE:
    case CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const UpdateCustomerMobileNumberReducers = (
  state = { update_mobile_number: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        update_mobile_number: action.payload,
        message: action.payload.message,
      };
    case UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const VerifyMobileNumberOtpReducers = (
  state = { verify_otp: {} },
  action
) => {
  switch (action.type) {
    case VERIFY_MOBILE_NUMBER_OTP_REQUEST:
      return {
        loading: true,
      };
    case VERIFY_MOBILE_NUMBER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        verify_otp: action.payload,
        message: action.payload.message,
      };
    case VERIFY_MOBILE_NUMBER_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const GoodwillRefundCountReducers = (
  state = { goodwillrefund_count: {} },
  action
) => {
  switch (action.type) {
    case GET_GOODWILL_REFUND_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_GOODWILL_REFUND_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        goodwillrefund_count: action.payload,
        message: action.payload.message,
      };
    case GET_GOODWILL_REFUND_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getGoodWillRefundHistoryReducers = (
  state = { goodwillrefund_refund_history: [] },
  action
) => {
  switch (action.type) {
    case GET_GOODWILL_REFUND_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case GET_GOODWILL_REFUND_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        goodwillrefund_refund_history: action.payload,
        message: action.payload.message,
      };
    case GET_GOODWILL_REFUND_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getGoodWillNormalRefundHistoryReducers = (
  state = { goodwillrefund_normal_refund_history: [] },
  action
) => {
  switch (action.type) {
    case GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        goodwillrefund_normal_refund_history: action.payload,
        message: action.payload.message,
      };
    case GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getOrderProductSKUReducers = (
  state = { product_sku_order: {} },
  action
) => {
  switch (action.type) {
    case GET_ORDER_PRODUCT_SKU_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_PRODUCT_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        product_sku_order: action.payload,
        message: action.payload.message,
      };
    case GET_ORDER_PRODUCT_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const orderRefundReducers = (state = { order_refund: {} }, action) => {
  switch (action.type) {
    case ORDER_REFUND_REQUEST:
      return {
        loading: true,
      };
    case ORDER_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
        order_refund: action.payload,
        message: action.payload.message,
      };
    case ORDER_REFUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAllCoupansReducers = (state = { all_coupans: [] }, action) => {
  switch (action.type) {
    case GET_ALL_COUPANS_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_COUPANS_SUCCESS:
      return {
        ...state,
        loading: false,
        all_coupans: action.payload,
        message: action.payload.message,
      };
    case GET_ALL_COUPANS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const CreateReturnReducers = (
  state = { return_order_req: [] },
  action
) => {
  switch (action.type) {
    case CREATE_RETURN_REQUEST:
      return {
        loading: true,
      };
    case CREATE_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        return_order_req: action.payload,
        message: action.payload.message,
      };
    case CREATE_RETURN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const CreateAccountDetailsReducers = (
  state = { account_details: {} },
  action
) => {
  switch (action.type) {
    case CREATE_ACCOUNT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        account_details: action.payload,
        message: action.payload.message,
      };
    case CREATE_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerAddressReducers = (
  state = { address_list: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ADDRESS_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address_list: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCustomerStateListReducers = (
  state = { all_state_list: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_STATE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        all_state_list: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_STATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const UpdateCustomerAddressReducers = (
  state = { customer_address_update: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_ADDRESS_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        customer_address_update: action.payload,
        message: action.payload.message,
        isUpdated: true,
      };
    case UPDATE_CUSTOMER_ADDRESS_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case UPDATE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const CustomerAcountDetailsListingReducers = (
  state = { account_details_list: {} },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        account_details_list: action.payload,
        message: action.payload.message,
      };
    case GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const UpdateAccountDetailsReducers = (
  state = { update_account_details_list: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_ACCOUNT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        update_account_details_list: action.payload,
        message: action.payload.message,
        isUpdated: true,
      };
    case UPDATE_ACCOUNT_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
      };
    case UPDATE_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getIssueTypesReducers = (
  state = { issue_types_list: {} },
  action
) => {
  switch (action.type) {
    case GET_ISSUE_TYPES_REQUEST:
      return {
        loading: true,
      };
    case GET_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        issue_types_list: action.payload,
        message: action.payload.message,
      };
    case GET_ISSUE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const CreateNewTicketReducers = (
  state = { new_ticket_creation: {} },
  action
) => {
  switch (action.type) {
    case CREATE_NEW_TICKET_REQUEST:
      return {
        loading: true,
      };
    case CREATE_NEW_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        new_ticket_creation: action.payload,
        message: action.payload.message,
        isCreated: true,
      };
    case CREATE_NEW_TICKET_RESET:
      return {
        ...state,
        loading: false,
        isCreated: false,
      };
    case CREATE_NEW_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAllPriorityReducers = (
  state = { priority_list: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_PRIORITY_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        priority_list: action.payload,
        message: action.payload.message,
      };
    case GET_ALL_PRIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updatePriorityReducers = (
  state = { update_priority: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_PRIORITY_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        update_priority: action.payload,
        message: action.payload.message,
      };
    case UPDATE_PRIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const sendCoupansReducers = (
  state = { coupans_sharing: {} },
  action
) => {
  switch (action.type) {
    case SHARE_COUPANS_REQUEST:
      return {
        loading: true,
      };
    case SHARE_COUPANS_SUCCESS:
      return {
        ...state,
        loading: false,
        coupans_sharing: action.payload,
        message: action.payload.message,
      };
    case SHARE_COUPANS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const cancelReplaceOrderReducers = (
  state = { order_replace_cancel: {} },
  action
) => {
  switch (action.type) {
    case ORDER_REPLACE_CANCEL_ORDER_REQUEST:
      return {
        loading: true,
      };
    case ORDER_REPLACE_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order_replace_cancel: action.payload,
        message: action.payload.message,
      };
    case ORDER_REPLACE_CANCEL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
