import React from "react";
import { Bar } from "react-chartjs-2";

const GroupedBarChart = ({ ticket_issue_data }) => {
  const labels = ticket_issue_data && ticket_issue_data.map((i) => i.month);

  const dataSets = [];

  Object.keys(ticket_issue_data[0]).forEach((key) => {
    if (key !== "month" && key !== "year" && key !== "total_tickets") {
      dataSets.push({
        label: key,
        data: ticket_issue_data && ticket_issue_data.map((item) => item[key]),
        backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${
          Math.random() * 255
        },0.6)`,
        borderRadius: 100,
        barPercentage: 0.8,
        categoryPercentage: 0.2,
      });
    }
  });

  const data = {
    labels: labels,
    datasets: dataSets,
  };

  const options = {
    scales: {
      y: {
        border: { dash: [7, 7] }, // for the grid lines
        grid: {
          color: "#E5E5EF", // for the grid lines
          tickColor: "#E5E5EF", // for the tick mark
          tickBorderDash: [8, 8], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 1,
          offset: true,
          drawTicks: true, // true is default
          drawOnChartArea: true, // true is default
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
        beginAtZero: true,
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          lineWidth: 5,
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
      },
    },
    plugins: {
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your tooltip body
        },
        titleFont: {
          family: "Poppins_SemiBold",
        },
      },
      responsive: true,
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
          },
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default GroupedBarChart;
