export const GET__TEAM_DASHBOARD_DATA_REQUEST =
  "GET__TEAM_DASHBOARD_DATA_REQUEST";
export const GET__TEAM_DASHBOARD_DATA_SUCCESS =
  "GET__TEAM_DASHBOARD_DATA_SUCCESS";
export const GET__TEAM_DASHBOARD_DATA_FAILURE =
  "GET__TEAM_DASHBOARD_DATA_FAILURE";

export const GET_TEAM_DOUGHNUT_REQUEST = "GET_TEAM_DOUGHNUT_REQUEST";
export const GET_TEAM_DOUGHNUT_SUCCESS = "GET_TEAM_DOUGHNUT_SUCCESS";
export const GET_TEAM_DOUGHNUT_FAILURE = "GET_TEAM_DOUGHNUT_FAILURE";

export const GET_TEAM_ALL_AGENTS_REQUEST = "GET_TEAM_ALL_AGENTS_REQUEST";
export const GET_TEAM_ALL_AGENTS_SUCCESS = "GET_TEAM_ALL_AGENTS_SUCCESS";
export const GET_TEAM_ALL_AGENTS_FAILURE = "GET_TEAM_ALL_AGENTS_FAILURE";

export const GET_TEAM_NEW_TICKETS_REQUEST = "GET_TEAM_NEW_TICKETS_REQUEST";
export const GET_TEAM_NEW_TICKETS_SUCCESS = "GET_TEAM_NEW_TICKETS_SUCCESS";
export const GET_TEAM_NEW_TICKETS_FAILURE = "GET_TEAM_NEW_TICKETS_FAILURE";

export const GET_TEAM_ALL_TICKETS_REQUEST = "GET_TEAM_ALL_TICKETS_REQUEST";
export const GET_TEAM_ALL_TICKETS_SUCCESS = "GET_TEAM_ALL_TICKETS_SUCCESS";
export const GET_TEAM_ALL_TICKETS_FAILURE = "GET_TEAM_ALL_TICKETS_FAILURE";

export const GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST =
  "GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST";
export const GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS =
  "GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS";
export const GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE =
  "GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE";

export const ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST =
  "ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST";
export const ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS =
  "ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS";
export const ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE =
  "ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE";
export const ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET =
  "ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET";

export const GET_TEAM_TICKET_DETAILS__REQUEST =
  "GET_TEAM_TICKET_DETAILS__REQUEST";
export const GET_TEAM_TICKET_DETAILS__SUCCESS =
  "GET_TEAM_TICKET_DETAILS__SUCCESS";
export const GET_TEAM_TICKET_DETAILS__FAILURE =
  "GET_TEAM_TICKET_DETAILS__FAILURE";

export const GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST =
  "GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST";
export const GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS =
  "GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS";
export const GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE =
  "GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE";

export const GET_TICKET_ALL_STATUSES__REQUEST =
  "GET_TICKET_ALL_STATUSES__REQUEST";
export const GET_TICKET_ALL_STATUSES__SUCCESS =
  "GET_TICKET_ALL_STATUSES__SUCCESS";
export const GET_TICKET_ALL_STATUSES__FAILURE =
  "GET_TICKET_ALL_STATUSES__FAILURE";

export const UPDATE_TICKET_STATUS__REQUEST = "UPDATE_TICKET_STATUS__REQUEST";
export const UPDATE_TICKET_STATUS__SUCCESS = "UPDATE_TICKET_STATUS__SUCCESS";
export const UPDATE_TICKET_STATUS__FAILURE = "UPDATE_TICKET_STATUS__FAILURE";
export const UPDATE_TICKET_STATUS__RESET = "UPDATE_TICKET_STATUS__RESET";

export const GET_CALL_DETAILS_BY_TICKET_ID_REQUEST =
  "GET_CALL_DETAILS_BY_TICKET_ID_REQUEST";
export const GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS =
  "GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS";
export const GET_CALL_DETAILS_BY_TICKET_ID_FAILURE =
  "GET_CALL_DETAILS_BY_TICKET_ID_FAILURE";

export const GET_TICKET_CONVERSATION_BY_ID_REQUEST =
  "GET_TICKET_CONVERSATION_BY_ID_REQUEST";
export const GET_TICKET_CONVERSATION_BY_ID_SUCCESS =
  "GET_TICKET_CONVERSATION_BY_ID_SUCCESS";
export const GET_TICKET_CONVERSATION_BY_ID_FAILURE =
  "GET_TICKET_CONVERSATION_BY_ID_FAILURE";

export const GET_TICKET_AGING_REPORT_GRAPH_REQUEST =
  "GET_TICKET_AGING_REPORT_GRAPH_REQUEST";
export const GET_TICKET_AGING_REPORT_GRAPH_SUCCESS =
  "GET_TICKET_AGING_REPORT_GRAPH_SUCCESS";
export const GET_TICKET_AGING_REPORT_GRAPH_FAILURE =
  "GET_TICKET_AGING_REPORT_GRAPH_FAILURE";

export const GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST =
  "GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST";
export const GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS =
  "GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS";
export const GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE =
  "GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE";

export const GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST =
  "GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST";
export const GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS =
  "GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS";
export const GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE =
  "GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE";

export const GET_TICKET_DETAILS_REMARKS_REQUEST =
  "GET_TICKET_DETAILS_REMARKS_REQUEST";
export const GET_TICKET_DETAILS_REMARKS_SUCCESS =
  "GET_TICKET_DETAILS_REMARKS_SUCCESS";
export const GET_TICKET_DETAILS_REMARKS_FAILURE =
  "GET_TICKET_DETAILS_REMARKS_FAILURE";

export const ADD_REMARKS_REQUEST = "ADD_REMARKS_REQUEST";
export const ADD_REMARKS_SUCCESS = "ADD_REMARKS_SUCCESS";
export const ADD_REMARKS_FAILURE = "ADD_REMARKS_FAILURE";
export const ADD_REMARKS_RESET = "ADD_REMARKS_RESET";

export const GET_TEAM_LEAD_CALL_COUNT_REQUEST = "GET_TEAM_LEAD_CALL_COUNT_REQUEST";
export const GET_TEAM_LEAD_CALL_COUNT_SUCCESS = "GET_TEAM_LEAD_CALL_COUNT_SUCCESS";
export const GET_TEAM_LEAD_CALL_COUNT_FAILURE = "GET_TEAM_LEAD_CALL_COUNT_FAILURE";