import React from "react";
import "./progress-bar.css";
import { Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';

const ProgressBar = (props) => {
  const fiveStarUsers = parseInt(props.fiveStarUsers, 10) || 0;
  const totalUsers = parseInt(props.totalUsers, 10) || 0;

  // Avoid division by zero and ensure progress is capped at 100%
  const progress =
    totalUsers !== 0 ? Math.min((fiveStarUsers / totalUsers) * 100, 100) : 0;

  return (
    <Tooltip arrow TransitionComponent={Zoom} title={`Total: ${props.fiveStarUsers}`}>
      <div className="progress-bar">
        <div
          className="progress"
          style={{
            width: `${progress}%`,
            backgroundColor: props.bgcolor,
            height: "6px",
            borderRadius: "3px",
          }}
        ></div>
      </div>
    </Tooltip>
  );
};

export default ProgressBar;
