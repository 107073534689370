import { BaseURL } from "../../BaseURL";
import {
  POST_REPLY_ON_TICKET_FAILURE,
  POST_REPLY_ON_TICKET_REQUEST,
  POST_REPLY_ON_TICKET_SUCCESS,
} from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import {
  ADD_REMARKS_FAILURE,
  ADD_REMARKS_REQUEST,
  ADD_REMARKS_SUCCESS,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS,
  GET_CALL_DETAILS_BY_TICKET_ID_FAILURE,
  GET_CALL_DETAILS_BY_TICKET_ID_REQUEST,
  GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS,
  GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE,
  GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST,
  GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS,
  GET_TEAM_ALL_AGENTS_FAILURE,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS,
  GET_TEAM_ALL_AGENTS_REQUEST,
  GET_TEAM_ALL_AGENTS_SUCCESS,
  GET_TEAM_ALL_TICKETS_FAILURE,
  GET_TEAM_ALL_TICKETS_REQUEST,
  GET_TEAM_ALL_TICKETS_SUCCESS,
  GET_TEAM_DOUGHNUT_FAILURE,
  GET_TEAM_DOUGHNUT_REQUEST,
  GET_TEAM_DOUGHNUT_SUCCESS,
  GET_TEAM_LEAD_CALL_COUNT_FAILURE,
  GET_TEAM_LEAD_CALL_COUNT_REQUEST,
  GET_TEAM_LEAD_CALL_COUNT_SUCCESS,
  GET_TEAM_NEW_TICKETS_FAILURE,
  GET_TEAM_NEW_TICKETS_REQUEST,
  GET_TEAM_NEW_TICKETS_SUCCESS,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS,
  GET_TEAM_TICKET_DETAILS__FAILURE,
  GET_TEAM_TICKET_DETAILS__REQUEST,
  GET_TEAM_TICKET_DETAILS__SUCCESS,
  GET_TICKET_AGING_REPORT_GRAPH_FAILURE,
  GET_TICKET_AGING_REPORT_GRAPH_REQUEST,
  GET_TICKET_AGING_REPORT_GRAPH_SUCCESS,
  GET_TICKET_ALL_STATUSES__FAILURE,
  GET_TICKET_ALL_STATUSES__REQUEST,
  GET_TICKET_ALL_STATUSES__SUCCESS,
  GET_TICKET_CONVERSATION_BY_ID_FAILURE,
  GET_TICKET_CONVERSATION_BY_ID_REQUEST,
  GET_TICKET_CONVERSATION_BY_ID_SUCCESS,
  GET_TICKET_DETAILS_REMARKS_FAILURE,
  GET_TICKET_DETAILS_REMARKS_REQUEST,
  GET_TICKET_DETAILS_REMARKS_SUCCESS,
  GET__TEAM_DASHBOARD_DATA_FAILURE,
  GET__TEAM_DASHBOARD_DATA_REQUEST,
  GET__TEAM_DASHBOARD_DATA_SUCCESS,
  UPDATE_TICKET_STATUS__FAILURE,
  UPDATE_TICKET_STATUS__REQUEST,
  UPDATE_TICKET_STATUS__SUCCESS,
} from "../../Constants/TeamLeaderConstants/TeamLeaderConstants";
import api from "../../axiosConfig";

export const getTeamCountsInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET__TEAM_DASHBOARD_DATA_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/team-lead-dashboard`);
    dispatch({
      type: GET__TEAM_DASHBOARD_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    debugger;
    dispatch({
      type: GET__TEAM_DASHBOARD_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamDougnut = (from_date, to_date) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_DOUGHNUT_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/team-lead-tickets-count?from_date=${from_date}&to_date=${to_date}`
    );
    dispatch({
      type: GET_TEAM_DOUGHNUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_DOUGHNUT_FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamDougnut_with_normal = (date) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_DOUGHNUT_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/team-lead-tickets-count?date=${date}`
    );
    dispatch({
      type: GET_TEAM_DOUGHNUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_DOUGHNUT_FAILURE,
      payload: error.message,
    });
  }
};

export const getAllAgents = (per_page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_ALL_AGENTS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/all-agents?per_page=${per_page}`
    );
    dispatch({
      type: GET_TEAM_ALL_AGENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_ALL_AGENTS_FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamNewTickets = (page, per_page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_NEW_TICKETS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/team-lead-new-tickets?page=${page}&per_page=${per_page}`
    );
    dispatch({
      type: GET_TEAM_NEW_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_NEW_TICKETS_FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamAllTickets = (page, per_page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_ALL_TICKETS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/team-lead-all-tickets?page=${page}&per_page=${per_page}`
    );
    dispatch({
      type: GET_TEAM_ALL_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_ALL_TICKETS_FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamAllAgentsListForAssignee = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/agent-list`);
    dispatch({
      type: GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE,
      payload: error.message,
    });
  }
};

export const assignNewTeamTickets =
  (ticket_id, agent_id) => async (dispatch) => {
    try {
      dispatch({
        type: ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/assign-ticket`, {
        ticket_id,
        agent_id,
      });
      dispatch({
        type: ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getTeamTicketDetails = (ticket_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_TICKET_DETAILS__REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/ticket-details?ticket_id=${ticket_id}`
    );
    dispatch({
      type: GET_TEAM_TICKET_DETAILS__SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_TICKET_DETAILS__FAILURE,
      payload: error.message,
    });
  }
};

export const getTeamTicketDetailsWithId = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/ticket-details?order_id=${order_id}`
    );
    dispatch({
      type: GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE,
      payload: error.message,
    });
  }
};

export const getTicketStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TICKET_ALL_STATUSES__REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/ticket-status`);
    dispatch({
      type: GET_TICKET_ALL_STATUSES__SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TICKET_ALL_STATUSES__FAILURE,
      payload: error.message,
    });
  }
};

export const updateTicketStatus =
  (ticket_id, ticket_status) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_TICKET_STATUS__REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/update-ticket-status`, {
        ticket_id,
        ticket_status,
      });
      dispatch({
        type: UPDATE_TICKET_STATUS__SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TICKET_STATUS__FAILURE,
        payload: error.message,
      });
    }
  };

export const getCallDetailsAction = (ticket_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CALL_DETAILS_BY_TICKET_ID_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/get-ticket-all-calls?ticket_id=${ticket_id}`
    );
    dispatch({
      type: GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CALL_DETAILS_BY_TICKET_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const getTicketConversation =
  (ticket_id, per_page, page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TICKET_CONVERSATION_BY_ID_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/ticket-conversations?ticket_id=${ticket_id}&per_page=${per_page}&page=${page}`
      );
      dispatch({
        type: GET_TICKET_CONVERSATION_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKET_CONVERSATION_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const ReplyOnConversation =
  (ticket_id, message, attachment) => async (dispatch) => {
    try {
      dispatch({
        type: POST_REPLY_ON_TICKET_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/reply-on-ticket`, {
        ticket_id,
        message,
        attachment,
      });
      dispatch({
        type: POST_REPLY_ON_TICKET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_REPLY_ON_TICKET_FAILURE,
        payload: error.message,
      });
    }
  };

export const getTicketAgingGraphAction = (date) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TICKET_AGING_REPORT_GRAPH_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/tickets-aging?date=${date}`
    );
    dispatch({
      type: GET_TICKET_AGING_REPORT_GRAPH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TICKET_AGING_REPORT_GRAPH_FAILURE,
      payload: error.message,
    });
  }
};

export const getIssueTypeTicketGraphAction = (date) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/tickets-by-issue?date=${date}`
    );
    dispatch({
      type: GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE,
      payload: error.message,
    });
  }
};

export const getPriorityTypeTicketGraphAction = (date) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/tickets-by-priorities?date=${date}`
    );
    dispatch({
      type: GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE,
      payload: error.message,
    });
  }
};

export const getTicketDetailsRemarksAction =
  (ticket_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TICKET_DETAILS_REMARKS_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/comment-log?ticket_id=${ticket_id}`
      );
      dispatch({
        type: GET_TICKET_DETAILS_REMARKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKET_DETAILS_REMARKS_FAILURE,
        payload: error.message,
      });
    }
  };

// export const getTicketDetailsWithOrderIdRemarksAction =
//   (order_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: GET_TICKET_DETAILS_REMARKS_REQUEST,
//       });

//       const { data } = await api.get(
//         `${BaseURL}/agent/comment-log?order_id=${order_id}`
//       );
//       dispatch({
//         type: GET_TICKET_DETAILS_REMARKS_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: GET_TICKET_DETAILS_REMARKS_FAILURE,
//         payload: error.message,
//       });
//     }
//   };

export const AddRemarksAction =
  (type, comment, reference_id) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_REMARKS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/comment-on-ticket?type=${type}&comment=${comment}&reference_id=${reference_id}`
      );
      dispatch({
        type: ADD_REMARKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_REMARKS_FAILURE,
        payload: error.message,
      });
    }
  };

export const Get_Team_Lead_CountsAction =
  (page, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TEAM_LEAD_CALL_COUNT_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/get-teamlead-calls?page=${page}&per_page=${per_page}`
      );
      dispatch({
        type: GET_TEAM_LEAD_CALL_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEAM_LEAD_CALL_COUNT_FAILURE,
        payload: error.message,
      });
    }
  };
