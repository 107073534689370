import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "../../Assets/User/arrow-left.png";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountdetailsListingActions,
  updateAccountDetailsBankListActions,
  updateAccountDetailsListActions,
} from "../../Actions/CustomerActions/CustomerActions";
import Loader from "../../Components/Loader/Loader";
import UpdateIcon from "../../Assets/Customer-Details/Frame.png";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import { UPDATE_ACCOUNT_DETAILS_RESET } from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import TableLoader from "../../Components/Loader/TableLoader";
import "./AccountDetails.css";
import ModalContainer from "../../Components/Modal/Modal";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import HelmetTitle from "../../Components/MetaData/MetaData";

const AccountDetails = () => {
  const alert = useAlert();
  const { customer_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // account details list state
  const { account_details_list, loading: account_details_loading } =
    useSelector((state) => state.customer_account_details_list);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  const [upi_list, setUpi_list] = useState([]);
  const [bank_acc_list, setbank_acc_list] = useState([]);

  const [isHit, setIsHit] = useState(false);
  const [isIndex, setIsIndex] = useState();

  useEffect(() => {
    account_details_list &&
      account_details_list?.data?.vpa &&
      setUpi_list(account_details_list?.data?.vpa);
  }, [account_details_list]);

  useEffect(() => {
    account_details_list &&
      account_details_list?.data?.bank_accounts &&
      setbank_acc_list(account_details_list?.data?.bank_accounts);
  }, [account_details_list]);

  const [isValidUpiId, setIsValidUpiId] = useState("");

  const handleUpiChange = (field, value, index) => {
    console.log("<<VALUE>> ", value);
    const updatedAddressList = [...upi_list];
    updatedAddressList[index][field] = value;
    setUpi_list(updatedAddressList);
    console.log("NXYZZ", updatedAddressList[0][field]);
    const input = value;
    const upiIdRegex = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/;
    setIsValidUpiId(upiIdRegex.test(input));
  };

  const handleBankChange = (field, value, index) => {
    const updatedAddressList = [...bank_acc_list];
    updatedAddressList[index][field] = value;
    setbank_acc_list(updatedAddressList);
  };

  console.log("UPI ID: ", upi_list);
  console.log("Bank Account: ", bank_acc_list);

  //   update account details state
  const { update_account_details_list, loading: update_details_loading } =
    useSelector((state) => state.update_account_details_data);

  const handleUpi = (id, index) => {
    console.log("IsValidUpi Id: ", isValidUpiId);
    setIsHit(true);
    setIsIndex(index);
    let filter_data = upi_list && upi_list.filter((i) => i.id === id);
    console.log(filter_data);

    // converting into object
    const filtered_data_object = filter_data.reduce((acc, i) => {
      acc["data"] = i;
      return acc;
    }, {});

    let final_obj = filtered_data_object?.data;

    console.log("Final: ", final_obj);
    if (isValidUpiId === false) {
      alert.error("Please Enter a valid UPI Id...");
      setIsValidUpiId("");
    } else if (
      customer_id !== null &&
      customer_id !== "" &&
      final_obj?.upi_id !== "" &&
      final_obj?.upi_id !== null
    ) {
      setIsValidUpiId("");
      dispatch(
        updateAccountDetailsListActions(
          customer_id,
          final_obj?.id,
          "UPI",
          final_obj?.upi_id,
          "update"
        )
      );
    }
  };

  const handleBankAccount = (id, index) => {
    setIsHit(true);
    setIsIndex(index);
    let filter_data = bank_acc_list && bank_acc_list.filter((i) => i.id === id);
    console.log(filter_data);

    // converting into object
    const filtered_data_object = filter_data.reduce((acc, i) => {
      acc["data"] = i;
      return acc;
    }, {});

    let final_obj = filtered_data_object?.data;

    console.log("Final: ", final_obj);
    dispatch(
      updateAccountDetailsBankListActions(
        customer_id,
        final_obj?.id,
        "BankAccount",
        final_obj?.bank_name,
        final_obj?.account_no,
        final_obj?.confirm_account_no,
        final_obj?.ifsc,
        final_obj?.account_name,
        "update"
      )
    );
  };

  useEffect(() => {
    if (
      update_account_details_list &&
      update_account_details_list?.status === "error" &&
      isHit
    ) {
      alert.error(
        update_account_details_list && update_account_details_list?.message
      );
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_RESET,
      });
    } else if (
      update_account_details_list &&
      update_account_details_list?.status === "success" &&
      isHit
    ) {
      alert.success(
        update_account_details_list && update_account_details_list?.message
      );
    }
  }, [update_account_details_list, alert, dispatch, isHit]);

  console.log("Update", account_details_list);

  const [index, setIndex] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  const handleModal = (i) => {
    setIndex(i);
    setIsopen(true);
  };

  const closeModal = () => {
    setIndex(null);
  };

  const renderModal = () => {
    if (index !== null) {
      return (
        <ModalContainer
          Isopen={isOpen}
          index={index}
          closeModal={closeModal}
          onData={closeModal}
          customer_id={customer_id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (customer_id !== "" && customer_id !== null && index === null) {
      dispatch(AccountdetailsListingActions(customer_id));
    }
  }, [customer_id, dispatch, index]);

  return (
    <>
      <HelmetTitle title={`Add/Update Account Details`} />
      <Navbar searchBar={handleSearchBar} />
      {account_details_loading ? (
        <Loader />
      ) : (
        <>
          {account_details_list?.logout ? (
            <NoDataFromApi
              title={
                "Something went wrong, Please Try Logout & Login Again......"
              }
              btn={true}
            />
          ) : (
            <div className="home-container">
              <div className="header-links-container">
                <Link
                  onClick={() => navigate(-1)}
                  className="header-redirection"
                >
                  <img src={ArrowLeft} alt="" />
                  <h1>Edit Account Details</h1>
                </Link>
                <div className="button-action-acc-cta">
                  <Button onClick={() => handleModal(11)}>Add UPI</Button>
                  <Button onClick={() => handleModal(12)}>
                    Add Bank Details
                  </Button>
                </div>
              </div>

              {/* UPI details */}
              {upi_list && upi_list?.length > 0
                ? upi_list?.map((i, index) => (
                    <div
                      key={i.id}
                      className="ticket-details-info edit-profile-h2"
                    >
                      <h2>Update UPI - {index + 1}</h2>
                      <div className="user-info-edit-profile-grid-row">
                        <div>
                          <label htmlFor={`upi_id${index}`}>UPI Id</label>
                          <input
                            id={`upi_id${index}`}
                            value={
                              i.upi_id !== "" && i.upi_id !== null
                                ? i.upi_id
                                : ""
                            }
                            onChange={(e) =>
                              handleUpiChange("upi_id", e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                      <div className="update-profile-cta">
                        <Button onClick={() => handleUpi(i.id, index)}>
                          {isIndex === index && update_details_loading ? (
                            <TableLoader />
                          ) : (
                            <>
                              <img src={UpdateIcon} alt="" />
                              &nbsp;&nbsp; Update
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  ))
                : null}

              {/* Bank details */}
              {bank_acc_list && bank_acc_list?.length > 0
                ? bank_acc_list?.map((i, index) => (
                    <div
                      key={i.id}
                      className="ticket-details-info edit-profile-h2"
                    >
                      <h2>Account Details - {index + 1}</h2>
                      <div className="user-info-edit-profile-grid-row">
                        <div>
                          <label htmlFor={`account_name${index}`}>
                            Account Holder Name
                          </label>
                          <input
                            id={`account_name${index}`}
                            value={
                              i.account_name !== "" && i.account_name !== null
                                ? i.account_name
                                : ""
                            }
                            onChange={(e) =>
                              handleBankChange(
                                "account_name",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`account_number${index}`}>
                            Account Number
                          </label>
                          <input
                            id={`account_number${index}`}
                            value={
                              i.account_no !== "" && i.account_no !== null
                                ? i.account_no
                                : ""
                            }
                            onChange={(e) =>
                              handleBankChange(
                                "account_no",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`confirm_account_number${index}`}>
                            Confirm Account Number
                          </label>
                          <input
                            id={`confirm_account_number${index}`}
                            value={
                              i.confirm_account_no !== "" &&
                              i.confirm_account_no !== null
                                ? i.confirm_account_no
                                : ""
                            }
                            onChange={(e) =>
                              handleBankChange(
                                "confirm_account_no",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`ifsc${index}`}>IFSC Code</label>
                          <input
                            id={`ifsc${index}`}
                            value={
                              i.ifsc !== "" && i.ifsc !== null ? i.ifsc : ""
                            }
                            onChange={(e) =>
                              handleBankChange("ifsc", e.target.value, index)
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`bank_name${index}`}>Bank Name</label>
                          <input
                            id={`bank_name${index}`}
                            value={
                              i.bank_name !== "" && i.bank_name !== null
                                ? i.bank_name
                                : ""
                            }
                            onChange={(e) =>
                              handleBankChange(
                                "bank_name",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="update-profile-cta">
                        <Button onClick={() => handleBankAccount(i.id, index)}>
                          {isIndex === index && update_details_loading ? (
                            <TableLoader />
                          ) : (
                            <>
                              <img src={UpdateIcon} alt="" />
                              &nbsp;&nbsp; Update
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          )}
        </>
      )}
      {renderModal()}
    </>
  );
};

export default AccountDetails;
