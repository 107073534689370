export const agentsList = [
  {
    userId: 101,
    username: "Jenny",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 4.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1.png"),
  },
  {
    userId: 102,
    username: "Nikki Rose",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 2.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1 (1).png"),
  },
  {
    userId: 103,
    username: "Marry",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 4.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1 (2).png"),
  },
  {
    userId: 104,
    username: "James",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 4.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1 (3).png"),
  },
  {
    userId: 105,
    username: "Barbie",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 4.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1 (4).png"),
  },
  {
    userId: 106,
    username: "Bunny",
    AvgCallingTime: "10 Minutes",
    CallCount: "16 Calls",
    AvgFeedBack: 4.6,
    pendingTicket: 10,
    completeTicket: 6,
    totalTicket: "16 Tickets",
    totaldailytalktimeavg: "2 Hours",
    uri: require("../Assets/Agents-Img/Ellipse 1 (5).png"),
  },
];

export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#F0F0F0",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "23px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
  }),
};

export const customStylesSKU = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#6C6C6C",
    backgroundColor: state.isSelected ? "#fffff" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#fffff",
    border: "1px solid #E6E6E6",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "11px",
    "&:hover": {
      border: "1px solid #E6E6E6 !important",
    },
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#6C6C6C" }),
};

export const dateArray = [
  {
    id: 0,
    date: "Today",
  },
  {
    id: 1,
    date: "1 Month",
  },
  {
    id: 3,
    date: "3 Month",
  },
  {
    id: 6,
    date: "6 Month",
  },
];

export const agent_dateArray = [
  {
    id: 0,
    date: "Today",
  },
  {
    id: 1,
    date: "1 Month",
  },
  {
    id: 3,
    date: "3 Month",
  },
  {
    id: 6,
    date: "6 Month",
  },
  {
    id: 7,
    date: "Custom Date"
  }
];

export const AgentsDashboardcolorfulObjects = [
  {
    id: 1,
    count: 60,
    title: "Total Tickets",
  },
  {
    id: 2,
    count: 35,
    title: "Resolve Tickets",
  },
  {
    id: 3,
    count: 15,
    title: "Hold Tickets",
  },
  {
    id: 4,
    count: 10,
    title: "Pending Tickets",
  },
  {
    id: 5,
    count: 40,
    title: "Total Call Counts",
  },
  {
    id: 6,
    count: "1000 Minutes",
    title: "Total Call Volume",
  },
  {
    id: 7,
    count: "2 Hours",
    title: "Total Daily Talk Time",
  },
];
