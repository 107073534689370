import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./coupans.css";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCoupansActions,
  sendCoupansActions,
} from "../../../Actions/CustomerActions/CustomerActions";
import PaginationLoader from "../../Loader/PaginationLoader";
import { useAlert } from "react-alert";
import { useState } from "react";
import TableLoader from "../../Loader/TableLoader";

const ShareCoupans = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { customer_id } = props;

  const { all_coupans, loading } = useSelector((state) => state.coupans);

  // share coupans state
  const { coupans_sharing, loading: coupans_loading } = useSelector(
    (state) => state.coupansShare
  );

  useEffect(() => {
    dispatch(getAllCoupansActions());
  }, [dispatch]);

  let coupans = all_coupans && all_coupans?.data;

  console.log("Customer_Id: ", props.customer_id);

  const [isShared, setisShared] = useState(false);
  const [isIndex, setIsIndex] = useState();

  const sendCoupans = (id, index) => {
    setisShared(true);
    setIsIndex(index);
    if (customer_id !== null && id !== "" && id !== null) {
      dispatch(sendCoupansActions(customer_id, id));
    }
  };

  console.log(coupans_sharing);

  useEffect(() => {
    if (coupans_sharing && coupans_sharing?.success === true && isShared) {
      alert.success(coupans_sharing && coupans_sharing?.message);
      setisShared(false);
    } else if (
      coupans_sharing &&
      coupans_sharing?.success === false &&
      isShared
    ) {
      alert.error(coupans_sharing && coupans_sharing?.message);
      setisShared(false);
    }
  }, [coupans_sharing, isShared, alert]);

  return (
    <>
      <HeadingTitle title="Share Coupons" closeModal={props.close} />
      {loading ? (
        <PaginationLoader />
      ) : (
        <div className="coupans-wrapper">
          {coupans &&
            coupans.map((i, index) => (
              <div
                key={index}
                className="coupans"
                style={{
                  backgroundColor: i.color || "rgb(247, 126, 184)",
                  display: new Date(i.end_date) < new Date() ? "none" : "block",
                }}
              >
                {i.customer_based === 1 && (
                  <div className="flag-coupons">
                    <p>Customer Base Coupon</p>
                  </div>
                )}
                <div
                  className="header-coupan-flex-row"
                  style={{ paddingTop: i.customer_based === 0 && "10px" }}
                >
                  <h3>
                    Offer Price{" "}
                    {parseInt(i.type) === 0
                      ? "₹"
                      : parseInt(i.type) === 1
                      ? "%"
                      : null}
                    {parseInt(i.type) === 0
                      ? i.amount
                      : parseInt(i.type) === 1
                      ? i.discount
                      : null}
                  </h3>
                  <Button onClick={() => sendCoupans(i.id, index)}>
                    {coupans_loading && isIndex === index ? (
                      <TableLoader isSmall={true} />
                    ) : (
                      "SEND"
                    )}
                  </Button>
                </div>
                <p className="coupan-content">{i.description}</p>
                <Button
                  className="cta-product"
                  onClick={() => navigator.clipboard.writeText(i.code)}
                >
                  {i.code}
                </Button>
                <p className="coupan-content date-coupan">
                  Valid Date: {i.end_date}
                </p>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default ShareCoupans;
