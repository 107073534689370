import React from "react";
import "./Navbar.css";
import Logo from "../../Assets/Logo.png";
import searchIcon from "../../Assets/SideBar-Icons/search.png";
import Avatar from "../../Assets/SideBar-Icons/User-logo.png";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { BaseURL } from "../../BaseURL";
import axios from "axios";
import TableLoader from "../Loader/TableLoader";
// import { useDispatch } from "react-redux";

const Navbar = (props) => {
  let user = JSON.parse(localStorage.getItem("user"));

  let token = user && user?.api_token;
  const alert = useAlert();
  const navigate = useNavigate();
  // const { customerTilesdata, loading } = useSelector((state) => state.customer);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  console.log("Token: ", token);

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second delay between retries

  const [loading, setLoading] = useState(false);
  const handleLogout = async () => {
    setLoading(true);
    let retries = 0;

    while (retries < MAX_RETRIES) {
      try {
        const response = await axios.get(`${BaseURL}/agent/logout`, {
          headers,
        });

        if (response && response?.data?.success === true) {
          alert.success(response && response?.data?.message);
          localStorage.removeItem("user");
          navigate("/login");
          setLoading(false);
        }
        // If the request is successful, exit the loop
        break;
      } catch (error) {
        if (error?.response?.status === 500) {
        } else {
          setLoading(false);
          alert.error(error.message);
        }
        retries++;

        // If it's not the last retry, wait before retrying
        if (retries < MAX_RETRIES) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }
  };

  const [phone_number, setPhoneNumber] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    if (phone_number === "") {
      return alert.error(
        "Please Search with the Mobile Number, Email or Order Number..."
      );
    }
    props.searchBar(phone_number);
    setPhoneNumber("");
  };

  const getUserName = (str) => {
    if (!str) {
      return "";
    }

    const trimmedStr = str.trim();
    const firstLetter = trimmedStr.charAt(0);
    const nextSpaceIndex = trimmedStr.indexOf(" ");

    if (nextSpaceIndex === -1) {
      return firstLetter;
    }

    return firstLetter + trimmedStr.charAt(nextSpaceIndex + 1);
  };

  const userName = getUserName(user && user.full_name);

  // const searchParams = new URLSearchParams(window.location.search);
  // let phone_number_params = searchParams.get("phone_number");

  // useEffect(() => {
  //   if (props.loaded === true) {
  //     dispatch(getCustomerTilesAction(phone_number_params));
  //   }
  // }, [dispatch, props.loaded, phone_number_params]);

  // let usename = customerTilesdata?.data?.customer;

  return (
    <>
      <div
        className="navbar-container"
        style={{ zIndex: props.zindex && "9999999" }}
      >
        <div className="navbar-d-flex">
          <div>
            <Link to={`/`}>
              <img className="hiscraves-logo" src={Logo} alt="" />
            </Link>
          </div>
          <div>
            <div className="search-area">
              <form onSubmit={(e) => handleSearch(e)}>
                <input
                  type="text"
                  value={phone_number}
                  id="phone_number"
                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[a-zA-Z0-9@. ]*$/;

                    if (regex.test(input)) {
                      setPhoneNumber(input);
                    } else {
                      alert.error("Special Character Not Allowed...");
                    }
                    // You might want to provide some form of visual feedback here for the user if the input is invalid
                  }}
                  placeholder={
                    "Search by Email, Mobile Number and Order Number...."
                  }
                />
                <Button type="submit" className="search-btn">
                  <img src={searchIcon} alt="searchIcon" />
                </Button>
              </form>
            </div>
          </div>
          <div>
            <div className="avatar-flex-row">
              {user && user ? (
                <div className="login-user-icon">
                  <p>{userName}</p>
                </div>
              ) : (
                <img src={Avatar} alt="avatar" />
              )}
              <div>
                <p>{user && user.full_name}</p>
                <Button
                  style={{ margin: "auto", display: "flex" }}
                  className="logout"
                  onClick={handleLogout}
                >
                  {loading ? <TableLoader /> : "Logout"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
