export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";

export const GET_AGENTS_DOUGHNUT_REQUEST = "GET_AGENTS_DOUGHNUT_REQUEST";
export const GET_AGENTS_DOUGHNUT_SUCCESS = "GET_AGENTS_DOUGHNUT_SUCCESS";
export const GET_AGENTS_DOUGHNUT_FAILURE = "GET_AGENTS_DOUGHNUT_FAILURE";

export const GET_AGENT_BY_ID_REQUEST = "GET_AGENT_BY_ID_REQUEST";
export const GET_AGENT_BY_ID_SUCCESS = "GET_AGENT_BY_ID_SUCCESS";
export const GET_AGENT_BY_ID_FAILURE = "GET_AGENT_BY_ID_FAILURE";

export const GET_AGENT_DOUGHUNUT_BY_ID_REQUEST =
  "GET_AGENT_DOUGHUNUT_BY_ID_REQUEST";
export const GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS =
  "GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS";
export const GET_AGENT_DOUGHUNUT_BY_ID_FAILURE =
  "GET_AGENT_DOUGHUNUT_BY_ID_FAILURE";

export const GET_AGENT_TICKETS_BY_ID_REQUEST =
  "GET_AGENT_TICKETS_BY_ID_REQUEST";
export const GET_AGENT_TICKETS_BY_ID_SUCCESS =
  "GET_AGENT_TICKETS_BY_ID_SUCCESS";
export const GET_AGENT_TICKETS_BY_ID_FAILURE =
  "GET_AGENT_TICKETS_BY_ID_FAILURE";

export const GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST =
  "GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST";
export const GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS =
  "GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS";
export const GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE =
  "GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE";

export const CALL_TO_CUSTOMER_REQUEST = "CALL_TO_CUSTOMER_REQUEST";
export const CALL_TO_CUSTOMER_SUCCESS = "CALL_TO_CUSTOMER_SUCCESS";
export const CALL_TO_CUSTOMER_FAILURE = "CALL_TO_CUSTOMER_FAILURE";

export const GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST = "GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST";
export const GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS = "GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS";
export const GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE = "GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE";

export const GET_AGENT_CALL_COUNT_REQUEST = "GET_AGENT_CALL_COUNT_REQUEST";
export const GET_AGENT_CALL_COUNT_SUCCESS = "GET_AGENT_CALL_COUNT_SUCCESS";
export const GET_AGENT_CALL_COUNT_FAILURE = "GET_AGENT_CALL_COUNT_FAILURE";
