import React from "react";
import "./Remarks.css";

const Remarks = (i) => {
  console.log(i);
  return (
    <div className="remarks-wrapper">
      <div className="remarks-user-info-flex-wrapper">
        <div>
          <div className="img-remarks-user">
            {i.avatar_location && i.avatar_location !== null ? (
              <img src={i.avatar_location} alt="" />
            ) : (
              <div className="user-remarks-name-img">
                {i?.agent?.first_name?.slice(0, 1)}
                {i?.agent?.last_name?.slice(0, 1)}
              </div>
            )}
          </div>
          <div className="name-info-remarks">
            <p>{i?.agent?.first_name} {i?.agent?.last_name}</p>
          </div>
        </div>
        <div>
            <p>{new Date(i.created_at).toDateString()} | {new Date(i.created_at).toLocaleTimeString()}</p>
        </div>
      </div>
      <p className="comment-remarks">{i.comment}</p>
    </div>
  );
};

export default Remarks;
