import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoute";
import RoleRoute from "./Components/ProtectedRoutes/RoleRoute";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import AgentDashboard from "./Screens/AgentDasboard/AgentDashboard";
import AgentProfile from "./Screens/AgentProfile/AgentProfile";
import Login from "./Screens/Login/Login";
import TeamLeaderHome from "./Screens/TeamLeaderDashboard/TeamLeaderHome";
import UnAuthorized from "./Components/unAuthorized/UnAuthorized";
import CustomerDashboard from "./Screens/CustomerCareDashboard/CustomerDashboard";
import CustomerOrderDetails from "./Screens/CustomerOrderDetails/CustomerOrderDetails";
import { useEffect } from "react";
import Tracking from "./Components/ModalContent/Tracking/Tracking";
import TicketDetails from "./Components/TicketDetails/TicketDetails";
import EditProfile from "./Screens/EditProfile/EditProfile";
import GoodWillRefund from "./Screens/GoodWillRefund/GoodWillRefund";
import AccountDetails from "./Screens/AccountDetails/AccountDetails";
import NoDataFromApi from "./Components/NotFound/NoDataFromApi";
import UnRepliedCounts from "./Screens/UnRepliedCounts/UnRepliedCounts";
import CallCounts from "./Screens/CallCounts/CallCounts";

const App = () => {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      <Navigate to="/login" />;
    }
  }, []);
  return (
    <Router>
      <ScrollTop />
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<ProtectedRoute />}>
          <Route
            element={<RoleRoute element={<AgentDashboard />} roles={["1"]} />}
            path="/"
          />
          <Route
            element={<RoleRoute element={<TeamLeaderHome />} roles={["2"]} />}
            path="/team-leader-dashboard"
          />
          <Route
            element={<RoleRoute element={<AgentProfile />} roles={["2"]} />}
            path="/agent-profile/:agent_id"
          />
          <Route
            element={
              <RoleRoute element={<CustomerDashboard />} roles={["1", "2"]} />
            }
            path="/customer-dashboard"
          />
          <Route
            element={
              <RoleRoute element={<GoodWillRefund />} roles={["1", "2"]} />
            }
            path="/goodwill-refund/:customer_id/:order_no"
          />
          <Route
            element={
              <RoleRoute element={<TicketDetails />} roles={["1", "2"]} />
            }
            path="/ticket-details/:ticket_Id"
          />
          <Route
            element={<RoleRoute element={<EditProfile />} roles={["1", "2"]} />}
            path="/edit-profile/:user_id"
          />
          <Route
            element={<RoleRoute element={<Tracking />} roles={["1", "2"]} />}
            path="/tracking-details/:order_id"
          />
          <Route
            element={
              <RoleRoute
                element={<CustomerOrderDetails />}
                roles={["1", "2"]}
              />
            }
            path="/customer-order-details/:id/:customer_id"
          />
          <Route
            element={
              <RoleRoute element={<AccountDetails />} roles={["1", "2"]} />
            }
            path="/account-details/:customer_id"
          />
          <Route
            element={
              <RoleRoute element={<UnRepliedCounts />} roles={["1", "2"]} />
            }
            path="/unreplied-Conversations/:id?"
          />
          <Route
            element={<RoleRoute element={<CallCounts />} roles={["1", "2"]} />}
            path="/call-count"
          />
          <Route element={<UnAuthorized />} path="/unauthorized" />
        </Route>
        <Route
          element={<NoDataFromApi title="Oops No Route Found..." />}
          path="*"
        />
      </Routes>
    </Router>
  );
};

export default App;
