import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "../../Assets/User/arrow-left.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  AccountdetailsListingActions,
  OrderRefundActions,
  getGoodWillNormalRefundHistoryActions,
  getGoodWillRefundActions,
  getGoodWillRefundHistoryActions,
  getOrderProductSKUActions,
} from "../../Actions/CustomerActions/CustomerActions";
import Loader from "../../Components/Loader/Loader";
import DataTable from "../../Components/DataTable/DataTable";
import {
  Goodwill_normal_refund_header,
  Goodwill_refund_header,
  refund_Option,
} from "../../DummyArray/HiscravesData";
import Select from "react-select";
import "./GoodWillRefund.css";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import { useCallback } from "react";
import { customStylesSKU } from "../../DummyArray/DashboardArrData";
import { Button } from "@material-ui/core";
import RefundIcon from "../../Assets/Agents-Img/money-2.png";
import { useAlert } from "react-alert";
import TableLoader from "../../Components/Loader/TableLoader";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import ModalContainer from "../../Components/Modal/Modal";
import HelmetTitle from "../../Components/MetaData/MetaData";
import PaginationLoader from "../../Components/Loader/PaginationLoader";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const GoodWillRefund = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { customer_id, order_no } = useParams();

  // count tiles
  const { goodwillrefund_count, loading } = useSelector(
    (state) => state.good_will_refund_count
  );

  // good will refund history
  const { goodwillrefund_refund_history, loading: good_will_history_loading } =
    useSelector((state) => state.good_will_refund_history_data);

  // good will normal refund history
  const {
    goodwillrefund_normal_refund_history,
    loading: googd_will_normal_loading,
  } = useSelector((state) => state.good_will_normal_history_data);

  // account details list state
  const { account_details_list, loading: account_details_loading } =
    useSelector((state) => state.customer_account_details_list);

  const navigate = useNavigate();
  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  const [goodwill_refund_history_arr, setGoodwill_refund_history_arr] =
    useState([]);

  const [Normal_refund_history_arr, setNormal_refund_history_arr] = useState(
    []
  );

  // const fetchAllApi = useCallback(async () => {
  //   if (customer_id && customer_id !== null) {
  //     await dispatch(getGoodWillRefundActions(customer_id));
  //     await dispatch(getGoodWillRefundHistoryActions(customer_id));
  //     dispatch(getGoodWillNormalRefundHistoryActions(customer_id));
  //   }
  // }, [customer_id, dispatch]);

  // useEffect(() => {
  //   fetchAllApi();
  // }, [fetchAllApi]);

  let refund_titles_data =
    goodwillrefund_count &&
    goodwillrefund_count?.data &&
    goodwillrefund_count?.data?.tiles;

  useEffect(() => {
    goodwillrefund_refund_history &&
      setGoodwill_refund_history_arr(
        goodwillrefund_refund_history && goodwillrefund_refund_history?.data
      );
  }, [goodwillrefund_refund_history]);

  useEffect(() => {
    goodwillrefund_normal_refund_history &&
      setNormal_refund_history_arr(
        goodwillrefund_normal_refund_history &&
          goodwillrefund_normal_refund_history?.data?.data
      );
  }, [goodwillrefund_normal_refund_history]);

  // let goodwill_normal_refund_history_data =
  //   goodwillrefund_normal_refund_history &&
  //   goodwillrefund_normal_refund_history?.data;

  // console.log("Normal History: ", goodwill_normal_refund_history_data);

  const DashboardData = [
    {
      id: 1,
      count: refund_titles_data && refund_titles_data?.total_orders,
      title: "Total Orders",
      color: "rgba(217, 172, 245, 1)",
    },
    {
      id: 2,
      count: refund_titles_data && refund_titles_data?.total_return_orders,
      title: "Total Return Orders",
      color: "rgba(171, 219, 245, 1)",
    },
    {
      id: 3,
      count:
        refund_titles_data && refund_titles_data?.total_goodwill_Refund_orders,
      title: "Total Goodwill Refund Order",
      color: "rgba(255, 204, 222, 1)",
    },
    {
      id: 3,
      count: refund_titles_data && refund_titles_data?.total_order_value,
      title: "Total Order Value",
      color: "#F4E869",
    },
    {
      id: 4,
      count: refund_titles_data && refund_titles_data?.total_goodwill_Refund,
      title: "Total Goodwill Refund Enteries",
      color: "rgba(245, 196, 196, 1)",
    },
    {
      id: 5,
      count:
        "₹" + refund_titles_data && refund_titles_data?.total_refund_amount,
      title: "Total Normal Refund",
      color: "rgba(171, 245, 188, 1)",
    },
    // xxxxxx
    {
      id: 6,
      count:
        "₹" + refund_titles_data &&
        refund_titles_data?.total_goodwill_Refund_amount,
      title: "Total Amount of Goodwill",
      color: "rgba(171, 192, 245, 1)",
    },
  ];

  const [isActiveTab, setIsActiveTab] = useState(0);

  const { product_sku_order, loading: sku_loading } = useSelector(
    (state) => state.product_sku
  );
  const { order_refund, loading: refundLoading } = useSelector(
    (state) => state.order_refund_customer
  );

  useEffect(() => {
    if (order_no !== "") {
      dispatch(getOrderProductSKUActions(order_no));
    }
  }, [order_no, dispatch]);

  const [selectedValues, setSelectedValues] = useState([]);
  const [order_items_qty, setOrder_item_qty] = useState([]);

  const [return_amount, setreturn_amount] = useState(0);
  const [accounts, setAccounts] = useState("");
  const [upi_id, setupi_id] = useState("");
  const [bank_name, setbank_name] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [confirm_account_number, setconfirm_account_number] = useState("");
  const [ifsc, setifsc] = useState("");
  const [account_name, setaccount_name] = useState("");
  const [reason, setReason] = useState("");

  let upi_id_list = account_details_list && account_details_list?.data?.vpa;
  let bank_acc_list =
    account_details_list && account_details_list?.data?.bank_accounts;

  const [hit, setHit] = useState(false);

  const handleAccount = async (selectedOption) => {
    setaccount_number(selectedOption.value);
    let filter_bank =
      bank_acc_list &&
      bank_acc_list?.length > 0 &&
      bank_acc_list.filter((i) => i.id === selectedOption?.id);

    const filtered_data_object = filter_bank.reduce((acc, i) => {
      acc["data"] = i;
      return acc;
    }, {});

    let send_final_data = filtered_data_object && filtered_data_object?.data;
    await setbank_name(send_final_data && send_final_data?.bank_name);
    await setconfirm_account_number(
      send_final_data && send_final_data?.confirm_account_no
    );
    await setifsc(send_final_data && send_final_data?.ifsc);
    setaccount_name(send_final_data && send_final_data?.account_name);
  };

  const handleRefundOption = (val) => {
    setAccounts(val);
    if (val === "UPI") {
      dispatch(AccountdetailsListingActions(customer_id));
    } else if (val === "BankAccount") {
      dispatch(AccountdetailsListingActions(customer_id));
    }
  };

  // Modal component
  const [isIndex, setIsIndex] = useState(null);
  const [order_id, setOrder_id] = useState("");
  const handleModal = (index, Id) => {
    setIsIndex(index);
    setOrder_id(Id);
  };

  const [message, setMessage] = useState("");

  const closeModal = () => {
    setIsIndex(null);
  };

  const handleAnotherModal = (i) => {
    if (i) {
      handleModal(15);
    }
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          message={message}
          order_no={order_no}
          return_amount={return_amount}
          order_items_qty={order_items_qty}
          accounts={accounts}
          upi_id={upi_id}
          bank_name={bank_name}
          account_number={account_number}
          confirm_account_number={confirm_account_number}
          ifsc={ifsc}
          reason={reason}
          order_id={order_id}
          customer_id={customer_id}
          goodwillRefund={true}
          handleAnotherModal={handleAnotherModal}
        />
      );
    }
    return null;
  };

  const [loadCheck, setIsLoadCheck] = useState(false);

  const refundAmount = () => {
    if (accounts === "") {
      return alert.error("Please Select Refund Option");
    }
    if (accounts === "UPI" && upi_id === "") {
      return alert.error("Please Select UPI Id");
    }
    if (
      accounts === "BankAccount" &&
      bank_name === "" &&
      account_number === "" &&
      confirm_account_number === "" &&
      ifsc === "" &&
      account_name === ""
    ) {
      return alert.error("Please Select Accounts");
    }
    console.log(
      order_no,
      return_amount,
      order_items_qty,
      accounts,
      upi_id,
      bank_name,
      account_number,
      confirm_account_number,
      ifsc,
      account_name,
      reason
    );
    if (
      (product_sku_order && product_sku_order?.data?.id !== "") ||
      (product_sku_order && product_sku_order?.data?.id !== null) ||
      (product_sku_order && product_sku_order?.data?.id !== undefined)
    ) {
      setIsLoadCheck(true);
      api
        .get(
          `${BaseURL}/agent/goodwill-refund-history?customer_id=${customer_id}&order_id=${product_sku_order?.data?.id}`
        )
        .then((response) => {
          console.log("RESPONSE: ", response && product_sku_order?.data);
          if (
            parseInt(return_amount) >
              parseInt(
                product_sku_order && product_sku_order?.data?.paid_amount
              ) &&
            response &&
            response?.data?.data?.length > 0
          ) {
            handleModal(
              16,
              response && response?.data?.data[0]?.goodwill_refund[0]?.order_id
            );
            setMessage(
              "The Return Amount Is greater Than Paid Amount & The Order You Selected For a Refund Has Already Been Processed, Do You Want To Proceed?"
            );
            // handleModal(15);
            setIsLoadCheck(false);
          } else if (
            parseInt(return_amount) >
            parseInt(product_sku_order && product_sku_order?.data?.paid_amount)
          ) {
            setMessage(
              "The Return Amount Is greater Than Paid Amount, Do You Want To Proceed?"
            );
            handleModal(15);
            setIsLoadCheck(false);
          } else if (response && response?.data?.data?.length > 0) {
            if (
              parseInt(return_amount) >
              parseInt(
                product_sku_order && product_sku_order?.data?.paid_amount
              )
            ) {
              setMessage(
                "The Return Amount Is greater Than Paid Amount, Do You Want To Proceed?"
              );
            } else {
              setMessage(
                "The Order You Selected For a Refund Has Already Been Processed, Do You Want To Proceed?"
              );
            }
            // handleModal(15);
            handleModal(
              16,
              response && response?.data?.data[0]?.goodwill_refund[0]?.order_id
            );
            setIsLoadCheck(false);
          } else {
            setHit(true);
            dispatch(
              OrderRefundActions(
                order_no,
                return_amount,
                order_items_qty,
                accounts,
                upi_id,
                bank_name,
                account_number,
                confirm_account_number,
                ifsc,
                reason
              )
            );
            setIsLoadCheck(false);
          }
          console.log("RESPONSE1: ", response?.data?.data);
        })
        .catch((err) => {
          // console.log(err);
          alert.error(err.message);
          setIsLoadCheck(false);
        });
    }
  };

  useEffect(() => {
    if (order_refund && order_refund?.status === "error" && hit) {
      alert.error(order_refund && order_refund?.message);
      setHit(false);
    } else if (order_refund && order_refund?.status === "success" && hit) {
      alert.success(order_refund && order_refund?.message);
      setSelectedValues([]);
      setOrder_item_qty([]);
      setupi_id("");
      setAccounts("");
      setaccount_number("");
      setreturn_amount("");
      setHit(false);
      dispatch(getGoodWillRefundActions(customer_id));
      dispatch(getGoodWillRefundHistoryActions(customer_id));
      dispatch(getGoodWillNormalRefundHistoryActions(customer_id));
    }
  }, [order_refund, alert, hit, dispatch, customer_id]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);

    // Update new fields based on selected options
    setOrder_item_qty((prevFields) => {
      const updatedFields = selectedOptions.map((option) => {
        // Check if there's a previously entered value for this field
        const prevField = prevFields.find(
          (field) => field.order_item_id === option.value
        );
        return {
          order_item_id: option.value,
          qty: prevField ? prevField.qty : "",
          label: option.label,
        };
      });

      return updatedFields;
    });
  };

  // pagination code starts here
  const [VisiblePages, setVisibilePages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total_page, setTotal_page] = useState("");

  let maxVisibilePages = 5;

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);
  const [page, setPage] = useState(1);

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const fetchGoodWill_history = useCallback(
    (page) => {
      setIsLoading(true);
      api
        .get(
          `${BaseURL}/agent/goodwill-refund-history?customer_id=${customer_id}&page=${page}`
        )
        .then((response) => {
          setGoodwill_refund_history_arr(response && response?.data?.data);
          setTotal_page(response && response?.data?.total_page);
          setTotal_entries(response && response?.data?.total);
          setFirst_item(response && response?.data?.first_item);
          setLast_item(response && response?.data?.last_item);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert.error(err.message);
        });
    },
    [customer_id, alert]
  );

  const handlePageClick = useCallback(
    (page) => {
      setPage(page);
      fetchGoodWill_history(page);
    },
    [fetchGoodWill_history]
  );

  const renderPagination = useCallback(() => {
    if (
      goodwillrefund_refund_history &&
      goodwillrefund_refund_history?.total_page !== page
    ) {
      setRight_icon(false);
    }
    if (total_page && total_page !== page) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page !== ""
        ? total_page
        : goodwillrefund_refund_history &&
            goodwillrefund_refund_history?.total_page
    );

    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    page,
    goodwillrefund_refund_history,
    isLoading,
    handlePageClick,
    total_page,
    maxVisibilePages,
  ]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  const handleIncrement = () => {
    if (
      (goodwillrefund_refund_history &&
        goodwillrefund_refund_history?.total_page !== page) ||
      (total_page && total_page !== page)
    ) {
      setRight_icon(false);
      setPage(page + 1);
      fetchGoodWill_history(page + 1);
    } else {
      setRight_icon(true);
    }
  };

  const handleDecrement = () => {
    if (page === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setPage(page - 1);
      fetchGoodWill_history(page - 1);
    }
  };

  useEffect(() => {
    if (
      goodwillrefund_refund_history &&
      goodwillrefund_refund_history?.total_page !== page
    ) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [goodwillrefund_refund_history, page]);

  useEffect(() => {
    if (total_page && total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, page]);

  // pagination code ends here

  // normal refund history pagination code starts here
  // pagination code starts here
  const [VisiblePages_normal, setVisibilePages_normal] = useState([]);
  const [isLoading_normal, setIsLoading_normal] = useState(false);
  const [total_page_normal, setTotal_page_normal] = useState("");

  const [right_icon_normal, setRight_icon_normal] = useState(false);
  const [left_icon_normal, setLeft_icon_normal] = useState(false);
  const [page_normal, setPage_normal] = useState(1);

  const [first_item_normal, setFirst_item_normal] = useState("");
  const [last_item_normal, setLast_item_normal] = useState("");
  const [total_entries_normal, setTotal_entries_normal] = useState("");

  const fetchNormal_history = useCallback(
    (page) => {
      setIsLoading_normal(true);
      api
        .get(
          `${BaseURL}/agent/order-refund-history?customer_id=${customer_id}&page=${page}`
        )
        .then((response) => {
          setNormal_refund_history_arr(response && response?.data?.data);
          setTotal_page_normal(response && response?.data?.total_page);
          setTotal_entries_normal(response && response?.data?.total);
          setFirst_item_normal(response && response?.data?.first_item);
          setLast_item_normal(response && response?.data?.last_item);
          setIsLoading_normal(false);
        })
        .catch((err) => {
          setIsLoading_normal(false);
          alert.error(err.message);
        });
    },
    [customer_id, alert]
  );

  const handlePageClick_normal = useCallback(
    (page) => {
      setPage_normal(page);
      fetchNormal_history(page);
    },
    [fetchNormal_history]
  );

  const renderPagination_normal = useCallback(() => {
    if (
      goodwillrefund_normal_refund_history &&
      goodwillrefund_normal_refund_history?.total_page !== page_normal
    ) {
      setRight_icon_normal(false);
    }
    if (total_page_normal && total_page_normal !== page_normal) {
      setRight_icon_normal(false);
    }
    const totalPages = Math.ceil(
      total_page_normal !== ""
        ? total_page_normal
        : goodwillrefund_normal_refund_history &&
            goodwillrefund_normal_refund_history?.total_page
    );

    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page_normal - pageOffset;
    let endpage = page_normal + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick_normal(i)}
          className={page_normal === i ? "active-index" : "page-numbers"}
        >
          {page_normal === i && isLoading_normal ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages_normal(pageNumbers);
    return pageNumbers;
  }, [
    page_normal,
    goodwillrefund_normal_refund_history,
    isLoading_normal,
    handlePageClick_normal,
    total_page_normal,
    maxVisibilePages,
  ]);

  useEffect(() => {
    renderPagination();
    renderPagination_normal();
  }, [renderPagination, renderPagination_normal]);

  const handleIncrement_normal = () => {
    if (
      (goodwillrefund_normal_refund_history &&
        goodwillrefund_normal_refund_history?.total_page !== page_normal) ||
      (total_page_normal && total_page_normal !== page_normal)
    ) {
      setRight_icon_normal(false);
      setPage_normal(page_normal + 1);
      fetchNormal_history(page_normal + 1);
    } else {
      setRight_icon_normal(true);
    }
  };

  const handleDecrement_normal = () => {
    if (page_normal === 1) {
      setLeft_icon_normal(true);
    } else {
      setRight_icon_normal(false);
      setLeft_icon_normal(false);
      setPage_normal(page_normal - 1);
      fetchNormal_history(page_normal - 1);
    }
  };

  useEffect(() => {
    if (
      goodwillrefund_normal_refund_history &&
      goodwillrefund_normal_refund_history?.total_page !== page_normal
    ) {
      setRight_icon_normal(false);
    } else {
      setRight_icon_normal(true);
    }
  }, [goodwillrefund_normal_refund_history, page_normal]);

  useEffect(() => {
    if (total_page_normal && total_page_normal !== page_normal) {
      setRight_icon_normal(false);
    } else {
      setRight_icon_normal(true);
    }
  }, [total_page_normal, page_normal]);

  // pagination code ends here
  // normal refund history pagination code ends here

  const handleNewFieldValueChange = (index, value) => {
    setOrder_item_qty((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].qty = value;
      return updatedFields;
    });
  };

  console.log("SKU List: ", order_items_qty);
  console.log("Order Items: ", order_no);

  console.log("UPI ID LIST: ", upi_id_list);
  console.log("BANK ACCOUNT LIST: ", goodwillrefund_refund_history);

  useEffect(() => {
    if (isIndex === null) {
      setSelectedValues([]);
      setOrder_item_qty([]);
      setupi_id("");
      setAccounts("");
      setaccount_number("");
      setreturn_amount("");
      setReason("");
      dispatch(getGoodWillRefundActions(customer_id));
      dispatch(getGoodWillRefundHistoryActions(customer_id));
      dispatch(getGoodWillNormalRefundHistoryActions(customer_id));
    }
  }, [isIndex, customer_id, dispatch]);

  console.log(
    "GOODWILL DATA: ",
    goodwillrefund_normal_refund_history &&
      goodwillrefund_normal_refund_history?.data
  );

  return (
    <>
      <HelmetTitle
        title={`${
          loading || good_will_history_loading || googd_will_normal_loading
            ? "Loading..."
            : "Good Will Refund"
        }`}
      />
      <Navbar searchBar={handleSearchBar} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {goodwillrefund_count && goodwillrefund_count?.logout ? (
            <NoDataFromApi
              btn={true}
              title="Something went wrong, Please Try Logout & Login Again..."
            />
          ) : (
            <div className="home-container">
              <Link onClick={() => navigate(-1)} className="header-redirection">
                <img src={ArrowLeft} alt="" />
                <h1>Goodwill Refund</h1>
              </Link>

              {/* dashboard count */}
              <div className="grid-box-row">
                {DashboardData &&
                  DashboardData.map((i) => (
                    <div
                      className="data-display"
                      key={i.id}
                      style={{ backgroundColor: i.color }}
                    >
                      <p>{i.count}</p>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>
              {/* dashboard count */}

              <div className="create-good-will-refund-container">
                <h2>Goodwill Refund</h2>
                <div className="order-refund-grid-row">
                  <div>
                    <label className="label-refund" htmlFor="">
                      Order Number
                    </label>{" "}
                    <br />
                    <input
                      className="qty"
                      type="text"
                      readOnly
                      defaultValue={order_no ? order_no : ""}
                    />
                  </div>
                  <div>
                    <label className="label-refund" htmlFor="">
                      Product SKU
                    </label>{" "}
                    <br />
                    <Select
                      placeholder="Select SKU"
                      options={
                        product_sku_order &&
                        product_sku_order?.data &&
                        product_sku_order &&
                        product_sku_order?.data?.order_items?.map((option) => ({
                          value: option?.id,
                          label: option?.product?.product_code,
                        }))
                      }
                      isMulti={true}
                      styles={customStylesSKU}
                      isLoading={sku_loading}
                      value={selectedValues}
                      onChange={handleSelectChange}
                    />
                  </div>
                  {order_items_qty.map((field, index) => (
                    <div key={index}>
                      <label className="label-refund">
                        {field.label} | Qty:
                      </label>{" "}
                      <br />
                      <input
                        className="qty"
                        type="text"
                        value={field.qty}
                        onChange={(e) =>
                          handleNewFieldValueChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                  <div>
                    <label className="label-refund" htmlFor="RefundOption">
                      Refund Option <span className="dot-red">*</span>
                    </label>
                    <Select
                      placeholder={`${
                        accounts !== "" ? accounts : "Select Refund Option"
                      }`}
                      options={refund_Option.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      className="select"
                      id="RefundOption"
                      value={accounts}
                      styles={customStylesSKU}
                      onChange={(selectedOption) =>
                        handleRefundOption(selectedOption.value)
                      }
                    />
                  </div>
                  {accounts === "UPI" && (
                    <div>
                      <label className="label-refund" htmlFor="RefundOption">
                        Select UPI ID <span className="dot-red">*</span>
                      </label>
                      <Select
                        placeholder={`${
                          upi_id !== "" ? upi_id : "Select Option"
                        }`}
                        options={
                          upi_id_list &&
                          upi_id_list.map((option) => ({
                            value: option.upi_id,
                            label: option.upi_id,
                          }))
                        }
                        className="select"
                        id="RefundOption"
                        isLoading={account_details_loading}
                        value={upi_id}
                        styles={customStylesSKU}
                        onChange={(selectedOption) =>
                          setupi_id(selectedOption.value)
                        }
                      />
                    </div>
                  )}
                  {accounts === "BankAccount" && (
                    <div>
                      <label className="label-refund" htmlFor="RefundOption">
                        Select Account No <span className="dot-red">*</span>
                      </label>
                      <Select
                        placeholder={`${
                          account_number !== ""
                            ? account_number
                            : "Select Option"
                        }`}
                        options={
                          bank_acc_list &&
                          bank_acc_list.map((option) => ({
                            value: option.account_no,
                            label: option.account_no,
                            id: option.id,
                          }))
                        }
                        className="select"
                        id="RefundOption"
                        isLoading={account_details_loading}
                        value={account_number}
                        styles={customStylesSKU}
                        onChange={(selectedOption) =>
                          handleAccount(selectedOption)
                        }
                      />
                    </div>
                  )}
                  <div>
                    <label className="label-refund">Refund Amount</label>{" "}
                    <input
                      className="qty"
                      type="number"
                      value={return_amount}
                      onChange={(e) => setreturn_amount(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="label-refund">Reason</label>{" "}
                    <input
                      className="qty"
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
                <div className="refund-cta">
                  <Button onClick={() => refundAmount()}>
                    {refundLoading || loadCheck ? (
                      <TableLoader />
                    ) : (
                      <>
                        <img src={RefundIcon} alt="" />
                        &nbsp;&nbsp;Goodwill Refund
                      </>
                    )}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  padding:
                    good_will_history_loading || googd_will_normal_loading
                      ? "40px 0px 40px 0px"
                      : "",
                }}
              >
                {good_will_history_loading || googd_will_normal_loading ? (
                  <PaginationLoader />
                ) : (
                  <div>
                    <div className="header-change-type">
                      <div
                        onClick={() => setIsActiveTab(0)}
                        style={{
                          backgroundColor:
                            isActiveTab === 0
                              ? "rgba(119, 0, 21, 1)"
                              : "rgba(222, 222, 222, 1)",
                          color: isActiveTab === 0 ? "white" : "#1C1C1C",
                        }}
                      >
                        <p>Goodwill Refund History</p>
                      </div>
                      <div
                        onClick={() => setIsActiveTab(1)}
                        style={{
                          backgroundColor:
                            isActiveTab === 1
                              ? "rgba(119, 0, 21, 1)"
                              : "rgba(222, 222, 222, 1)",
                          color: isActiveTab === 1 ? "white" : "#1C1C1C",
                        }}
                      >
                        <p>Normal Refund History</p>
                      </div>
                    </div>
                    {isActiveTab === 0 ? (
                      <div
                        className="table-wrapper-pagination"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="table-container">
                          <h3>Goodwill Refund</h3>
                          <DataTable
                            header={Goodwill_refund_header}
                            goodwillData={
                              goodwill_refund_history_arr &&
                              goodwill_refund_history_arr
                            }
                            customer_id={customer_id}
                          />
                          {/* <div className="view-more">{renderPagination()}</div> */}
                        </div>
                        <div className="pagination-entries-row">
                          <p>
                            Showing {first_item !== "" ? first_item : 1} To{" "}
                            {last_item !== ""
                              ? last_item
                              : goodwillrefund_refund_history &&
                                goodwillrefund_refund_history?.last_item}{" "}
                            of,{" "}
                            {total_entries && total_entries !== ""
                              ? total_entries
                              : goodwillrefund_refund_history &&
                                goodwillrefund_refund_history?.total}{" "}
                            entries
                          </p>
                          <div className="pagination-chevron-icon">
                            <Button
                              disabled={left_icon || page === 1 ? true : false}
                              className="icon-chevron"
                              onClick={handleDecrement}
                            >
                              <ChevronLeftIcon />
                            </Button>
                            <div className="view-more">{VisiblePages}</div>
                            <Button
                              disabled={
                                right_icon ||
                                (goodwillrefund_refund_history &&
                                  goodwillrefund_refund_history?.total_page ===
                                    page)
                                  ? true
                                  : right_icon ||
                                    (total_page && total_page === page)
                                  ? true
                                  : false
                              }
                              className="icon-chevron"
                              onClick={handleIncrement}
                            >
                              <ChevronRightIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="table-wrapper-pagination"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="table-container">
                          <h3>Refund History</h3>
                          <DataTable
                            header={Goodwill_normal_refund_header}
                            normal_refund_data={
                              Normal_refund_history_arr &&
                              Normal_refund_history_arr
                            }
                            customer_id={customer_id}
                          />
                          {/* <div className="view-more">{renderPagination()}</div> */}
                        </div>
                        <div className="pagination-entries-row">
                          <p>
                            Showing{" "}
                            {first_item_normal !== "" ? first_item_normal : 1}{" "}
                            To{" "}
                            {last_item_normal !== ""
                              ? last_item_normal
                              : goodwillrefund_normal_refund_history &&
                                goodwillrefund_normal_refund_history?.last_item}{" "}
                            of,{" "}
                            {total_entries_normal && total_entries_normal !== ""
                              ? total_entries_normal
                              : goodwillrefund_normal_refund_history &&
                                goodwillrefund_normal_refund_history?.total}{" "}
                            entries
                          </p>
                          <div className="pagination-chevron-icon">
                            <Button
                              disabled={
                                left_icon_normal || page_normal === 1
                                  ? true
                                  : false
                              }
                              className="icon-chevron"
                              onClick={handleDecrement_normal}
                            >
                              <ChevronLeftIcon />
                            </Button>
                            <div className="view-more">
                              {VisiblePages_normal}
                            </div>
                            <Button
                              disabled={
                                right_icon_normal ||
                                (goodwillrefund_normal_refund_history &&
                                  goodwillrefund_normal_refund_history?.total_page ===
                                    page_normal)
                                  ? true
                                  : right_icon_normal ||
                                    (total_page_normal &&
                                      total_page_normal === page_normal)
                                  ? true
                                  : false
                              }
                              className="icon-chevron"
                              onClick={handleIncrement_normal}
                            >
                              <ChevronRightIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {renderModal()}
    </>
  );
};

export default GoodWillRefund;
