import React, { useState } from 'react'
import { HeadingTitle } from '../../Modal/Modal'
import Select from 'react-select';
import { customStylesSKU, productSkus } from '../../../DummyArray/HiscravesData';
import './Refund.css'
import RefundIcon from '../../../Assets/SideBar-Icons/money-2.png';
import { Button } from '@material-ui/core';

const Refund = (props) => {
    const [OrderId, setOrderId] = useState('MRC000052933')
    const [productSKU, SetProductSku] = useState('')
    const [refundAmount, setRefundAmount] = useState('399.00')

    const handleSku = (selectedOption) => {
        SetProductSku(selectedOption)
    }

    const handleRefund = () => {
        alert('InProgress...')
    }
    return (
        <>
            <HeadingTitle title='Refund' closeModal={props.close} />
            <div className="input-refund">
                <div className="inputs">
                    <label htmlFor="OrderID">Order ID</label> <br />
                    <input type="text" value={OrderId} onChange={(e) => setOrderId(e.target.value)} id="OrderID" />
                </div>
                <div className="inputs">
                    <label htmlFor="productSku">Product SKU</label>
                    <Select placeholder={`${productSKU !== '' ? productSKU : 'Select Product SKU'}`} options={productSkus.map((option) => ({
                        value: option,
                        label: option
                    }))}
                    className='select'
                        id='productSku'
                        value={productSKU}
                        styles={customStylesSKU}
                        onChange={handleSku}
                    />
                </div>
                <div className="inputs rt-input-input">
                    <label htmlFor="refundAmount">Refund Amount</label> <br />
                    <input type="text" value={refundAmount} onChange={(e) => setRefundAmount(e.target.value)} id="refundAmount" />
                </div>
            </div>
            <div className="refund-payment-btn">
                <Button type='submit' onClick={handleRefund}><img src={RefundIcon} alt="" />&nbsp; Refund Payment</Button>
            </div>
        </>
    )
}

export default Refund