import React, { useEffect, useState } from "react";
import "./Login.css";
import Logo from "../../Assets/Logo.png";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { clearErrors } from "../../Actions/LoginActions/LoginActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../BaseURL";
import axios from "axios";
import TableLoader from "../../Components/Loader/TableLoader";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import EyeIconCross from "@material-ui/icons/VisibilityOff";
import HelmetTitle from "../../Components/MetaData/MetaData";

const Login = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("password");
  const [isHide, setIsHide] = useState(false);

  const handlePassword = () => {
    setIsHide(!isHide);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const [loading, setLoading] = useState(false);

  let isAuthenticated = localStorage.getItem("user");
  if (isAuthenticated) {
    isAuthenticated = JSON.parse(isAuthenticated);
  }

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(`${BaseURL}/agent/agent-login`, { email, password })
      .then((response) => {
        let data = response?.data;
        if (data && !data?.logout && data?.status === "success") {
          localStorage.setItem("user", JSON.stringify(data && data?.data));
        }
        if (data && data?.status === "error") {
          alert.error(data && data?.message);
          dispatch(clearErrors());
        }
        if (!data?.logout && data && data?.status === "success") {
          alert.success(data && data?.message);
          dispatch(clearErrors());
          navigate("/unauthorized");
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated && isAuthenticated !== undefined) {
      navigate("/unauthorized");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <HelmetTitle title={"Login - Hiscraves"} />
      <div className="login-screen-container">
        <div className="login-inputs-wrapper-container">
          <div className="img-logo">
            <img src={Logo} alt="" />
            <h1>Welcome</h1>
            <p>Please enter your log in details below......</p>
          </div>
          <form>
            <div className="inputs-login-row">
              <label htmlFor="Email">Enter Email</label> <br />
              <input
                type="email"
                id="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </div>
            <div className="inputs-login-row relative-pass">
              <label htmlFor="password">Password</label> <br />
              <input
                type={type}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
              <div className="show-hide-password">
                {isHide ? (
                  <Button onClick={() => handlePassword()}>
                    <EyeIcon />
                  </Button>
                ) : (
                  <Button onClick={() => handlePassword()}>
                    <EyeIconCross />
                  </Button>
                )}
              </div>
            </div>
            <div className="login-submit-btn">
              {loading ? (
                <Button disabled={true} style={{ color: "white" }}>
                  <TableLoader />
                </Button>
              ) : (
                <Button type="submit" onClick={handleLogin}>
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
