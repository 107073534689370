import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { OrderRefundActions } from "../../../Actions/CustomerActions/CustomerActions";
import TableLoader from "../../Loader/TableLoader";
import { useState } from "react";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useCallback } from "react";

const GoodWillRefundModal = (props) => {
  const alert = useAlert();
  const {
    order_no,
    return_amount,
    order_items_qty,
    accounts,
    upi_id,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    close,
    reason
  } = props;
  const dispatch = useDispatch();
  
  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const { order_refund, loading } = useSelector(
    (state) => state.order_refund_customer
  );

  const [hit, setHit] = useState(false);

  const handleRefund = () => {
    setHit(true);
    dispatch(
      OrderRefundActions(
        order_no,
        return_amount,
        order_items_qty,
        accounts,
        upi_id,
        bank_name,
        account_number,
        confirm_account_number,
        ifsc,
        reason
      )
    );
  };

  useEffect(() => {
    if (order_refund && order_refund?.status === "error" && hit) {
      alert.error(order_refund && order_refund?.message);
      setHit(false);
      closeModal();
    } else if (order_refund && order_refund?.status === "success" && hit) {
      alert.success(order_refund && order_refund?.message);
      setHit(false);
      closeModal();
    }
  }, [order_refund, alert, hit, closeModal]);
  return (
    <>
      <HeadingTitle title="Alerts" closeModal={props.close} />
      <div className="content-cancel">
        <h3>Are you sure!</h3>
        <p>{props.message}</p>
        <div className="center-button-cancel">
          <Button onClick={() => handleRefund()}>
            {loading ? <TableLoader /> : "Yes, Continue"}
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </div>
      {/* {renderModal()} */}
    </>
  );
};

export default GoodWillRefundModal;
