import React, { useCallback, useEffect, useState } from "react";
import HelmetTitle from "../../Components/MetaData/MetaData";
import Navbar from "../../Components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Get_Team_Lead_CountsAction } from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import DataTable from "../../Components/DataTable/DataTable";
import { team_leader_call_counts_header } from "../../DummyArray/HiscravesData";
import { getAgent_Call_countAction } from "../../Actions/AgentActions/AgentActions";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import TableLoader from "../../Components/Loader/TableLoader";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { BaseURL } from "../../BaseURL";
import api from "../../axiosConfig";

const CallCounts = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  //   team_lead_call_count_state
  const { team_lead_call_count, loading } = useSelector(
    (state) => state.teamLeadCallCount
  );

  //   agent_call_count_state
  const { agent_call_count, loading: agent_loading } = useSelector(
    (state) => state.agentLeadCallCount
  );

  const navigate = useNavigate();

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  let user = JSON.parse(localStorage.getItem("user")).user_type;

  const [team_leadCallCount, setTeamLeadCallCount] = useState([]);
  const [agent_callCount, setAgentCallCount] = useState([]);

  useEffect(() => {
    if (user && user === "2") {
      dispatch(Get_Team_Lead_CountsAction(1, 10));
    }
    if (user && user === "1") {
      dispatch(getAgent_Call_countAction(1, 10));
    }
  }, [dispatch, alert, user]);

  useEffect(() => {
    if (
      team_lead_call_count &&
      team_lead_call_count?.data?.length > 0 &&
      user === "2"
    ) {
      setTeamLeadCallCount(team_lead_call_count && team_lead_call_count?.data);
    }
    if (
      agent_call_count &&
      agent_call_count?.data?.length > 0 &&
      user === "1"
    ) {
      setAgentCallCount(agent_call_count && agent_call_count?.data);
    }
  }, [team_lead_call_count, agent_call_count, user]);

  console.log("Team Lead Call Counts: ", team_leadCallCount);
  console.log("Agent Call Counts: ", agent_callCount);

  const [page, setPage] = useState(1);

  const [audio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudio = (audioSrc) => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.src = audioSrc;
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleEnded = () => {
      setIsPlaying(false);
    };

    const handleError = () => {
      setIsPlaying(false);
      // Handle error as needed
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("error", handleError);

    // Cleanup event listeners when component unmounts
    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("error", handleError);
    };
  }, [audio]);

  // pagination code starts here

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const [total_page, setTotal_page] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);

  const [VisiblePages, setVisibilePages] = useState([]);

  let maxVisibilePages = 5;

  let per_page = 10;

  // fetch Next Page Data
  const fetchNextData = useCallback(
    (page) => {
      setIsLoading(true);
      let apiUrl =
        user === "2"
          ? `${BaseURL}/agent/get-teamlead-calls?page=${page}&per_page=${per_page}`
          : `${BaseURL}/agent/get-agent-calls?page=${page}&per_page=${per_page}`;
      api
        .get(apiUrl)
        .then((response) => {
          user === "2"
            ? setTeamLeadCallCount(response?.data?.data)
            : setAgentCallCount(response?.data?.data);
          setTotal_page(response && response?.data?.total_page);
          setTotal_entries(response && response?.data?.total);
          setFirst_item(response && response?.data?.first_item);
          setLast_item(response && response?.data?.last_item);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert.error(err.message);
        });
    },
    [alert, per_page, user]
  );
  // fetch Next Page Data

  const handlePageClick = useCallback(
    (page) => {
      setPage(page);
      fetchNextData(page);
    },
    [fetchNextData]
  );

  const renderPagination = useCallback(() => {
    const totalPages = Math.ceil(
      total_page !== ""
        ? total_page
        : user === "2"
        ? team_lead_call_count?.total_page
        : agent_call_count?.total_page
    );

    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    page,
    isLoading,
    total_page,
    maxVisibilePages,
    agent_call_count?.total_page,
    team_lead_call_count?.total_page,
    handlePageClick,
    user,
  ]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  const handleIncrement = () => {
    if (
      (user === "2"
        ? team_lead_call_count?.total_page !== page
        : agent_call_count?.total_page !== page) ||
      (total_page && total_page !== page)
    ) {
      setRight_icon(false);
      setPage(page + 1);
      fetchNextData(page + 1);
    } else {
      setRight_icon(true);
    }
  };

  const handleDecrement = () => {
    if (page === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setPage(page - 1);
      fetchNextData(page - 1);
    }
  };

  useEffect(() => {
    if (
      user === "2"
        ? team_lead_call_count?.total_page !== page
        : agent_call_count?.total_page !== page
    ) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [team_lead_call_count, page, user, agent_call_count]);

  useEffect(() => {
    if (total_page && total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, page]);
  // pagination code ends here

  // console.log("Team Lead Call Count: ", team_lead_call_count);
  // console.log("Agent Call Count: ", agent_call_count);

  return (
    <>
      <HelmetTitle title={`Hiscraves - Call Count`} />
      <Navbar searchBar={handleSearchBar} />
      {loading || agent_loading ? (
        <Loader />
      ) : team_lead_call_count && team_lead_call_count?.logout === true ? (
        <NoDataFromApi
          btn={true}
          title={team_lead_call_count && team_lead_call_count?.message}
        />
      ) : (
        <div className="home-container">
          <div className="team-leader-dashboard">
            <Link onClick={() => navigate(-1)} className="agent-profile-head">
              <img src={Arrow} alt="" />
              <h1>Call Counts Details</h1>
            </Link>

            <div className="table-wrapper-pagination">
              <div className="table-container">
                <div className="header-text-dropdown">
                  <h3>Call History</h3>
                  <div className="select-dropdown-filters"></div>
                </div>
                <DataTable
                  header={team_leader_call_counts_header}
                  team_lead_call_count={
                    user === "2"
                      ? Array.isArray(team_leadCallCount) && team_leadCallCount
                      : user === "1"
                      ? Array.isArray(agent_callCount) && agent_callCount
                      : []
                  }
                  items_per_page={10}
                  isPlaying={isPlaying}
                  current_page={page}
                  handleAudio={handleAudio}
                />
              </div>

              <div className="pagination-entries-row">
                <p>
                  Showing {first_item !== "" ? first_item : 1} To{" "}
                  {last_item !== ""
                    ? last_item
                    : user === "2"
                    ? team_lead_call_count?.last_item
                    : agent_call_count?.last_item}{" "}
                  of,{" "}
                  {total_entries && total_entries !== ""
                    ? total_entries
                    : user === "2"
                    ? team_lead_call_count?.total
                    : agent_call_count?.total}{" "}
                  entries
                </p>
                <div className="pagination-chevron-icon">
                  <Button
                    disabled={left_icon || page === 1 ? true : false}
                    className="icon-chevron"
                    onClick={handleDecrement}
                  >
                    <ChevronLeftIcon />
                  </Button>
                  <div className="view-more">{VisiblePages}</div>
                  <Button
                    disabled={
                      right_icon ||
                      (user === "2"
                        ? team_lead_call_count?.total_page === page
                        : agent_call_count?.total_page === page)
                        ? true
                        : right_icon || (total_page && total_page === page)
                        ? true
                        : false
                    }
                    className="icon-chevron"
                    onClick={handleIncrement}
                  >
                    <ChevronRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CallCounts;
