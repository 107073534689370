import {
  GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE,
  GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST,
  GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS,
} from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import {
  ADD_REMARKS_FAILURE,
  ADD_REMARKS_REQUEST,
  ADD_REMARKS_RESET,
  ADD_REMARKS_SUCCESS,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET,
  ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS,
  GET_CALL_DETAILS_BY_TICKET_ID_FAILURE,
  GET_CALL_DETAILS_BY_TICKET_ID_REQUEST,
  GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS,
  GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE,
  GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST,
  GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST,
  GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS,
  GET_TEAM_ALL_AGENTS_FAILURE,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST,
  GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS,
  GET_TEAM_ALL_AGENTS_REQUEST,
  GET_TEAM_ALL_AGENTS_SUCCESS,
  GET_TEAM_ALL_TICKETS_FAILURE,
  GET_TEAM_ALL_TICKETS_REQUEST,
  GET_TEAM_ALL_TICKETS_SUCCESS,
  GET_TEAM_DOUGHNUT_FAILURE,
  GET_TEAM_DOUGHNUT_REQUEST,
  GET_TEAM_DOUGHNUT_SUCCESS,
  GET_TEAM_LEAD_CALL_COUNT_FAILURE,
  GET_TEAM_LEAD_CALL_COUNT_REQUEST,
  GET_TEAM_LEAD_CALL_COUNT_SUCCESS,
  GET_TEAM_NEW_TICKETS_FAILURE,
  GET_TEAM_NEW_TICKETS_REQUEST,
  GET_TEAM_NEW_TICKETS_SUCCESS,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST,
  GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS,
  GET_TEAM_TICKET_DETAILS__FAILURE,
  GET_TEAM_TICKET_DETAILS__REQUEST,
  GET_TEAM_TICKET_DETAILS__SUCCESS,
  GET_TICKET_AGING_REPORT_GRAPH_FAILURE,
  GET_TICKET_AGING_REPORT_GRAPH_REQUEST,
  GET_TICKET_AGING_REPORT_GRAPH_SUCCESS,
  GET_TICKET_ALL_STATUSES__FAILURE,
  GET_TICKET_ALL_STATUSES__REQUEST,
  GET_TICKET_ALL_STATUSES__SUCCESS,
  GET_TICKET_CONVERSATION_BY_ID_FAILURE,
  GET_TICKET_CONVERSATION_BY_ID_REQUEST,
  GET_TICKET_CONVERSATION_BY_ID_SUCCESS,
  GET_TICKET_DETAILS_REMARKS_FAILURE,
  GET_TICKET_DETAILS_REMARKS_REQUEST,
  GET_TICKET_DETAILS_REMARKS_SUCCESS,
  GET__TEAM_DASHBOARD_DATA_FAILURE,
  GET__TEAM_DASHBOARD_DATA_REQUEST,
  GET__TEAM_DASHBOARD_DATA_SUCCESS,
  UPDATE_TICKET_STATUS__FAILURE,
  UPDATE_TICKET_STATUS__REQUEST,
  UPDATE_TICKET_STATUS__RESET,
  UPDATE_TICKET_STATUS__SUCCESS,
} from "../../Constants/TeamLeaderConstants/TeamLeaderConstants";
import { CLEAR_ERRORS } from "../../Constants/UserConstants/UserConstants";

export const getTeamDashboardReducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET__TEAM_DASHBOARD_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET__TEAM_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        message: action.payload.message,
      };
    case GET__TEAM_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamDoughnutReducers = (state = { doughnut: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_DOUGHNUT_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_DOUGHNUT_SUCCESS:
      return {
        ...state,
        loading: false,
        doughnut: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_DOUGHNUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getAllAgentsReducer = (state = { agents: [] }, action) => {
  switch (action.type) {
    case GET_TEAM_ALL_AGENTS_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_ALL_AGENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamLeaderNewTicketsReducer = (
  state = { newTickets: [], allTickets: [] },
  action
) => {
  switch (action.type) {
    case GET_TEAM_NEW_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_NEW_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        newTickets: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_NEW_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamLeaderAllTicketsReducer = (
  state = { allTickets: [] },
  action
) => {
  switch (action.type) {
    case GET_TEAM_ALL_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTickets: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_ALL_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamLeaderAllAgentsForAsignee = (
  state = { allagentsList: [] },
  action
) => {
  switch (action.type) {
    case GET_TEAM_ALL_AGENTS_LIST_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_ALL_AGENTS_LIST_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allagentsList: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_ALL_AGENTS_LIST_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const assignNewTicketReducers = (state = { assignee: {} }, action) => {
  switch (action.type) {
    case ASSIGN_NEW_TEAM_LEADER_TICKETS_REQUEST:
      return {
        loading: true,
      };
    case ASSIGN_NEW_TEAM_LEADER_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        assignee: action.payload,
        message: action.payload.message,
        isPosted: true,
      };
    case ASSIGN_NEW_TEAM_LEADER_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case ASSIGN_NEW_TEAM_LEADER_TICKETS_RESET:
      return {
        ...state,
        isPosted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamTicketDetails = (
  state = { teamTicketDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_TEAM_TICKET_DETAILS__REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_TICKET_DETAILS__SUCCESS:
      return {
        ...state,
        loading: false,
        teamTicketDetails: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_TICKET_DETAILS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTeamTicketWithIdDetails = (
  state = { teamTicketDetails_id: {} },
  action
) => {
  switch (action.type) {
    case GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__SUCCESS:
      return {
        ...state,
        loading: false,
        teamTicketDetails_id: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_TICKET_DETAILS_WITH_ORDER_ID__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTicketStatusReducer = (state = { Allstatus: {} }, action) => {
  switch (action.type) {
    case GET_TICKET_ALL_STATUSES__REQUEST:
      return {
        loading: true,
      };
    case GET_TICKET_ALL_STATUSES__SUCCESS:
      return {
        ...state,
        loading: false,
        Allstatus: action.payload,
        message: action.payload.message,
      };
    case GET_TICKET_ALL_STATUSES__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateTicketStatusReducers = (
  state = { ticket_status_update_view: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_TICKET_STATUS__REQUEST:
      return {
        loading: true,
      };
    case UPDATE_TICKET_STATUS__SUCCESS:
      return {
        ...state,
        loading: false,
        ticket_status_update_view: action.payload,
        message: action.payload.message,
        isUpdated: true,
      };
    case UPDATE_TICKET_STATUS__RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case UPDATE_TICKET_STATUS__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getCallDetailsTicketIdReducers = (
  state = { callDetails: [] },
  action
) => {
  switch (action.type) {
    case GET_CALL_DETAILS_BY_TICKET_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_CALL_DETAILS_BY_TICKET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        callDetails: action.payload,
        message: action.payload.message,
      };
    case GET_CALL_DETAILS_BY_TICKET_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTicketConversationReducers = (
  state = { conversation: [] },
  action
) => {
  switch (action.type) {
    case GET_TICKET_CONVERSATION_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_TICKET_CONVERSATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        conversation: action.payload,
        message: action.payload.message,
      };
    case GET_TICKET_CONVERSATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTicketAgingReportGraphReducers = (
  state = { ticket_aging_report: {} },
  action
) => {
  switch (action.type) {
    case GET_TICKET_AGING_REPORT_GRAPH_REQUEST:
      return {
        loading: true,
      };
    case GET_TICKET_AGING_REPORT_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        ticket_aging_report: action.payload,
        message: action.payload.message,
      };
    case GET_TICKET_AGING_REPORT_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getIssueTypeTicketGraphReducers = (
  state = { issue_type_ticket_graph: [] },
  action
) => {
  switch (action.type) {
    case GET_ISSUE_TYPE_TICKETS_GRAPH_REQUEST:
      return {
        loading: true,
      };
    case GET_ISSUE_TYPE_TICKETS_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        issue_type_ticket_graph: action.payload,
        message: action.payload.message,
      };
    case GET_ISSUE_TYPE_TICKETS_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getPriorityTypeTicketGraphReducers = (
  state = { priority_type_ticket_graph: [] },
  action
) => {
  switch (action.type) {
    case GET_PRIORITY_TYPE_TICKETS_GRAPH_REQUEST:
      return {
        loading: true,
      };
    case GET_PRIORITY_TYPE_TICKETS_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        priority_type_ticket_graph: action.payload,
        message: action.payload.message,
      };
    case GET_PRIORITY_TYPE_TICKETS_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getTicketDetailsRemarksReducers = (
  state = { ticket_details_remarks: [] },
  action
) => {
  switch (action.type) {
    case GET_TICKET_DETAILS_REMARKS_REQUEST:
      return {
        loading: true,
      };
    case GET_TICKET_DETAILS_REMARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticket_details_remarks: action.payload,
        message: action.payload.message,
      };
    case GET_TICKET_DETAILS_REMARKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const addRemarksReducers = (state = { add_remarks: {} }, action) => {
  switch (action.type) {
    case ADD_REMARKS_REQUEST:
      return {
        loading: true,
      };
    case ADD_REMARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_remarks: action.payload,
        message: action.payload.message,
        isPosted: true,
      };
    case ADD_REMARKS_RESET:
      return {
        ...state,
        loading: false,
        isPosted: false,
      };
    case ADD_REMARKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getGoodwillRefundHistoryDataReducers = (
  state = { good_will_refund_history: [] },
  action
) => {
  switch (action.type) {
    case GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        good_will_refund_history: action.payload,
        message: action.payload.message,
      };
    case GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const getLead_Call_CountReducers = (
  state = { team_lead_call_count: {} },
  action
) => {
  switch (action.type) {
    case GET_TEAM_LEAD_CALL_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_TEAM_LEAD_CALL_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        team_lead_call_count: action.payload,
        message: action.payload.message,
      };
    case GET_TEAM_LEAD_CALL_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
