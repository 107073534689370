import React from 'react'

const ReadOnlyDetails = (props) => {
    return (
       <div className='orders-customer'>
        <h3>{props.title}</h3>
        {
            props.product && <h4>(Product Id: {props.product})</h4>
        }
       </div>
    )
}

export default ReadOnlyDetails