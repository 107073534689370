import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "../BarChart/Bar.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  console.log("PROPADATA: ", props);
  const data = {
    labels: [
      "Resolve",
      "Hold",
      "Pending",
      "Open",
      "Duplicate",
      "InProgress",
      "Reopened",
      "Closed",
    ],
    datasets: [
      {
        data: props?.data && [
          props?.data?.resolve_tickets,
          props?.data?.hold_tickets,
          props?.data?.pending_tickets,
          props?.data?.open_tickets,
          props?.data?.duplicate_tickets,
          props?.data?.in_progress_tickets,
          props?.data?.reopened_tickets,
          props?.data?.closed_tickets,
        ],
        borderWidth: 0,
        backgroundColor: [
          "#E06153",
          "#EA8D4E",
          "#916FDF",
          "#37B99C",
          "rgb(153, 176, 128)",
          "rgb(249, 181, 114)",
          "rgb(217, 172, 245)",
          "rgb(210, 233, 233)",
        ],
        hoverOffset: props.hoverOffset,
        borderRadius: 5,
      },
    ],
  };

  const data_sec = {
    labels: [
      "Resolve",
      "Hold",
      "Pending",
      "Open",
      "Duplicate",
      "InProgress",
      "Reopened",
      "Closed",
    ],
    datasets: [
      {
        data: props?.data && [
          props?.data?.resolve_tickets,
          props?.data?.hold_tickets,
          props?.data?.pending_tickets,
          props?.data?.open_tickets,
          props?.data?.duplicate_tickets,
          props?.data?.in_progress_tickets,
          props?.data?.reopened_tickets,
          props?.data?.closed_tickets,
        ],
        borderWidth: 0,
        backgroundColor: [
          "#E06153",
          "#EA8D4E",
          "#916FDF",
          "#37B99C",
          "rgb(153, 176, 128)",
          "rgb(249, 181, 114)",
          "rgb(217, 172, 245)",
          "rgb(210, 233, 233)",
        ],
        hoverOffset: props.hoverOffset,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: "60%",
    layout: {
      padding: props.padding,
    },
    plugins: {
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your tooltip body
        },
        titleFont: {
          family: "Poppins_SemiBold",
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          padding: 10,
          font: {
            family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
          },
          usePointStyle: true, // Use point style markers
        },
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;

      // calulating average
      let sum = 0;
      data.datasets.forEach((i) => {
        i.data.forEach((j) => {
          sum += parseInt(j);
        });
      });

      const xcoor = chart.getDatasetMeta(0)?.data[0]?.x;
      const ycoor = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = "12px Poppins_SemiBold";
      ctx.fillStyle = "#9291A5";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`Total Tickets`, xcoor, ycoor + 20);

      ctx.font = "24px Poppins_Bold";
      ctx.fillStyle = "#1E1B39";
      ctx.fillText(`${sum}`, xcoor, ycoor);
    },
  };

  return (
    <div className="dougnut-chart">
      <Doughnut
        plugins={[textCenter]}
        data={props.active ? data : data_sec}
        options={options}
      />
    </div>
  );
};

export default DoughnutChart;
