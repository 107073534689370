import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  callToCustomerReducers,
  getAgentByIdReducers,
  getAgentCall_Count_Reducers,
  getAgentDashboardReducers,
  getAgentDoughnutReducers,
  getAgentDoughuntByIdReducers,
  getAgentTicketsByBearerReducers,
  getAgentTicketsByIdReducers,
  getUnrepliedticketConversationReducers,
} from "./Reducers/AgentReducers/AgentReducers";
import {
  addRemarksReducers,
  assignNewTicketReducers,
  getAllAgentsReducer,
  getCallDetailsTicketIdReducers,
  getGoodwillRefundHistoryDataReducers,
  getIssueTypeTicketGraphReducers,
  getLead_Call_CountReducers,
  getPriorityTypeTicketGraphReducers,
  getTeamDashboardReducers,
  getTeamDoughnutReducers,
  getTeamLeaderAllAgentsForAsignee,
  getTeamLeaderAllTicketsReducer,
  getTeamLeaderNewTicketsReducer,
  getTeamTicketDetails,
  getTeamTicketWithIdDetails,
  getTicketAgingReportGraphReducers,
  getTicketConversationReducers,
  getTicketDetailsRemarksReducers,
  getTicketStatusReducer,
  updateTicketStatusReducers,
} from "./Reducers/TeamLeaderReducers/TeamLeaderReducers";
import {
  CancelCustomerOrderReducers,
  CreateAccountDetailsReducers,
  CreateNewTicketReducers,
  CreateReturnReducers,
  CustomerAcountDetailsListingReducers,
  GoodwillRefundCountReducers,
  UpdateAccountDetailsReducers,
  UpdateCustomerAddressReducers,
  UpdateCustomerInfoReducers,
  UpdateCustomerMobileNumberReducers,
  VerifyMobileNumberOtpReducers,
  cancelReplaceOrderReducers,
  getAllCoupansReducers,
  getAllPriorityReducers,
  getCustomerAddressReducers,
  getCustomerCurrentOrderReducers,
  getCustomerDashboardByIdReducers,
  getCustomerDashboardChartReducers,
  getCustomerDashboardReducers,
  getCustomerOrderDetailsReducer,
  getCustomerStateListReducers,
  getCustomerTicketLogsReducers,
  getCustomerTicketsReducers,
  getGoodWillNormalRefundHistoryReducers,
  getGoodWillRefundHistoryReducers,
  getIssueTypesReducers,
  getOrderProductSKUReducers,
  getOrderTrackingReducers,
  getReasonsforCancellingOrderReducers,
  getSubReasonsforCancellingOrderReducers,
  orderRefundReducers,
  orderSynchronizedReducers,
  postReplyOnTicketReducers,
  sendCoupansReducers,
  updatePriorityReducers,
} from "./Reducers/CustomerDashboardReducers/CustomerDashboardReducers";

const reducer = combineReducers({
  agent: getAgentDashboardReducers,
  team: getTeamDashboardReducers,
  teamDoughnut: getTeamDoughnutReducers,
  agentDoughnut: getAgentDoughnutReducers,
  customer: getCustomerDashboardReducers,
  allAgents: getAllAgentsReducer,
  IndividualAgent: getAgentByIdReducers,
  AgentIdDoughnut: getAgentDoughuntByIdReducers,
  agentTicketsId: getAgentTicketsByIdReducers,
  agentTicketsBearer: getAgentTicketsByBearerReducers,
  getNewTickets: getTeamLeaderNewTicketsReducer,
  getAllTickets: getTeamLeaderAllTicketsReducer,
  agentList: getTeamLeaderAllAgentsForAsignee,
  assignNewTicketsTeam: assignNewTicketReducers,
  teamTicketDetails: getTeamTicketDetails,
  customerOrder: getCustomerCurrentOrderReducers,
  customerTickets: getCustomerTicketsReducers,
  allstatus: getTicketStatusReducer,
  updateTicketStatus: updateTicketStatusReducers,
  callDetails: getCallDetailsTicketIdReducers,
  ticketConversation: getTicketConversationReducers,
  customerOrderDetails: getCustomerOrderDetailsReducer,
  ticketLogsCustomer: getCustomerTicketLogsReducers,
  sync: orderSynchronizedReducers,
  orderTracked: getOrderTrackingReducers,
  PostReply: postReplyOnTicketReducers,
  reasons: getReasonsforCancellingOrderReducers,
  subReasons: getSubReasonsforCancellingOrderReducers,
  ticketAgingReport: getTicketAgingReportGraphReducers,
  issueTypeTicketGraph: getIssueTypeTicketGraphReducers,
  priorityTypeTicketGraph: getPriorityTypeTicketGraphReducers,
  ticketDetailsRemarks: getTicketDetailsRemarksReducers,
  addRemarks: addRemarksReducers,
  dashboardChart: getCustomerDashboardChartReducers,
  customer_dashboard_id: getCustomerDashboardByIdReducers,
  updated_user: UpdateCustomerInfoReducers,
  mobile_number_update: UpdateCustomerMobileNumberReducers,
  verify_otp_mobile: VerifyMobileNumberOtpReducers,
  cancel_order: CancelCustomerOrderReducers,
  ticket_details_id: getTeamTicketWithIdDetails,
  good_will_refund_count: GoodwillRefundCountReducers,
  good_will_refund_history_data: getGoodWillRefundHistoryReducers,
  good_will_normal_history_data: getGoodWillNormalRefundHistoryReducers,
  product_sku: getOrderProductSKUReducers,
  order_refund_customer: orderRefundReducers,
  coupans: getAllCoupansReducers,
  createReturn_request: CreateReturnReducers,
  accountDetails: CreateAccountDetailsReducers,
  address_all_list: getCustomerAddressReducers,
  statelist: getCustomerStateListReducers,
  update_Cust_addrees: UpdateCustomerAddressReducers,
  customer_account_details_list: CustomerAcountDetailsListingReducers,
  update_account_details_data: UpdateAccountDetailsReducers,
  issues_types: getIssueTypesReducers,
  new_ticket: CreateNewTicketReducers,
  priority: getAllPriorityReducers,
  updatePriority: updatePriorityReducers,
  historyofGoodwill: getGoodwillRefundHistoryDataReducers,
  coupansShare: sendCoupansReducers,
  callingCust: callToCustomerReducers,
  replaceOrderCancel: cancelReplaceOrderReducers,
  unrepliedCon: getUnrepliedticketConversationReducers,
  teamLeadCallCount: getLead_Call_CountReducers,
  agentLeadCallCount: getAgentCall_Count_Reducers,
});

const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
