import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getCallDetailsAction } from "../../../Actions/TeamLeaderActions/TeamLeaderActions";
import PaginationLoader from "../../Loader/PaginationLoader";
import NoDataFromApi from "../../NotFound/NoDataFromApi";
import "./CallRecording.css";
import { Button } from "@mui/material";
import Triangle from "../../../Assets/Customer-Details/Triangle.png";
import Pause from "../../../Assets/Customer-Details/pause.png";
import { useState } from "react";

const CallDetails = (props) => {
  const { ticket_id } = props;
  const dispatch = useDispatch();
  const { callDetails, loading } = useSelector((state) => state.callDetails);
  console.log(props.ticket_id);

  let callInfo = callDetails && callDetails?.data;

  useEffect(() => {
    if (ticket_id && ticket_id) {
      dispatch(getCallDetailsAction(ticket_id));
    }
  }, [dispatch, ticket_id]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isIndex, setIsIndex] = useState();
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleAudio = (index) => {
    setIsIndex(index);
    togglePlay();
  };

  console.log("CallDetails: ", callDetails);
  return (
    <div>
      <HeadingTitle title="Call Details" closeModal={props.close} />
      {loading ? (
        <PaginationLoader />
      ) : (
        <div className="audio-agent-record">
          {callInfo && callInfo?.length > 0 ? (
            Array.isArray(callInfo) && callInfo?.map((i, index) => (
              <div key={index}>
                <div className="recording-icon">
                  {isPlaying && isIndex === index ? (
                    <div>
                      <audio
                        onEnded={() => setIsPlaying(false)}
                        autoPlay={isPlaying && isIndex === index ? true : false}
                        src={i.recording_url}
                        controls
                      ></audio>
                    </div>
                  ) : null}
                </div>
                <div className="call-recordings">
                  <div className="call-recording-column">
                    <p>{i.call_time}</p>
                    <p>{i.duration}</p>
                  </div>
                  <div className="call-recording-column">
                    <Button
                      className="audio-button"
                      onClick={() => handleAudio(index)}
                    >
                      {isPlaying && isIndex === index ? (
                        <img className="pause" src={Pause} alt="" />
                      ) : (
                        <img src={Triangle} alt="" />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFromApi
              height={true}
              width_new={true}
              title="There is No Data In our Database For Call Recordings..."
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CallDetails;
