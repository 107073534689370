export const GET_CUSTOMER_DASHBOARD_DATA_REQUEST =
  "GET_CUSTOMER_DASHBOARD_DATA_REQUEST";
export const GET_CUSTOMER_DASHBOARD_DATA_SUCCESS =
  "GET_CUSTOMER_DASHBOARD_DATA_SUCCESS";
export const GET_CUSTOMER_DASHBOARD_DATA_FAILURE =
  "GET_CUSTOMER_DASHBOARD_DATA_FAILURE";

export const GET_CUSTOMER_ORDER_DATA_REQUEST =
  "GET_CUSTOMER_ORDER_DATA_REQUEST";
export const GET_CUSTOMER_ORDER_DATA_SUCCESS =
  "GET_CUSTOMER_ORDER_DATA_SUCCESS";
export const GET_CUSTOMER_ORDER_DATA_FAILURE =
  "GET_CUSTOMER_ORDER_DATA_FAILURE";

export const GET_CUSTOMER_TICKETS_REQUEST = "GET_CUSTOMER_TICKETS_REQUEST";
export const GET_CUSTOMER_TICKETS_SUCCESS = "GET_CUSTOMER_TICKETS_SUCCESS";
export const GET_CUSTOMER_TICKETS_FAILURE = "GET_CUSTOMER_TICKETS_FAILURE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";

export const GET_CUSTOMER_TICKET_LOGS_REQUEST =
  "GET_CUSTOMER_TICKET_LOGS_REQUEST";
export const GET_CUSTOMER_TICKET_LOGS_SUCCESS =
  "GET_CUSTOMER_TICKET_LOGS_SUCCESS";
export const GET_CUSTOMER_TICKET_LOGS_FAILURE =
  "GET_CUSTOMER_TICKET_LOGS_FAILURE";

export const ORDER_SYNCHRONIZED_REQUEST = "ORDER_SYNCHRONIZED_REQUEST";
export const ORDER_SYNCHRONIZED_SUCCESS = "ORDER_SYNCHRONIZED_SUCCESS";
export const ORDER_SYNCHRONIZED_FAILURE = "ORDER_SYNCHRONIZED_FAILURE";
export const ORDER_SYNCHRONIZED_RESET = "ORDER_SYNCHRONIZED_RESET";

export const GET_ORDER_TRACKING_REQUEST = "GET_ORDER_TRACKING_REQUEST";
export const GET_ORDER_TRACKING_SUCCESS = "GET_ORDER_TRACKING_SUCCESS";
export const GET_ORDER_TRACKING_FAILURE = "GET_ORDER_TRACKING_FAILURE";

export const POST_REPLY_ON_TICKET_REQUEST = "POST_REPLY_ON_TICKET_REQUEST";
export const POST_REPLY_ON_TICKET_SUCCESS = "POST_REPLY_ON_TICKET_SUCCESS";
export const POST_REPLY_ON_TICKET_FAILURE = "POST_REPLY_ON_TICKET_FAILURE";

export const GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST =
  "GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST";
export const GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS =
  "GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS";
export const GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE =
  "GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE";

export const GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST =
  "GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST";
export const GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS =
  "GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS";
export const GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE =
  "GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE";

export const GET_CUSTOMER_DASHBOARD_CHART_REQUEST =
  "GET_CUSTOMER_DASHBOARD_CHART_REQUEST";
export const GET_CUSTOMER_DASHBOARD_CHART_SUCCESS =
  "GET_CUSTOMER_DASHBOARD_CHART_SUCCESS";
export const GET_CUSTOMER_DASHBOARD_CHART_FAILURE =
  "GET_CUSTOMER_DASHBOARD_CHART_FAILURE";

export const GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST =
  "GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST";
export const GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS =
  "GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS";
export const GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE =
  "GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE";

export const UPDATE_CUSTOMER_INFO_REQUEST = "UPDATE_CUSTOMER_INFO_REQUEST";
export const UPDATE_CUSTOMER_INFO_SUCCESS = "UPDATE_CUSTOMER_INFO_SUCCESS";
export const UPDATE_CUSTOMER_INFO_FAILURE = "UPDATE_CUSTOMER_INFO_FAILURE";
export const UPDATE_CUSTOMER_INFO_RESET = "UPDATE_CUSTOMER_INFO_RESET";

export const CANCEL_CUSTOMER_ORDER_REQUEST = "CANCEL_CUSTOMER_ORDER_REQUEST";
export const CANCEL_CUSTOMER_ORDER_SUCCESS = "CANCEL_CUSTOMER_ORDER_SUCCESS";
export const CANCEL_CUSTOMER_ORDER_FAILURE = "CANCEL_CUSTOMER_ORDER_FAILURE";
export const CANCEL_CUSTOMER_ORDER_RESET = "CANCEL_CUSTOMER_ORDER_RESET";

export const CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST =
  "CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST";
export const CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS =
  "CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS";
export const CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE =
  "CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE";

export const UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST =
  "UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST";
export const UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS =
  "UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS";
export const UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE =
  "UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE";

export const VERIFY_MOBILE_NUMBER_OTP_REQUEST =
  "VERIFY_MOBILE_NUMBER_OTP_REQUEST";
export const VERIFY_MOBILE_NUMBER_OTP_SUCCESS =
  "VERIFY_MOBILE_NUMBER_OTP_SUCCESS";
export const VERIFY_MOBILE_NUMBER_OTP_FAILURE =
  "VERIFY_MOBILE_NUMBER_OTP_FAILURE";

export const GET_GOODWILL_REFUND_COUNT_REQUEST =
  "GET_GOODWILL_REFUND_COUNT_REQUEST";
export const GET_GOODWILL_REFUND_COUNT_SUCCESS =
  "GET_GOODWILL_REFUND_COUNT_SUCCESS";
export const GET_GOODWILL_REFUND_COUNT_FAILURE =
  "GET_GOODWILL_REFUND_COUNT_FAILURE";

export const GET_GOODWILL_REFUND_HISTORY_REQUEST =
  "GET_GOODWILL_REFUND_HISTORY_REQUEST";
export const GET_GOODWILL_REFUND_HISTORY_SUCCESS =
  "GET_GOODWILL_REFUND_HISTORY_SUCCESS";
export const GET_GOODWILL_REFUND_HISTORY_FAILURE =
  "GET_GOODWILL_REFUND_HISTORY_FAILURE";

export const GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST =
  "GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST";
export const GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS =
  "GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS";
export const GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE =
  "GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE";

export const GET_ORDER_PRODUCT_SKU_REQUEST = "GET_ORDER_PRODUCT_SKU_REQUEST";
export const GET_ORDER_PRODUCT_SKU_SUCCESS = "GET_ORDER_PRODUCT_SKU_SUCCESS";
export const GET_ORDER_PRODUCT_SKU_FAILURE = "GET_ORDER_PRODUCT_SKU_FAILURE";

export const ORDER_REFUND_REQUEST = "ORDER_REFUND_REQUEST";
export const ORDER_REFUND_SUCCESS = "ORDER_REFUND_SUCCESS";
export const ORDER_REFUND_FAILURE = "ORDER_REFUND_FAILURE";

export const GET_ALL_COUPANS_REQUEST = "GET_ALL_COUPANS_REQUEST";
export const GET_ALL_COUPANS_SUCCESS = "GET_ALL_COUPANS_SUCCESS";
export const GET_ALL_COUPANS_FAILURE = "GET_ALL_COUPANS_FAILURE";


export const CREATE_RETURN_REQUEST = "CREATE_RETURN_REQUEST";
export const CREATE_RETURN_SUCCESS = "CREATE_RETURN_SUCCESS";
export const CREATE_RETURN_FAILURE = "CREATE_RETURN_FAILURE";


export const CREATE_ACCOUNT_DETAILS_REQUEST = "CREATE_ACCOUNT_DETAILS_REQUEST";
export const CREATE_ACCOUNT_DETAILS_SUCCESS = "CREATE_ACCOUNT_DETAILS_SUCCESS";
export const CREATE_ACCOUNT_DETAILS_FAILURE = "CREATE_ACCOUNT_DETAILS_FAILURE";

export const GET_CUSTOMER_ADDRESS_REQUEST = "GET_CUSTOMER_ADDRESS_REQUEST";
export const GET_CUSTOMER_ADDRESS_SUCCESS = "GET_CUSTOMER_ADDRESS_SUCCESS";
export const GET_CUSTOMER_ADDRESS_FAILURE = "GET_CUSTOMER_ADDRESS_FAILURE";

export const GET_CUSTOMER_STATE_LIST_REQUEST = "GET_CUSTOMER_STATE_LIST_REQUEST";
export const GET_CUSTOMER_STATE_LIST_SUCCESS = "GET_CUSTOMER_STATE_LIST_SUCCESS";
export const GET_CUSTOMER_STATE_LIST_FAILURE = "GET_CUSTOMER_STATE_LIST_FAILURE";

export const UPDATE_CUSTOMER_ADDRESS_REQUEST = "UPDATE_CUSTOMER_ADDRESS_REQUEST";
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = "UPDATE_CUSTOMER_ADDRESS_SUCCESS";
export const UPDATE_CUSTOMER_ADDRESS_FAILURE = "UPDATE_CUSTOMER_ADDRESS_FAILURE";
export const UPDATE_CUSTOMER_ADDRESS_RESET = "UPDATE_CUSTOMER_ADDRESS_RESET";

export const GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST = "GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST";
export const GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS = "GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS";
export const GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE = "GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE";

export const UPDATE_ACCOUNT_DETAILS_REQUEST = "UPDATE_ACCOUNT_DETAILS_REQUEST";
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = "UPDATE_ACCOUNT_DETAILS_SUCCESS";
export const UPDATE_ACCOUNT_DETAILS_FAILURE = "UPDATE_ACCOUNT_DETAILS_FAILURE";
export const UPDATE_ACCOUNT_DETAILS_RESET = "UPDATE_ACCOUNT_DETAILS_RESET";



export const GET_ISSUE_TYPES_REQUEST = "GET_ISSUE_TYPES_REQUEST";
export const GET_ISSUE_TYPES_SUCCESS = "GET_ISSUE_TYPES_SUCCESS";
export const GET_ISSUE_TYPES_FAILURE = "GET_ISSUE_TYPES_FAILURE";

export const CREATE_NEW_TICKET_REQUEST = "CREATE_NEW_TICKET_REQUEST";
export const CREATE_NEW_TICKET_SUCCESS = "CREATE_NEW_TICKET_SUCCESS";
export const CREATE_NEW_TICKET_FAILURE = "CREATE_NEW_TICKET_FAILURE";
export const CREATE_NEW_TICKET_RESET = "CREATE_NEW_TICKET_RESET";

export const GET_ALL_PRIORITY_REQUEST = "GET_ALL_PRIORITY_REQUEST";
export const GET_ALL_PRIORITY_SUCCESS = "GET_ALL_PRIORITY_SUCCESS";
export const GET_ALL_PRIORITY_FAILURE = "GET_ALL_PRIORITY_FAILURE";


export const UPDATE_PRIORITY_REQUEST = "UPDATE_PRIORITY_REQUEST";
export const UPDATE_PRIORITY_SUCCESS = "UPDATE_PRIORITY_SUCCESS";
export const UPDATE_PRIORITY_FAILURE = "UPDATE_PRIORITY_FAILURE";


export const GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST = "GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST";
export const GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS = "GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS";
export const GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE = "GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE";

export const SHARE_COUPANS_REQUEST = "SHARE_COUPANS_REQUEST";
export const SHARE_COUPANS_SUCCESS = "SHARE_COUPANS_SUCCESS";
export const SHARE_COUPANS_FAILURE = "SHARE_COUPANS_FAILURE";


export const ORDER_REPLACE_CANCEL_ORDER_REQUEST = "ORDER_REPLACE_CANCEL_ORDER_REQUEST";
export const ORDER_REPLACE_CANCEL_ORDER_SUCCESS = "ORDER_REPLACE_CANCEL_ORDER_SUCCESS";
export const ORDER_REPLACE_CANCEL_ORDER_FAILURE = "ORDER_REPLACE_CANCEL_ORDER_FAILURE";
