import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import Navbar from "../../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import "./EditProfile.css";
import {
  UpdateCustomerAddressActions,
  UpdateCustomerInfoActions,
  VerifyMobileNumberOTPActions,
  getCustomerAddressActions,
  getCustomerDashboardByIdActions,
  getCustomerStateListActions,
  updateCustomerMobileNumber,
} from "../../Actions/CustomerActions/CustomerActions";
import ReadOnlyInputs from "../../Components/ReadOnlyInputs/ReadOnlyInputs";
import { useState } from "react";
import DynamicInputs from "../../Components/DynamicInputs/DynamicInputs";
import { Button } from "@material-ui/core";
import UpdateIcon from "../../Assets/Customer-Details/Frame.png";
import { useAlert } from "react-alert";
import {
  UPDATE_CUSTOMER_ADDRESS_RESET,
  UPDATE_CUSTOMER_INFO_RESET,
} from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import TableLoader from "../../Components/Loader/TableLoader";
import Select from "react-select";
import { customStylesSKU } from "../../DummyArray/HiscravesData";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import HelmetTitle from "../../Components/MetaData/MetaData";

const EditProfile = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id } = useParams();

  // edit customer info state
  const {
    updated_customer_info,
    loading: customer_info_loading,
    isUpdated,
  } = useSelector((state) => state.updated_user);

  // update mobile number state
  const { update_mobile_number, loading: readingOtp_loading } = useSelector(
    (state) => state.mobile_number_update
  );

  // verify otp state
  const { verify_otp, loading: VerifyOtp_loading } = useSelector(
    (state) => state.verify_otp_mobile
  );

  const { customer_dashboard_data, loading } = useSelector(
    (state) => state.customer_dashboard_id
  );

  //   usestate for user details
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [customer_email_id, setCustomer_email_id] = useState("");
  const [Joining_Date, setJoining_date] = useState("");

  //   usestate for address 1
  const [mobile_number, setMobile_number] = useState("");

  const [hit, setHit] = useState(false);

  const [otp, setOtp] = useState("");

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  useEffect(() => {
    if (user_id && user_id !== "" && user_id !== null) {
      dispatch(getCustomerDashboardByIdActions(user_id));
    }
  }, [dispatch, user_id]);

  let customer_data = customer_dashboard_data && customer_dashboard_data?.data;

  console.log("Customer Data: ", customer_data);

  const handleFirstName = (e) => {
    setfirst_name(e.target.value);
  };

  const handleLastName = (e) => {
    setlast_name(e.target.value);
  };

  const handleCustomerEmail = (e) => {
    setCustomer_email_id(e.target.value);
  };

  useEffect(() => {
    if (customer_data && customer_data !== "" && customer_data !== null) {
      setfirst_name(customer_data && customer_data?.first_name);
      setlast_name(customer_data && customer_data?.last_name);
      setCustomer_email_id(customer_data && customer_data?.email);
      setJoining_date(
        customer_data &&
          new Date(customer_data?.created_at).toLocaleDateString()
      );
      setMobile_number(customer_data && customer_data?.phone);
    }
  }, [customer_data]);

  const updateUserDetails = () => {
    if (first_name !== "" && last_name !== "" && customer_email_id !== "") {
      dispatch(
        UpdateCustomerInfoActions(
          user_id,
          first_name,
          last_name,
          customer_email_id
        )
      );
    } else {
      alert.error("Please Fill All The Fields");
    }
  };

  useEffect(() => {
    if (
      updated_customer_info &&
      updated_customer_info?.status === "success" &&
      isUpdated
    ) {
      alert.success(updated_customer_info && updated_customer_info?.message);
      dispatch({
        type: UPDATE_CUSTOMER_INFO_RESET,
      });
    } else if (
      updated_customer_info &&
      updated_customer_info?.status === "error"
    ) {
      alert.error(updated_customer_info && updated_customer_info?.message);
    }
  }, [updated_customer_info, isUpdated, dispatch, alert]);

  const handleOtp = () => {
    setHit(true);
    if (mobile_number !== "") {
      dispatch(updateCustomerMobileNumber(user_id, mobile_number));
    } else {
      alert.error("Please Fill Mobile Number...");
    }
  };

  let OTP_verify = update_mobile_number && update_mobile_number?.data;

  console.log("OTP", OTP_verify);

  useEffect(() => {
    if (
      update_mobile_number &&
      update_mobile_number?.status === "error" &&
      hit
    ) {
      setHit(false);
      alert.error(update_mobile_number && update_mobile_number?.message);
    } else if (
      update_mobile_number &&
      update_mobile_number?.status === "success" &&
      hit
    ) {
      setHit(false);
      alert.success(update_mobile_number && update_mobile_number?.message);
      setOtp(OTP_verify && OTP_verify?.otp);
    }
  }, [update_mobile_number, alert, hit, OTP_verify]);

  console.log("Mobile Number: ", update_mobile_number);

  const [verifyHit, setVerifyHit] = useState(false);

  const VerifyOtp = () => {
    setVerifyHit(true);
    if (otp !== "" && mobile_number !== "" && user_id !== null) {
      dispatch(VerifyMobileNumberOTPActions(user_id, mobile_number, otp));
    } else {
      alert.error(
        !mobile_number
          ? "Please Enter Mobile Number"
          : !otp
          ? "Please Enter OTP..."
          : null
      );
    }
  };

  useEffect(() => {
    if (verify_otp && verify_otp?.status === "success" && verifyHit) {
      setVerifyHit(false);
      alert.success(verify_otp && verify_otp?.message);
    } else if (verify_otp && verify_otp?.status === "error" && verifyHit) {
      setVerifyHit(false);
      alert.error(verify_otp && verify_otp?.message);
    }
  }, [verify_otp, alert, verifyHit]);

  console.log("OTP:", OTP_verify && OTP_verify?.otp?.otp);

  // customer address state
  const { address_list, loading: address_loading } = useSelector(
    (state) => state.address_all_list
  );

  const { all_state_list, loading: state_list_loading } = useSelector(
    (state) => state.statelist
  );

  useEffect(() => {
    if (user_id && user_id !== null) {
      dispatch(getCustomerAddressActions(user_id));
    }
    dispatch(getCustomerStateListActions());
  }, [dispatch, user_id]);

  console.log("State_List: ", all_state_list);

  const [address_lists, setAddress_list] = useState([]);

  useEffect(() => {
    address_list && setAddress_list(address_list && address_list?.data);
  }, [address_list]);

  const handleAddressChange = (field, value, index, newField, newValue) => {
    const updatedAddressList = [...address_lists];
    updatedAddressList[index][field] = value;
    if (field === "shipping_state_name") {
      updatedAddressList[index][newField] = newValue;
    }
    setAddress_list(updatedAddressList);
  };

  console.log("Address List: ", address_lists);

  // update customer state
  const { customer_address_update, loading: customer_address_loading } =
    useSelector((state) => state.update_Cust_addrees);

  const [isIndex, setIsIndex] = useState();
  const [isHIt, setIsHit] = useState(false);

  const update_customer_address = (Id, index) => {
    setIsIndex(index);
    // filtering data by id
    let filter_data = address_lists && address_lists.filter((i) => i.id === Id);

    // removing unneccsarry fields from object
    let filter_need_data =
      filter_data &&
      filter_data.map(
        ({
          is_selected,
          is_default,
          consignee,
          address_as,
          forward,
          billing_first_name,
          billing_last_name,
          billing_email,
          billing_phone,
          billing_address1,
          billing_address2,
          billing_city,
          billing_state_id,
          billing_state_name,
          billing_pincode,
          billing_alternate_phone,
          created_by,
          updated_by,
          remember_token,
          created_at,
          updated_at,
          deleted_at,
          expected_delivery_date,
          ...rest
        }) => rest
      );

    // converting into object
    const filtered_data_object = filter_need_data.reduce((acc, i) => {
      acc["data"] = i;
      return acc;
    }, {});

    let final_obj = filtered_data_object?.data;
    console.log("Final Data: ", final_obj);
    if (
      final_obj.shipping_first_name === null ||
      final_obj.shipping_first_name === "" ||
      final_obj.shipping_last_name === null ||
      final_obj.shipping_last_name === "" ||
      final_obj.shipping_email === null ||
      final_obj.shipping_email === "" ||
      final_obj.shipping_phone === null ||
      final_obj.shipping_phone === "" ||
      final_obj.shipping_phone.length < 10 ||
      // final_obj.shipping_alternate_phone === null ||
      // final_obj.shipping_alternate_phone === "" ||
      final_obj.shipping_address1 === null ||
      final_obj.shipping_address1 === "" ||
      final_obj.shipping_address2 === null ||
      final_obj.shipping_address2 === "" ||
      final_obj.shipping_city === null ||
      final_obj.shipping_city === "" ||
      final_obj.shipping_pincode === null ||
      final_obj.shipping_pincode === "" ||
      final_obj.shipping_state_name === null ||
      final_obj.shipping_state_name === ""
    ) {
      return alert.error(
        final_obj.shipping_first_name === null ||
          final_obj.shipping_first_name === ""
          ? "Please Enter First Name"
          : final_obj.shipping_last_name === null ||
            final_obj.shipping_last_name === ""
          ? "Please Enter Last Name"
          : final_obj.shipping_email === null || final_obj.shipping_email === ""
          ? "Please Enter Email Id"
          : final_obj.shipping_phone === null || final_obj.shipping_phone === ""
          ? "Please Enter Phone Number"
          : // : final_obj.shipping_alternate_phone === null ||
          //   final_obj.shipping_alternate_phone === ""
          // ? "Please Enter Alternate Phone Number"
          final_obj.shipping_phone.length < 10
          ? "Mobile no should be of 10 digit"
          : final_obj.shipping_address1 === null ||
            final_obj.shipping_address1 === ""
          ? "Please Enter House/Flat/Office No"
          : final_obj.shipping_address2 === null ||
            final_obj.shipping_address2 === ""
          ? "Please Enter Locality/Town"
          : final_obj.shipping_city === null || final_obj.shipping_city === ""
          ? "Please Enter City"
          : final_obj.shipping_pincode === null ||
            final_obj.shipping_pincode === ""
          ? "Please Enter Pincode"
          : final_obj.shipping_state_name === null ||
            final_obj.shipping_state_name === ""
          ? "Please Enter State Name"
          : null
      );
    } else {
      setIsHit(true);
      dispatch(
        UpdateCustomerAddressActions(
          final_obj.id,
          final_obj.customer_id,
          final_obj.shipping_first_name,
          final_obj.shipping_last_name,
          final_obj.shipping_email,
          final_obj.shipping_phone,
          final_obj.shipping_alternate_phone,
          final_obj.shipping_address1,
          final_obj.shipping_address2,
          final_obj.shipping_city,
          final_obj.shipping_state_id,
          final_obj.shipping_pincode,
          final_obj.shipping_state_name
        )
      );
    }
  };

  useEffect(() => {
    if (
      customer_address_update &&
      customer_address_update?.status === "success" &&
      isHIt
    ) {
      alert.success(
        customer_address_update && customer_address_update?.message
      );
      dispatch({
        type: UPDATE_CUSTOMER_ADDRESS_RESET,
      });
      setIsHit(false);
    } else if (
      customer_address_update &&
      customer_address_update?.status === "error" &&
      isHIt
    ) {
      alert.error(customer_address_update && customer_address_update?.message);
      setIsHit(false);
    }
  }, [customer_address_update, alert, dispatch, isHIt]);

  console.log(customer_dashboard_data);

  return (
    <>
      <HelmetTitle
        // eslint-disable-next-line
        title={`${
          loading || address_loading || state_list_loading
            ? "Loading..." // eslint-disable-next-line
            : "Edit Profile" + " - " + first_name + " " + last_name
        }`}
      />
      <Navbar searchBar={handleSearchBar} />
      {loading || address_loading || state_list_loading ? (
        <Loader />
      ) : (
        <>
          {customer_dashboard_data?.logout ? (
            <NoDataFromApi
              btn={true}
              title={
                "Something went wrong, Please Try Logout & Login Again......"
              }
            />
          ) : (
            <div className="home-container">
              <Link onClick={() => navigate(-1)} className="agent-profile-head">
                <img src={Arrow} alt="" />
                <h1>Edit Profile</h1>
              </Link>

              {/* user details */}
              <div className="ticket-details-info edit-profile-h2">
                <h2>User Details</h2>
                <div className="user-info-edit-profile-grid-row">
                  <ReadOnlyInputs label={"User Id"} value={user_id} />
                  <DynamicInputs
                    label="First Name"
                    id="First_name"
                    value={first_name === null ? "" : first_name}
                    onChange={handleFirstName}
                  />
                  <DynamicInputs
                    label="Last Name"
                    id="Last_name"
                    value={last_name === null ? "" : last_name}
                    onChange={handleLastName}
                  />
                  <DynamicInputs
                    label="Customer Email Id"
                    id="Customer_Email_Id"
                    value={customer_email_id === null ? "" : customer_email_id}
                    onChange={handleCustomerEmail}
                  />
                  <ReadOnlyInputs label={"Joining Date"} value={Joining_Date} />
                </div>
                <div className="update-profile-cta">
                  <Button onClick={updateUserDetails}>
                    {customer_info_loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        <img src={UpdateIcon} alt="" />
                        &nbsp;&nbsp; Update
                      </>
                    )}
                  </Button>
                </div>
              </div>

              {/* Update Mobile Number */}
              <div className="ticket-details-info edit-profile-h2">
                <h2>Update Mobile Number</h2>
                <div className="user-info-edit-profile-grid-row">
                  <div className="mobile-number-update">
                    <label htmlFor="mobile_number">Mobile Number</label> <br />
                    <div className="mobile-input">
                      <input
                        type="number"
                        name="mobile_number"
                        id="mobile_number"
                        value={mobile_number}
                        onChange={(e) => setMobile_number(e.target.value)}
                      />
                      <Button onClick={handleOtp}>
                        {readingOtp_loading ? <TableLoader /> : "Send OTP"}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="otp">Enter OTP</label> <br />
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      // value={otp}
                      value={readingOtp_loading ? "Auto Reading OTP..." : otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                </div>
                <div className="update-profile-cta">
                  <Button onClick={VerifyOtp}>
                    {VerifyOtp_loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        <img src={UpdateIcon} alt="" />
                        &nbsp;&nbsp; Update
                      </>
                    )}
                  </Button>
                </div>
              </div>

              {/* Address-1 */}
              {Array.isArray(address_lists) && address_lists?.length > 0
                ? address_lists?.map((i, index) => (
                    <div
                      className="ticket-details-info edit-profile-h2"
                      key={i.id}
                    >
                      <h2>Address-{index + 1}</h2>
                      <div className="user-info-edit-profile-grid-row">
                        <div>
                          <label htmlFor={`fname${index}`}>First Name</label>
                          <input
                            id={`fname${index}`}
                            value={
                              i.shipping_first_name !== "" &&
                              i.shipping_first_name !== null
                                ? i.shipping_first_name
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_first_name",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`lname${index}`}>Last Name</label>
                          <input
                            id={`lname${index}`}
                            value={
                              i.shipping_last_name !== null
                                ? i.shipping_last_name
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_last_name",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`mobile_number${index}`}>
                            Mobile Number
                          </label>
                          <input
                            id={`mobile_number${index}`}
                            value={
                              i.shipping_phone !== null &&
                              i.shipping_phone !== ""
                                ? i.shipping_phone
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_phone",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`alt_mobile_number${index}`}>
                            Alternate Mobile Number
                          </label>
                          <input
                            id={`alt_mobile_number${index}`}
                            value={
                              i.shipping_alternate_phone !== null &&
                              i.shipping_alternate_phone !== ""
                                ? i.shipping_alternate_phone
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_alternate_phone",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`email_id${index}`}>Email Id</label>
                          <input
                            id={`email_id${index}`}
                            value={
                              i.shipping_email !== null &&
                              i.shipping_email !== ""
                                ? i.shipping_email
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_email",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`pincode${index}`}>Pincode</label>
                          <input
                            id={`pincode${index}`}
                            value={
                              i.shipping_pincode !== null &&
                              i.shipping_pincode !== ""
                                ? i.shipping_pincode
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_pincode",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`House_Flat_Office_No${index}`}>
                            House/Flat/Office No.
                          </label>
                          <input
                            id={`House_Flat_Office_No${index}`}
                            value={
                              i.shipping_address1 !== null &&
                              i.shipping_address1 !== ""
                                ? i.shipping_address1
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_address1",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`Locality_Town${index}`}>
                            Locality/Town
                          </label>
                          <input
                            id={`Locality_Town${index}`}
                            value={
                              i.shipping_address2 !== null &&
                              i.shipping_address2 !== ""
                                ? i.shipping_address2
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_address2",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor={`City_District${index}`}>
                            City/District
                          </label>
                          <input
                            id={`City_District${index}`}
                            value={
                              i.shipping_city !== null && i.shipping_city !== ""
                                ? i.shipping_city
                                : ""
                            }
                            onChange={(e) =>
                              handleAddressChange(
                                "shipping_city",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="state">State</label>
                          <Select
                            placeholder={`${
                              i.shipping_state_name !== null &&
                              i.shipping_state_name !== ""
                                ? i.shipping_state_name
                                : "Please Select State"
                            }`}
                            options={
                              all_state_list &&
                              all_state_list?.data &&
                              all_state_list?.data.map((option) => ({
                                value: option.name,
                                label: option.name,
                                id: option.id,
                              }))
                            }
                            id="state"
                            value={i.shipping_state_name}
                            styles={customStylesSKU}
                            onChange={(selected) =>
                              handleAddressChange(
                                "shipping_state_name",
                                selected.value,
                                index,
                                "shipping_state_id",
                                selected.id
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="update-profile-cta">
                        <Button
                          onClick={() => update_customer_address(i.id, index)}
                        >
                          {isIndex === index && customer_address_loading ? (
                            <TableLoader />
                          ) : (
                            <>
                              <img src={UpdateIcon} alt="" />
                              &nbsp;&nbsp; Update
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditProfile;
