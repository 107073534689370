import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import Select from "react-select";
import {
  customStylesSKU,
  refund_Option,
} from "../../../DummyArray/HiscravesData";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountdetailsListingActions,
  cancelCustomerOrderActions,
  cancelCustomerOrderWithBankActions,
  cancelCustomerOrderWithOriginalSourceActions,
  createReturnActions,
  createReturnBankActions,
  // getOrderProductSKUActions,
  getReasonsActions,
  getSubReasonsActions,
} from "../../../Actions/CustomerActions/CustomerActions";
import { useAlert } from "react-alert";
import TableLoader from "../../Loader/TableLoader";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

const CreateReturn = (props) => {
  const {
    close,
    create_return_customer_details,
    customer_id,
    orderNo,
    order_items,
    payment_mode,
    order_status,
  } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const { reasons, loading } = useSelector((state) => state.reasons);
  const { Sub_reasons, loading: subReasonLoading } = useSelector(
    (state) => state.subReasons
  );

  const { id: order_id } = useParams();

  // order id
  // const { customerOrder, loading: customerOrderLoading } = useSelector(
  //   (state) => state.customerOrder
  // );

  // product sku state
  // const { product_sku_order, loading: sku_loading } = useSelector(
  //   (state) => state.product_sku
  // );

  const [reason, setReason] = useState("");
  const [id, setId] = useState("");

  const [sub_reason, setSub_Reason] = useState("");
  const [sub_id, setsub_Id] = useState("");

  const [refundOption, setRefundOption] = useState("");
  const [comment, setComment] = useState("");

  const [upi_id, setupi_id] = useState("");
  const [bank_name, setbank_name] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [confirm_account_number, setconfirm_account_number] = useState("");
  const [ifsc, setifsc] = useState("");
  const [account_name, setaccount_name] = useState("");

  // account details list state
  const { account_details_list, loading: account_details_loading } =
    useSelector((state) => state.customer_account_details_list);

  const handleRefundOption = (selectedOption) => {
    console.log("Selected Option: ", selectedOption, customer_id);
    setRefundOption(selectedOption);
    if (selectedOption === "UPI") {
      dispatch(AccountdetailsListingActions(customer_id));
    } else if (selectedOption === "BankAccount") {
      dispatch(AccountdetailsListingActions(customer_id));
    }
  };

  const handleAccount = async (selectedOption) => {
    setaccount_number(selectedOption.value);
    let filter_bank =
      bank_acc_list &&
      bank_acc_list?.length > 0 &&
      bank_acc_list.filter((i) => i.id === selectedOption?.id);

    const filtered_data_object = filter_bank.reduce((acc, i) => {
      acc["data"] = i;
      return acc;
    }, {});

    let send_final_data = filtered_data_object && filtered_data_object?.data;
    console.log(send_final_data, "Bank");
    await setbank_name(send_final_data && send_final_data?.bank_name);
    await setconfirm_account_number(
      send_final_data && send_final_data?.confirm_account_no
    );
    await setifsc(send_final_data && send_final_data?.ifsc);
    setaccount_name(send_final_data && send_final_data?.account_name);
  };

  let upi_id_list = account_details_list && account_details_list?.data?.vpa;
  let bank_acc_list =
    account_details_list && account_details_list?.data?.bank_accounts;
  console.log("Bank Account List: ", bank_acc_list);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const handleReason = (option) => {
    setReason(option.value);
    setId(option.id);
  };

  const handleSubReason = (option) => {
    setSub_Reason(option.value);
    setsub_Id(option.id);
  };

  useEffect(() => {
    if (id) {
      dispatch(getSubReasonsActions("Cancel", id));
    }
    dispatch(getReasonsActions("Cancel"));
  }, [dispatch, id]);

  let reasons_list = reasons && reasons?.data && reasons?.data?.reason_list;
  let sub_reason_list =
    Sub_reasons && Sub_reasons?.data && Sub_reasons?.data?.sub_reason_list;

  // cancel with upi id state
  const {
    cancel_customer_order,
    loading: cancel_upi_loading,
    error,
  } = useSelector((state) => state.cancel_order);

  // create return some additonal usestate
  const [selectedValues, setSelectedValues] = useState([]);
  const [order_items_qty, setOrder_item_qty] = useState([]);

  // eslint-disable-next-line
  const [order_no, setOrder_no] = useState(orderNo);

  // const [isChange, setIsChanges] = useState(false);

  const [upiHit, setUpiHit] = useState(false);

  // const [cancel_error, setCancel_error] = useState("");

  const handleReturn = () => {
    setUpiHit(true);
    console.log("Order Id: ", props.order_id);
    console.log("Reason Id: ", id);
    console.log("Accounts: ", refundOption);
    console.log("Bank Name: ", bank_name);
    console.log("Account Number: ", account_number);
    console.log("Confirm Acc Number: ", confirm_account_number);
    console.log("IFSC: ", ifsc);
    console.log("Account Name: ", account_name);
    console.log("Reason Sub ID: ", sub_id);
    if (id === "" || refundOption === "") {
      alert.error(
        `${
          id === ""
            ? "Please Choose Reason"
            : refundOption === ""
            ? "Please Choose Refund Option"
            : null
        }`
      );
      // setCancel_error("Please Fill All The Field...");
      // setTimeout(() => {
      //   setCancel_error("");
      // }, 5000);
      setUpiHit(false);
    } else if (refundOption === "UPI") {
      dispatch(
        cancelCustomerOrderActions(
          props.order_id,
          id,
          refundOption,
          upi_id,
          sub_id
        )
      );
    } else if (refundOption === "BankAccount" || refundOption === "Wallet") {
      dispatch(
        cancelCustomerOrderWithBankActions(
          props.order_id,
          id,
          refundOption,
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          account_name,
          sub_id
        )
      );
    } else if (refundOption === "OriginalSource") {
      // alert.show("Original Source");
      dispatch(
        cancelCustomerOrderWithOriginalSourceActions(
          props.order_id,
          id,
          refundOption,
          sub_id
        )
      );
    }
  };

  const handlePrepaidCancel = () => {
    setUpiHit(true);
    if (id === "") {
      alert.error("Please Choose Reason...");
      // setCancel_error("Please Fill All The Field...");
      // setTimeout(() => {
      //   setCancel_error("");
      // }, 5000);
      setUpiHit(false);
    } else {
      dispatch(cancelCustomerOrderActions(props.order_id, id, sub_id));
    }
  };

  useEffect(() => {
    if (
      cancel_customer_order &&
      cancel_customer_order?.status === "error" &&
      upiHit
    ) {
      alert.error(cancel_customer_order && cancel_customer_order?.message);
      // setCancel_error(cancel_customer_order && cancel_customer_order?.message);
      // setTimeout(() => {
      //   setCancel_error("");
      // }, 5000);
      setUpiHit(false);
      // closeModal();
    } else if (
      cancel_customer_order &&
      cancel_customer_order?.status === "success" &&
      upiHit
    ) {
      alert.success(cancel_customer_order && cancel_customer_order?.message);
      setUpiHit(false);
      closeModal();
    }
    if (error && upiHit) {
      alert.error(error);
    }
  }, [cancel_customer_order, alert, upiHit, closeModal, error]);

  // useEffect(() => {
  //   if (customer_id && customer_id !== null) {
  //     dispatch(getCustomerOrder(customer_id, 6));
  //   }
  // }, [dispatch, customer_id]);

  console.log("Props Data: ", create_return_customer_details, customer_id);

  // let order_numbers_data = customerOrder && customerOrder?.data;

  // console.log(order_numbers_data);

  // let order_id = [];

  // eslint-disable-next-line
  const [order_ids, setOrder_id] = useState(order_id);

  // Array.isArray(order_numbers_data) &&
  //   order_numbers_data?.forEach((i) => {
  //     if (!order_id.includes(i.id)) {
  //       order_id.push({ order_no: i.order_number, id: i.id });
  //     }
  //   });

  // console.log("Order Id: ", order_id);

  // const handleOrderNoChange = (val) => {
  //   setSelectedValues([]);
  //   // setIsChanges(true);
  //   setOrder_no(val.value);
  //   setOrder_id(val.id);
  // };

  const handleSelectChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);

    // Update new fields based on selected options
    setOrder_item_qty((prevFields) => {
      const updatedFields = selectedOptions.map((option) => {
        // Check if there's a previously entered value for this field
        const prevField = prevFields.find((field) => field.id === option.value);
        return {
          id: option.value,
          qty: prevField ? prevField.qty : "",
          label: option.label,
        };
      });

      return updatedFields;
    });
  };

  const handleNewFieldValueChange = (index, value) => {
    setOrder_item_qty((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].qty = value;
      return updatedFields;
    });
  };

  // useEffect(() => {
  //   if (orderNo !== "" && orderNo !== null && orderNo !== undefined) {
  //     dispatch(getOrderProductSKUActions(orderNo));
  //     // setIsChanges(false);
  //   }
  // }, [order_no, dispatch, orderNo]);

  console.log("Order Items Qty: ", order_items_qty);

  const [createReturnHit, setCreate_return_hit] = useState(false);

  // create return state
  const { return_order_req, loading: createReturn_request_loading } =
    useSelector((state) => state.createReturn_request);

  const handleReturnItem = () => {
    setCreate_return_hit(true);
    console.log("Reason Id: ", id);
    console.log("Accounts: ", refundOption);
    console.log("Bank Name: ", bank_name);
    console.log("Account Number: ", account_number);
    console.log("Confirm Acc Number: ", confirm_account_number);
    console.log("IFSC: ", ifsc);
    console.log("Account Name: ", account_name);
    console.log("Reason Sub ID: ", sub_id);
    console.log("UPI ID: ", upi_id);
    console.log("Order Items Qty: ", order_items_qty);
    console.log("Customer Id: ", customer_id);
    console.log("Comments: ", comment);
    console.log("Order Ids: ", order_ids);
    if (
      id === ""
      // refundOption === "" ||
      // upi_id === "" ||
      // sub_id === ""
      // order_ids === "" ||
      // customer_id === "" ||
      // order_items_qty.length <= 0 ||
      // comment === ""
    ) {
      alert.error(!id ? "Please Choose Reason" : null);
    } else {
      dispatch(
        createReturnActions(
          order_ids,
          customer_id,
          order_items_qty,
          id,
          sub_id,
          comment,
          refundOption,
          upi_id
        )
      );
    }
  };

  const handleReturnItemBankAcc = () => {
    setCreate_return_hit(true);
    if (
      id === ""
      // refundOption === "" ||
      // sub_id === ""
      // order_ids === "" ||
      // customer_id === "" ||
      // order_items_qty.length <= 0 ||
      // comment === "" ||
      // bank_name === "" ||
      // account_number === "" ||
      // confirm_account_number === "" ||
      // ifsc === "" ||
      // account_name === ""
    ) {
      alert.error(!id ? "Please Choose Reason" : null);
    } else {
      dispatch(
        createReturnBankActions(
          order_ids,
          customer_id,
          order_items_qty,
          id,
          sub_id,
          comment,
          refundOption,
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          account_name
        )
      );
    }
  };

  useEffect(() => {
    if (
      return_order_req &&
      return_order_req?.status === "success" &&
      createReturnHit
    ) {
      alert.success(return_order_req && return_order_req?.message);
      close();
    } else if (
      return_order_req &&
      return_order_req?.status === "error" &&
      createReturnHit
    ) {
      alert.error(return_order_req && return_order_req?.message);
    }
  }, [return_order_req, alert, createReturnHit, close]);

  console.log("Order Items: ", order_items);

  const isOptionDisabled = (option) => {
    // Example condition: Disable options where name is 'DisabledOption'
    return (
      option.return_qty_left === 0 || option.product.return_available === 0
    );
    // Modify this condition as per your requirement
  };

  console.log("PM: ", payment_mode);

  return (
    <>
      <HeadingTitle
        title={props.comingCancel ? "Cancel Order" : "Create Return"}
        closeModal={props.close}
      />
      {/* {loading ? (
        <PaginationLoader />
      ) : (
        <> */}
      <div className="input-refund">
        {/* {cancel_error !== "" ? (
          <p className={`${cancel_error !== "" && "cancel_error"}`}>
            {cancel_error !== "" && cancel_error}
          </p>
        ) : null} */}
        {create_return_customer_details &&
          create_return_customer_details !== null && (
            <>
              <div className="inputs">
                <label className="label-refund" htmlFor="">
                  Order Number
                </label>{" "}
                <br />
                <input type="text" readOnly value={order_no} />
                {/* <Select
                      placeholder={`${
                        order_no !== "" ? order_no : "Please Select Order No"
                      }`}
                      // options={
                      //   order_id &&
                      //   order_id.map((option) => ({
                      //     value: option.order_no,
                      //     label: option.order_no,
                      //     id: option.id,
                      //   }))
                      // }
                      value={order_no}
                      styles={customStylesSKU}
                      onChange={handleOrderNoChange}
                    /> */}
              </div>
              <div className="inputs">
                <div>
                  <label className="label-refund" htmlFor="">
                    Product SKU <span className="dot-red">*</span>
                  </label>{" "}
                  <br />
                  <Select
                    options={((order_items && order_items) || []).map(
                      (option) => ({
                        value: option.id,
                        label: option?.product?.product_code,
                        isDisabled: isOptionDisabled(option),
                      })
                    )}
                    placeholder="Select SKU"
                    isMulti={true}
                    styles={customStylesSKU}
                    value={selectedValues}
                    onChange={handleSelectChange}
                    isLoading={
                      order_items && order_items?.length > 0 ? false : true
                    }
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                </div>
              </div>
              <div className="inputs">
                {order_items_qty.map((field, index) => (
                  <div className="inputs" key={index}>
                    <label className="label-refund">
                      Qty. ({field.label}): <span className="dot-red">*</span>
                    </label>{" "}
                    <br />
                    <input
                      className="qty"
                      type="text"
                      value={field.qty}
                      onChange={(e) =>
                        handleNewFieldValueChange(index, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        <div
          className="inputs"
          // style={{
          //   paddingTop:
          //     cancel_error !== "" && cancel_error.length >= 40
          //       ? "30px"
          //       : cancel_error !== "" && cancel_error.length <= 39 && "20px",
          // }}
        >
          <label htmlFor="SelectReason">
            Select Reason <span className="dot-red">*</span>
          </label>
          <Select
            placeholder={`${reason !== "" ? reason : "Select Reason"}`}
            options={
              reasons_list &&
              reasons_list.map((option) => ({
                value: option.name,
                label: option.name,
                id: option.id,
              }))
            }
            className="select"
            id="SelectReason"
            value={reason}
            isLoading={loading}
            styles={customStylesSKU}
            onChange={(selectedOption) => handleReason(selectedOption)}
          />
        </div>
        <>
          <div className="inputs">
            <label htmlFor="SelectSubReason">Select Sub Reason</label>
            <Select
              placeholder={`${
                sub_reason !== "" ? sub_reason : "Select Sub Reason"
              }`}
              isLoading={subReasonLoading}
              options={
                sub_reason_list &&
                sub_reason_list.map((option) => ({
                  value: option.name,
                  label: option.name,
                  id: option.id,
                }))
              }
              className="select"
              id="SelectSubReason"
              value={sub_reason}
              styles={customStylesSKU}
              onChange={(selectedOption) => handleSubReason(selectedOption)}
            />
          </div>
        </>
        {(payment_mode === "Prepaid" && props.comingCancel) ||
        (order_status !== "Delivered" &&
          payment_mode === "cash_on_delivery" &&
          props.comingCancel) ? null : (
          <>
            <div className="inputs">
              <label htmlFor="RefundOption">
                Refund Option <span className="dot-red">*</span>
              </label>
              <Select
                placeholder={`${
                  refundOption !== "" ? refundOption : "Select Option"
                }`}
                options={refund_Option.map((option) => ({
                  value: option,
                  label: option,
                }))}
                className="select"
                id="RefundOption"
                value={refundOption}
                styles={customStylesSKU}
                onChange={(selectedOption) =>
                  handleRefundOption(selectedOption.value)
                }
              />
            </div>
            {refundOption === "UPI" && (
              <div className="inputs">
                <label htmlFor="RefundOption">
                  Select UPI ID <span className="dot-red">*</span>
                </label>
                <Select
                  placeholder={`${upi_id !== "" ? upi_id : "Select Option"}`}
                  options={
                    upi_id_list &&
                    upi_id_list.map((option) => ({
                      value: option.upi_id,
                      label: option.upi_id,
                    }))
                  }
                  className="select"
                  id="RefundOption"
                  isLoading={account_details_loading}
                  value={upi_id}
                  styles={customStylesSKU}
                  onChange={(selectedOption) => setupi_id(selectedOption.value)}
                />
              </div>
            )}
            {refundOption === "BankAccount" && (
              <div className="inputs">
                <label htmlFor="RefundOption">
                  Select Account No <span className="dot-red">*</span>
                </label>
                <Select
                  placeholder={`${
                    account_number !== "" ? account_number : "Select Option"
                  }`}
                  options={
                    bank_acc_list &&
                    bank_acc_list.map((option) => ({
                      value: option.account_no,
                      label: option.account_no,
                      id: option.id,
                    }))
                  }
                  className="select"
                  id="RefundOption"
                  isLoading={account_details_loading}
                  value={account_number}
                  styles={customStylesSKU}
                  onChange={(selectedOption) => handleAccount(selectedOption)}
                />
              </div>
            )}
          </>
        )}
        <div className="inputs">
          <label htmlFor="Comment">
            {props.comingCancel ? "Remark" : "Comment"}
          </label>{" "}
          <br />
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            id="Comment"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div className="btn-create-return">
        {create_return_customer_details &&
        create_return_customer_details !== null ? (
          <>
            {refundOption === "UPI" ? (
              <Button type="submit" onClick={handleReturnItem}>
                {createReturn_request_loading ? <TableLoader /> : "Submit"}
              </Button>
            ) : (
              <Button type="submit" onClick={handleReturnItemBankAcc}>
                {createReturn_request_loading ? <TableLoader /> : "Submit"}
              </Button>
            )}
          </>
        ) : (
          <Button
            type="submit"
            onClick={
              (payment_mode === "Prepaid" && props.comingCancel) ||
              (order_status !== "Delivered" &&
                payment_mode === "cash_on_delivery" &&
                props.comingCancel)
                ? handlePrepaidCancel
                : handleReturn
            }
          >
            {cancel_upi_loading ? <TableLoader /> : "Submit"}
          </Button>
        )}
      </div>
    </>
    //   )}
    // </>
  );
};

export default CreateReturn;
