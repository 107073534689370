import React, { useState } from "react";
import ModalContainer, { HeadingTitle } from "../../Modal/Modal";
import "./CancelOrder.css";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { cancelReplaceOrderActions } from "../../../Actions/CustomerActions/CustomerActions";
import TableLoader from "../../Loader/TableLoader";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useCallback } from "react";

const CancelOrder = (props) => {
  const alert = useAlert();
  const { order_id: id, scloseModal, payment_mode, order_status } = props;
  const dispatch = useDispatch();

  const { order_replace_cancel, loading } = useSelector(
    (state) => state.replaceOrderCancel
  );

  const [isIndex, setIsIndex] = useState(null);

  const [order_id, setOrder_id] = useState("");

  const handleModal = (index, id) => {
    setIsIndex(index);
    setOrder_id(id);
  };

  const closeModal = useCallback(() => {
    setIsIndex(null);
    scloseModal(null);
  }, [scloseModal]);

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          comingCancel={true}
          order_id={order_id}
          customer_id={props.customer_id}
          payment_mode={payment_mode}
          order_status={order_status}
        />
      );
    }
    return null;
  };

  const [isHit, setIsHit] = useState(false);

  const handleCancelReplaceOrder = () => {
    setIsHit(true);
    dispatch(cancelReplaceOrderActions(id));
  };

  useEffect(() => {
    if (
      order_replace_cancel &&
      order_replace_cancel?.success === true &&
      isHit
    ) {
      alert.success(order_replace_cancel && order_replace_cancel?.message);
      setIsHit(false);
      closeModal();
    } else if (
      order_replace_cancel &&
      order_replace_cancel?.success === false &&
      isHit
    ) {
      alert.error(order_replace_cancel && order_replace_cancel?.message);
      setIsHit(false);
      closeModal();
    }
  }, [order_replace_cancel, alert, isHit, closeModal]);

  console.log("Order Id: ", order_replace_cancel);
  return (
    <>
      <HeadingTitle title="Cancel Order" closeModal={props.close} />
      <div className="content-cancel">
        <h3>are you sure cancel order?</h3>
        <p>Your complete order canceled and payment will be returned to you.</p>
        <div className="center-button-cancel">
          {props.is_replacement_order === 1 ? (
            <Button onClick={handleCancelReplaceOrder}>
              {loading ? <TableLoader /> : "Yes, cancel"}
            </Button>
          ) : (
            <Button onClick={() => handleModal(4, props.order_id)}>
              Yes, cancel
            </Button>
          )}
          <Button onClick={closeModal}>No, don’t cancel</Button>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default CancelOrder;
