import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import "./TicketDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  AddRemarksAction,
  getTeamTicketDetails,
  getTeamTicketDetailsWithId,
  getTicketDetailsRemarksAction,
  getTicketStatus,
  updateTicketStatus,
} from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import ChatHistory from "../../Assets/Customer-Details/message-time.png";
import Loader from "../Loader/Loader";
import ReadOnlyInputs from "../ReadOnlyInputs/ReadOnlyInputs";
import NoDataFromApi from "../NotFound/NoDataFromApi";
import { useState } from "react";
import Select from "react-select";
import {
  customStylesSKU,
  customStylesSKU_data,
} from "../../DummyArray/HiscravesData";
import call from "../../Assets/Customer-Details/call.png";
import frame from "../../Assets/Customer-Details/Frame.png";
import {
  ADD_REMARKS_RESET,
  UPDATE_TICKET_STATUS__RESET,
} from "../../Constants/TeamLeaderConstants/TeamLeaderConstants";
import { useAlert } from "react-alert";
import ModalContainer from "../Modal/Modal";
import { clearErrors } from "../../Actions/LoginActions/LoginActions";
import { Button } from "@material-ui/core";
import TableLoader from "../Loader/TableLoader";
import Remarks from "../Remarks/Remarks";
import PaginationLoader from "../Loader/PaginationLoader";
import TicketHistory from "../TicketHistory/TicketHistory";
import {
  UpdatedPriorityActions,
  getAllPriorityActions,
  getCustomerTilesAction,
} from "../../Actions/CustomerActions/CustomerActions";
import HelmetTitle from "../MetaData/MetaData";
import SwiperCards from "../Swiper/Swiper";

const TicketDetails = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  let type_user = user && user?.user_type;
  console.log("USER: ", type_user);
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticket_Id } = useParams();

  const query = new URLSearchParams(useLocation().search);
  const customer = query.get("customer");
  const phone_number_params = query.get("customer_id");

  // customer_info
  const { customerTilesdata } = useSelector((state) => state.customer);

  //   ticket details state
  const { teamTicketDetails, loading } = useSelector(
    (state) => state.teamTicketDetails
  );

  const { teamTicketDetails_id, loading: team_ticket_id_loading } = useSelector(
    (state) => state.ticket_details_id
  );

  //   get all status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // update priority state
  const { update_priority, loading: update_priority_loading } = useSelector(
    (state) => state.updatePriority
  );

  //   update status state
  const { ticket_status_update_view, loading: updateLoading } = useSelector(
    (state) => state.updateTicketStatus
  );

  //   remarks state
  const { ticket_details_remarks, loading: remarksLoading } = useSelector(
    (state) => state.ticketDetailsRemarks
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  // add remarks state
  const {
    add_remarks,
    isPosted,
    loading: add_remarks_loading,
  } = useSelector((state) => state.addRemarks);

  const [status, setStatus] = useState("");
  const [priority, setpriority] = useState("");
  const [priority_name, setpriority_name] = useState("");
  const [ticket_status, setTicket_status] = useState("");

  const [team_tickets, setTeam_tickets] = useState();

  const [isHitPass, setIsHit_pass] = useState(false);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  //   Modal Script starts here
  const [isIndex, setIsIndex] = useState(null);
  const closeModal = () => {
    setIsIndex(null);
  };

  const handleModal = (index) => {
    setIsIndex(index);
  };

  let customerInfo = customerTilesdata && customerTilesdata?.data?.customer;

  const [customer_name_info, setCustomer_name_info] = useState("");

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          ticket_id={team_tickets && team_tickets?.id ? team_tickets?.id : null}
          customer_img={customerInfo && customerInfo?.avatar_location}
          customer_mobile_number={customerInfo && customerInfo?.phone}
          customer_name={
            customerInfo?.full_name === undefined
              ? customer_name_info
              : customerInfo?.full_name
          }
        />
      );
    }
    return null;
  };

  // let teamTicketsData = teamTicketDetails && teamTicketDetails?.data;
  let statusList = Allstatus?.data && Object.values(Allstatus?.data);

  useEffect(() => {
    if (team_tickets?.status || team_tickets?.priority) {
      setStatus(team_tickets && team_tickets?.status);
      setpriority_name(team_tickets && team_tickets?.priority);
    }
  }, [team_tickets]);

  useEffect(() => {
    if (ticket_Id && !customer && customer === null) {
      dispatch(getTeamTicketDetails(ticket_Id));
    } else if (ticket_Id && customer && customer !== null) {
      dispatch(getTeamTicketDetailsWithId(ticket_Id));
    }
  }, [dispatch, ticket_Id, customer]);

  const [isMyTicket, setIsMyTicket] = useState();

  useEffect(() => {
    setTeam_tickets([]);
    if (!customer && customer === null) {
      teamTicketDetails &&
        setTeam_tickets(teamTicketDetails && teamTicketDetails?.data);
      setIsMyTicket(teamTicketDetails && teamTicketDetails?.isMyTicket);
    } else if (customer && customer !== null) {
      teamTicketDetails_id &&
        setTeam_tickets(teamTicketDetails_id && teamTicketDetails_id?.data);
      setIsMyTicket(teamTicketDetails_id && teamTicketDetails_id?.isMyTicket);
    }
  }, [teamTicketDetails, teamTicketDetails_id, customer]);

  useEffect(() => {
    dispatch(getTicketStatus());
  }, [dispatch]);

  const updateStatus = () => {
    setIsHit_pass(true);
    if (ticket_status !== "" && priority !== "") {
      dispatch(UpdatedPriorityActions(ticket_Id, priority));
      dispatch(updateTicketStatus(ticket_Id, ticket_status));
    } else if (priority !== "") {
      dispatch(UpdatedPriorityActions(ticket_Id, priority));
    } else if (ticket_status !== "") {
      dispatch(updateTicketStatus(ticket_Id, ticket_status));
    } else {
      alert.error("Please Select Status OR Priority...");
    }
  };

  useEffect(() => {
    if (
      ticket_status_update_view &&
      ticket_status_update_view?.success === true &&
      isHitPass
    ) {
      setTicket_status(null);
      alert.success(
        ticket_status_update_view && ticket_status_update_view?.message
      );
      dispatch({
        type: UPDATE_TICKET_STATUS__RESET,
      });
      dispatch(clearErrors());
    } else if (
      ticket_status_update_view &&
      ticket_status_update_view?.success === false &&
      isHitPass
    ) {
      alert.error(
        ticket_status_update_view && ticket_status_update_view?.message
      );
    }
  }, [dispatch, ticket_status_update_view, alert, isHitPass]);

  useEffect(() => {
    if (update_priority && update_priority?.success === true && isHitPass) {
      alert.success(update_priority && update_priority?.message);
      setIsHit_pass(false);
    } else if (
      update_priority &&
      update_priority?.success === false &&
      isHitPass
    ) {
      alert.error(update_priority && update_priority?.message);
      setIsHit_pass(false);
    }
  }, [update_priority, alert, isHitPass]);

  console.log("Ticket Detailsxxxxx: ", update_priority);

  useEffect(() => {
    if (ticket_Id && ticket_Id !== null) {
      dispatch(getTicketDetailsRemarksAction(ticket_Id));
    }
  }, [dispatch, ticket_Id]);

  let remarks_data = ticket_details_remarks && ticket_details_remarks?.data;
  let ticket_history =
    teamTicketDetails_id &&
    Array.isArray(teamTicketDetails_id?.tickets) &&
    teamTicketDetails_id?.tickets?.length > 0
      ? teamTicketDetails_id?.tickets
      : teamTicketDetails &&
        Array.isArray(teamTicketDetails?.tickets) &&
        teamTicketDetails?.tickets?.length > 0
      ? teamTicketDetails?.tickets
      : [];

  console.log("Ticket Detailsxx: ", ticket_history, teamTicketDetails_id);

  const [comment, setComment] = useState("");
  const [isHit, setIshit] = useState(false);
  let type = 3;

  const handleAddRemarks = (e) => {
    e.preventDefault();
    if (
      comment !== "" &&
      ticket_Id !== null &&
      ticket_Id !== "" &&
      ticket_Id !== undefined
    ) {
      setIshit(true);
      dispatch(AddRemarksAction(type, encodeURIComponent(comment), ticket_Id));
    } else {
      alert.error("Please Add Comment...");
    }
  };

  console.log(ticket_Id, "ticket id");

  useEffect(() => {
    if (isPosted && add_remarks?.success === true && isHit) {
      setComment("");
      alert.success(add_remarks && add_remarks?.message);
      dispatch(getTicketDetailsRemarksAction(ticket_Id));
      dispatch({
        type: ADD_REMARKS_RESET,
      });
      setIshit(false);
    } else if (add_remarks?.success === false && isHit) {
      setIshit(false);
      alert.error(add_remarks && add_remarks?.message);
    }
  }, [dispatch, isPosted, alert, ticket_Id, add_remarks, isHit]);

  console.log("Ticket Details: ", ticket_Id);

  useEffect(() => {
    if (
      teamTicketDetails &&
      teamTicketDetails?.success === false &&
      customer &&
      customer === null
    ) {
      alert.error(teamTicketDetails && teamTicketDetails?.message);
      // dispatch(clearErrors());
    }
    if (
      teamTicketDetails_id &&
      teamTicketDetails_id?.success === false &&
      customer &&
      customer !== null
    ) {
      alert.error(teamTicketDetails_id && teamTicketDetails_id?.message);
      // dispatch(clearErrors());
    }
  }, [teamTicketDetails, teamTicketDetails_id, alert, dispatch, customer]);

  console.log("Priority: ", update_priority);

  useEffect(() => {
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  console.log("STATUS: ", status, ticket_status);
  console.log("PRIORITY: ", remarks_data);

  console.log("DATA: ", teamTicketDetails);

  useEffect(() => {
    if (phone_number_params && customer) {
      dispatch(getCustomerTilesAction(phone_number_params));
    }
  }, [dispatch, phone_number_params, customer]);

  console.log(
    "Customer Info: ",
    team_tickets?.customer?.first_name +
      " " +
      team_tickets?.customer?.last_name +
      "/" +
      customerInfo?.full_name
  );

  useEffect(() => {
    if (team_tickets && team_tickets?.customer?.first_name === null) {
      setCustomer_name_info(team_tickets && team_tickets?.customer?.last_name);
    } else if (team_tickets && team_tickets?.customer?.last_name === null) {
      setCustomer_name_info(team_tickets && team_tickets?.customer?.first_name);
    } else if (
      team_tickets &&
      team_tickets?.customer?.first_name !== null &&
      team_tickets &&
      team_tickets?.customer?.last_name !== null
    ) {
      setCustomer_name_info(
        team_tickets &&
          team_tickets?.customer?.first_name +
            " " +
            team_tickets?.customer?.last_name
      );
    }
    // xxxxxxxxxxxxxxxxx
  }, [team_tickets]);
  return (
    <>
      <HelmetTitle
        title={`${
          (loading && statusLoading && remarksLoading) || team_ticket_id_loading
            ? "Loading..."
            : "Ticket Details"
        }`}
      />
      <Navbar searchBar={handleSearchBar} />
      {(loading && statusLoading && remarksLoading) ||
      team_ticket_id_loading ? (
        <Loader />
      ) : (
        <>
          {Allstatus?.logout || teamTicketDetails_id?.tickets === "" ? (
            <>
              <NoDataFromApi
                showNew={teamTicketDetails_id?.tickets === "" ? true : false}
                title={
                  teamTicketDetails_id?.tickets === ""
                    ? "No Tickets Found For This Order..."
                    : "Something went wrong, Please Try Logout & Login Again......"
                }
                btn={Allstatus?.logout ? true : false}
              />
            </>
          ) : (
            <div className="home-container">
              <Link onClick={() => navigate(-1)} className="agent-profile-head">
                <img src={Arrow} alt="" />
                <h1>Tickets Details</h1>
              </Link>

              {/* ticket details  */}
              <div className="ticket-details-info">
                {/* {team_tickets &&
                  Array.isArray(team_tickets?.ticket_all_items) &&
                  team_tickets?.ticket_all_items?.map((i) => (
                    <div className="product-img" key={i.product_id}> */}
                {/* <img src={i.image_url} alt="" /> */}
                <SwiperCards
                  Slider_new={
                    team_tickets &&
                    Array.isArray(team_tickets?.ticket_all_items) &&
                    team_tickets?.ticket_all_items
                  }
                />
                {/* </div>
                  ))} */}
                <div className="ticket-details-grid-row">
                  {team_tickets &&
                    Array.isArray(team_tickets?.ticket_all_items) &&
                    team_tickets?.ticket_all_items?.map((i, index) => (
                      <ReadOnlyInputs
                        label={`Item Name (${index + 1})`}
                        id="Itemname"
                        value={i.title}
                      />
                    ))}
                  <ReadOnlyInputs
                    label="Ticket No"
                    id="ticket_id"
                    value={team_tickets && team_tickets?.ticket_no}
                  />
                  <ReadOnlyInputs label="Id" id="id" value={ticket_Id} />
                  <ReadOnlyInputs
                    label="Order Id"
                    id="OrderId"
                    value={team_tickets && team_tickets?.order_id}
                  />
                  <div className="textarea-description">
                    <label htmlFor="">Title</label> <br />
                    <textarea
                      value={team_tickets && team_tickets?.title}
                      name="title"
                      id="title"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="textarea-description">
                    <label htmlFor="">Description</label> <br />
                    <textarea
                      value={team_tickets && team_tickets?.message}
                      name="description"
                      id="description"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <ReadOnlyInputs
                    label="Created Date"
                    id="create_date"
                    dateValue={true}
                    value={
                      team_tickets &&
                      new Date(team_tickets?.created_at).toLocaleString()
                    }
                  />
                  <ReadOnlyInputs
                    label="Created By"
                    id="create_by"
                    value={team_tickets && team_tickets?.created_by}
                  />
                  <ReadOnlyInputs
                    label="Issue Type"
                    id="issue_type"
                    value={team_tickets && team_tickets?.issue_type?.title}
                  />
                  <ReadOnlyInputs
                    label="Last Update Date"
                    id="LastUpdateDate"
                    dateValue={true}
                    value={
                      team_tickets &&
                      new Date(team_tickets?.updated_at).toLocaleString()
                    }
                  />
                  {/* <ReadOnlyInputs
                    label="Priority"
                    id="Priority"
                    value={team_tickets && team_tickets?.priority}
                  /> */}
                  <div>
                    <label className="priority" htmlFor="Priority">
                      Priority
                    </label>
                    <Select
                      placeholder={`${
                        priority_name !== ""
                          ? priority_name
                          : team_tickets && team_tickets?.priority
                          ? team_tickets?.priority
                          : "Choose Priority"
                      }`}
                      options={
                        Array.isArray(priority_list?.data) &&
                        priority_list?.data?.length > 0
                          ? priority_list?.data?.map((option, index) => ({
                              value: option,
                              label: option,
                              index: index + 1,
                            }))
                          : []
                      }
                      className="select"
                      isDisabled={type_user === "1" ? true : false}
                      id="Priority"
                      value={priority_name}
                      styles={
                        type_user === "1"
                          ? customStylesSKU_data
                          : customStylesSKU
                      }
                      onChange={(selectedOption) =>
                        setpriority(selectedOption.index) +
                        setpriority_name(selectedOption.value)
                      }
                    />
                  </div>
                  <ReadOnlyInputs
                    label="Language"
                    id="Language"
                    value={team_tickets && team_tickets?.language}
                  />
                  <div className="inputs top">
                    <label htmlFor="SelectProduct">Status</label>
                    <Select
                      placeholder={`${
                        status !== ""
                          ? status
                          : team_tickets && team_tickets?.status
                          ? team_tickets?.status
                          : "Status"
                      }`}
                      options={
                        statusList?.length > 0 &&
                        statusList.map((option, index) => ({
                          value: option,
                          label: option,
                          index: index + 1,
                        }))
                      }
                      isDisabled={isMyTicket === false ? true : false}
                      className="select"
                      id="SelectProduct"
                      value={status}
                      isLoading={priority_loading}
                      styles={
                        isMyTicket === false
                          ? customStylesSKU_data
                          : customStylesSKU
                      }
                      onChange={(selectedOption) =>
                        setStatus(selectedOption.value) +
                        setTicket_status(selectedOption.index)
                      }
                    />
                  </div>
                </div>
                {/* <div className="ticket-details-grid-row">
                 
                </div> */}
                <div className="button-grid-row">
                  <Button
                    disabled={team_tickets && team_tickets?.id ? false : true}
                    onClick={() => handleModal(1)}
                  >
                    <img src={ChatHistory} alt="" />
                    &nbsp;<p>View chat History</p>
                  </Button>
                  {/* debugger */}
                  <Button
                    disabled={team_tickets && team_tickets?.id ? false : true}
                    onClick={() => handleModal(9, ticket_Id)}
                  >
                    <img src={call} alt="" />
                    &nbsp;<p>View Call History</p>
                  </Button>
                  <Button
                    disabled={team_tickets && team_tickets?.id ? false : true}
                    onClick={updateStatus}
                  >
                    {updateLoading || update_priority_loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        <img src={frame} alt="" />
                        &nbsp;<p>Update</p>
                      </>
                    )}
                  </Button>
                </div>
              </div>
              {/* ticket details */}

              <div className="ticket-details-info">
                <h2>Ticket History</h2>
                <div
                  className={`${
                    ticket_history && ticket_history?.length > 0
                      ? "team-ticket-history"
                      : ""
                  }`}
                >
                  {ticket_history && ticket_history?.length > 0 ? (
                    ticket_history.map((ticket, index) => (
                      <TicketHistory key={index} {...ticket} />
                    ))
                  ) : (
                    <NoDataFromApi
                      height={true}
                      width_small={true}
                      title="No Ticket History Found..."
                    />
                  )}
                </div>
              </div>

              {/* remarks data */}
              <div className="ticket-details-info">
                <h2>Agent Remarks</h2>
                {add_remarks_loading ? (
                  <PaginationLoader />
                ) : (
                  <div
                    className="remarks-container"
                    style={{
                      height:
                        Array.isArray(remarks_data) && remarks_data?.length <= 0
                          ? "auto"
                          : "initial",
                    }}
                  >
                    {Array.isArray(remarks_data) && remarks_data?.length > 0 ? (
                      remarks_data?.map((i) => <Remarks key={i.id} {...i} />)
                    ) : (
                      <NoDataFromApi
                        height={true}
                        width_small={true}
                        title="There is No Remarks To Show..."
                      />
                    )}
                  </div>
                )}
                <form onSubmit={handleAddRemarks}>
                  <div className="add-remarks">
                    <h4>Add Remarks</h4>
                    <input
                      type="text"
                      name="comment"
                      id="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  <div className="add-remarks-cta">
                    <Button type="submit">
                      {add_remarks_loading ? <TableLoader /> : "Submit"}
                    </Button>
                  </div>
                </form>
              </div>
              {/* remarks data */}
              {renderModal()}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TicketDetails;
