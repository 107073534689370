import React from "react";
import "./NoDataFromApi.css";
import Emoiji from "../../Assets/funny-404-error-page-design.gif";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const NoDataFromApi = (props) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    setTimeout(() => {
      navigate("/login");
    }, 200);
  };
  return (
    <div
      className="no-categories"
      style={{ height: props.height ? "auto" : "100vh" }}
    >
      <img
        style={{ width: props.width_new ? "100%" : props.width_small && "50%" }}
        src={Emoiji}
        alt=""
      />
      <div
        style={{
          top: props.width_new ? "-60px" : props.width_small && "-80px",
          marginTop: props.mb && '20px'
        }}
      >
        <p>{props.title}...</p>
        {props.btn ? (
          <Button onClick={() => handleLogout()} className="login-back">
            Go Back To Login
          </Button>
        ) : (
          props.showNew && (
            <Button onClick={() => navigate(-1)} className="login-back">
              Go Back
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default NoDataFromApi;
