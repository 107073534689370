import React from "react";

const TableLoader = (props) => {
  return (
    <div
      style={{
        width: props.isSmall && "15px",
        height: props.isSmall && "15px",
      }}
      className="table-loader"
    ></div>
  );
};

export default TableLoader;
