import React, { useCallback, useEffect, useState } from "react";
import HelmetTitle from "../../Components/MetaData/MetaData";
import Navbar from "../../Components/Navbar/Navbar";
import Arrow from "../../Assets/Agents-Img/arrow-left.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Components/DataTable/DataTable";
import { unreplied_conversation_header } from "../../DummyArray/HiscravesData";
import { useDispatch, useSelector } from "react-redux";
import { getUnrepliedticketConversationAction } from "../../Actions/AgentActions/AgentActions";
import Loader from "../../Components/Loader/Loader";
import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TableLoader from "../../Components/Loader/TableLoader";
import { BaseURL } from "../../BaseURL";
import { useAlert } from "react-alert";
import api from "../../axiosConfig";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import Select from "react-select";
import { getTicketStatus } from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import { getAllPriorityActions } from "../../Actions/CustomerActions/CustomerActions";
import { customStyles } from "../../DummyArray/DashboardArrData";
import Download_Icon from "../../Assets/document-download.png";

const UnRepliedCounts = () => {
  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get All Status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  const { unreplied_conversation, loading } = useSelector(
    (state) => state.unrepliedCon
  );

  const [unreplied_conversation_arr, setUnreplied_conversation_arr] = useState(
    []
  );

  const [page, setPage] = useState(1);
  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");
  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);
  const [total_page, setTotal_page] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // handle functions for filters
  const [isTrigger, setIsTrigger] = useState(false);

  const [status, setStatus] = useState("");
  const [display_status, setDisplay_status] = useState("");

  const [priority, setPriority] = useState("");
  const [display_priority, setDisplay_priority] = useState("");

  const handleStatus = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    setStatus(selectedOption?.id);
    setDisplay_status(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  const handlePriority = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    // setXPage(1);
    setPriority(selectedOption?.index);
    setDisplay_priority(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  // handle functions for filters

  const fetchTickets = useCallback(
    (page, i) => {
      setIsLoading(true);
      let apiUrl = `${BaseURL}/agent/un-replied-tickets?agent_id=${id}&page=${page}&per_page=${10}`;
      if (status !== "") {
        apiUrl += `&status=${status}`;
      }

      if (priority !== "") {
        apiUrl += `&priority=${priority}`;
      }
      api
        .get(apiUrl)
        .then((response) => {
          console.log(response?.data?.data, "sn");
          // if (response && response?.data?.data?.length > 0) {
          setTotal_page(response && response?.data?.total_page);
          setTotal_entries(response && response?.data?.total);
          // }
          setUnreplied_conversation_arr(response && response?.data?.data);
          setFirst_item(response?.data && response?.data?.first_item);
          setLast_item(response?.data && response?.data?.last_item);
          setIsLoading(false);
        })
        .catch((err) => {
          alert.error(err.message);
          console.log("Error: ", err);
          setIsLoading(false);
        });
    },
    [alert, id, priority, status]
  );

  const handleDecrement = () => {
    if (page === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setPage(page - 1);
      fetchTickets(page - 1, true);
    }
  };
  const handleIncrement = () => {
    if (
      (unreplied_conversation && unreplied_conversation?.total_page === page) ||
      (total_page && total_page === page)
    ) {
      // setPage(page);
      setRight_icon(true);
    } else {
      setRight_icon(false);
      setPage(page + 1);
      fetchTickets(page + 1, true);
    }
  };

  // status component starts here
  useEffect(() => {
    if (isTrigger) {
      fetchTickets(page, 2);
    }
  }, [isTrigger, fetchTickets, page]);
  // status component ends here

  useEffect(() => {
    if (unreplied_conversation && unreplied_conversation?.total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [unreplied_conversation, page]);

  useEffect(() => {
    if (total_page && total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, page]);

  const handlePageClick = useCallback(
    (page) => {
      setPage(page);
      fetchTickets(page);
    },
    [fetchTickets]
  );

  console.log("Total: ", unreplied_conversation);

  let maxVisibilePages = 5;
  const [VisiblePages, setVisibilePages] = useState([]);
  const renderPagination = useCallback(() => {
    if (unreplied_conversation && unreplied_conversation?.total_page !== page) {
      setRight_icon(false);
    }
    if (total_page && total_page !== page) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page && total_page !== ""
        ? total_page
        : unreplied_conversation?.total_page
    );
    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;
    const pageNumbers = [];

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    handlePageClick,
    isLoading,
    maxVisibilePages,
    page,
    // ticketsBearer?.total_page,
    total_page,
    unreplied_conversation,
  ]);

  console.log("Visible Pages: ", VisiblePages);

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  useEffect(() => {
    if (id !== null || id !== undefined || id !== "") {
      dispatch(getUnrepliedticketConversationAction(id, 1, 10));
    } else {
      dispatch(getUnrepliedticketConversationAction(1, 10));
    }
  }, [dispatch, id]);
  console.log(unreplied_conversation?.logout, "ss");

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  const fetch = useCallback(() => {
    unreplied_conversation &&
      setUnreplied_conversation_arr(
        unreplied_conversation && unreplied_conversation?.data
      );
  }, [unreplied_conversation]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // status and priority apis

  useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  let statusList = Allstatus?.data ? Object.values(Allstatus?.data) : [];

  if (
    Array.isArray(statusList) &&
    statusList?.length > 0 &&
    !statusList.includes("All")
  ) {
    statusList?.splice(0, 0, "All");
  }

  let priority_list_arr =
    Array.isArray(priority_list?.data) && priority_list?.data?.length > 0
      ? priority_list?.data
      : [];

  if (
    Array.isArray(priority_list?.data) &&
    priority_list?.data?.length > 0 &&
    !priority_list_arr?.includes("All")
  ) {
    priority_list_arr?.splice(0, 0, "All");
  }

  console.log("DATA: ", id);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      let apiUrl_newX = `${BaseURL}/agent/export-un-replied-tickets`;

      // Check if id parameter is provided
      if (id !== "" && id !== undefined && id !== null) {
        apiUrl_newX += `?agent_id=${id}`;
      }

      // Check if status parameter is provided
      if (status !== "") {
        apiUrl_newX += `${id === undefined ? "?" : "&"}status=${status}`;
      }

      // Check if priority parameter is provided
      if (priority !== "") {
        apiUrl_newX += `${
          id === undefined && status === "" ? "?" : "&"
        }priority=${priority}`;
      }

      const response = await api.get(apiUrl_newX);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoadingCsv(false);
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoadingCsv(false);
    }
  };

  console.log("UnReplied_Conversatioon: ", unreplied_conversation_arr);

  return (
    <>
      <HelmetTitle title={`Hiscraves - Un-replied Conversations`} />
      <Navbar searchBar={handleSearchBar} />
      {loading ? (
        <Loader />
      ) : unreplied_conversation && unreplied_conversation?.logout === true ? (
        <NoDataFromApi
          btn={true}
          title={unreplied_conversation && unreplied_conversation?.message}
        />
      ) : (
        <div className="home-container">
          <div className="team-leader-dashboard">
            <Link onClick={() => navigate(-1)} className="agent-profile-head">
              <img src={Arrow} alt="" />
              <h1>Un-replied Conversations</h1>
            </Link>

            <div className="table-wrapper-pagination">
              <div className="table-container">
                <div className="header-text-dropdown">
                  <h3>Tickets</h3>
                  <div className="select-dropdown-filters">
                    <Button onClick={downloadCSV} className="export-csv-cta">
                      {loadingCSV ? (
                        <TableLoader />
                      ) : (
                        <>
                          <img src={Download_Icon} alt="" />
                          &nbsp; Export CSV
                        </>
                      )}
                    </Button>
                    <Select
                      placeholder={
                        display_status ? display_status : "Select Status"
                      }
                      options={
                        Array.isArray(statusList) &&
                        statusList.map((option, index) => ({
                          value: option,
                          label: option,
                          id: option === "All" ? "" : index,
                        }))
                      }
                      value={display_status}
                      styles={customStyles}
                      isLoading={statusLoading}
                      onChange={handleStatus}
                    />
                    <Select
                      placeholder={
                        display_priority ? display_priority : "Select Priority"
                      }
                      options={
                        Array.isArray(priority_list_arr) &&
                        priority_list_arr?.length > 0
                          ? priority_list_arr?.map((option, index) => ({
                              value: option,
                              label: option,
                              index: option === "All" ? "" : index - 1,
                            }))
                          : []
                      }
                      value={display_priority}
                      styles={customStyles}
                      isLoading={priority_loading}
                      onChange={handlePriority}
                    />
                  </div>
                </div>
                <DataTable
                  header={unreplied_conversation_header}
                  unreplied_conversation_data={
                    unreplied_conversation_arr &&
                    Array.isArray(unreplied_conversation_arr)
                      ? unreplied_conversation_arr
                      : []
                  }
                  changeCTA={true}
                  items_per_page={10}
                  current_page={page}
                />
                <div className="pagination-entries-row">
                  <p>
                    Showing {first_item !== "" ? first_item : 1} To{" "}
                    {last_item !== ""
                      ? last_item
                      : unreplied_conversation &&
                        unreplied_conversation?.last_item}{" "}
                    of,{" "}
                    {total_entries && total_entries !== ""
                      ? total_entries
                      : unreplied_conversation &&
                        unreplied_conversation?.total}{" "}
                    entries
                  </p>
                  <div className="pagination-chevron-icon">
                    <Button
                      disabled={left_icon || page === 1 ? true : false}
                      className="icon-chevron"
                      onClick={handleDecrement}
                    >
                      <ChevronLeftIcon />
                    </Button>
                    <div className="view-more">{VisiblePages}</div>
                    <Button
                      disabled={
                        right_icon ||
                        (unreplied_conversation &&
                          unreplied_conversation?.total_page === page)
                          ? true
                          : right_icon || (total_page && total_page === page)
                          ? true
                          : false
                      }
                      className="icon-chevron"
                      onClick={handleIncrement}
                    >
                      <ChevronRightIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnRepliedCounts;
