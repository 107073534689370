import React, { useEffect } from "react";
import "./TicketLogs.css";
import Avatar from "../../../Assets/User/Ellipse 4.png";
import CrossIcon from "../../../Assets/User/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import { getTicketConversation } from "../../../Actions/TeamLeaderActions/TeamLeaderActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import { useRef } from "react";
import api from "../../../axiosConfig";
import { BaseURL } from "../../../BaseURL";
import PaginationLoader from "../../Loader/PaginationLoader";
import NoDataFromApi from "../../NotFound/NoDataFromApi";
import { Button } from "@mui/material";
// import Triangle from "../../../Assets/Customer-Details/Triangle.png";
import Send from "../../../Assets/Frame.png";
import Attachment from "../../../Assets/Vector.png";
import TableLoader from "../../Loader/TableLoader";
import { Link } from "react-router-dom";
// import Triangle from "../../../Assets/Customer-Details/Triangle.png";
import PDF_Icon from "../../../Assets/pdf.png";
import DOC_Icon from "../../../Assets/doc.png";
import XL_Icon from "../../../Assets/xl.png";
import { useAlert } from "react-alert";
import No_Image from "../../../Assets/No-Image-Placeholder.svg.png";

const TicketLogs = (props) => {
  const { customer_img, customer_mobile_number, customer_name } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const { conversation, loading } = useSelector(
    (state) => state.ticketConversation
  );

  const chatContainerRef = useRef(null);

  const { ticket_id } = props;

  let user = JSON.parse(localStorage.getItem("user"));

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [replyLoading, setReplyLoading] = useState(false);

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    const allowedExtensions = [".png", ".jpg", ".pdf"];
    const urls = [];
    const validFiles = [];

    for (let i = 0; i < newFiles?.length; i++) {
      const file = newFiles[i];
      const fileExtension = file.name.substring(file.name.lastIndexOf("."));

      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        urls.push(URL.createObjectURL(file));
        validFiles.push(file);
      } else {
        // alert.error(`File with disallowed extension: ${file.name}`);
        alert.error("Only JPG,PNG & PDF File Allowed...");
        // You can show an error message or take other appropriate actions.
      }
    }

    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...validFiles,
    ]);
    setFileUrls([...fileUrls, ...urls]);
    console.log(fileUrls, "fileUrls");
  };

  // const [customAlert, setCustomAlert] = useState("");

  const [conversationLogs, setConversationLogs] = useState([]);
  const [pages, setPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let per_page = 5;

  const pageRef = useRef(pages);

  let msg;
  const handleReply = (event) => {
    setFileUrls([]);
    setSelectedFiles([]);
    setReplyLoading(true);
    event.preventDefault();
    var formData = new FormData();
    if (selectedFiles?.length > 0) {
      for (let i = 0; i < selectedFiles?.length; i++) {
        const file = selectedFiles[i];
        const allowedExtensions = [".png", ".jpg", ".pdf"];

        // Get the file extension
        const fileExtension = file.name.substring(file.name.lastIndexOf("."));

        // Check if the file has an allowed extension
        if (allowedExtensions.includes(fileExtension.toLowerCase())) {
          formData.append("attachment[]", file);
        } else {
          // Handle the case where the file has a disallowed extension
          alert.error(`The ${file.name} will not Be uploaded...`);
          // You can show an error message or take other appropriate actions.
        }
      }
    }
    msg = document.getElementById("message").value;
    formData.append("message", msg);
    formData.append("ticket_id", ticket_id);
    if (msg !== "" || selectedFiles?.length !== 0) {
      api
        .post(`${BaseURL}/agent/reply-on-ticket`, formData)
        .then((response) => {
          if (response.data.success === true) {
            // pageRef.current = 0;
            // setPages(0);
            // fetchNext();
            api
              .get(
                `${BaseURL}/agent/ticket-conversations?ticket_id=${ticket_id}&per_page=${per_page}`
              )
              .then((response) => {
                const mergedAgents = [
                  ...conversationLogs,
                  ...response?.data?.data,
                ];
                const uniqueArr = Array.from(
                  new Set(mergedAgents.map((obj) => JSON.stringify(obj)))
                ).map((str) => JSON.parse(str));
                setConversationLogs(uniqueArr);
                // setCustomAlert();
                alert.success("Replied Successfully....");
                setFileUrls([]);
                setSelectedFiles([]);
                document.getElementById("message").value = "";
                setReplyLoading(false);
              })
              .catch((err) => {
                console.log("Nx: ", err);
                setReplyLoading(false);
              });
          } else if (response && response.data.success === false) {
            alert.error(response && response?.data?.message);
            setReplyLoading(false);
          }
        })
        .catch((err) => {
          // console.log("Nx: ", err);
          alert.error(err.message);
          setReplyLoading(false);
        });
    } else {
      // setCustomAlert("Please Enter Message...");
      alert.error("Please Enter Message...");
      setReplyLoading(false);
    }
  };

  console.log("Selected Files: ", selectedFiles, msg);

  // const renderFilePreviews = () => {
  //   return <span>{fileUrls}</span>;
  // };

  const openFileInput = () => {
    document.getElementById("attachments").click();
  };

  useEffect(() => {
    if (ticket_id && ticket_id !== undefined) {
      dispatch(getTicketConversation(ticket_id, 5, 1));
    }
  }, [dispatch, ticket_id]);

  // infinite scroll

  const fetchNext = () => {
    console.log(true);
    setPages((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    let page = pageRef.current;
    setIsLoading(true);
    api
      .get(
        `${BaseURL}/agent/ticket-conversations?ticket_id=${ticket_id}&per_page=${per_page}&page=${page}`
      )
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          setHasMore(false);
          setIsLoading(false);
        } else {
          const mergedAgents = [...response?.data?.data, ...conversationLogs];
          const uniqueArr = Array.from(
            new Set(mergedAgents.map((obj) => JSON.stringify(obj)))
          ).map((str) => JSON.parse(str));
          setConversationLogs(uniqueArr);
          // const existingLogs = conversationLogs.map((obj) =>
          //   JSON.stringify(obj)
          // );
          // const newLogs = response?.data?.data.map((obj) =>
          //   JSON.stringify(obj)
          // );

          // const mergedLogs = Array.from(
          //   new Set([...newLogs, ...existingLogs])
          // ).map((str) => JSON.parse(str));

          // setConversationLogs(mergedLogs);

          // const updatedLogs = [...conversationLogs, ...response?.data?.data];
          // setConversationLogs(updatedLogs);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        // console.log("Hello", err);
        alert.error(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (conversation && conversation?.data) {
      setConversationLogs(conversation?.data);
    }
  }, [conversation]);

  console.log("Conversation Logs: ", conversationLogs);

  const closeModal = () => {
    props.scloseModal(null);
    setPages(1);
  };

  // setTimeout(() => {
  //   setCustomAlert("");
  // }, 8000);

  console.log(pages, "Ticket Id");
  console.log(conversationLogs, "ConversationLogs....");

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the component mounts or when new content is added
    scrollToBottom();
  }, [conversationLogs]);

  console.log(
    "CUSTOMER NAME: ",
    customer_img,
    customer_mobile_number,
    customer_name
  );

  const [fileTypes, setFileTypes] = useState([]);

  useEffect(() => {
    // Function to check the file type of a blob
    const checkFileType = async (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const header = new Uint8Array(reader.result).subarray(0, 4);
          if (
            header[0] === 0x25 &&
            header[1] === 0x50 &&
            header[2] === 0x44 &&
            header[3] === 0x46
          ) {
            resolve("pdf");
          } else if (
            header[0] === 0x89 &&
            header[1] === 0x50 &&
            header[2] === 0x4e &&
            header[3] === 0x47
          ) {
            resolve("png");
          } else {
            resolve("unknown");
          }
        };
        reader.readAsArrayBuffer(blob);
      });
    };

    // Function to determine file types for all blobs
    const determineFileTypes = async () => {
      const types = await Promise.all(
        fileUrls.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          return checkFileType(blob);
        })
      );
      setFileTypes(types);
    };

    // Call the function to determine file types
    determineFileTypes();
  }, [fileUrls]);

  return (
    <>
      <div className="heading-user-logs">
        <div className="ticket-logs-grid-row">
          <div className="left-side-col">
            <div>
              <img
                className="user-img-chat"
                src={
                  customer_img !== null &&
                  customer_img !== undefined &&
                  customer_img !== ""
                    ? customer_img
                    : Avatar
                }
                alt=""
              />
            </div>
            <div>
              <p className="head-line">
                {customer_name !== null &&
                customer_name !== undefined &&
                customer_name !== ""
                  ? customer_name
                  : user && user?.full_name}
              </p>
              <p className="mobile-number">
                {customer_mobile_number !== null &&
                customer_mobile_number !== undefined &&
                customer_mobile_number !== ""
                  ? customer_mobile_number
                  : user && user?.phone}
              </p>
            </div>
            {/* mapping data start here */}
          </div>
          <div className="left-side-col">
            <div className="bg-white" onClick={closeModal}>
              <img src={CrossIcon} alt="" />
            </div>
          </div>
        </div>
        {isLoading && (
          <p className="alert-reply">
            {isLoading && "Syncing Messages Please Wait...."}
          </p>
        )}
      </div>
      {loading ? (
        <PaginationLoader />
      ) : (
        <div
          ref={chatContainerRef}
          id="Chat"
          style={{
            height: fileUrls?.length > 0 ? "65vh" : "75vh",
            overflowY: "auto",
          }}
          className="chat-row"
        >
          <InfiniteScroll
            dataLength={conversationLogs && conversationLogs?.length}
            next={fetchNext}
            hasMore={hasMore}
            scrollableTarget="Chat"
            // loader={isLoading && <PaginationLoader small={true} />}
            // style={{ overflow: "auto" }}
          >
            {Array.isArray(conversationLogs) && conversationLogs?.length > 0
              ? conversationLogs.map((i) => (
                  <div
                    key={i.id}
                    className={`${
                      i.message_by === 1
                        ? "customer-design-float"
                        : i.message_by === 2
                        ? "agent-design-float"
                        : null
                    }`}
                  >
                    <div
                      className={`${
                        i.message_by === 1
                          ? "customer-design"
                          : i.message_by === 2
                          ? "agent-design"
                          : null
                      }`}
                    >
                      <div className="name-cont">
                        {i.message_by === 2 ? null : (
                          <div className="head-name-add-by">
                            {i?.add_by?.avatar_location !== null ? (
                              <img
                                onError={(e) => (e.target.src = No_Image)}
                                src={
                                  i?.add_by?.avatar_location === null ||
                                  i?.add_by === null
                                    ? No_Image
                                    : i?.add_by?.avatar_location
                                }
                                alt=""
                              />
                            ) : (
                              <div className="ticket-user-icon">
                                <p>
                                  {i?.add_by?.full_name?.slice(0, 1)}
                                  {i?.add_by?.full_name
                                    ?.split(" ")[1]
                                    ?.charAt(0)}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        <h4>{i?.add_by?.full_name}</h4>
                        {i.message_by === 1 ? null : (
                          <div className="head-name-add-by">
                            {i?.add_by?.avatar_location !== null ? (
                              <img
                                onError={(e) => (e.target.src = No_Image)}
                                src={
                                  i?.add_by?.avatar_location === null ||
                                  i?.add_by === null
                                    ? No_Image
                                    : i?.add_by?.avatar_location
                                }
                                alt=""
                              />
                            ) : (
                              <div className="ticket-user-icon">
                                <p>
                                  {i?.add_by?.full_name?.slice(0, 1)}
                                  {i?.add_by?.full_name
                                    .split(" ")[1]
                                    ?.charAt(0)}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <p
                        className={`${
                          i.message_by === 2 ? "message" : "no_message"
                        }`}
                      >
                        {i?.message
                          ? i?.message
                          : // <span>No Message Added By User</span>
                            null}
                      </p>
                      {/* <div className="attachments-link">
                        {i?.attachments?.length > 0 &&
                          i?.attachments?.map((j, index) => (
                            <Link
                              target="_blank"
                              to={`${j?.filepath}`}
                              key={index}
                              className="product-img-conversation"
                            >
                              {j.filename.lastIndexOf(".") !== -1 &&
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "png" ? (
                                <img src={j.filepath} alt="" />
                              ) : j.filename.slice(
                                  j.filename.lastIndexOf(".") + 1
                                ) === "pdf" ? (
                                <img src={PDF_Icon} alt="" />
                              ) : j.filename.slice(
                                  j.filename.lastIndexOf(".") + 1
                                ) === "doc" ||
                                j.filename.slice(
                                  j.filename.lastIndexOf(".") + 1
                                ) === "docx" ? (
                                <img src={DOC_Icon} alt="" />
                              ) : j.filename.slice(
                                  j.filename.lastIndexOf(".") + 1
                                ) === "xls" ||
                                j.filename.slice(
                                  j.filename.lastIndexOf(".") + 1
                                ) === "xlsx" ? (
                                <img src={XL_Icon} alt="" />
                              ) : (
                                <video
                                  src={j.filepath}
                                  width="100%"
                                  height="80"
                                  controls={false}
                                ></video>
                              )}
                            </Link>
                          ))}
                      </div> */}
                    </div>
                    <p className="date-agent">
                      {new Date(i?.created_at).toLocaleString()}
                    </p>
                    <div
                      style={{
                        gridAutoFlow: i.message_by === 2 && "dense",
                        direction: i.message_by === 2 && "rtl",
                      }}
                      className="attachments-link"
                    >
                      {i?.attachments?.length > 0 &&
                        i?.attachments?.map((j, index) => (
                          <Link
                            target="_blank"
                            to={`${j?.filepath}`}
                            key={index}
                            className="product-img-conversation"
                          >
                            {j.filename.lastIndexOf(".") !== -1 &&
                            j.filename.slice(
                              j.filename.lastIndexOf(".") + 1
                            ) === "png" ? (
                              <img src={j.filepath} alt="" />
                            ) : j.filename.lastIndexOf(".") !== -1 &&
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "jpg" ? (
                              <img src={j.filepath} alt="" />
                            ) : j.filename.lastIndexOf(".") !== -1 &&
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "webp" ? (
                              <img src={j.filepath} alt="" />
                            ) : j.filename.lastIndexOf(".") !== -1 &&
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "jpeg" ? (
                              <img src={j.filepath} alt="" />
                            ) : j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "pdf" ? (
                              <img src={PDF_Icon} alt="" />
                            ) : j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "doc" ||
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "docx" ? (
                              <img src={DOC_Icon} alt="" />
                            ) : j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "xls" ||
                              j.filename.slice(
                                j.filename.lastIndexOf(".") + 1
                              ) === "xlsx" ? (
                              <img src={XL_Icon} alt="" />
                            ) : (
                              <video
                                src={j.filepath}
                                width="100%"
                                height="80"
                                controls={false}
                              ></video>
                            )}
                          </Link>
                        ))}
                    </div>
                  </div>
                ))
              : !loading && (
                  <NoDataFromApi
                    height={true}
                    width_new={true}
                    title="No Conversation Found For The Provided Ticket Id..."
                  />
                )}
          </InfiniteScroll>
          <form id="reply_form" onSubmit={handleReply} method="post">
            <div>
              <div
                className="input-reply"
                style={{
                  marginBottom:
                    fileUrls && fileUrls?.length > 0 ? "42px" : "0px",
                }}
              >
                <input
                  id="message"
                  name="message"
                  type="text"
                  placeholder="Type Here..."
                />
                <div>
                  <input
                    id="attachments"
                    name="attachments"
                    type="file"
                    multiple
                    style={{ visibility: "hidden", width: "1px" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    id="file_attachment"
                    type="button"
                    onClick={openFileInput}
                  >
                    <img
                      onError={(e) => (e.target.src = No_Image)}
                      src={Attachment}
                      alt=""
                    />
                  </Button>
                  <Button type="submit">
                    {replyLoading ? <TableLoader /> : <img src={Send} alt="" />}
                  </Button>
                </div>
              </div>
              {/* <p className="files-length">{fileUrls.length > 0 && renderFilePreviews()}</p> */}
              {/* <div className="files-input-tag">
                {fileUrls && fileUrls?.length > 0
                  ? fileUrls.map((i, index) => (
                      <img
                        key={index}
                        className="files-length"
                        src={i}
                        alt=""
                      />
                    ))
                  : null}
              </div> */}

              <div className="files-input-tag">
                {fileUrls &&
                  fileUrls.length > 0 &&
                  fileUrls.map((url, index) => (
                    <div key={index}>
                      {fileTypes[index] === "pdf" ? (
                        <img
                          className="files-length"
                          src={PDF_Icon}
                          alt="PDF icon"
                        />
                      ) : (
                        <img className="files-length" src={url} alt="" />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default TicketLogs;
