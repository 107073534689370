import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./Bar.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
export const BarChart = ({ data_list, number_of_incident, avg_incident }) => {
  let arr = [];
  // eslint-disable-next-line
  Object.entries(data_list).map(([key, value]) => {
    if (!arr.includes(key)) {
      arr.push(value);
    }
  });

  const data = {
    labels: [
      "1 Day",
      "3 Days",
      "7 Days",
      "14 Days",
      "30 Days",
      "60 Days",
      "60+ Days",
    ],
    datasets: [
      {
        label: "Tickets",
        data: arr,
        backgroundColor: [
          "#3A6598",
          "#EFA252",
          "#009470",
          "#DD581F",
          "#A22D62",
          "#FECE60",
        ],
        barThickness: 40,
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        border: { dash: [7, 7] }, // for the grid lines
        grid: {
          color: "#E5E5EF", // for the grid lines
          tickColor: "#E5E5EF", // for the tick mark
          tickBorderDash: [8, 8], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 1,
          offset: true,
          drawTicks: true, // true is default
          drawOnChartArea: true, // true is default
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
        beginAtZero: true,
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          lineWidth: 5,
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
      },
    },
    plugins: {
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your tooltip body
        },
        titleFont: {
          family: "Poppins_SemiBold",
        },
      },
      responsive: true,
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="bar-chart-agent-dashboard">
      <Bar data={data} options={options} />
      <div className="incidents-grid">
        <p>Number of Tickets: {number_of_incident}</p>
        <p>Average Tickets Duration: {avg_incident}</p>
      </div>
    </div>
  );
};
