import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAgents,
  getIssueTypeTicketGraphAction,
  getPriorityTypeTicketGraphAction,
  getTeamAllAgentsListForAssignee,
  getTeamAllTickets,
  getTeamCountsInfo,
  getTeamDougnut,
  getTeamDougnut_with_normal,
  getTeamNewTickets,
  getTicketAgingGraphAction,
  getTicketStatus,
} from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import Agents from "../../Components/Agents/Agents";
import { BarChart } from "../../Components/Bars/BarChart/BarChart";
import DoughnutChart from "../../Components/Bars/DougnutChart/DoughnutChart";
import CardHeader from "../../Components/CardHeader/CardHeader";
import AgentsProfileCount from "../../Components/Counts/AgentsProfileCount";
import Loader from "../../Components/Loader/Loader";
import "./TeamLeader.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import PaginationLoader from "../../Components/Loader/PaginationLoader";
import DataTable from "../../Components/DataTable/DataTable";
import {
  teamLeaderAllTicket,
  teamLeaderNewTicket,
} from "../../DummyArray/HiscravesData";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";
import TableLoader from "../../Components/Loader/TableLoader";
import { customStyles, dateArray } from "../../DummyArray/DashboardArrData";
import GroupedBarChart from "../../Components/Bars/GroupedBar/GroupedBar";
import ModalContainer from "../../Components/Modal/Modal";
import Select from "react-select";
import HelmetTitle from "../../Components/MetaData/MetaData";
import { getAllPriorityActions } from "../../Actions/CustomerActions/CustomerActions";
import Download_Icon from "../../Assets/document-download.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useAlert } from "react-alert";

const TeamLeaderHome = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.team);
  const { doughnut } = useSelector((state) => state.teamDoughnut);
  const { agents, loading: agentsLoading } = useSelector(
    (state) => state.allAgents
  );

  // get All Status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // ticket aging state
  const { ticket_aging_report } = useSelector(
    (state) => state.ticketAgingReport
  );

  // ticket by issue state
  const { issue_type_ticket_graph } = useSelector(
    (state) => state.issueTypeTicketGraph
  );

  // priority by issue state
  const { priority_type_ticket_graph } = useSelector(
    (state) => state.priorityTypeTicketGraph
  );

  // assignee list
  const { allagentsList, loading: agentLoading } = useSelector(
    (state) => state.agentList
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");

  const [first_item_all, setFirst_item_all] = useState("");
  const [last_item_all, setLast_item_all] = useState("");

  const { newTickets } = useSelector((state) => state.getNewTickets);

  const { allTickets } = useSelector((state) => state.getAllTickets);

  const [filterTrue, setFilterTrue] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingFilter2, setLoadingFilter2] = useState(false);
  const [loadingFilter3, setLoadingFilter3] = useState(false);
  const [loadingFilter4, setLoadingFilter4] = useState(false);

  const [lineBarGraph, SetLineBarGraph] = useState(
    dateArray && dateArray[0]?.id
  );

  const [issueBy_Ticket_bar, setIssueBy_Ticket_bar] = useState(
    dateArray && dateArray[3]?.id
  );

  const [priorityBy_Ticket_bar, setPriorityBy_Ticket_bar] = useState(
    dateArray && dateArray[3]?.id
  );

  // const [date, setDate] = useState(dateArray && dateArray[3]?.id);

  const getPreviousDate = (monthsAgo) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthsAgo);
    return currentDate;
  };

  const [previousDate, setPreviousDate] = useState(getPreviousDate(6));

  useEffect(() => {
    setPreviousDate(getPreviousDate(6));
  }, []);

  const [DoughnutGraph, SetDoughnutGraph] = useState([
    previousDate,
    new Date(),
  ]);

  // const start_date = DoughnutGraph && DoughnutGraph[0];
  // const end_date = DoughnutGraph && DoughnutGraph[1];

  // Format the date as "dd-MM-yyyy"
  // const from_date = start_date
  //   ? start_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  // const to_date = end_date
  //   ? end_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  const [additionalLoader, setAdditionalLoader] = useState(true);
  const [paginationLoader, setPaginationLoader] = useState(false);

  const [hasNextPage, setHasNextPage] = useState(true);

  const [hoverOffset, setHoverOffset] = useState(30);
  const [padding, setPadding] = useState(30);
  const handleLineBarGraph = async (children) => {
    setFilterTrue(true);
    setLoadingFilter2(true);
    SetLineBarGraph(children.id);
    await dispatch(getTicketAgingGraphAction(children.id));
    setLoadingFilter2(false);
  };

  // agents
  const [agentList, setAgentList] = useState([]);

  const [page, setPage] = useState(1);
  const pageRef = useRef(page);
  let per_pages = 6;

  // load more actions
  const loadmore = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      pageRef.current = nextPage;
      return nextPage;
    });
    let page = pageRef.current;

    setPaginationLoader(true);
    api
      .get(`${BaseURL}/agent/all-agents?page=${page}&per_page=${per_pages}`)
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          setPaginationLoader(false);
          setHasNextPage(false);
        } else {
          const mergedAgents = [...agentList, ...response?.data?.data];
          const uniqueArr = Array.from(
            new Set(mergedAgents.map((obj) => JSON.stringify(obj)))
          ).map((str) => JSON.parse(str));
          setAgentList(uniqueArr);
          setPaginationLoader(false);
        }
      })
      .catch((err) => {
        // console.log(err.message);
        alert.error(err.message);
        setPaginationLoader(false);
      });
  };

  let teamsData = data && data?.data;

  const CountsTeam = [
    {
      count: teamsData && teamsData?.active_users,
      title: "Active Agents",
      color: "#D9ACF5",
    },
    {
      count: teamsData && teamsData?.total_call_count,
      title: "Total Call Counts",
      color: "#ABDBF5",
    },
    {
      count: teamsData && teamsData?.missed_call_count,
      title: "Missed Call Counts",
      color: "#99B080",
    },
    {
      count: teamsData && teamsData?.recieved_call_count,
      title: "Received Call Counts",
      color: "#D6C7AE",
    },
    {
      count: teamsData && teamsData?.total_call_valume,
      title: "Total Call Volume",
      color: "#FFCCDE",
    },
    {
      count: teamsData && teamsData?.average_rating,
      title: "Average Feedback Rating",
      color: "#BEADFA",
    },
    {
      count: teamsData && teamsData?.un_replied_tickets_count,
      title: "UnReplied Tickets Count",
      color: "#F9B572",
    },
    {
      count: teamsData && teamsData?.sla_breach_count,
      title: "SLA Breach Count",
      color: "#EADFB4",
    },
    {
      count: teamsData && teamsData?.sla_breach_age_avg,
      title: "SLA Breach Age Avg.",
      color: "#FFEAA7",
    },
  ];

  const handleDoughnutChart = async (children) => {
    const start_date = children && children[0];
    const end_date = children && children[1];

    // Format the date as "dd-MM-yyyy"
    const from_date = start_date
      ? start_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;

    const to_date = end_date
      ? end_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;
    setFilterTrue(true);
    setLoadingFilter(true);
    SetDoughnutGraph(children);
    if (from_date !== null && to_date !== null) {
      await dispatch(getTeamDougnut(from_date, to_date));
    } else {
      await dispatch(getTeamDougnut_with_normal(6));
    }
    setLoadingFilter(false);
  };

  const handleSearchBar = (search) => {
    let phone_number = `?phone_number=${search}`;
    navigate(`/customer-dashboard/${phone_number}`);
  };

  const handleIssueByTicketGraph = async (children) => {
    setFilterTrue(true);
    setLoadingFilter3(true);
    setIssueBy_Ticket_bar(children.id);
    await dispatch(getIssueTypeTicketGraphAction(children.id));
    setLoadingFilter3(false);
  };

  const handlePriorityByTicketGraph = async (children) => {
    setFilterTrue(true);
    setLoadingFilter4(true);
    setPriorityBy_Ticket_bar(children.id);
    await dispatch(getPriorityTypeTicketGraphAction(children.id));
    setLoadingFilter4(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setHoverOffset(5);
      setPadding(5);
    } else {
      setHoverOffset(30);
      setPadding(30);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!filterTrue) {
        try {
          await Promise.all([
            dispatch(getTeamCountsInfo()),
            dispatch(getTeamDougnut_with_normal(6)),
            dispatch(getTicketAgingGraphAction(lineBarGraph)),
            dispatch(getIssueTypeTicketGraphAction(issueBy_Ticket_bar)),
            dispatch(getPriorityTypeTicketGraphAction(priorityBy_Ticket_bar)),
          ]);

          // Handle data or dispatch actions as needed
          setAdditionalLoader(false);
        } catch (error) {
          // Handle errors appropriately
          // console.error("API error:", error);
          alert.error(error.message);
        }
      }
    };

    fetchData();
  }, [
    dispatch,
    filterTrue,
    issueBy_Ticket_bar,
    lineBarGraph,
    priorityBy_Ticket_bar,
    alert,
  ]);

  useEffect(() => {
    dispatch(getAllAgents(6));
    dispatch(getTeamNewTickets(1, 10));
    dispatch(getTeamAllTickets(1, 10));
  }, [dispatch]);

  useEffect(() => {
    if (agents?.data && agents?.data) {
      setAgentList(agents?.data);
    }
  }, [agents]);

  // Pagination code
  const [teamNewTickets, setTeamNewTickets] = useState([]);
  const [teamAllTickets, setTeamAllTickets] = useState([]);
  const [Tpage, setTPage] = useState(1);
  const [Xpage, setXPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [newTickLoading, setnewTicketLoading] = useState(false);
  const [allIsLoading, setAllIsLoading] = useState(false);
  const [allTickLoading, setallTicketLoading] = useState(false);
  let per_page = 10;

  console.log("Page: ", Tpage, Xpage);

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);

  const [right_icon_all, setRight_icon_all] = useState(false);
  const [left_icon_all, setLeft_icon_all] = useState(false);

  const [new_tickets_total_entries, setNew_tickets_total_entries] =
    useState("");
  const [total_page_new_tickets, setTotal_page_new_tickets] = useState("");

  const fetchNewTickets = useCallback(
    (page, sec, priority) => {
      if (sec) {
        setIsLoading(true);
      } else {
        setnewTicketLoading(true);
      }

      let ticket_Api_uri = `${BaseURL}/agent/team-lead-new-tickets?page=${page}&per_page=${per_page}`;

      // if (status !== null && status !== undefined) {
      //   ticket_Api_uri += `&status=${status}`;
      // }

      if (priority !== null && priority !== undefined) {
        ticket_Api_uri += `&priority=${priority}`;
      }

      api
        .get(ticket_Api_uri)
        .then((response) => {
          // if (response && response?.data?.data?.length > 0) {
          setTotal_page_new_tickets(response && response?.data?.total_page);
          setNew_tickets_total_entries(response && response?.data?.total);
          // }
          setTeamNewTickets(response && response?.data?.data);
          setFirst_item(response && response?.data?.first_item);
          setLast_item(response && response?.data?.last_item);
          if (sec) {
            setIsLoading(false);
          } else {
            setnewTicketLoading(false);
          }
        })
        .catch((err) => {
          alert.error(err.message);
          console.log("Error: ", err);
          if (sec) {
            setIsLoading(false);
          } else {
            setnewTicketLoading(false);
          }
        });
    },
    [per_page, alert]
  );

  // filter for all tickets
  const [status, setStatus] = useState("");
  const [display_status, setDisplay_status] = useState("");

  const [priority, setPriority] = useState("");
  const [display_priority, setDisplay_priority] = useState("");

  // new tickets state
  // const [newstatus, setnewStatus] = useState("");
  // const [newdisplay_status, setnewDisplay_status] = useState("");

  const [newpriority, setnewPriority] = useState("");
  const [newdisplay_priority, setnewDisplay_priority] = useState("");
  // new tickets state

  const [total_page, setTotal_page] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  const [agent_id, setAgent_id] = useState("");
  const [agent_display, setAgent_display] = useState("");

  const fetchAllTickets = useCallback(
    (page, sec, status, priority, agent_id) => {
      let apiUrl = `${BaseURL}/agent/team-lead-all-tickets?page=${page}&per_page=${per_page}`;
      console.log("SEC:", priority);
      if (sec) {
        setAllIsLoading(true);
      } else {
        setallTicketLoading(true);
      }

      if (status !== null && status !== undefined) {
        apiUrl += `&status=${status}`;
      }

      if (priority !== null && priority !== undefined) {
        apiUrl += `&priority=${priority}`;
      }
      if (agent_id !== "" && agent_id !== null && agent_id !== undefined) {
        apiUrl += `&agent_id=${agent_id}`;
      }

      api
        .get(apiUrl)
        .then((response) => {
          if (response && response?.data?.data?.length > 0) {
            setTotal_page(response && response?.data?.total_page);
            setTotal_entries(response && response?.data?.total);
          }
          setTeamAllTickets(response && response?.data?.data);
          setFirst_item_all(response && response?.data?.first_item);
          setLast_item_all(response && response?.data?.last_item);
          if (sec) {
            setAllIsLoading(false);
          } else {
            setallTicketLoading(false);
          }
        })
        .catch((err) => {
          alert.error(err.message);
          console.log("Error: ", err);
          if (sec) {
            setAllIsLoading(false);
          } else {
            setallTicketLoading(false);
          }
        });
    },
    [per_page, alert]
  );

  const handlePageClick = useCallback(
    (page, sec) => {
      setTPage(page);
      fetchNewTickets(page, sec, newpriority);
    },
    [fetchNewTickets, newpriority]
  );

  const handleAllTickets = useCallback(
    (page, sec) => {
      setXPage(page);
      fetchAllTickets(page, sec, status, priority, agent_id);
    },
    [fetchAllTickets, priority, status, agent_id]
  );

  let maxVisibilePages = 5;
  let [visiblePagesForTickets, setVisiblePagesForTickets] = useState([]);
  let [visiblePagesForAllTickets, setVisiblePagesForAllTickets] = useState([]);

  const renderTicketsPagination = useCallback(() => {
    if (newTickets && newTickets?.total_page !== Tpage) {
      setRight_icon(false);
    }

    if (total_page_new_tickets && total_page_new_tickets !== Tpage) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page_new_tickets && total_page_new_tickets !== ""
        ? total_page_new_tickets
        : newTickets && newTickets?.total_page
    );
    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = Tpage - pageOffset;
    let endpage = Tpage + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i, true)}
          className={Tpage === i ? "active-index" : "page-numbers"}
        >
          {Tpage === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }

    setVisiblePagesForTickets(pageNumbers);

    return pageNumbers;
  }, [
    Tpage,
    handlePageClick,
    isLoading,
    maxVisibilePages,
    newTickets,
    total_page_new_tickets,
  ]);

  const renderAllTicketsPagination = useCallback(() => {
    if (
      (allTickets && allTickets?.total_page !== Xpage) ||
      (total_page && total_page !== Xpage)
    ) {
      setRight_icon_all(false);
    }
    const totalPages = Math.ceil(
      total_page && total_page !== ""
        ? total_page
        : allTickets && allTickets?.total_page
    );
    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = Xpage - pageOffset;
    let endpage = Xpage + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handleAllTickets(i, true)}
          className={Xpage === i ? "active-index" : "page-numbers"}
        >
          {Xpage === i && allIsLoading ? <TableLoader /> : i}
        </Button>
      );
    }

    setVisiblePagesForAllTickets(pageNumbers);

    return pageNumbers;
  }, [
    Xpage,
    allTickets,
    handleAllTickets,
    allIsLoading,
    maxVisibilePages,
    total_page,
  ]);

  useEffect(() => {
    renderTicketsPagination();
    renderAllTicketsPagination();
  }, [renderTicketsPagination, renderAllTicketsPagination]);

  const fetchTickets = useCallback(() => {
    newTickets && setTeamNewTickets(newTickets?.data);
    allTickets && setTeamAllTickets(allTickets?.data);
  }, [newTickets, allTickets]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  let ticket_aging_report_data =
    ticket_aging_report && ticket_aging_report?.data;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let issue_type_ticket_graphs =
    issue_type_ticket_graph && issue_type_ticket_graph?.data;
  let priority_type_ticket_graphs =
    priority_type_ticket_graph && priority_type_ticket_graph?.data;

  // converting month number to month name
  const ticket_issue_bars = Array.isArray(issue_type_ticket_graphs)
    ? issue_type_ticket_graphs.map((item) => {
        const monthNumber = item.month; // Assuming the month number is stored as 'month' property
        const monthName = monthNames[monthNumber - 1]; // Arrays are 0-indexed

        return {
          ...item,
          month: monthName,
        };
      })
    : [];

  // converting month number to month name
  const priority_issue_bars = Array.isArray(priority_type_ticket_graphs)
    ? priority_type_ticket_graphs?.map((item) => {
        const monthNumber = item.month;
        const monthName = monthNames[monthNumber - 1];
        return {
          ...item,
          month: monthName,
        };
      })
    : [];

  console.log("Issue By Ticket: ", ticket_aging_report_data);

  const [isIndex, setIsIndex] = useState(null);
  const [ticket_id, setTicketId] = useState();
  const handleModal = (index, id) => {
    console.log("Index: ", index);
    console.log("IDS:", id);
    setIsIndex(index);
    setTicketId(id);
  };

  const closeModal = useCallback(() => {
    setIsIndex(null);
  }, []);

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          ticket_id={ticket_id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isIndex === null) {
      fetchNewTickets(1);
      fetchAllTickets(1);
      setDisplay_status("");
      setStatus("");
      setDisplay_priority("");
      setPriority("");
      setAgent_display("");
      setAgent_id("");
      setXPage(1);
    }
  }, [isIndex, fetchAllTickets, fetchNewTickets]);

  useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  let statusList = Allstatus?.data ? Object.values(Allstatus?.data) : [];

  if (
    Array.isArray(statusList) &&
    statusList?.length > 0 &&
    !statusList.includes("All")
  ) {
    statusList?.splice(0, 0, "All");
  }

  let priority_list_arr =
    Array.isArray(priority_list?.data) && priority_list?.data?.length > 0
      ? priority_list?.data
      : [];

  if (
    Array.isArray(priority_list?.data) &&
    priority_list?.data?.length > 0 &&
    !priority_list_arr?.includes("All")
  ) {
    priority_list_arr?.splice(0, 0, "All");
  }

  console.log("Status: ", priority_list_arr);

  const handleStatus = (selectedOption) => {
    setXPage(1);
    setStatus(selectedOption?.id);
    setDisplay_status(selectedOption?.value);
    // fetchAllTickets(page, 2, selectedOption?.id);
  };

  // const handlenewTicketStatus = (selectedOption) => {
  //   setTPage(1);
  //   setnewStatus(selectedOption?.id);
  //   setnewDisplay_status(selectedOption?.value);
  //   console.log("STATUS: ", selectedOption);
  // };

  const handleAgent = (selectedOption) => {
    setXPage(1);
    setAgent_id(selectedOption?.id);
    setAgent_display(selectedOption?.label);
  };

  const handlePriority = (selectedOption) => {
    setnewTicketLoading(false);
    setXPage(1);
    setPriority(selectedOption?.index);
    setDisplay_priority(selectedOption?.value);
    // fetchAllTickets(page, 2, selectedOption?.index);
  };

  const handleNewPriority = (selectedOption) => {
    setTPage(1);
    setnewPriority(selectedOption?.index);
    setnewDisplay_priority(selectedOption?.value);
  };

  useEffect(() => {
    if (status !== null && status !== undefined) {
      fetchAllTickets(Xpage, 2, status, priority, agent_id);
    } else if (priority !== null && priority !== undefined) {
      fetchAllTickets(Xpage, 2, status, priority, agent_id);
    } else if (agent_id !== "" && agent_id !== null && agent_id !== undefined) {
      fetchAllTickets(Xpage, 2, status, priority, agent_id);
    }
  }, [status, fetchAllTickets, priority, Xpage, agent_id]);

  useEffect(() => {
    if (
      // newpriority !== "" &&
      newpriority !== null &&
      newpriority !== undefined
    ) {
      fetchNewTickets(Tpage, 2, newpriority);
    }
  }, [fetchNewTickets, newpriority, Tpage]);

  console.log("Pages: ", visiblePagesForAllTickets);

  const [loadingCSV, setLoadingCsv] = useState(false);
  const [loading_new_CSV, setLoading_newCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      let apiUrlNew = `${BaseURL}/agent/export-team-lead-tickets`;

      if (status !== "") {
        apiUrlNew += `?status=${status}`;
      }

      if (priority !== "") {
        apiUrlNew += `${status === "" ? "?" : "&"}priority=${priority}`;
      }

      if (agent_id !== "") {
        apiUrlNew += `${
          status === "" && priority === "" ? "?" : "&"
        }agent_id=${agent_id}`;
      }

      const response = await api.get(apiUrlNew);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoadingCsv(false);
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoadingCsv(false);
    }
  };

  const downloadCSV_new_Tickets = async () => {
    setLoading_newCsv(true);
    try {
      let new_api_url = `${BaseURL}/agent/export-team-lead-new-tickets`;

      if (newpriority !== "") {
        new_api_url += `?priority=${newpriority}`;
      }

      const response = await api.get(new_api_url);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoading_newCsv(false);
      }

      setLoading_newCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoading_newCsv(false);
    }
  };

  const handleDecrement = () => {
    if (Tpage === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setTPage(Tpage - 1);
      fetchNewTickets(Tpage - 1, true, newpriority);
    }
  };
  const handleIncrement = () => {
    if (newTickets && newTickets?.total_page === Tpage) {
      // setPage(page);
      setRight_icon(true);
    } else {
      setRight_icon(false);
      setTPage(Tpage + 1);
      fetchNewTickets(Tpage + 1, true, newpriority);
    }
  };

  const handleDecrement_all = () => {
    if (Xpage === 1) {
      setLeft_icon_all(true);
    } else {
      setRight_icon_all(false);
      setLeft_icon_all(false);
      setXPage(Xpage - 1);
      fetchAllTickets(Xpage - 1, true, status, priority, agent_id);
    }
  };
  const handleIncrement_all = () => {
    if (
      (allTickets && allTickets?.total_page === Xpage) ||
      (total_page && total_page === Xpage)
    ) {
      // setPage(page);
      setRight_icon_all(true);
    } else {
      setRight_icon_all(false);
      setXPage(Xpage + 1);
      fetchAllTickets(Xpage + 1, true, status, priority, agent_id);
    }
  };

  useEffect(() => {
    if (newTickets && newTickets?.total_page !== Tpage) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [newTickets, Tpage]);

  useEffect(() => {
    if (total_page_new_tickets && total_page_new_tickets !== Tpage) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page_new_tickets, Tpage]);

  useEffect(() => {
    if (allTickets && allTickets?.total_page !== Xpage) {
      setRight_icon_all(false);
    } else {
      setRight_icon_all(true);
    }
  }, [allTickets, Xpage]);

  useEffect(() => {
    if (total_page && total_page !== Xpage) {
      setRight_icon_all(false);
    } else {
      setRight_icon_all(true);
    }
  }, [Xpage, total_page]);

  const handleNavigation = () => {
    navigate(`/unreplied-Conversations`);
  };

  // unused function
  const clickRevert = () => {};

  useEffect(() => {
    dispatch(getTeamAllAgentsListForAssignee());
  }, [dispatch]);

  console.log("Total Page: ", total_page);
  console.log("Visble Page: ", visiblePagesForAllTickets);

  let agentList_filter = allagentsList && allagentsList?.data;

  console.log("Agent List: ", new_tickets_total_entries);

  const handleCallCount = () => {
    navigate("/call-count");
  };

  return (
    <>
      <HelmetTitle
        title={`${
          loading || agentsLoading || additionalLoader
            ? "Loading..."
            : "Team Lead Dashboard"
        }`}
      />
      <Navbar loaded={false} searchBar={handleSearchBar} />
      {loading || additionalLoader ? (
        <Loader />
      ) : (
        <div>
          {data && !data?.logout ? (
            <div className="home-container">
              {/* team leader dashboard count */}
              <div className="team-leader-dashboard">
                <h1 className="heading-team-leader">Team Leader Dashboard</h1>
                <div className="grid-team-leader-counts">
                  {CountsTeam &&
                    CountsTeam.map((i, index) => (
                      <div
                        className="grid-column-team-leader-counts"
                        style={{
                          backgroundColor: i.color,
                          cursor: (index === 6 || index === 1) && "pointer",
                        }}
                        onClick={
                          index === 6
                            ? handleNavigation
                            : index === 1
                            ? handleCallCount
                            : clickRevert
                        }
                        key={index}
                      >
                        <AgentsProfileCount
                          index={index}
                          color={i.color}
                          title={i.title}
                          count={i.count}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {/* team leader dashboard count */}
              {/* agents list component  */}
              <h2 className="heading-team-leader mt-agents-list">
                Agents List
              </h2>
              {agentsLoading ? (
                <PaginationLoader />
              ) : (
                <>
                  <div className="grid-agents-list">
                    {Array.isArray(agentList) && agentList.length > 0
                      ? agentList.map((i, index) => (
                          <div className="grid-column-agents-list" key={index}>
                            <Agents
                              index={index}
                              uri={i.uri}
                              userId={i.id}
                              Username={i.first_name + " " + i.last_name}
                              // AvgCallingTime={0}
                              CallCount={i?.calls?.total_calls || 0}
                              AvgFeedback={i?.reviews?.average}
                              feedback_total_count={i?.reviews?.count}
                              PendingTicket={
                                i?.tickets?.[0]?.pending_tickets || 0
                              }
                              CompleteTickets={
                                i?.tickets?.[0]?.resolve_tickets || 0
                              }
                              TotalTickets={i?.tickets?.[0]?.total_tickets || 0}
                              TotalDailyTalkTimeAvg={
                                i?.calls?.daily_call_talk_time || 0
                              }
                              isActive={i?.is_active}
                              unreplied_Ticket_counts={
                                i?.un_replied_tickets_count
                              }
                            />
                          </div>
                        ))
                      : null}
                  </div>

                  {paginationLoader ? (
                    <PaginationLoader />
                  ) : (
                    <div
                      className="agent-view-more"
                      style={{ marginBottom: !hasNextPage && "3rem" }}
                    >
                      {hasNextPage && (
                        <Button onClick={() => loadmore()}>View More</Button>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* agents list component */}

              {/* graph component starts here */}
              <div className="graph-container-wrapper">
                <div className="graph-bar-dougnut-grid-row">
                  <div>
                    <CardHeader
                      BarGraph={handleLineBarGraph}
                      para="Statistics"
                      title="Ticket Aging Report"
                      // dropdown={true}
                    />
                    {loadingFilter2 ? (
                      <PaginationLoader />
                    ) : (
                      <>
                        {ticket_aging_report_data &&
                        !ticket_aging_report_data ? (
                          <NoDataFromApi
                            height={true}
                            width_small={true}
                            title="No Data To Show, Try Changing Filter Dropdown..."
                          />
                        ) : (
                          <BarChart
                            number_of_incident={
                              ticket_aging_report &&
                              ticket_aging_report?.total_incidents
                            }
                            avg_incident={
                              ticket_aging_report &&
                              ticket_aging_report?.incidents_avg
                            }
                            data_list={
                              ticket_aging_report_data
                                ? ticket_aging_report_data
                                : []
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <CardHeader
                      // DoughnutGraph={handleDoughnutChart}
                      dataRange={handleDoughnutChart}
                      para="Statistics"
                      title="Tickets Status Wise"
                      dropdown={false}
                      showOldData={true}
                      showNew={true}
                      showDateRange={true}
                      DoughnutGraph={DoughnutGraph}
                      showBottom={true}
                    />
                    {loadingFilter ? (
                      <PaginationLoader />
                    ) : (
                      <DoughnutChart
                        active={true}
                        hoverOffset={hoverOffset}
                        padding={padding}
                        data={doughnut && doughnut?.data}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* graph component ends here */}

              {/* issue type tickets graph component starts here */}
              <div className="issue-type-graph">
                <CardHeader
                  BarGraph={handleIssueByTicketGraph}
                  para="Statistics"
                  title="Issue Type Tickets"
                  dropdown={true}
                  showOldData={true}
                />
                {loadingFilter3 ? (
                  <PaginationLoader />
                ) : (
                  <>
                    {ticket_issue_bars && ticket_issue_bars.length <= 0 ? (
                      <NoDataFromApi
                        height={true}
                        width_small={true}
                        title="No Data To Show, Try Changing Filter Dropdown..."
                      />
                    ) : (
                      <GroupedBarChart
                        ticket_issue_data={
                          ticket_issue_bars && ticket_issue_bars
                        }
                      />
                    )}
                  </>
                )}
              </div>
              {/* issue type tickets graph component ends here */}

              {/* priority type tickets graph component starts here */}
              <div className="issue-type-graph">
                <CardHeader
                  BarGraph={handlePriorityByTicketGraph}
                  para="Statistics"
                  title="Priority Type Tickets"
                  dropdown={true}
                  showOldData={true}
                />
                {loadingFilter4 ? (
                  <PaginationLoader />
                ) : (
                  <>
                    {priority_issue_bars && priority_issue_bars.length <= 0 ? (
                      <NoDataFromApi
                        height={true}
                        width_small={true}
                        title="No Data To Show, Try Changing Filter Dropdown..."
                      />
                    ) : (
                      <GroupedBarChart
                        ticket_issue_data={
                          priority_issue_bars && priority_issue_bars
                        }
                      />
                    )}
                  </>
                )}
              </div>
              {/* issue type tickets graph component ends here */}

              <h2 className="all-tickets-head">New Tickets</h2>
              <div className="table-wrapper">
                {newTickLoading && !allTickLoading ? (
                  <PaginationLoader small={true} />
                ) : (
                  <div className="table-container">
                    <div className="header-text-dropdown">
                      <h3>Tickets</h3>
                      <div className="select-dropdown-filters">
                        <Button
                          onClick={downloadCSV_new_Tickets}
                          className="export-csv-cta"
                        >
                          {loading_new_CSV ? (
                            <TableLoader />
                          ) : (
                            <>
                              <img src={Download_Icon} alt="" />
                              &nbsp; Export CSV
                            </>
                          )}
                        </Button>
                        {/* <Select
                          placeholder={
                            newdisplay_status
                              ? newdisplay_status
                              : "Select Status"
                          }
                          options={
                            Array.isArray(statusList) &&
                            statusList.map((option, index) => ({
                              value: option,
                              label: option,
                              id: option === "All" ? "" : index,
                            }))
                          }
                          value={newdisplay_status}
                          styles={customStyles}
                          isLoading={statusLoading}
                          onChange={handlenewTicketStatus}
                        /> */}
                        {/* <Select
                          placeholder={
                            agent_display ? agent_display : "Select Agent"
                          }
                          options={
                            Array.isArray(agentList_filter) &&
                            agentList_filter.map((option, index) => ({
                              value: option.id,
                              label: option.full_name,
                              id: option === "All" ? "" : option.id,
                            }))
                          }
                          value={agent_display}
                          styles={customStyles}
                          isLoading={agentLoading}
                          onChange={handleAgent}
                        /> */}
                        <Select
                          placeholder={
                            newdisplay_priority
                              ? newdisplay_priority
                              : "Select Priority"
                          }
                          options={
                            Array.isArray(priority_list_arr) &&
                            priority_list_arr.length > 0
                              ? priority_list_arr?.map((option, index) => ({
                                  value: option,
                                  label: option,
                                  index: option === "All" ? "" : index - 1,
                                }))
                              : []
                          }
                          value={newdisplay_priority}
                          styles={customStyles}
                          isLoading={priority_loading}
                          onChange={handleNewPriority}
                        />
                      </div>
                    </div>
                    <DataTable
                      header={teamLeaderNewTicket}
                      newTickets={teamNewTickets && teamNewTickets}
                      onHandleReload={handleModal}
                      items_per_page={10}
                      current_page={Tpage}
                    />
                    <div className="pagination-entries-row">
                      <p>
                        Showing {first_item !== "" ? first_item : 1} To{" "}
                        {last_item !== ""
                          ? last_item
                          : newTickets && newTickets?.last_item}{" "}
                        of, {new_tickets_total_entries} entries
                      </p>
                      <div className="pagination-chevron-icon">
                        <Button
                          disabled={left_icon || Tpage === 1 ? true : false}
                          className="icon-chevron"
                          onClick={handleDecrement}
                        >
                          <ChevronLeftIcon />
                        </Button>
                        <div className="view-more">
                          {visiblePagesForTickets}
                        </div>
                        <Button
                          disabled={
                            right_icon ||
                            (newTickets && newTickets?.total_page === Tpage)
                              ? true
                              : right_icon ||
                                (total_page_new_tickets &&
                                  total_page_new_tickets === Tpage)
                              ? true
                              : false
                          }
                          className="icon-chevron"
                          onClick={handleIncrement}
                        >
                          <ChevronRightIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* All Tickets */}
              <h2 className="all-tickets-head">All Tickets</h2>
              <div className="table-wrapper-pagination">
                {allTickLoading ? (
                  <PaginationLoader small={true} />
                ) : (
                  <div className="table-container">
                    <div className="header-text-dropdown">
                      <h3>Assigned Tickets</h3>
                      <div className="select-dropdown-filters">
                        <Button
                          onClick={downloadCSV}
                          className="export-csv-cta"
                        >
                          {loadingCSV ? (
                            <TableLoader />
                          ) : (
                            <>
                              <img src={Download_Icon} alt="" />
                              &nbsp; Export CSV
                            </>
                          )}
                        </Button>
                        <Select
                          placeholder={
                            display_status ? display_status : "Select Status"
                          }
                          options={
                            Array.isArray(statusList) &&
                            statusList.map((option, index) => ({
                              value: option,
                              label: option,
                              id: option === "All" ? "" : index,
                            }))
                          }
                          value={display_status}
                          styles={customStyles}
                          isLoading={statusLoading}
                          onChange={handleStatus}
                        />
                        <Select
                          placeholder={
                            agent_display ? agent_display : "Select Agent"
                          }
                          options={
                            Array.isArray(agentList_filter) &&
                            agentList_filter.map((option, index) => ({
                              value: option.id,
                              label: option.full_name,
                              id: option === "All" ? "" : option.id,
                            }))
                          }
                          value={agent_display}
                          styles={customStyles}
                          isLoading={agentLoading}
                          onChange={handleAgent}
                        />
                        <Select
                          placeholder={
                            display_priority
                              ? display_priority
                              : "Select Priority"
                          }
                          options={
                            Array.isArray(priority_list_arr) &&
                            priority_list_arr.length > 0
                              ? priority_list_arr?.map((option, index) => ({
                                  value: option,
                                  label: option,
                                  index: option === "All" ? "" : index - 1,
                                }))
                              : []
                          }
                          value={display_priority}
                          styles={customStyles}
                          isLoading={priority_loading}
                          onChange={handlePriority}
                        />
                      </div>
                    </div>
                    <DataTable
                      header={teamLeaderAllTicket}
                      allTickets={teamAllTickets && teamAllTickets}
                      onHandleReload={handleModal}
                      items_per_page={10}
                      current_page={Xpage}
                    />
                    <div className="pagination-entries-row">
                      <p>
                        Showing {first_item_all !== "" ? first_item_all : 1} To{" "}
                        {last_item_all !== ""
                          ? last_item_all
                          : allTickets && allTickets?.last_item}{" "}
                        of,{" "}
                        {total_entries && total_entries !== ""
                          ? total_entries
                          : allTickets && allTickets?.total}{" "}
                        entries
                      </p>
                      {/* <div className="view-more">
                        {visiblePagesForAllTickets}
                      </div> */}
                      <div className="pagination-chevron-icon">
                        <Button
                          disabled={left_icon_all || Xpage === 1 ? true : false}
                          className="icon-chevron"
                          onClick={handleDecrement_all}
                        >
                          <ChevronLeftIcon />
                        </Button>
                        <div className="view-more">
                          {visiblePagesForAllTickets}
                        </div>
                        <Button
                          disabled={
                            right_icon_all ||
                            (allTickets && allTickets?.total_page === Xpage)
                              ? true
                              : right_icon_all ||
                                (total_page && total_page === Xpage)
                              ? true
                              : false
                          }
                          className="icon-chevron"
                          onClick={handleIncrement_all}
                        >
                          <ChevronRightIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <NoDataFromApi
              btn={true}
              title="Something went wrong, Please Try Logout & Login Again..."
            />
          )}
        </div>
      )}
      {renderModal()}
    </>
  );
};

export default TeamLeaderHome;
