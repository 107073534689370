import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import "./DataTable.css";
import { Button, TableHead } from "@material-ui/core";
import Eye from "../../Assets/Customer-Details/eye-infrom.png";
import Message from "../../Assets/Customer-Details/message-time.png";
import Call_icon from "../../Assets/Customer-Details/call.png";
import Call from "../../Assets/call_details.png";
import { Link, useNavigate } from "react-router-dom";
import ModalContainer from "../Modal/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callToCustomerAction } from "../../Actions/AgentActions/AgentActions";
import TableLoader from "../Loader/TableLoader";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import MicroPhoneIcon from "../../Assets/Customer-Details/microphone-2.svg";
import PauseIcon from "../../Assets/Customer-Details/pause.png";

const DataTable = ({
  isPlaying,
  data,
  header,
  orderItems,
  newTickets,
  allTickets,
  changeCTA,
  goodwillData,
  normal_refund_data,
  onHandleReload,
  customer_id,
  courier_name,
  tracking_no,
  customer_name,
  customer_mobile_number,
  unreplied_conversation_data,
  customer_img,
  items_per_page,
  current_page,
  team_lead_call_count,
  handleAudio,
}) => {
  const alert = useAlert();
  const navigate = useNavigate();

  console.log("sss", customer_name);

  const [isIndex, setIsIndex] = useState(null);
  const [ticket_id, setTicketId] = useState();
  const [order_id, setOrder_id] = useState("");

  const [activeIndex, setActiveIndex] = useState();

  const [customer_first_name, setCustomer_first_name] = useState("");
  const [customer_last_name, setCustomer_last_name] = useState("");

  const handleModal = (index, id, audio, i) => {
    if (index === 1 && audio !== null && audio !== undefined) {
      setCustomer_first_name(
        audio?.first_name !== null ? audio?.first_name : ""
      );
      setCustomer_last_name(audio?.last_name !== null ? audio?.last_name : "");
      setIsIndex(index);
      setOrder_id(id);
      setTicketId(id);
      return;
    }
    if (index === 2000) {
      setActiveIndex(i);
      return handleAudio(audio);
    }
    if (index === 8) {
      return navigate(`/ticket-details/${id}`);
    }
    setIsIndex(index);
    setOrder_id(id);
    setTicketId(id);
  };

  const handleModalAssing = (index, id) => {
    onHandleReload(index, id);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          ticket_id={ticket_id}
          customer_id={customer_id}
          order_id={order_id}
          customer_img={customer_img}
          customer_mobile_number={customer_mobile_number}
          customer_name={
            customer_name === undefined
              ? customer_first_name + " " + customer_last_name
              : customer_name
          }
        />
      );
    }
    return null;
  };

  const dispatch = useDispatch();

  const { calling_customer, loading } = useSelector(
    (state) => state.callingCust
  );

  const [indexcall, setIsIndexCall] = useState("");

  const [isHit, setIsHit] = useState(false);

  const handleCall = (ticket_id, index) => {
    setIsHit(true);
    setIsIndexCall(index);
    dispatch(callToCustomerAction(ticket_id));
  };

  useEffect(() => {
    if (calling_customer && calling_customer?.success === false && isHit) {
      alert.error(calling_customer && calling_customer?.message);
      setIsHit(false);
    } else if (
      calling_customer &&
      calling_customer?.success === true &&
      isHit
    ) {
      alert.success(calling_customer && calling_customer?.message);
      setIsHit(false);
    }
  }, [calling_customer, alert, isHit]);

  return (
    <>
      <TableContainer>
        <Table className="table" aria-label="dynamic table">
          <TableHead>
            <TableRow>
              {header &&
                header.map((i, index) => (
                  <TableCell className="header" key={index}>
                    {i}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) && data?.length > 0
              ? data?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1 + items_per_page * (current_page - 1)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.ticket_id}</TableCell>
                    <TableCell>
                      {row.title.length > 50 ? (
                        <>{row.title.substring(0, 50)}...</>
                      ) : (
                        row.title
                      )}
                    </TableCell>
                    <TableCell>{row.order_id}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>
                      <span className="red">{row.sla}</span>
                    </TableCell>
                    <TableCell>
                      {new Date(row.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.updated_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Link to={`/ticket-details/${row.id}`}>
                        <Button
                          type="submit"
                          // onClick={() => handleModal(8, row.id)}
                          className="status-btn"
                          style={{
                            color:
                              row?.status === "On Hold" ||
                              row?.status === "Open" ||
                              row?.status === "Reopened"
                                ? "#6C6C6C"
                                : row?.status === "Pending" ||
                                  row?.status === "In Progress"
                                ? "#FF0000"
                                : row?.status === "Resolved"
                                ? "#37B99C"
                                : row?.status === "Closed"
                                ? "green"
                                : row?.status === "Duplicate"
                                ? "#1D5D9B"
                                : "#1c1c1c",
                          }}
                        >
                          {row?.status}
                        </Button>
                      </Link>
                    </TableCell>
                    {changeCTA ? (
                      <TableCell>
                        <div className="action-btns-new">
                          <Button
                            onClick={() => handleModal(1, row.id, row.customer)}
                          >
                            <img src={Message} alt="" />
                          </Button>
                          <Button onClick={() => handleModal(9, row.id)}>
                            <img className="call_details" src={Call} alt="" />
                          </Button>
                          <Button
                            className="call_push"
                            onClick={() => handleCall(row?.id, index)}
                          >
                            {indexcall === index && loading ? (
                              <TableLoader isSmall={true} />
                            ) : (
                              <img src={Call_icon} alt="" />
                            )}
                          </Button>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell className="action-btns">
                        <Button onClick={() => handleModal(1, row.id)}>
                          <img src={Message} alt="" />
                        </Button>
                        <Button onClick={() => handleModal(8, row.id)}>
                          <img src={Eye} alt="" />
                        </Button>
                        <Button
                          className="call_pushx"
                          onClick={() => handleCall(row?.id, index)}
                        >
                          {indexcall === index && loading ? (
                            <TableLoader isSmall={true} />
                          ) : (
                            <img src={Call_icon} alt="" />
                          )}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              : null}

            {orderItems &&
              orderItems.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>
                    {row?.product?.front_img_url ? (
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          overflowClipMargin: "unset",
                          overflow: "hidden",
                        }}
                        src={row?.product?.front_img_url}
                        alt=""
                      />
                    ) : (
                      <span style={{ color: "red" }}>No Image</span>
                    )}
                  </TableCell>
                  <TableCell>{row?.product_color?.name}</TableCell>
                  <TableCell>{row?.product_size?.size_code}</TableCell>
                  <TableCell>{row.order_id}</TableCell>
                  {/* <TableCell>{row.product_id}</TableCell> */}
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{courier_name}</TableCell>
                  <TableCell>{row.display_order_item_statuses}</TableCell>
                  <TableCell>
                    {row?.product?.return_available === 0
                      ? "Return not available"
                      : "Return Available"}
                  </TableCell>
                  <TableCell>{tracking_no}</TableCell>
                </TableRow>
              ))}

            {Array.isArray(newTickets) && newTickets?.length > 0
              ? newTickets?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1 + items_per_page * (current_page - 1)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.ticket_id}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.order_id}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>
                      <span className="red">{row.sla}</span>
                    </TableCell>
                    <TableCell>
                      {new Date(row.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell className="asiggned">
                      <Button
                        type="submit"
                        onClick={() => handleModalAssing(7, row.id)}
                      >
                        Assign
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}

            {Array.isArray(unreplied_conversation_data) &&
            unreplied_conversation_data?.length > 0
              ? unreplied_conversation_data?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1 + items_per_page * (current_page - 1)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.ticket_no}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.order_id}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>
                      {new Date(row.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.updated_at).toLocaleString()}
                    </TableCell>
                    {row?.agent !== null ? (
                      <>
                        <TableCell>
                          <Link className="icons-agents">
                            {row && row?.agent?.avatar_location !== null ? (
                              <img
                                className="all-tickets-icon"
                                src={row && row?.agent?.avatar_location}
                                alt=""
                              />
                            ) : (
                              <div className="agent-name-uri">
                                <p>
                                  {row?.agent?.full_name?.slice(0, 1)}
                                  {row?.agent?.full_name
                                    .split(" ")[1]
                                    ?.charAt(0)}
                                </p>
                              </div>
                            )}{" "}
                            {row?.agent?.full_name}
                          </Link>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell>
                        <p className="no-agent-assigned">No Agent Assigned</p>
                      </TableCell>
                    )}
                    <TableCell>
                      <Link to={`/ticket-details/${row.id}`}>
                        <Button
                          type="submit"
                          // onClick={() => handleModal(8, row.id)}
                          className="status-btn"
                          style={{
                            color:
                              row?.status === "On Hold" ||
                              row?.status === "Open" ||
                              row?.status === "Reopened"
                                ? "#6C6C6C"
                                : row?.status === "Pending" ||
                                  row?.status === "In Progress"
                                ? "#FF0000"
                                : row?.status === "Resolved"
                                ? "#37B99C"
                                : row?.status === "Closed"
                                ? "green"
                                : row?.status === "Duplicate"
                                ? "#1D5D9B"
                                : "#1c1c1c",
                          }}
                        >
                          {row?.status}
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell className="action-btns-new">
                      <Button onClick={() => handleModal(1, row.id)}>
                        <img src={Message} alt="" />
                      </Button>
                      <Button onClick={() => handleModal(8, row.id)}>
                        <img src={Eye} alt="" />
                      </Button>
                      <Button
                        className="call_pus_call"
                        onClick={() => handleCall(row?.id, index)}
                      >
                        {indexcall === index && loading ? (
                          <TableLoader isSmall={true} />
                        ) : (
                          <img src={Call_icon} alt="" />
                        )}
                      </Button>
                    </TableCell>
                    {/* <TableCell className="asiggned">
                      <Button
                        type="submit"
                        onClick={() => handleModalAssing(7, row.id)}
                      >
                        Assign
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
              : null}

            {Array.isArray(allTickets) && allTickets?.length > 0
              ? allTickets?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1 + items_per_page * (current_page - 1)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.ticket_id}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.order_id}</TableCell>
                    <TableCell>
                      {row?.agent?.id ? row?.agent?.id : null}
                    </TableCell>
                    <TableCell>
                      <span className="red">{row.sla}</span>
                    </TableCell>
                    {row?.agent !== null ? (
                      <>
                        <TableCell>
                          <Link className="icons-agents">
                            {row && row?.agent?.avatar_location !== null ? (
                              <img
                                className="all-tickets-icon"
                                src={row && row?.agent?.avatar_location}
                                alt=""
                              />
                            ) : (
                              <div className="agent-name-uri">
                                <p>
                                  {row?.agent?.full_name?.slice(0, 1)}
                                  {row?.agent?.full_name
                                    .split(" ")[1]
                                    ?.charAt(0)}
                                </p>
                              </div>
                            )}{" "}
                            {row?.agent?.full_name}
                          </Link>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell>
                        <p className="no-agent-assigned">No Agent Assigned</p>
                      </TableCell>
                    )}
                    <TableCell>{row.language}</TableCell>
                    <TableCell>
                      <Link to={`/ticket-details/${row.id}`}>
                        <Button
                          type="submit"
                          // onClick={() => handleModal(8, row.id)}
                          className="status-btn"
                          style={{
                            color:
                              row?.status === "On Hold" ||
                              row?.status === "Open" ||
                              row?.status === "Reopened"
                                ? "#6C6C6C"
                                : row?.status === "Pending" ||
                                  row?.status === "In Progress"
                                ? "#FF0000"
                                : row?.status === "Resolved"
                                ? "#37B99C"
                                : row?.status === "Closed"
                                ? "green"
                                : row?.status === "Duplicate"
                                ? "#1D5D9B"
                                : "#1c1c1c",
                          }}
                        >
                          {row?.status}
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {new Date(row.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell className="asiggned">
                      <Button
                        type="submit"
                        onClick={() => handleModalAssing(7, row.id)}
                      >
                        ReAssign
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}

            {normal_refund_data && normal_refund_data?.length > 0
              ? normal_refund_data?.map((i, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {i?.order_id}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests.map((i) => (
                        <p>
                          {i?.order_item?.product?.product_code}{" "}
                          {i?.order_item?.product?.product_code && ","}
                        </p>
                      ))} */}
                      {i?.order_item?.product?.product_code}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests.map((i) => (
                        <p>
                          {i?.order_item?.qty} {i?.order_item?.qty && ","}
                        </p>
                      ))} */}
                      {i?.order_item?.return_qty}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => (
                        <p>
                          {i?.return_type === "Cancel"
                            ? i?.paid_amount
                            : i?.return_amount}
                        </p>
                      ))} */}

                      {/* {row?.order_return_requests?.map((i) => (
                        <p>
                          {i?.return_amount === 0
                            ? "₹" + i?.wallet_return_amount
                            : "₹" + i?.return_amount}
                        </p>
                      ))} */}
                      {i?.return_amount === 0
                        ? "₹" + i?.wallet_return_amount
                        : "₹" + i?.return_amount}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => (
                        <p>{i?.return_reason_name}</p>
                      ))} */}
                      {i?.return_reason?.name}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => (
                        <p>{i?.return_sub_reason_name}</p>
                      ))} */}
                      {i?.return_sub_reason?.name}
                    </TableCell>
                    <TableCell>{i?.accounts || "-"}</TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => (
                        <p>{i?.razorpayx_response_id}</p>
                      ))} */}
                      {i?.razorpayx_response_id || "-"}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => ( */}
                      <p>{i?.return_type}</p>
                      {/* ))} */}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => ( */}
                      <p>
                        {new Date(i?.created_at).toLocaleDateString() +
                          " , " +
                          new Date(i?.created_at).toLocaleTimeString()}
                      </p>
                      {/* ))} */}
                    </TableCell>
                    <TableCell>
                      {/* {row?.order_return_requests?.map((i) => ( */}
                      <p>{i?.display_return_payment_statuses}</p>
                      {/* ))} */}
                    </TableCell>
                    <TableCell>
                      <div className="btn-history">
                        <Button onClick={() => handleModal(16, i?.order_id)}>
                          History
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : null}

            {Array.isArray(goodwillData) && goodwillData?.length > 0
              ? goodwillData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.id}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) =>
                        i.skus?.length > 1 ? (
                          <p>{i.skus.map((j) => j.sku).join(" / ")}</p>
                        ) : (
                          i?.skus.map((j) => <p>{j.sku}</p>)
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) =>
                        i.skus?.length > 1 ? (
                          <p>{i.skus.map((j) => j.qty).join(" / ")}</p>
                        ) : (
                          i?.skus.map((j) => <p>{j.qty}</p>)
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>₹{i?.return_amount}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>{i.reason}</p>
                      ))}
                    </TableCell>
                    <TableCell>{row?.payment_mode}</TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>{i?.razorpayx_response_id}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>
                          {i?.admin_user?.first_name !== "" &&
                            i?.admin_user?.first_name}{" "}
                          {i?.admin_user?.last_name !== "" &&
                            i?.admin_user?.last_name}
                        </p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>
                          {new Date(i?.created_at).toLocaleDateString() +
                            " , " +
                            new Date(i?.created_at).toLocaleTimeString()}
                        </p>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.goodwill_refund?.map((i) => (
                        <p>{i?.return_status}</p>
                      ))}
                    </TableCell>
                    <TableCell>
                      <div className="btn-history">
                        <Button
                          onClick={() =>
                            handleModal(16, row?.goodwill_refund[0]?.order_id)
                          }
                        >
                          History
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : null}

            {Array.isArray(team_lead_call_count) &&
            team_lead_call_count?.length > 0
              ? team_lead_call_count?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1 + items_per_page * (current_page - 1)}
                    </TableCell>
                    <TableCell>{row?.id}</TableCell>
                    <TableCell>{row?.from}</TableCell>
                    <TableCell>
                      {row?.call_start_time !== "" &&
                      row?.call_start_time !== null &&
                      row?.call_start_time !== undefined ? (
                        <>
                          {new Date(row?.call_start_time).toLocaleDateString() +
                            ", " +
                            new Date(row?.call_start_time).toLocaleTimeString()}
                        </>
                      ) : (
                        <p>NA</p>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.call_end_time !== "" &&
                      row?.call_end_time !== null &&
                      row?.call_end_time !== undefined ? (
                        <>
                          {new Date(row?.call_end_time).toLocaleDateString() +
                            ", " +
                            new Date(row?.call_end_time).toLocaleTimeString()}
                        </>
                      ) : (
                        <p>NA</p>
                      )}
                    </TableCell>
                    <TableCell>{row?.duration}</TableCell>
                    <TableCell>{row?.call_type}</TableCell>
                    <TableCell>
                      {row?.agent
                        ? row?.agent?.full_name + " - " + row?.agent?.id
                        : "NA"}
                    </TableCell>
                    <TableCell className="new-call-c">
                      <Button
                        disabled={row.recording_url === "0" ? true : false}
                        onClick={() =>
                          handleModal(2000, row.id, row.recording_url, index)
                        }
                      >
                        <img
                          src={
                            isPlaying && activeIndex === index
                              ? PauseIcon
                              : MicroPhoneIcon
                          }
                          alt=""
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {renderModal()}
    </>
  );
};

export default DataTable;
