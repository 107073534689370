import { BaseURL } from "../../BaseURL";
import {
  CANCEL_CUSTOMER_ORDER_FAILURE,
  CANCEL_CUSTOMER_ORDER_REQUEST,
  CANCEL_CUSTOMER_ORDER_SUCCESS,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST,
  CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_DETAILS_FAILURE,
  CREATE_ACCOUNT_DETAILS_REQUEST,
  CREATE_ACCOUNT_DETAILS_SUCCESS,
  CREATE_NEW_TICKET_FAILURE,
  CREATE_NEW_TICKET_REQUEST,
  CREATE_NEW_TICKET_SUCCESS,
  CREATE_RETURN_FAILURE,
  CREATE_RETURN_REQUEST,
  CREATE_RETURN_SUCCESS,
  GET_ALL_COUPANS_FAILURE,
  GET_ALL_COUPANS_REQUEST,
  GET_ALL_COUPANS_SUCCESS,
  GET_ALL_PRIORITY_FAILURE,
  GET_ALL_PRIORITY_REQUEST,
  GET_ALL_PRIORITY_SUCCESS,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST,
  GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS,
  GET_CUSTOMER_ADDRESS_FAILURE,
  GET_CUSTOMER_ADDRESS_REQUEST,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE,
  GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST,
  GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS,
  GET_CUSTOMER_DASHBOARD_CHART_FAILURE,
  GET_CUSTOMER_DASHBOARD_CHART_REQUEST,
  GET_CUSTOMER_DASHBOARD_CHART_SUCCESS,
  GET_CUSTOMER_DASHBOARD_DATA_FAILURE,
  GET_CUSTOMER_DASHBOARD_DATA_REQUEST,
  GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
  GET_CUSTOMER_ORDER_DATA_FAILURE,
  GET_CUSTOMER_ORDER_DATA_REQUEST,
  GET_CUSTOMER_ORDER_DATA_SUCCESS,
  GET_CUSTOMER_STATE_LIST_FAILURE,
  GET_CUSTOMER_STATE_LIST_REQUEST,
  GET_CUSTOMER_STATE_LIST_SUCCESS,
  GET_CUSTOMER_TICKETS_FAILURE,
  GET_CUSTOMER_TICKETS_REQUEST,
  GET_CUSTOMER_TICKETS_SUCCESS,
  GET_CUSTOMER_TICKET_LOGS_FAILURE,
  GET_CUSTOMER_TICKET_LOGS_REQUEST,
  GET_CUSTOMER_TICKET_LOGS_SUCCESS,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST,
  GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS,
  GET_GOODWILL_REFUND_COUNT_FAILURE,
  GET_GOODWILL_REFUND_COUNT_REQUEST,
  GET_GOODWILL_REFUND_COUNT_SUCCESS,
  GET_GOODWILL_REFUND_HISTORY_FAILURE,
  GET_GOODWILL_REFUND_HISTORY_REQUEST,
  GET_GOODWILL_REFUND_HISTORY_SUCCESS,
  GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE,
  GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST,
  GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS,
  GET_ISSUE_TYPES_FAILURE,
  GET_ISSUE_TYPES_REQUEST,
  GET_ISSUE_TYPES_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_PRODUCT_SKU_FAILURE,
  GET_ORDER_PRODUCT_SKU_REQUEST,
  GET_ORDER_PRODUCT_SKU_SUCCESS,
  GET_ORDER_TRACKING_FAILURE,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_SUCCESS,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
  GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
  GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
  ORDER_REFUND_FAILURE,
  ORDER_REFUND_REQUEST,
  ORDER_REFUND_SUCCESS,
  ORDER_REPLACE_CANCEL_ORDER_FAILURE,
  ORDER_REPLACE_CANCEL_ORDER_REQUEST,
  ORDER_REPLACE_CANCEL_ORDER_SUCCESS,
  ORDER_SYNCHRONIZED_FAILURE,
  ORDER_SYNCHRONIZED_REQUEST,
  ORDER_SYNCHRONIZED_SUCCESS,
  SHARE_COUPANS_FAILURE,
  SHARE_COUPANS_REQUEST,
  SHARE_COUPANS_SUCCESS,
  UPDATE_ACCOUNT_DETAILS_FAILURE,
  UPDATE_ACCOUNT_DETAILS_REQUEST,
  UPDATE_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_INFO_FAILURE,
  UPDATE_CUSTOMER_INFO_REQUEST,
  UPDATE_CUSTOMER_INFO_SUCCESS,
  UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE,
  UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST,
  UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS,
  UPDATE_PRIORITY_FAILURE,
  UPDATE_PRIORITY_REQUEST,
  UPDATE_PRIORITY_SUCCESS,
  VERIFY_MOBILE_NUMBER_OTP_FAILURE,
  VERIFY_MOBILE_NUMBER_OTP_REQUEST,
  VERIFY_MOBILE_NUMBER_OTP_SUCCESS,
} from "../../Constants/CustomerDashboardConstants/CustomerDashboardConstants";
import api from "../../axiosConfig";

export const getCustomerTilesAction = (input) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_DATA_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/dashboard?input=${input}`);
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getCustomerOrder = (customer_id, type) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_ORDER_DATA_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/customer-orders?customer_id=${customer_id}&type=${type}`
    );
    dispatch({
      type: GET_CUSTOMER_ORDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_ORDER_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getCustomerTickets =
  (customer_id, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_TICKETS_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/customer-tickets?customer_id=${customer_id}&per_page=${per_page}`
      );
      dispatch({
        type: GET_CUSTOMER_TICKETS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_TICKETS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCorderDetails = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_DETAILS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/get-order-details?order_id=${order_id}`
    );
    dispatch({
      type: GET_ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

export const getCustomerTicketLogs = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_TICKET_LOGS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/ticket-log?order_id=${order_id}`
    );
    dispatch({
      type: GET_CUSTOMER_TICKET_LOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_TICKET_LOGS_FAILURE,
      payload: error.message,
    });
  }
};

export const synchronizedOrder = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_SYNCHRONIZED_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/syn-order-tracking?order_id=${order_id}`
    );
    dispatch({
      type: ORDER_SYNCHRONIZED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_SYNCHRONIZED_FAILURE,
      payload: error.message,
    });
  }
};

export const getOrderTracking = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_TRACKING_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/order-tracking-details?order_id=${order_id}`
    );
    dispatch({
      type: GET_ORDER_TRACKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_TRACKING_FAILURE,
      payload: error.message,
    });
  }
};

export const getReasonsActions = (type) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/reason-list?type=${type}`);
    dispatch({
      type: GET_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
      payload: error.message,
    });
  }
};

export const getSubReasonsActions = (type, id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/reason-list?type=${type}&id=${id}`
    );
    dispatch({
      type: GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SUB_REASONS_FOR_CANCELLING_THE_ORDER_FAILURE,
      payload: error.message,
    });
  }
};

export const getCustomerDashboardChartActions =
  (customer_id, month) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_DASHBOARD_CHART_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/dashboard-chart?customer_id=${customer_id}&month=${month}`
      );
      dispatch({
        type: GET_CUSTOMER_DASHBOARD_CHART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_DASHBOARD_CHART_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCustomerDashboardByIdActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_BY_ID_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/get-customer-profile?id=${id}`
    );
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_DASHBOARD_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const UpdateCustomerInfoActions =
  (id, first_name, last_name, email) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CUSTOMER_INFO_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/update-customer-info`, {
        id,
        first_name,
        last_name,
        email,
      });
      dispatch({
        type: UPDATE_CUSTOMER_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_INFO_FAILURE,
        payload: error.message,
      });
    }
  };

export const updateCustomerMobileNumber = (id, phone) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER_MOBILE_NUMBER_REQUEST,
    });

    const { data } = await api.post(
      `${BaseURL}/agent/update-customer-mobile?id=${id}&phone=${phone}`
    );
    dispatch({
      type: UPDATE_CUSTOMER_MOBILE_NUMBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMER_MOBILE_NUMBER_FAILURE,
      payload: error.message,
    });
  }
};

export const VerifyMobileNumberOTPActions =
  (id, phone, otp) => async (dispatch) => {
    try {
      dispatch({
        type: VERIFY_MOBILE_NUMBER_OTP_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/verify-otp`, {
        id,
        phone,
        otp,
      });
      dispatch({
        type: VERIFY_MOBILE_NUMBER_OTP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VERIFY_MOBILE_NUMBER_OTP_FAILURE,
        payload: error.message,
      });
    }
  };

export const cancelCustomerOrderActions =
  (order_id, reason_id, accounts, upi_id, reason_sub_id) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/cancel-order?order_id=${order_id}&reason_id=${reason_id}&accounts=${accounts}&upi_id=${upi_id}&reason_sub_id=${reason_sub_id}`
      );
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_FAILURE,
        payload: error.message,
      });
    }
  };

export const cancelCustomerOrderWithBankActions =
  (
    order_id,
    reason_id,
    accounts,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    account_name,
    reason_sub_id
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/cancel-order?order_id=${order_id}&reason_id=${reason_id}&accounts=${accounts}&bank_name=${bank_name}&account_number=${account_number}&confirm_account_number=${confirm_account_number}&ifsc=${ifsc}&account_name=${account_name}&reason_sub_id=${reason_sub_id}`
      );
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE,
        payload: error.message,
      });
    }
  };

export const cancelCustomerOrderWithOriginalSourceActions =
  (order_id, reason_id, accounts, reason_sub_id) => async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/cancel-order?order_id=${order_id}&reason_id=${reason_id}&accounts=${accounts}&reason_sub_id=${reason_sub_id}`
      );
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_CUSTOMER_ORDER_WITH_BANK_ACCOUNT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getGoodWillRefundActions = (customer_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GOODWILL_REFUND_COUNT_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/goodwill-refund-counts?customer_id=${customer_id}`
    );
    dispatch({
      type: GET_GOODWILL_REFUND_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_GOODWILL_REFUND_COUNT_FAILURE,
      payload: error.message,
    });
  }
};

export const getGoodWillRefundHistoryActions =
  (customer_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GOODWILL_REFUND_HISTORY_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/goodwill-refund-history?customer_id=${customer_id}`
      );
      dispatch({
        type: GET_GOODWILL_REFUND_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GOODWILL_REFUND_HISTORY_FAILURE,
        payload: error.message,
      });
    }
  };

export const getGoodwillRefundHistoryDataActions =
  (customer_id, order_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GOODWILL_REFUND_HISTOTY_DATA_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/goodwill-refund-history?customer_id=${customer_id}&order_id=${order_id}`
      );
      dispatch({
        type: GET_GOODWILL_REFUND_HISTOTY_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GOODWILL_REFUND_HISTOTY_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getGoodWillNormalRefundHistoryActions =
  (customer_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_GOODWILL_NORMAL_REFUND_HISTORY_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/order-refund-history?customer_id=${customer_id}`
      );
      dispatch({
        type: GET_GOODWILL_NORMAL_REFUND_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GOODWILL_NORMAL_REFUND_HISTORY_FAILURE,
        payload: error.message,
      });
    }
  };

export const getOrderProductSKUActions = (order_no) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_PRODUCT_SKU_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/get-order-items?order_no=${order_no}`
    );
    dispatch({
      type: GET_ORDER_PRODUCT_SKU_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_PRODUCT_SKU_FAILURE,
      payload: error.message,
    });
  }
};

export const OrderRefundActions =
  (
    order_no,
    return_amount,
    order_items_qty,
    accounts,
    upi_id,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    reason
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDER_REFUND_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-goodwill-refund`,
        {
          order_no,
          return_amount,
          order_items_qty,
          accounts,
          upi_id,
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          reason,
        }
      );
      dispatch({
        type: ORDER_REFUND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_REFUND_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllCoupansActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_COUPANS_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/coupons`);
    dispatch({
      type: GET_ALL_COUPANS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUPANS_FAILURE,
      payload: error.message,
    });
  }
};

export const createReturnActions =
  (
    order_id,
    customer_id,
    selected_order_items,
    reason_id,
    sub_reason_id,
    comments,
    accounts,
    upi_id
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_RETURN_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/return-order`, {
        order_id,
        customer_id,
        selected_order_items,
        reason_id,
        sub_reason_id,
        comments,
        accounts,
        upi_id,
      });
      dispatch({
        type: CREATE_RETURN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_RETURN_FAILURE,
        payload: error.message,
      });
    }
  };

export const createReturnBankActions =
  (
    order_id,
    customer_id,
    selected_order_items,
    reason_id,
    sub_reason_id,
    comments,
    accounts,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    account_name
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_RETURN_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/return-order`, {
        order_id,
        customer_id,
        selected_order_items,
        reason_id,
        sub_reason_id,
        comments,
        accounts,
        bank_name,
        account_number,
        confirm_account_number,
        ifsc,
        account_name,
      });
      dispatch({
        type: CREATE_RETURN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_RETURN_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCustomerAddressActions = (customer_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_ADDRESS_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/customer-addresses?customer_id=${customer_id}`
    );
    dispatch({
      type: GET_CUSTOMER_ADDRESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_ADDRESS_FAILURE,
      payload: error.message,
    });
  }
};

export const getCustomerStateListActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER_STATE_LIST_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/get-state-list`);
    dispatch({
      type: GET_CUSTOMER_STATE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_STATE_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const UpdateCustomerAddressActions =
  (
    id,
    customer_id,
    first_name,
    last_name,
    email,
    phone,
    alternate_phone,
    address1,
    address2,
    city,
    state_id,
    pincode,
    state_name
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CUSTOMER_ADDRESS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-update-address`,
        {
          id,
          customer_id,
          first_name,
          last_name,
          email,
          phone,
          alternate_phone,
          address1,
          address2,
          city,
          state_id,
          pincode,
          state_name,
        }
      );
      dispatch({
        type: UPDATE_CUSTOMER_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_ADDRESS_FAILURE,
        payload: error.message,
      });
    }
  };

export const AccountdetailsListingActions =
  (customer_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/customer-refund-accounts?customer_id=${customer_id}`
      );
      dispatch({
        type: GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_ACCOUNTS_DETAILS_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const updateAccountDetailsListActions =
  (customer_id, id, accounts, upi_id, type) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-update-refund-account`,
        {
          customer_id,
          id,
          accounts,
          upi_id,
          type,
        }
      );
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const updateAccountDetailsBankListActions =
  (
    customer_id,
    id,
    accounts,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    account_name,
    type
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-update-refund-account`,
        {
          customer_id,
          id,
          accounts,
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          account_name,
          type,
        }
      );
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ACCOUNT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const createAccountDetailsUPIActions =
  (customer_id, accounts, upi_id, type) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-update-refund-account`,
        {
          customer_id,
          accounts,
          upi_id,
          type,
        }
      );
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const createAccountDetailsBankActions =
  (
    customer_id,
    accounts,
    bank_name,
    account_number,
    confirm_account_number,
    ifsc,
    account_name,
    type
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_REQUEST,
      });

      const { data } = await api.post(
        `${BaseURL}/agent/create-update-refund-account`,
        {
          customer_id,
          accounts,
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          account_name,
          type,
        }
      );
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_ACCOUNT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getIssuesTypesActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ISSUE_TYPES_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/get-issue-types`);
    dispatch({
      type: GET_ISSUE_TYPES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ISSUE_TYPES_FAILURE,
      payload: error.message,
    });
  }
};

export const CreateNewTicketActions =
  (title, message, order_id, items, issue_type_id, user_id, call_id) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_NEW_TICKET_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/create-ticket`, {
        title,
        message,
        order_id,
        items,
        issue_type_id,
        user_id,
        call_id,
      });
      dispatch({
        type: CREATE_NEW_TICKET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_NEW_TICKET_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllPriorityActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PRIORITY_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/get-priorities`);
    dispatch({
      type: GET_ALL_PRIORITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRIORITY_FAILURE,
      payload: error.message,
    });
  }
};

export const UpdatedPriorityActions =
  (ticket_id, priority) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PRIORITY_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/change-priority`, {
        ticket_id,
        priority,
      });
      dispatch({
        type: UPDATE_PRIORITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PRIORITY_FAILURE,
        payload: error.message,
      });
    }
  };

export const sendCoupansActions =
  (customer_id, coupon_id) => async (dispatch) => {
    try {
      dispatch({
        type: SHARE_COUPANS_REQUEST,
      });

      const { data } = await api.post(`${BaseURL}/agent/share-coupon`, {
        customer_id,
        coupon_id,
      });
      dispatch({
        type: SHARE_COUPANS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHARE_COUPANS_FAILURE,
        payload: error.message,
      });
    }
  };

export const cancelReplaceOrderActions = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_REPLACE_CANCEL_ORDER_REQUEST,
    });

    const { data } = await api.post(`${BaseURL}/agent/order-replace-cancel`, {
      order_id,
    });
    dispatch({
      type: ORDER_REPLACE_CANCEL_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_REPLACE_CANCEL_ORDER_FAILURE,
      payload: error.message,
    });
  }
};
