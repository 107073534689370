import React from "react";
import "./ReadOnlyInputs.css";

const ReadOnlyInputs = (props) => {
  return (
    <div className="inputs-agent">
      <label htmlFor={props.id}>{props.label}</label> <br />
      {props.dateValue ? (
        <>
          <input type="text" id={props.id} value={props.value ? props.value : ""} readOnly />{" "}
          <br />
        </>
      ) : (
        <>
          <input
            type="text"
            id={props.id}
            defaultValue={props.value ? props.value : ""}
            readOnly
          />{" "}
          <br />
        </>
      )}
    </div>
  );
};

export default ReadOnlyInputs;
