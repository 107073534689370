import React from "react";
import { Link } from "react-router-dom";

const TicketHistory = (ticket) => {
  // console.log("DATA:::",ticket)
  return (
    <Link to={`/ticket-details/${ticket.id}`} className="ticket-history-col">
      <p className="ticket-id">Ticket Id: {ticket.ticket_id}</p>
      {ticket.ticket_items[0] && (
        <div className="info-item-ticket-grid">
          <img
            className="tickets-item-uir"
            src={ticket.ticket_items[0].image_url}
            alt=""
          />
          <div className="content-col-tickets-his">
            <h4>{ticket.ticket_items[0].title}</h4>
            <p>Order Id: {ticket.order_id}</p>
          </div>
        </div>
      )}
      <h3 className="ticket-title">{ticket.title}</h3>
      <p className="date-his">
        {
          // eslint-disable-next-line
          new Date(ticket.created_at).toDateString() +
            " " +
            "|" +
            " " +
            new Date(ticket.created_at).toLocaleTimeString()
        }
      </p>
      <p className="message-ticket">{ticket.message}</p>
    </Link>
  );
};

export default TicketHistory;
