import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
import { EffectCards } from "swiper/modules";
import Default_IMG from "../../Assets/No-Image-Placeholder.svg.png";

const SwiperCards = (props) => {
  return (
    <>
      {props.Slider_new ? (
        <div className="new-ticket-details-grid-row">
          {props.Slider_new ? (
            props.Slider_new?.map((i, index) => (
              <img
                className="ticket-details-img"
                key={index}
                src={i.image_url}
                onError={(e) => (e.target.src = Default_IMG)}
                alt=""
              />
            ))
          ) : (
            <img className="default-swiper-img" src={Default_IMG} alt="" />
          )}
        </div>
      ) : (
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"
        >
          {props.Slider && props.Slider?.length > 0 ? (
            props.Slider.map((i, index) => (
              <SwiperSlide key={index}>
                <img
                  src={i}
                  onError={(e) => (e.target.src = Default_IMG)}
                  alt=""
                />
              </SwiperSlide>
            ))
          ) : (
            <img className="default-swiper-img" src={Default_IMG} alt="" />
          )}
        </Swiper>
      )}
    </>
  );
};

export default SwiperCards;
