import React from "react";
import "./Agents.css";
import { Link } from "react-router-dom";

const Agents = (props) => {
  const getUserName = (str) => {
    if (!str) {
      return "";
    }

    const trimmedStr = str.trim();
    const firstLetter = trimmedStr.charAt(0);
    const nextSpaceIndex = trimmedStr.indexOf(" ");

    if (nextSpaceIndex === -1) {
      return firstLetter;
    }

    return firstLetter + trimmedStr.charAt(nextSpaceIndex + 1);
  };

  const userName = getUserName(props.Username);
  return (
    <>
      <Link to={`/agent-profile/${props.userId}`} key={props.index}>
        <div className="agent-tags-container">
          <div
            className={`${
              props.isActive === 1 ? "tag-agents" : "tag-inAgents"
            }`}
          >
            <p>{props.isActive === 1 ? "Active User" : "Not Active"}</p>
            <div className="triangle"></div>
          </div>
        </div>
        <div className="img-uri-agents">
          {props.uri ? (
            <img src={props.uri} alt="" />
          ) : (
            <div className="agent-icon">
              <p>{userName}</p>
            </div>
          )}
        </div>
        {/* agent info row 1 */}
        <div className="agents-details-grid-row mt-agents">
          <div>
            <p className="left-side-agent-info">User Id</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.userId}</p>
          </div>
        </div>
        {/* agent info row 1 */}
        {/* agent info row 2 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Username</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.Username}</p>
          </div>
        </div>
        {/* agent info row 2 */}
        {/* agent info row 3 */}
        {/* <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Avg. Calling Time</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.AvgCallingTime}</p>
          </div>
        </div> */}
        {/* agent info row 3 */}
        {/* agent info row 4 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Total Call Count</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.CallCount}</p>
          </div>
        </div>
        {/* agent info row 4 */}
        {/* agent info row 5 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Avg. Feedback</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.AvgFeedback}</p>
          </div>
        </div>
        {/* agent info row 5 */}
        {/* agent info row 8 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Feedback Total Count</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">
              {props.feedback_total_count}
            </p>
          </div>
        </div>
        {/* agent info row 8 */}
        {/* agent info row 6 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Pending Ticket</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.PendingTicket}</p>
          </div>
        </div>
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">UnReplied Ticket Counts</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.unreplied_Ticket_counts}</p>
          </div>
        </div>
        {/* agent info row 6 */}
        {/* agent info row 7 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Resolved Tickets</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.CompleteTickets}</p>
          </div>
        </div>
        {/* agent info row 7 */}
        {/* agent info row 8 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Total Tickets</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">{props.TotalTickets}</p>
          </div>
        </div>
        {/* agent info row 8 */}
        {/* agent info row 9 */}
        <div className="agents-details-grid-row">
          <div>
            <p className="left-side-agent-info">Total Daily Talk Time Avg.</p>
          </div>
          <div>:</div>
          <div>
            <p className="right-side-agent-info">
              {props.TotalDailyTalkTimeAvg}
            </p>
          </div>
        </div>
        {/* agent info row 9 */}
      </Link>
    </>
  );
};

export default Agents;
