import React, { useEffect, useState } from "react";
import DoughnutChart from "../../Components/Bars/DougnutChart/DoughnutChart";
import CardHeader from "../../Components/CardHeader/CardHeader";
import AgentsProfileCount from "../../Components/Counts/AgentsProfileCount";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import ReadOnlyInputs from "../../Components/ReadOnlyInputs/ReadOnlyInputs";
import AgentUri from "../../Assets/Agents-Img/Ellipse 1.png";
import StarIcon from "@material-ui/icons/Star";
import "./AgentDashboard.css";
import { Rating } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentCountsInfo,
  getAgentTicketsByBearerAction,
  getAgentsCountDoughnut,
  getAgentsCountDoughnut_with_normal,
} from "../../Actions/AgentActions/AgentActions";
import Loader from "../../Components/Loader/Loader";
import Navbar from "../../Components/Navbar/Navbar";
import { header } from "../../DummyArray/HiscravesData";
import DataTable from "../../Components/DataTable/DataTable";
import { useCallback } from "react";
import { Button } from "@material-ui/core";
import api from "../../axiosConfig";
import { BaseURL } from "../../BaseURL";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import TableLoader from "../../Components/Loader/TableLoader";
import { customStyles } from "../../DummyArray/DashboardArrData";
import PaginationLoader from "../../Components/Loader/PaginationLoader";
import Pusher from "pusher-js";
import ModalContainer from "../../Components/Modal/Modal";
import HelmetTitle from "../../Components/MetaData/MetaData";
import Select from "react-select";
import { getTicketStatus } from "../../Actions/TeamLeaderActions/TeamLeaderActions";
import { getAllPriorityActions } from "../../Actions/CustomerActions/CustomerActions";
import Download_Icon from "../../Assets/document-download.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NoDataFromApi from "../../Components/NotFound/NoDataFromApi";

const AgentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { data, loading } = useSelector((state) => state.agent);
  const { doughnut } = useSelector((state) => state.agentDoughnut);
  const { ticketsBearer, loading: ticketsLoading } = useSelector(
    (state) => state.agentTicketsBearer
  );

  // get All Status
  const { Allstatus, loading: statusLoading } = useSelector(
    (state) => state.allstatus
  );

  // priority list state
  const { priority_list, loading: priority_loading } = useSelector(
    (state) => state.priority
  );

  let agentData = data && data?.data;
  let agentInfo = agentData && agentData?.agent;

  const updatedData = [
    {
      color: "#9DE4F2",
      title: "Total Tickets",
      count: agentData && agentData?.total_tickets,
    },
    {
      color: "#F2AE24",
      title: "Resolve Tickets",
      count: agentData && agentData?.resolve_tickets,
    },
    {
      color: "#F23079",
      title: "Hold Tickets",
      count: agentData && agentData?.hold_tickets,
    },
    {
      color: "#C6A969",
      title: "InProgress Tickets",
      count: agentData && agentData?.in_progress_tickets,
    },
    {
      color: "#E6B9DE",
      title: "ReOpened Tickets",
      count: agentData && agentData?.reopened_tickets,
    },
    {
      color: "#4F6F52",
      title: "Duplicate Tickets",
      count: agentData && agentData?.duplicate_tickets,
    },
    {
      color: "#C6CF9B",
      title: "Closed Tickets",
      count: agentData && agentData?.closed_tickets,
    },
    {
      count: agentData && agentData?.open_tickets,
      title: "Open Tickets",
      color: "#E0AED0",
    },
    {
      color: "#F28D85",
      title: "Pending Tickets",
      count: agentData && agentData?.pending_tickets,
    },
    {
      color: "#F2BEB6",
      title: "Total Call Counts",
      count: agentData && agentData?.total_call_count,
    },
    {
      color: "#A3D9CC",
      title: "Total Call Volume",
      count: agentData && agentData?.total_call_valume,
    },
    {
      color: "#D0BFFF",
      count: agentData && agentData?.missed_call_count,
      title: "Missed Call Count",
    },
    {
      color: "#FFCF96",
      count: agentData && agentData?.recieved_call_count,
      title: "Received Call Count",
    },
    {
      color: "#B8F39E",
      title: "Total Daily Talk Time",
      count: agentData && agentData?.daily_call_talk_time,
    },
    {
      color: "#F23079",
      title: "Agent Join Age",
      count: agentData && agentData?.agent_join_age,
    },
    {
      color: "#64CCC5",
      title: "UnReplied Tickets Count",
      count: agentData && agentData?.un_replied_tickets_count,
    },
    {
      count: agentData && agentData?.sla_breach_count,
      title: "SLA Breach Count",
      color: "#EADFB4",
    },
    {
      count: agentData && agentData?.sla_breach_age_avg,
      title: "SLA Breach Age Avg.",
      color: "#FFEAA7",
    },
  ];

  const [first_item, setFirst_item] = useState("");
  const [last_item, setLast_item] = useState("");

  // Table pagination
  const [agentTickets, setAgentsTickets] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let per_page = 10;

  // filter for all tickets
  const [isTrigger, setIsTrigger] = useState(false);
  const [status, setStatus] = useState("");
  const [display_status, setDisplay_status] = useState("");

  const [priority, setPriority] = useState("");
  const [display_priority, setDisplay_priority] = useState("");

  const [total_page, setTotal_page] = useState("");
  const [total_entries, setTotal_entries] = useState("");

  // const [load, setLoad] = useState(false);

  const fetchTickets = useCallback(
    (page, i) => {
      setIsLoading(true);
      let apiUrl = `${BaseURL}/agent/agent-tickets?per_page=${per_page}&page=${page}`;
      if (status !== "") {
        apiUrl += `&status=${status}`;
      }

      if (priority !== "") {
        apiUrl += `&priority=${priority}`;
      }
      api
        .get(apiUrl)
        .then((response) => {
          if (response && response?.data?.data?.length > 0) {
            setTotal_page(response && response?.data?.total_page);
            setTotal_entries(response && response?.data?.total);
          }
          setAgentsTickets(response && response?.data?.data);
          setFirst_item(response?.data && response?.data?.first_item);
          setLast_item(response?.data && response?.data?.last_item);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          alert.error(err.message);
          setIsLoading(false);
        });
    },
    [per_page, priority, status, alert]
  );

  const handleStatus = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    setStatus(selectedOption?.id);
    setDisplay_status(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  const handlePriority = (selectedOption) => {
    setPage(1);
    setIsTrigger(true);
    // setXPage(1);
    setPriority(selectedOption?.index);
    setDisplay_priority(selectedOption?.value);
    // fetchTickets(page, 2);
  };

  useEffect(() => {
    if (isTrigger) {
      fetchTickets(page, 2);
    }
  }, [isTrigger, fetchTickets, page]);

  const handlePageClick = useCallback(
    (page) => {
      setPage(page);
      fetchTickets(page);
    },
    [fetchTickets]
  );

  const [right_icon, setRight_icon] = useState(false);
  const [left_icon, setLeft_icon] = useState(false);

  let maxVisibilePages = 5;
  const [VisiblePages, setVisibilePages] = useState([]);
  const renderPagination = useCallback(() => {
    if (ticketsBearer && ticketsBearer?.total_page !== page) {
      setRight_icon(false);
    }
    if (total_page && total_page !== page) {
      setRight_icon(false);
    }
    const totalPages = Math.ceil(
      total_page && total_page !== "" ? total_page : ticketsBearer?.total_page
    );
    const pageOffset = Math.floor(maxVisibilePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;
    const pageNumbers = [];

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxVisibilePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxVisibilePages + 1);
    }

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <TableLoader /> : i}
        </Button>
      );
    }
    setVisibilePages(pageNumbers);
    return pageNumbers;
  }, [
    handlePageClick,
    isLoading,
    maxVisibilePages,
    page,
    // ticketsBearer?.total_page,
    total_page,
    ticketsBearer,
  ]);

  const fetch = useCallback(() => {
    ticketsBearer && setAgentsTickets(ticketsBearer?.data);
  }, [ticketsBearer]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getPreviousDate = (monthsAgo) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthsAgo);
    return currentDate;
  };

  const [previousDate, setPreviousDate] = useState(getPreviousDate(6));

  useEffect(() => {
    setPreviousDate(getPreviousDate(6));
  }, []);

  const [DoughnutGraph, SetDoughnutGraph] = useState([
    previousDate,
    new Date(),
  ]);

  // const start_date = DoughnutGraph && DoughnutGraph[0];
  // const end_date = DoughnutGraph && DoughnutGraph[1];

  // Format the date as "dd-MM-yyyy"
  // const from_date = start_date
  //   ? start_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  // const to_date = end_date
  //   ? end_date
  //       .toLocaleDateString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       })
  //       .replace(/\//g, "-")
  //   : null;

  const [filterTrue, setFilterTrue] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const [width, setWidth] = useState("3rem");

  const handleDoughnutChart = async (children) => {
    const start_date = children && children[0];
    const end_date = children && children[1];

    // Format the date as "dd-MM-yyyy"
    const from_date = start_date
      ? start_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;

    const to_date = end_date
      ? end_date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-")
      : null;
    setFilterTrue(true);
    setLoadingFilter(true);
    SetDoughnutGraph(children);
    if (from_date !== null && to_date !== null) {
      await dispatch(getAgentsCountDoughnut(from_date, to_date));
    } else {
      await dispatch(getAgentsCountDoughnut_with_normal(6));
    }
    setLoadingFilter(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setWidth("2.8rem");
    }
  }, []);

  useEffect(() => {
    if (!filterTrue) {
      dispatch(getAgentCountsInfo());
      dispatch(getAgentsCountDoughnut_with_normal(6));
      dispatch(getAgentTicketsByBearerAction(10));
    }
  }, [dispatch, filterTrue]);

  console.log("Data: ", data);

  useEffect(() => {
    if (data && data?.logout) {
      localStorage.removeItem("user");
      navigate("/login");
      alert.error(data && data?.message);
    }
  }, [data, alert, navigate]);

  let review = agentData && agentData?.agent_review;

  console.log("Review: ", review);

  var pusher = new Pusher("3b069e9bc0cda16c722f", {
    cluster: "ap2",
    forceTLS: true,
  });
  const user = JSON.parse(localStorage.getItem("user"));

  const [isIndex, setIsIndex] = useState(null);
  const [call_data, setCall_data] = useState("");

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          data_call={call_data && call_data}
        />
      );
    }
    return null;
  };

  const [isClosed, setIsClosed] = useState(true);

  const getCallData = (call_id) => {
    api
      .get(`${BaseURL}/agent/get-call-details?call_id=${call_id}`)
      .then((response) => {
        console.log("call details", response?.data);
        console.log("Response: ", response && response?.data);
        //setIsLoading(false);
        if (response?.data?.status === "success" && isClosed) {
          // setIsIndex(13);
          setCall_data(response && response?.data?.callData);
        }
      })
      .catch((err) => {
        // console.log("Error: ", err);
        alert.error(err.message);
        //setIsLoading(false);
      });
  };

  var mobile = user?.phone ?? "08882204776";
  console.log("User: ", user, mobile);
  var channel = pusher.subscribe(mobile);
  channel.bind("call-event", function (data) {
    const messageData = data.message;
    console.log(
      "pusher data",
      messageData,
      messageData.EventType,
      messageData.CallSid
    );
    if (messageData.EventType === "Dial") {
      const d = getCallData(messageData.CallSid);
      console.log("pusher Dial data", d);
    }
    if (messageData.EventType === "Ringing") {
      const d = getCallData(messageData.CallSid);
      console.log("pusher Ringing data", d);
    }
    if (messageData.EventType === "Answered") {
      const d = getCallData(messageData.CallSid);
      console.log("pusher Answered data", d);
    }
    if (messageData.EventType === "Terminal") {
      const d = getCallData(messageData.CallSid);
      console.log("pusher Terminal data", d);
      setIsClosed(false);
      // setIsIndex(null);
    }
  });

  const handleSearchBar = useCallback(
    (search) => {
      let phone_number = `?phone_number=${search}`;
      navigate(`/customer-dashboard/${phone_number}`, {
        state: { call_data: call_data },
      });
    },
    [navigate, call_data]
  );

  useEffect(() => {
    if (call_data && call_data?.call_from !== "") {
      handleSearchBar(call_data && call_data?.call_from);
    }
  }, [call_data, handleSearchBar]);

  console.log(call_data, "call_data");

  useEffect(() => {
    dispatch(getTicketStatus());
    dispatch(getAllPriorityActions());
  }, [dispatch]);

  let statusList = Allstatus?.data ? Object.values(Allstatus?.data) : [];

  if (
    Array.isArray(statusList) &&
    statusList?.length > 0 &&
    !statusList.includes("All")
  ) {
    statusList?.splice(0, 0, "All");
  }

  let priority_list_arr =
    Array.isArray(priority_list?.data) && priority_list?.data?.length > 0
      ? priority_list?.data
      : [];

  if (
    Array.isArray(priority_list?.data) &&
    priority_list?.data?.length > 0 &&
    !priority_list_arr?.includes("All")
  ) {
    priority_list_arr?.splice(0, 0, "All");
  }

  console.log("Value: ", DoughnutGraph);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      let apiUrl_newX = `${BaseURL}/agent/export-agent-tickets`;

      if (status !== "") {
        apiUrl_newX += `?status=${status}`;
      }

      if (priority !== "") {
        apiUrl_newX += `${status === "" ? "?" : "&"}priority=${priority}`;
      }
      const response = await api.get(apiUrl_newX);
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
        setLoadingCsv(false);
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err.message);
      setLoadingCsv(false);
    }
  };

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  const handleDecrement = () => {
    if (page === 1) {
      setLeft_icon(true);
    } else {
      setRight_icon(false);
      setLeft_icon(false);
      setPage(page - 1);
      fetchTickets(page - 1);
    }
  };
  const handleIncrement = () => {
    if (
      (ticketsBearer && ticketsBearer?.total_page === page) ||
      (total_page && total_page === page)
    ) {
      // setPage(page);
      setRight_icon(true);
    } else {
      setRight_icon(false);
      setPage(page + 1);
      fetchTickets(page + 1);
    }
  };

  useEffect(() => {
    if (ticketsBearer && ticketsBearer?.total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [ticketsBearer, page]);

  useEffect(() => {
    if (total_page && total_page !== page) {
      setRight_icon(false);
    } else {
      setRight_icon(true);
    }
  }, [total_page, page]);

  console.log(ticketsBearer && ticketsBearer?.total_page);

  const handleNavigation = () => {
    navigate(`/unreplied-Conversations/${agentInfo && agentInfo?.id}`);
  };

  // unused function
  const clickRevert = () => {};

  const handleCallCount = () => {
    navigate("/call-count");
  };

  return (
    <>
      <HelmetTitle title={`Hiscraves - Agent Dashboard`} />
      <Navbar searchBar={handleSearchBar} />
      {loading || ticketsLoading ? (
        <Loader />
      ) : data && data?.success === false ? (
        <NoDataFromApi title={data && data?.message} />
      ) : (
        <div className="home-container">
          {/* team leader dashboard count */}
          <div className="team-leader-dashboard">
            <h1 className="heading-team-leader">My Dashboard</h1>
            <div className="grid-team-leader-counts">
              {updatedData &&
                updatedData.map((i, index) => (
                  <div
                    className="grid-column-team-leader-counts"
                    style={{
                      backgroundColor: i.color,
                      cursor: (index === 15 || index === 9) && "pointer",
                    }}
                    key={index}
                    onClick={
                      index === 15
                        ? handleNavigation
                        : index === 9
                        ? handleCallCount
                        : clickRevert
                    }
                  >
                    <AgentsProfileCount
                      index={index}
                      color={i.color}
                      title={i.title}
                      count={i.count}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* team leader dashboard count */}
          {/* user details graph ratings  */}
          <div className="user-details-graph-ratings-grid-row">
            <div>
              <div className="agent-tags-container">
                <div
                  className={`${
                    agentData && agentData.is_active === 1
                      ? "tag-agents"
                      : "tag-inAgents"
                  }`}
                >
                  <p>
                    {agentData && agentData.is_active === 1
                      ? "Active User"
                      : "Not Active"}
                  </p>
                  <div className="triangle"></div>
                </div>
              </div>
              <div className="agent-details-left-side">
                <div className="agent-img-left-side">
                  <img src={AgentUri} alt="" />
                </div>
              </div>
              {agentInfo && (
                <>
                  <ReadOnlyInputs
                    label="User Id"
                    id="UserId"
                    value={agentInfo?.id}
                  />
                  <ReadOnlyInputs
                    label="Username"
                    id="Username"
                    value={agentInfo?.full_name}
                  />
                  <ReadOnlyInputs
                    label="Join Date"
                    id="JoinDate"
                    value={
                      new Date(agentInfo?.join_data).toLocaleDateString() +
                      " , " +
                      new Date(agentInfo?.join_data).toLocaleTimeString()
                    }
                  />
                </>
              )}
            </div>
            <div>
              <CardHeader para="Statistics" title="Feedbacks" />
              <h3 className="rating-count">
                {review && review.average !== null
                  ? parseFloat(review?.average).toFixed(1)
                  : "0"}
              </h3>
              <div className="rating-stars">
                <Rating
                  size="large"
                  name="simple-controlled"
                  value={review && parseInt(review?.average)}
                  // onChange={(event, newValue) => {
                  //   setRating(newValue);
                  // }}
                  sx={{
                    fontSize: "2.8rem",
                    "& .MuiRating-icon": {
                      width: width,
                    },
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </div>
              {/* Rating Bar component starts here */}
              <div className="rating-progress-bar">
                {review && review?.rating?.length > 0
                  ? review?.rating?.map((x, index) => (
                      <div key={index} className="progress-bar-grid-row">
                        <div className="star-left-side">
                          <p style={{ flex: 0.5 }}>{x.agent_rating}</p>
                          <StarIcon style={{ color: "#F9CF3A" }} />
                        </div>
                        <div>
                          <ProgressBar
                            totalUsers={x.agent_rating}
                            fiveStarUsers={x.total}
                            bgcolor={
                              x?.agent_rating === 5
                                ? "#57E32C"
                                : x?.agent_rating === 4
                                ? "#B7DD29"
                                : x?.agent_rating === 3
                                ? "#FFE234"
                                : x?.agent_rating === 2
                                ? "#FFA534"
                                : x?.agent_rating === 1
                                ? "#FF4545"
                                : "#57E32C"
                            }
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {/* Rating Bar component ends here */}
            </div>
            <div>
              <CardHeader
                dataRange={handleDoughnutChart}
                para="Statistics"
                title="Tickets Status Wise"
                dropdown={false}
                showOldData={true}
                showNew={true}
                showDateRange={true}
                DoughnutGraph={DoughnutGraph}
                showBottom={true}
              />
              {loadingFilter ? (
                <PaginationLoader />
              ) : (
                <DoughnutChart data={doughnut && doughnut?.data} />
              )}
            </div>
          </div>
          {/* user details graph ratings  */}
          <h2 className="all-tickets-head">All Tickets</h2>
          <div className="table-wrapper-pagination">
            <div className="table-container">
              <div className="header-text-dropdown">
                <h3>Assigned Tickets</h3>
                <div className="select-dropdown-filters">
                  <Button onClick={downloadCSV} className="export-csv-cta">
                    {loadingCSV ? (
                      <TableLoader />
                    ) : (
                      <>
                        <img src={Download_Icon} alt="" />
                        &nbsp; Export CSV
                      </>
                    )}
                  </Button>
                  <Select
                    placeholder={
                      display_status ? display_status : "Select Status"
                    }
                    options={
                      Array.isArray(statusList) &&
                      statusList.map((option, index) => ({
                        value: option,
                        label: option,
                        id: option === "All" ? "" : index,
                      }))
                    }
                    value={display_status}
                    styles={customStyles}
                    isLoading={statusLoading}
                    onChange={handleStatus}
                  />
                  <Select
                    placeholder={
                      display_priority ? display_priority : "Select Priority"
                    }
                    options={
                      Array.isArray(priority_list_arr) &&
                      priority_list_arr?.length > 0
                        ? priority_list_arr?.map((option, index) => ({
                            value: option,
                            label: option,
                            index: option === "All" ? "" : index - 1,
                          }))
                        : []
                    }
                    value={display_priority}
                    styles={customStyles}
                    isLoading={priority_loading}
                    onChange={handlePriority}
                  />
                </div>
              </div>
              <DataTable
                header={header}
                data={agentTickets && agentTickets}
                changeCTA={true}
                items_per_page={10}
                current_page={page}
              />
              <div className="pagination-entries-row">
                <p>
                  Showing {first_item !== "" ? first_item : 1} To{" "}
                  {last_item !== ""
                    ? last_item
                    : ticketsBearer && ticketsBearer?.last_item}{" "}
                  of,{" "}
                  {total_entries && total_entries !== ""
                    ? total_entries
                    : ticketsBearer && ticketsBearer?.total}{" "}
                  entries
                </p>
                <div className="pagination-chevron-icon">
                  <Button
                    disabled={left_icon || page === 1 ? true : false}
                    className="icon-chevron"
                    onClick={handleDecrement}
                  >
                    <ChevronLeftIcon />
                  </Button>
                  <div className="view-more">{VisiblePages}</div>
                  <Button
                    disabled={
                      right_icon ||
                      (ticketsBearer && ticketsBearer?.total_page === page)
                        ? true
                        : right_icon || (total_page && total_page === page)
                        ? true
                        : false
                    }
                    className="icon-chevron"
                    onClick={handleIncrement}
                  >
                    <ChevronRightIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {renderModal()}
    </>
  );
};

export default AgentDashboard;
