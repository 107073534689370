import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import Select from "react-select";
import { customStylesSKU } from "../../../DummyArray/HiscravesData";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginationLoader from "../../Loader/PaginationLoader";
import {
  CreateNewTicketActions,
  getIssuesTypesActions,
  getOrderProductSKUActions,
} from "../../../Actions/CustomerActions/CustomerActions";
import { useAlert } from "react-alert";
import TableLoader from "../../Loader/TableLoader";
import api from "../../../axiosConfig";
import { BaseURL } from "../../../BaseURL";

const CreateTicket = (props) => {
  const alert = useAlert();
  const { customer_id, data_call, close } = props;
  const dispatch = useDispatch();
  // order id
  // const { customerOrder, loading } = useSelector(
  //   (state) => state.customerOrder
  // );
  const { product_sku_order, loading: productLoading } = useSelector(
    (state) => state.product_sku
  );

  // issues types
  const { issue_types_list, loading: issues_types_loading } = useSelector(
    (state) => state.issues_types
  );

  // create ticket state
  const { new_ticket_creation, loading: create_ticket_loading } = useSelector(
    (state) => state.new_ticket
  );

  const [ticket_id, setTicket_id] = useState("");

  const [order_id, setorder_id] = useState("");
  const [order_no, setOrder_no] = useState("");
  const [items, setitems] = useState([]);
  const [issue_type_id, setissue_type_id] = useState("");
  const [issue_type_name, setissue_type_name] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [label, setLabel] = useState("");

  const handleOrderId = (selected) => {
    setOrder_no(selected.value);
    setorder_id(selected.id);
    dispatch(getOrderProductSKUActions(selected.value));
  };

  const handleProductItems = (selected) => {
    setLabel(selected.label);
    setitems(selected);
  };

  console.log("It", items);

  const handleIssueType = (selected) => {
    setissue_type_id(selected.id);
    setissue_type_name(selected.value);
  };

  console.log("Customer Id: ", customer_id);

  // useEffect(() => {
  //   if (customer_id && customer_id !== null) {
  //     dispatch(getCustomerOrder(customer_id, 100000));
  //   }
  // }, [dispatch]);

  // let order_numbers_data = customerOrder && customerOrder?.data;

  const [order_data_list, setOrder_data_list] = useState([]);

  useEffect(() => {
    let type = "list";
    const fetchOrders = async () => {
      await api
        .get(
          `${BaseURL}/agent/customer-orders?customer_id=${customer_id}&type=${type}`
        )
        .then((response) => {
          setOrder_data_list(response && response?.data?.data);
          console.log("Response: ", response?.data?.data);
        })
        .catch((err) => {
          // console.log(err);
          alert.error(err.message);
        });
    };
    fetchOrders();
  }, [customer_id, alert]);

  // let order_id_arr = [];

  // order_numbers_data &&
  //   order_numbers_data?.forEach((i) => {
  //     if (!order_id_arr.includes(i.id)) {
  //       order_id_arr.push({ order_no: i.order_number, id: i.id });
  //     }
  //   });

  // console.log("Order Id Arr: ", product_sku_order);

  // call id number , type 0, comment

  useEffect(() => {
    dispatch(getIssuesTypesActions());
  }, [dispatch]);

  let issue_list_obj =
    issue_types_list &&
    issue_types_list?.data &&
    Object.entries(issue_types_list?.data).map(([id, value]) => ({
      id: parseInt(id),
      value,
    }));

  console.log("Issue_type_id:", issue_type_id);
  console.log("Call_data: ", data_call);
  console.log("User_id: ", data_call && data_call?.customer.id);

  const [ishit, setIshit] = useState(false);

  const handleCreateTickets = async () => {
    setIshit(true);
    console.log("User_id: ", data_call && data_call?.customer.id);
    console.log("Issue_type_id:", issue_type_id);
    console.log("Title: ", title);
    console.log("Comment: ", message);
    console.log("Call Id: ", data_call && data_call?.id);
    console.log("Order No: ", order_id);
    let items_list = await items.map((item) => item.value);
    console.log(items_list);
    if (
      data_call?.customer.id !== null &&
      data_call?.id !== null &&
      title !== "" &&
      message !== "" &&
      title?.length <= 100 &&
      message?.length <= 100
    ) {
      dispatch(
        CreateNewTicketActions(
          title,
          message,
          order_id,
          items_list,
          issue_type_id,
          data_call?.customer.id,
          data_call?.id
        )
      );
    } else if (title === "" || message?.length > 100 || title?.length > 100) {
      alert.error(
        `${
          title === ""
            ? "Please Enter Title"
            : message === ""
            ? "Please Enter Message"
            : message?.length > 100
            ? "Message Cannot Be greater than 100 Characters"
            : title.length > 100
            ? "Title Cannot Be greater than 100 Characters"
            : null
        }`
      );
    }
  };

  useEffect(() => {
    if (
      new_ticket_creation &&
      new_ticket_creation?.status === "error" &&
      ishit
    ) {
      alert.error(new_ticket_creation && new_ticket_creation?.message);
    } else if (
      new_ticket_creation &&
      new_ticket_creation?.status === "success" &&
      ishit
    ) {
      alert.success(new_ticket_creation && new_ticket_creation?.message);
      close();
    }
  }, [new_ticket_creation, alert, ishit, close]);

  useEffect(() => {
    if (new_ticket_creation && new_ticket_creation?.ticket !== null && ishit) {
      setTicket_id(new_ticket_creation && new_ticket_creation?.ticket?.id);
    }
  }, [new_ticket_creation, ishit]);

  console.log("Ticket Id: ", ticket_id);

  return (
    <>
      {issues_types_loading ? (
        <PaginationLoader />
      ) : (
        <>
          <HeadingTitle title="Create Tickets" closeModal={close} />
          <div className="input-refund">
            <div className="inputs">
              <label htmlFor="SelectReason">Select Order</label>
              <Select
                placeholder={`${order_no !== "" ? order_no : "Select Order"}`}
                options={
                  Array.isArray(order_data_list) &&
                  order_data_list.map((option) => ({
                    value: option.order_no,
                    label: option.order_no,
                    id: option.id,
                  }))
                }
                // isLoading={loading}
                className="select"
                id="SelectReason"
                value={order_id}
                styles={customStylesSKU}
                onChange={(selectedOption) => handleOrderId(selectedOption)}
              />
            </div>
            <>
              <div className="inputs">
                <label htmlFor="SelectSubReason">Select Product</label>
                <Select
                  isMulti={true}
                  placeholder={`${
                    label !== "" && label !== undefined
                      ? label
                      : "Select Product"
                  }`}
                  // options={
                  //   product_sku_order &&
                  //   product_sku_order?.data &&
                  //   product_sku_order &&
                  //   product_sku_order?.data?.order_items.map((option) => ({
                  //     value: option.product_id,
                  //     label: option?.product?.product_code,
                  //   }))
                  // }
                  options={(product_sku_order?.data?.order_items || []).map(
                    (option) => ({
                      value: option.product_id,
                      label: option?.product?.product_code,
                    })
                  )}
                  isLoading={productLoading}
                  className="select"
                  id="SelectSubReason"
                  value={label}
                  styles={customStylesSKU}
                  onChange={(selectedOption) =>
                    handleProductItems(selectedOption)
                  }
                />
              </div>
            </>
            <div className="inputs">
              <label htmlFor="RefundOption">Select Issue Type</label>
              <Select
                placeholder={`${
                  issue_type_name !== "" ? issue_type_name : "Select Issue Type"
                }`}
                options={
                  issue_list_obj &&
                  issue_list_obj.map((option) => ({
                    value: option.value,
                    label: option.value,
                    id: option.id,
                  }))
                }
                className="select"
                id="RefundOption"
                value={issue_type_name}
                styles={customStylesSKU}
                onChange={(selectedOption) => handleIssueType(selectedOption)}
              />
            </div>
            <div className="inputs-title">
              <label htmlFor="title">
                Title <span className="dot-red">*</span>
              </label>{" "}
              <br />
              <input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="inputs">
              <label htmlFor="message">
                Message <span className="dot-red">*</span>
              </label>{" "}
              <br />
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="btn-create-return">
            <Button onClick={handleCreateTickets} type="submit">
              {create_ticket_loading ? <TableLoader /> : "Create"}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CreateTicket;
