import React from "react";

const AgentsProfileCount = (props) => {
  return (
    <div key={props.index}>
      <p>{props.count}</p>
      <p>{props.title}</p>
    </div>
  );
};

export default AgentsProfileCount;
