import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createAccountDetailsUPIActions } from "../../../Actions/CustomerActions/CustomerActions";
import TableLoader from "../../Loader/TableLoader";
import { useAlert } from "react-alert";

const AddUPI = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { close, scloseModal, customer_id } = props;

  const { account_details, loading } = useSelector(
    (state) => state.accountDetails
  );

  const [upi_id, setUPI_id] = useState("");
  const [isHit, setIshit] = useState(false);
  const [isValidUpiId, setIsValidUpiId] = useState(false);

  const handleUpidId = (e) => {
    // const input = e.target.value;
    // const upiIdRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]+$/;
    const input = e.target.value;
    const upiIdRegex = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/;
    setUPI_id(input);
    setIsValidUpiId(upiIdRegex.test(input));
  };

  const handleAddUPI = () => {
    if (!isValidUpiId) {
      alert.error("Please Enter a valid UPI Id...");
    } else if (customer_id !== "" && customer_id !== null && upi_id !== "") {
      dispatch(
        createAccountDetailsUPIActions(customer_id, "UPI", upi_id, "create")
      );
      setIshit(true);
    } else if (upi_id === "") {
      return alert.error("Please Fill UPI Id...");
    }
    // scloseModal(null);
  };

  useEffect(() => {
    if (account_details && account_details?.status === "success" && isHit) {
      alert.success(account_details && account_details?.message);
      scloseModal(null);
      setIshit(false);
    } else if (
      account_details &&
      account_details?.status === "error" &&
      isHit
    ) {
      alert.error(account_details && account_details?.message);
      //   scloseModal(null);
      setIshit(false);
    }
  }, [account_details, isHit, alert, scloseModal]);

  console.log("Account Details: ", account_details);
  return (
    <>
      <HeadingTitle title="Add UPI" closeModal={close} />
      <div className="add-upi">
        <div>
          <label htmlFor="upi_id">Enter UPI ID</label> <br />
          <input
            type="text"
            id="upi_id"
            name="upi_id"
            value={upi_id}
            onChange={handleUpidId}
          />
        </div>
        <div className="create-upi-cta">
          <Button onClick={handleAddUPI}>
            {loading ? <TableLoader /> : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddUPI;
