import axios from "axios";
import jwtDecode from "jwt-decode";

const api = axios.create();

api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("user")).api_token;

  if (token) {
    try {
      const decodetoken = jwtDecode(token);
      const expirationTime = decodetoken.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (expirationTime < currentTime) {
        console.log("Token Expired");
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      localStorage.removeItem("user");
      console.log("Error Decoding JWT Token: ", error);
    }
  }
  return config;
});

export default api;
