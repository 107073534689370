import React from "react";
import "./userDetails.css";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const UserDetails = (props) => {
  const { userDetails } = props;

  console.log("Customer Details: ", userDetails);

  const getUserNameIcon = (str) => {
    if (!str) {
      return "";
    }

    const trimmedStr = str.trim();
    const firstLetter = trimmedStr.charAt(0);
    const nextSpaceIndex = trimmedStr.indexOf(" ");

    if (nextSpaceIndex === -1) {
      return firstLetter;
    }

    return firstLetter + trimmedStr.charAt(nextSpaceIndex + 1);
  };

  const userName = getUserNameIcon(userDetails?.CustomerName);

  return (
    <>
      <div className="user-img">
        {userDetails && userDetails.avatar !== null ? (
          <img src={userDetails.avatar} alt="" />
        ) : (
          <div className="agent-icon">
            <p>{userName}</p>
          </div>
        )}
      </div>
      <div className="user-details-grid-row">
        <div>
          <p className="title-name">User Id</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">{userDetails.UserID}</p>
        </div>
        {/* division */}
        <div>
          <p className="title-name">Customer Name</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">{userDetails.CustomerName}</p>
        </div>
        {/* division */}
        <div>
          <p className="title-name">Customer Email ID</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">{userDetails.CustomerEmailID}</p>
        </div>
        {/* division */}
        <div>
          <p className="title-name">Mobile Number</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">+91 {userDetails.MobileNumber}</p>
        </div>
        {/* division */}
        <div>
          <p className="title-name">Joining Date</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">{userDetails.JoiningDate}</p>
        </div>
        {/* division */}
        <div>
          <p className="title-name">Address</p>
        </div>
        <div>
          <p className="title-name">:</p>
        </div>
        <div>
          <p className="title-dnamic">{userDetails.Address}</p>
        </div>
      </div>
      <div className="edit-user-info">
        <Link to={`/edit-profile/${userDetails.UserID}`}>
          <Button>Edit</Button>
        </Link>
      </div>
    </>
  );
};

export default UserDetails;
