import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import CrossIcon from "../../Assets/SideBar-Icons/cross.png";
// import Tracking from "../ModalContent/Tracking/Tracking";
import Refund from "../ModalContent/Refund/Refund";
import CreateReturn from "../ModalContent/CreateReturn/CreateReturn";
import CancelOrder from "../ModalContent/CancelOrder/CancelOrder";
import ShareCoupans from "../ModalContent/ShareCoupans/ShareCoupans";
import TicketLogs from "../ModalContent/TicketLogs/TicketLogs";
import AssignedAgents from "../ModalContent/AssignedAgents/AssignedAgents";
import TicketDetails from "../ModalContent/TicketDetails/TicketDetails";
import CallDetails from "../ModalContent/CallDetails/CallDetails";
import CustomerTicketLogs from "../ModalContent/CustomerTicketLogs/CustomerTicketLogs";
import AddUPI from "../ModalContent/AddUPI/AddUPI";
import AddBank from "../ModalContent/AddBank/AddBank";
import CallPusher from "../ModalContent/Call/CallPusher";
import CreateTicket from "../ModalContent/CreateTicket/CreateTicket";
import GoodWillRefundModal from "../ModalContent/GoodWillRefundModal/GoodWillRefundModal";
import GoodWillHistory from "../ModalContent/GoodWillHistory/GoodWillHistory";
import CreateReplacement from "../ModalContent/CreateReplacement/CreateReplacement";

const ModalContainer = (props) => {
  const { index, order_id } = props;
  const [width, setWidth] = useState(400);
  console.log("CId: ", props.customer_id);
  const scloseModal = () => {
    props.onData(null);
  };

  console.log(index, "index");
  const style = {
    position: index === 1 || index === 13 ? "fixed" : "absolute",
    top: index === 1 ? 0 : index === 13 ? "initial" : "50%",
    bottom: index === 13 && 0,
    left: index !== 1 && index !== 13 ? "50%" : "initial",
    right: index === 1 ? 0 : index === 13 ? "10%" : "initial",
    transform: index === 1 || index === 13 ? null : "translate(-50%, -50%)",
    width: width,
    outline: 0,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: index === 1 || index === 13 ? 0 : 2,
    borderRadius: index === 1 || index === 13 ? 0 : 1,
    height:
      index === 1
        ? "100vh"
        : index === 7 ||
          index === 4 ||
          index === 12 ||
          index === 14 ||
          index === 16
        ? "600px"
        : index === 9
        ? "400px"
        : index === 6 || index === 17
        ? "550px"
        : "auto",
    overflowY:
      index === 7 ||
      index === 4 ||
      index === 12 ||
      index === 14 ||
      index === 16 ||
      index === 9 ||
      index === 6 ||
      index === 17
        ? "auto"
        : "none",
    borderTopRightRadius: index === 13 && "12px",
    borderTopLeftRadius: index === 13 && "12px",
  };

  const componentMap = {
    // 0: <Tracking order_id={props.order_id} close={props.closeModal} />,
    1: (
      <TicketLogs
        ticket_id={props.ticket_id}
        close={props.closeModal}
        scloseModal={scloseModal}
        customer_img={props.customer_img}
        customer_mobile_number={props.customer_mobile_number}
        customer_name={props.customer_name}
      />
    ),
    2: <Refund close={props.closeModal} />,
    4: (
      <CreateReturn
        order_id={order_id}
        comingCancel={props.comingCancel}
        close={props.closeModal}
        create_return_customer_details={props.create_return_customer_details}
        customer_id={props.customer_id}
        orderNo={props.orderNo}
        order_items={props.order_items}
        payment_mode={props.payment_mode}
        order_status={props.order_status}
      />
    ),
    5: (
      <CancelOrder
        order_id={order_id}
        scloseModal={scloseModal}
        close={props.closeModal}
        customer_id={props.customer_id}
        is_replacement_order={props.is_replacement_order}
        payment_mode={props.payment_mode}
        order_status={props.order_status}
      />
    ),
    6: (
      <ShareCoupans close={props.closeModal} customer_id={props.customer_id} />
    ),
    7: <AssignedAgents ticket_id={props.ticket_id} close={props.closeModal} />,
    8: <TicketDetails ticket_id={props.ticket_id} close={props.closeModal} />,
    9: <CallDetails ticket_id={props.ticket_id} close={props.closeModal} />,
    10: (
      <CustomerTicketLogs order_id={props.order_id} close={props.closeModal} />
    ),
    11: (
      <AddUPI
        scloseModal={scloseModal}
        customer_id={props.customer_id}
        close={props.closeModal}
      />
    ),
    12: (
      <AddBank
        scloseModal={scloseModal}
        customer_id={props.customer_id}
        close={props.closeModal}
      />
    ),
    13: (
      <CallPusher
        customer_id={props.customer_id}
        data_call={props.data_call}
        scloseModal={scloseModal}
      />
    ),
    14: (
      <CreateTicket
        customer_id={props.customer_id}
        data_call={props.data_call}
        close={props.closeModal}
      />
    ),
    15: (
      <GoodWillRefundModal
        message={props.message}
        close={props.closeModal}
        order_no={props.order_no}
        return_amount={props.return_amount}
        order_items_qty={props.order_items_qty}
        accounts={props.accounts}
        upi_id={props.upi_id}
        bank_name={props.bank_name}
        account_number={props.account_number}
        confirm_account_number={props.confirm_account_number}
        ifsc={props.ifsc}
        reason={props.reason}
      />
    ),
    16: (
      <GoodWillHistory
        customer_id={props.customer_id}
        order_id={props.order_id}
        close={props.closeModal}
        goodwillRefund={props.goodwillRefund}
        handleAnotherModal={props.handleAnotherModal}
      />
    ),
    17: (
      <CreateReplacement
        order_id={props.order_id}
        orderNo={props.orderNo}
        close={props.closeModal}
        order_items={props.order_items}
      />
    ),
  };

  useEffect(() => {
    if (index === 0 || index === 2 || index === 5 || index === 15) {
      setWidth(352);
    } else if (index === 4 || index === 6) {
      setWidth(400);
    } else if (index === 8 || index === 10) {
      setWidth(1120);
    }
  }, [index]);

  return (
    <Modal
      open={props.Isopen}
      onClose={props.closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      className={`modal-container ${
        index === 13 || (index === 1 && "mobile-res")
      }`}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{componentMap[index]}</Box>
    </Modal>
  );
};

export default ModalContainer;

export const HeadingTitle = (props) => {
  return (
    <div className="header-cross-title">
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {props.title}
      </Typography>
      <div className="bg-cross" onClick={props.closeModal}>
        <img src={CrossIcon} alt="" />
      </div>
    </div>
  );
};
