import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import PaginationLoader from "../../Loader/PaginationLoader";
import { useEffect } from "react";
import { getGoodwillRefundHistoryDataActions } from "../../../Actions/CustomerActions/CustomerActions";
import "./GoodWillHistory.css";
import NoDataFromApi from "../../NotFound/NoDataFromApi";

const GoodWillHistory = (props) => {
  const dispatch = useDispatch();
  const { good_will_refund_history, loading } = useSelector(
    (state) => state.historyofGoodwill
  );
  const { close, customer_id, order_id, goodwillRefund, handleAnotherModal } =
    props;

  console.log("Customer Id: ", customer_id);
  console.log("Order Id: ", order_id);

  const handleClose = () => {
    if (goodwillRefund) {
      handleAnotherModal(true);
    } else {
      close();
    }
  };

  useEffect(() => {
    if (customer_id !== null && order_id !== null) {
      dispatch(getGoodwillRefundHistoryDataActions(customer_id, order_id));
    }
  }, [dispatch, customer_id, order_id]);

  let history =
    good_will_refund_history && good_will_refund_history?.data?.length > 0
      ? good_will_refund_history?.data[0]
      : [];

  console.log("DATA: ", history);
  return (
    <>
      <HeadingTitle title="Goodwill History" closeModal={handleClose} />
      {loading ? (
        <PaginationLoader />
      ) : (
        <>
          {history?.length <= 0 ? (
            <NoDataFromApi
              height={true}
              width_new={true}
              title="No Data Found..."
            />
          ) : (
            <div className="history-container">
              <p className="order_no">Order No. {history.order_no}</p>
              <div className="hs-flex-container">
                {history &&
                  history?.goodwill_refund?.map(
                    (i) =>
                      // i?.skus?.map((j) => (
                      i?.skus?.length > 0 ? (
                        <div className="sku-list-col" key={i.id}>
                          <div className="sku-date">
                            <p>{i.skus.map((j) => j.sku).join(" / ")}</p>
                            <p>
                              {i.skus.map((j) => "QTY - " + j.qty).join(" / ")}
                            </p>
                            <p>
                              {new Date(history?.updated_at).toLocaleString()}
                            </p>
                          </div>
                          <div className="price-status">
                            <p>₹{i?.return_amount}</p>
                            <p>{i?.return_status}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="sku-list-col" key={i.id}>
                          <div className="sku-date">
                            <p>
                              {i?.skus.map((j) => (
                                <p>{j.sku}</p>
                              ))}
                            </p>
                            <p>
                              {i?.skus.map((j) => (
                                <p>{j.qty}</p>
                              ))}
                            </p>
                            <p>
                              {new Date(history?.created_at).toLocaleString()}
                            </p>
                          </div>
                          <div className="price-status">
                            <p>₹{i?.return_amount}</p>
                            <p>{i?.return_status}</p>
                          </div>
                        </div>
                      )
                    // ))
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GoodWillHistory;
