import React from "react";
import "./DynamicInputs.css";

const DynamicInputs = (props) => {
  const handleOnchange = (e) => {
    props.onChange(e);
  };
  return (
    <div
      className="dynamic-inputs"
      style={{ marginTop: props.top ? "8px" : "0px" }}
    >
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      {props.label && <br />}
      <input
        disabled={props.disabled ? true : false}
        type="text"
        name={props.id}
        id={props.id}
        value={props.value}
        onChange={(e) => handleOnchange(e)}
        placeholder={props.placeholder ? props.placeholder : ""}
      />
    </div>
  );
};

export default DynamicInputs;
