import {
  CALL_TO_CUSTOMER_FAILURE,
  CALL_TO_CUSTOMER_REQUEST,
  CALL_TO_CUSTOMER_SUCCESS,
  GET_AGENTS_DOUGHNUT_FAILURE,
  GET_AGENTS_DOUGHNUT_REQUEST,
  GET_AGENTS_DOUGHNUT_SUCCESS,
  GET_AGENT_BY_ID_FAILURE,
  GET_AGENT_BY_ID_REQUEST,
  GET_AGENT_BY_ID_SUCCESS,
  GET_AGENT_CALL_COUNT_FAILURE,
  GET_AGENT_CALL_COUNT_REQUEST,
  GET_AGENT_CALL_COUNT_SUCCESS,
  GET_AGENT_DOUGHUNUT_BY_ID_FAILURE,
  GET_AGENT_DOUGHUNUT_BY_ID_REQUEST,
  GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST,
  GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS,
  GET_AGENT_TICKETS_BY_ID_FAILURE,
  GET_AGENT_TICKETS_BY_ID_REQUEST,
  GET_AGENT_TICKETS_BY_ID_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST,
  GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS,
} from "../../Constants/AgentContants/AgentConstants";
import { BaseURL } from "../../BaseURL";
import api from "../../axiosConfig";

export const getAgentCountsInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_DATA_REQUEST,
    });

    const { data } = await api.get(`${BaseURL}/agent/agent-dashboard`);
    dispatch({
      type: GET_DASHBOARD_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getAgentsCountDoughnut =
  (from_date, to_date) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENTS_DOUGHNUT_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/agent-tickets-count?from_date=${from_date}&to_date=${to_date}`
      );
      dispatch({
        type: GET_AGENTS_DOUGHNUT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENTS_DOUGHNUT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgentsCountDoughnut_with_normal =
  (date) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENTS_DOUGHNUT_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/agent-tickets-count?date=${date}`
      );
      dispatch({
        type: GET_AGENTS_DOUGHNUT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENTS_DOUGHNUT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgentById = (agent_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AGENT_BY_ID_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/agent-dashboard?agent_id=${agent_id}`
    );
    dispatch({
      type: GET_AGENT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AGENT_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const getAgentDougnutById =
  (agent_id, from_date, to_date) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/agent-tickets-count?agent_id=${agent_id}&from_date=${from_date}&to_date=${to_date}`
      );
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgentDougnutById_with_normal =
  (agent_id, date) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/agent-tickets-count?agent_id=${agent_id}&date=${date}`
      );
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENT_DOUGHUNUT_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgentTicketsByIdAction =
  (agent_id, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENT_TICKETS_BY_ID_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/agent-tickets?agent_id=${agent_id}&per_page=${per_page}`
      );
      dispatch({
        type: GET_AGENT_TICKETS_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENT_TICKETS_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgentTicketsByBearerAction = (per_page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AGENT_TICKETS_BY_BEARER_TOKEN_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/agent-tickets?per_page=${per_page}`
    );
    dispatch({
      type: GET_AGENT_TICKETS_BY_BEARER_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AGENT_TICKETS_BY_BEARER_TOKEN_FAILURE,
      payload: error.message,
    });
  }
};

export const callToCustomerAction = (ticket_id) => async (dispatch) => {
  try {
    dispatch({
      type: CALL_TO_CUSTOMER_REQUEST,
    });

    const { data } = await api.get(
      `${BaseURL}/agent/call-to-customer?ticket_id=${ticket_id}`
    );
    dispatch({
      type: CALL_TO_CUSTOMER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CALL_TO_CUSTOMER_FAILURE,
      payload: error.message,
    });
  }
};

export const getUnrepliedticketConversationAction =
  (agent_id, page, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_UNREPLIED_TICKET_CONVERSATION_COUNT_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/un-replied-tickets?agent_id=${agent_id}&page=${page}&per_page=${per_page}`
      );
      dispatch({
        type: GET_UNREPLIED_TICKET_CONVERSATION_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_UNREPLIED_TICKET_CONVERSATION_COUNT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAgent_Call_countAction =
  (page, per_page) => async (dispatch) => {
    try {
      dispatch({
        type: GET_AGENT_CALL_COUNT_REQUEST,
      });

      const { data } = await api.get(
        `${BaseURL}/agent/get-agent-calls?page=${page}&per_page=${per_page}`
      );
      dispatch({
        type: GET_AGENT_CALL_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_AGENT_CALL_COUNT_FAILURE,
        payload: error.message,
      });
    }
  };
