import React, { useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { Button } from "@material-ui/core";
import { createAccountDetailsBankActions } from "../../../Actions/CustomerActions/CustomerActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import TableLoader from "../../Loader/TableLoader";

const AddBank = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { scloseModal, close, customer_id } = props;
  const [account_name, setAccount_name] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [confirm_account_number, setconfirm_account_number] = useState("");
  const [ifsc, setifsc] = useState("");
  const [bank_name, setbank_name] = useState("");

  const { account_details, loading } = useSelector(
    (state) => state.accountDetails
  );

  const [isHit, setIshit] = useState(false);

  const handleAddBank = async () => {
    if (
      customer_id !== "" &&
      customer_id !== null &&
      account_name !== "" &&
      account_number !== "" &&
      confirm_account_number !== "" &&
      ifsc !== "" &&
      bank_name !== ""
    ) {
      setIshit(true);
      await dispatch(
        createAccountDetailsBankActions(
          customer_id,
          "BankAccount",
          bank_name,
          account_number,
          confirm_account_number,
          ifsc,
          account_name,
          "create"
        )
      );
    } else if (bank_name === "") {
      return alert.error("Please Enter Bank Name...");
    } else if (
      account_name === ""
      // account_number === "" &&
      // confirm_account_number === "" &&
      // ifsc === "" &&
      // bank_name === ""
    ) {
      return alert.error("Please Enter Account Holder Name...");
    } else if (
      account_number === ""
      // confirm_account_number === "" &&
      // ifsc === "" &&
      // bank_name === ""
    ) {
      return alert.error("Please Enter Account Number...");
    } else if (
      confirm_account_number === ""
      // ifsc === "" &&
      // bank_name === ""
    ) {
      return alert.error("Please Enter Confirm Account Number...");
    } else if (
      ifsc === ""
      // bank_name === ""
    ) {
      return alert.error("Please Enter IFSC Code...");
    }
    // scloseModal(null);
  };

  useEffect(() => {
    if (account_details && account_details?.status === "success" && isHit) {
      alert.success(account_details && account_details?.message);
      scloseModal(null);
      setIshit(false);
    } else if (
      account_details &&
      account_details?.status === "error" &&
      isHit
    ) {
      alert.error(account_details && account_details?.message);
      //   scloseModal(null);
      setIshit(false);
    }
  }, [account_details, isHit, alert, scloseModal]);

  return (
    <>
      <HeadingTitle title="Add Bank Details" closeModal={close} />
      <div className="add-upi">
        <div>
          <label htmlFor="bank_name">Bank Name</label>{" "}
          <span className="dot-red">*</span> <br />
          <input
            type="text"
            id="bank_name"
            name="bank_name"
            value={bank_name}
            // onChange={(e) => setbank_name(e.target.value)}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[a-zA-Z0-9 ]*$/;

              if (regex.test(input)) {
                setbank_name(input);
              } else {
                alert.error("Special Character Not Allowed...");
              }
              // You might want to provide some form of visual feedback here for the user if the input is invalid
            }}
          />
        </div>
        <div>
          <label htmlFor="account_name">Account Holder Name</label>{" "}
          <span className="dot-red">*</span> <br />
          <input
            type="text"
            id="account_name"
            name="account_name"
            value={account_name}
            // onChange={(e) => setAccount_name(e.target.value)}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[a-zA-Z0-9 ]*$/;

              if (regex.test(input)) {
                setAccount_name(input);
              } else {
                alert.error("Special Character Not Allowed...");
              }
              // You might want to provide some form of visual feedback here for the user if the input is invalid
            }}
          />
        </div>
        <div>
          <label htmlFor="account_number">Account Number</label>{" "}
          <span className="dot-red">*</span> <br />
          <input
            type="text"
            id="account_number"
            name="account_number"
            value={account_number}
            // onChange={(e) => setaccount_number(e.target.value)}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[a-zA-Z0-9 ]*$/;

              if (regex.test(input)) {
                setaccount_number(input);
              } else {
                alert.error("Special Character Not Allowed...");
              }
              // You might want to provide some form of visual feedback here for the user if the input is invalid
            }}
          />
        </div>
        <div>
          <label htmlFor="confirm_account_number">
            Re-Enter Account Number
          </label>{" "}
          <span className="dot-red">*</span> <br />
          <input
            type="text"
            id="confirm_account_number"
            name="confirm_account_number"
            value={confirm_account_number}
            // onChange={(e) => setconfirm_account_number(e.target.value)}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[a-zA-Z0-9 ]*$/;

              if (regex.test(input)) {
                setconfirm_account_number(input);
              } else {
                alert.error("Special Character Not Allowed...");
              }
              // You might want to provide some form of visual feedback here for the user if the input is invalid
            }}
          />
        </div>
        <div>
          <label htmlFor="ifsc">IFSC Code</label>{" "}
          <span className="dot-red">*</span> <br />
          <input
            type="text"
            id="ifsc"
            name="ifsc"
            value={ifsc}
            // onChange={(e) => setifsc(e.target.value)}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[a-zA-Z0-9 ]*$/;

              if (regex.test(input)) {
                setifsc(input);
              } else {
                alert.error("Special Character Not Allowed...");
              }
              // You might want to provide some form of visual feedback here for the user if the input is invalid
            }}
          />
        </div>
        <div className="create-upi-cta">
          <Button onClick={handleAddBank}>
            {loading ? <TableLoader /> : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddBank;
