import React from "react";
import "./CallPusher.css";
import CrossIcon from "../../../Assets/User/Vector.png";
import { Button } from "@material-ui/core";
import { useState } from "react";
import ModalContainer from "../../Modal/Modal";
import { useAlert } from "react-alert";
import { AddRemarksAction } from "../../../Actions/TeamLeaderActions/TeamLeaderActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import TableLoader from "../../Loader/TableLoader";

const CallPusher = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { customer_id, ticket_id } = props;
  const { add_remarks, loading } = useSelector((state) => state.addRemarks);

  const [isIndex, setIsIndex] = useState(null);

  const closeModal = () => {
    props.scloseModal(null);
  };

  const createTicket = (e) => {
    e.preventDefault();
    handleModal(14);
  };

  const handleModal = (index) => {
    // alert.show(index);
    setIsIndex(index);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          customer_id={customer_id}
          data_call={props.data_call && props.data_call}
        />
      );
    }
    return null;
  };

  const [comment, setComment] = useState("");
  const [isHit, setIshit] = useState(false);

  const handleSaveComment = () => {
    setIshit(true);
    let type = 0;
    console.log(props?.data_call?.id);
    if (comment !== "" && props?.data_call?.id !== null) {
      dispatch(AddRemarksAction(type, comment, props.data_call?.id));
    } else {
      alert.error("Please Fill the Comment...");
    }
  };

  useEffect(() => {
    if (add_remarks && add_remarks?.status === "success" && isHit) {
      alert.success(add_remarks && add_remarks?.message);
    } else if (add_remarks && add_remarks?.status === "error" && isHit) {
      alert.error(add_remarks && add_remarks?.message);
    }
  }, [add_remarks, alert, isHit]);

  const [isMore, setIsMore] = useState(false);

  const handleToggleReadMore = () => {
    setIsMore(!isMore);
  };

  console.log(add_remarks, "sss");
  console.log("Ticket Id Call_pusher:", ticket_id);
  return (
    <>
      <div className="heading-cross-icon-call-details">
        <div>
          <p>Call Details</p>
        </div>
        <div>
          <div className="bg-white" onClick={closeModal}>
            <img src={CrossIcon} alt="" />
          </div>
        </div>
      </div>
      <div className="data-logs">
        <div>
          <p>Mobile No.</p>
        </div>
        <div>:</div>
        <div>
          <p>
            {props.data_call !== "" && props.data_call !== null
              ? props.data_call.call_from
              : null}
          </p>
        </div>
      </div>
      <div className="data-logs">
        <div>
          <p>Customer Name</p>
        </div>
        <div>:</div>
        <div>
          <p>
            {props.data_call !== "" && props.data_call !== null
              ? props?.data_call?.customer?.full_name
              : null}
          </p>
        </div>
      </div>
      <div className="data-logs hide-border-data">
        <div>
          <p>Call Status</p>
        </div>
        <div>:</div>
        <div>
          <p>
            {props.data_call !== "" && props.data_call !== null
              ? props?.data_call?.event_type
              : null}
          </p>
        </div>
      </div>
      <div className="data-logs hide-border-data">
        <div>
          <p>Selected Option</p>
        </div>
        <div>:</div>
        <div className="read-more-less">
          <p>
            {props.data_call !== "" && props.data_call !== null
              ? props?.data_call?.selectedOptions?.length > 18 && !isMore
                ? props?.data_call?.selectedOptions?.slice(0, 18) + "..."
                : isMore && props?.data_call?.selectedOptions
              : null}
          </p>
          <p>
            {!isMore ? (
              <span onClick={handleToggleReadMore}>Read More</span>
            ) : (
              <span onClick={handleToggleReadMore}>Read Less</span>
            )}
          </p>
        </div>
      </div>
      <div className="input-comment-box">
        <label htmlFor="comment">Comment</label> <br />
        <textarea
          name="comment"
          id="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          cols="10"
          rows="5"
        ></textarea>
        <div className="cta-comment-ticket">
          <Button onClick={handleSaveComment}>
            {loading ? <TableLoader /> : "Save Comment"}
          </Button>
          <Button type="submit" onClick={createTicket}>
            Create Ticket
          </Button>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default CallPusher;
